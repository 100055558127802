import React, { Component } from 'react';
import { globalPopupOpen } from 'common/Design';

//// 컴포넌트 사용처 : 청약 당첨예상 상세
class BuildRank extends Component {

  getCatption = (buildRank, constComp) => {
    let caption = '';
    if (buildRank === null) {
      caption = <p>
        <em>아쉽게도 {constComp}는 2022년</em> 기준 건설협회 시공능력평가순위 100위권내에 없어요.<br />
        해당순위는 2022년 대한 건설협회 시공능력평가순위 기준으로 산출되요.
                </p>
    } else {
      caption = <p>
        <em>{constComp}은 2022년</em>기준 국내 건설사 100위 안에 포함된 건실한 건설사로 확인되요.<br />
        해당순위는 2022년 대한 건설협회 시공능력평가순위 기준으로 산출되요.
                </p>
    }
    return caption;
  }

  render() {

    const { applyInfo, applyDtl, applyCalendar, exptScore, score, buildRank } = this.props.calcResult;

    const scoreType = (
      buildRank === null ? 'bad' : (
        (buildRank.rank >= 71) ? 'bad' :
          (buildRank.rank >= 41 && buildRank.rank < 71) ? 'normal' :
            (buildRank.rank >= 21 && buildRank.rank < 41) ? 'good' :
              (buildRank.rank < 21) ? 'best' : ''));

    return (
      <>
        <h3>
          <s>4.</s>아파트 건설사 순위
        <div className="q_mark bt">
            <div className="q_mark_inner">
              <p className="tit">아파트건설사 순위란?</p>
              <p className="txt">
                2022년 대한건설협회에서 발표하는 시공능력평가순위를 기준으로, 청약아파트의 건설사 순위를 안내해드려요.
            </p>
            </div>
          </div>
        </h3>
        <div className="sec" id="sec4">
          <h4>국내 건설사 <strong>시공능력평가 순위</strong></h4>
          <div className={`ty_graph ${scoreType}`}>
            <div>
              <div className="graph">
                <div className="dount_area">
                  <div className="circle_chart" data-percent={buildRank === null ? 100 : 101 - buildRank.rank} data-permax="100">
                    <div className="chart-box">
                      <svg>
                        <defs>
                          <linearGradient id={`dount_${scoreType}`} x1="0" y1="0" x2="1" y2="1">
                            <stop className="color_1" offset="0%" />
                            <stop className="color_2" offset="100%" />
                          </linearGradient>
                        </defs>
                        <circle stroke={`url(#dount_${scoreType})`} r="49%" cx="50%" cy="50%" className="circle_progress" stroke-width="15" stroke-linecap="round"></circle>
                        <circle className="marker" r="8" cx="50%" cy="125" style={{ 'filter': 'url(#shadow)' }}></circle>
                        <filter id="shadow">
                          <feDropShadow dx="0" dy="0" stdDeviation="2"
                            flood-color="#CDCDCD" />
                        </filter>
                      </svg>
                      <div className="per-txt rank"><s>{buildRank === null ? 100 : buildRank.rank}</s>위</div>
                    </div>
                  </div>
                </div>
                <div className="btn_inner">
                  <button onClick={() => globalPopupOpen('rank', 'pc')} type="button" className="btn_line">건설사 순위 전체보기</button>
                </div>
              </div>
            </div>
            <div>
              <div className="caption emoji ct">
                {
                  this.getCatption(buildRank, applyInfo.constComp)
                }
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default BuildRank;
