import React, { Component, Fragment } from 'react';
import $ from 'jquery';
import MapAptRcmdSrchPy from './MapAptRcmdSrchPy';
import MapAptRcmdSrchApr from './MapAptRcmdSrchApr';
import axios from 'axios';
import Moment from 'moment';
import { Doughnut, Bar, Line } from 'react-chartjs-2'
import { searchBjd, addRecentItem, getRecentItem, clearRecentItem, setRecentList } from 'common/Search';
import SearchResult from 'routes/common/SearchResult';
import RecentResult from 'routes/common/RecentResult';
import { orderBy } from 'lodash';
import { uiSearchVal, uiMoreView, uiAptHeaderCustom, globalPopupOpen, globalPopupClose, uiCalcSelect, uiRecommScroll } from 'common/Design';
import { addFavItem, getFavList, clearFavItem } from './MapAptFavUtil'

import HalfDonut from 'routes/chart/HalfDonut';
import MultiType from 'routes/chart/MultiType';
import RMateCandleStickType from 'routes/rMateChart/RMateCandleStickType';
import RMateHalfDonutType from 'routes/rMateChart/RMateHalfDonutType';
import RMateDonutType from 'routes/rMateChart/RMateDonutType';

import { findIndex } from 'common/ArrayUtil';
import { numberWithCommas, numberWithCounts, dateReplaceWithStag, numberReplaceWithStag } from 'common/StringUtil';
import _ from 'lodash';

//// 컴포넌트 사용처 : 아파트 추천
class MapAptRcmdSrch extends Component {

  constructor(props) {
    super(props);
    this.state = {
      aptRecommParam1: 'N', //주택소유여부
      aptRecommParam2Txt: '', //지역(화면에보여지는 텍스트)
      aptRecommParam2: '', //지역
      //aptRecommParam3 : 0, //평수
      //aptRecommParam4 : 0, //아파트연식

      aptRecommParam3Min: -1, //평수min
      aptRecommParam3Max: -1, //평수max

      aptRecommParam4Min: -1, //아파트연식min
      aptRecommParam4Max: -1, //아파트연식max

      aptRecommParam5: 0, //연소득액
      aptRecommParam6: 0, //현재보유자산
      aptRecommParam7: 0, //기존대출연상환액

      aptRecommLoanRateMap: {}, //대출지표맵(LTV등)

      aptRecommSearchList: [], // 주소검색결과리스트
      aptRecommResultList: [], // 추천결과리스트
      aptDtl: [],
      aptArea: [],
      aptAreaId: 0,

      searchResult: [],
      searchIndex: -1,
      recentResult: [],
      recentFocus: false,
      searchInfo: {},
      keyword: "",

      isRcmd: true,
      searchVisible: true,

      rcmdPayGraphList: [],
      graphList: [],
      onRenderGraph: false,

      yearPay: 0,
      dpsAmt: 0,
      assets: 0,
      realLoanAmount: 0,
    };
  }

  componentDidMount = () => {
    uiSearchVal.init();
    $('#house_X').click();
    this.setState({
      aptRecommParam1: 'N',
      aptRecommParam3Min: -1, //평수min
      aptRecommParam3Max: -1, //평수max
      aptRecommParam4Min: -1, //아파트연식min
      aptRecommParam4Max: -1, //아파트연식max
    });
  }

  componentDidUpdate = () => {
    if (localStorage.getItem('jwtToken') == undefined || localStorage.getItem('jwtToken') == null) {
      window.location.href = '/login';
      window.close();
    }
  }

  //aptRecommend
  onChangeAptRecommendParam1 = (e) => {
    this.setState((state) => {
      state.aptRecommParam1 = e.target.value;
      if (state.aptRecommParam1 !== '' && state.aptRecommParam2Txt !== '' && state.aptRecommParam2Txt.length > 5
        && !(state.aptRecommParam3Min === -1 && state.aptRecommParam3Max === -1)
        && !(state.aptRecommParam4Min === -1 && state.aptRecommParam4Max === -1)) {
        $('ul._ty1').addClass('on');
        $('span.choice').addClass('on');
        $('div.btn_list').addClass('hide');
      }
      else {
        $('ul._ty1').removeClass('on');
        $('span.choice').removeClass('on');
        $('div.btn_list').removeClass('hide');
      }
    });
  }
  onChangeAptRecommendParam2 = (e) => {
    this.setState((state) => {
      state.aptRecommParam2Txt = e.target.value;
      if (state.aptRecommParam1 !== '' && e.target.value !== '' && e.target.value.length > 5
        && !(state.aptRecommParam3Min === -1 && state.aptRecommParam3Max === -1)
        && !(state.aptRecommParam4Min === -1 && state.aptRecommParam4Max === -1)) {
        $('ul._ty1').addClass('on');
        $('span.choice').addClass('on');
        $('div.btn_list').addClass('hide');
      }
      else {
        $('ul._ty1').removeClass('on');
        $('span.choice').removeClass('on');
        $('div.btn_list').removeClass('hide');

        this.apt_income_inputRef.focus();
      }
    });
  }
  onChangeAptRecommendParam3 = (pyMin, pyMax) => {
    this.setState((state) => {
      state.aptRecommParam3Min = pyMin;
      state.aptRecommParam3Max = pyMax;
      if (state.aptRecommParam1 !== '' && state.aptRecommParam2Txt !== '' && state.aptRecommParam2Txt.length > 5
        && !(pyMin === -1 && pyMax === -1)
        && !(state.aptRecommParam4Min === -1 && state.aptRecommParam4Max === -1)) {
        $('ul._ty1').addClass('on');
        $('span.choice').addClass('on');
        $('div.btn_list').addClass('hide');
      }
      else {
        $('ul._ty1').removeClass('on');
        $('span.choice').removeClass('on');
        $('div.btn_list').removeClass('hide');

        this.apt_income_inputRef.focus();
      }
    });

  }
  onChangeAptRecommendParam4 = (aprMin, aprMax) => {
    this.setState((state) => {
      state.aptRecommParam4Min = aprMin;
      state.aptRecommParam4Max = aprMax;
      if (state.aptRecommParam1 !== '' && state.aptRecommParam2Txt !== '' && state.aptRecommParam2Txt.length > 5
        && !(state.aptRecommParam3Min === -1 && state.aptRecommParam3Max === -1)
        && !(aprMin === -1 && aprMax === -1)) {
        $('ul._ty1').addClass('on');
        $('span.choice').addClass('on');
        $('div.btn_list').addClass('hide');

        this.apt_income_inputRef.focus();
      }
      else {
        $('ul._ty1').removeClass('on');
        $('span.choice').removeClass('on');
        $('div.btn_list').removeClass('hide');
      }
    });
  }
  onChangeAptRecommendParam5 = (e) => {
    const max = e.target.getAttribute('maxLength');
    let value = e.target.value;

    if (value.length > max) value = value.slice(0, max);
    e.target.value = value.replace(/[^0-9]/g, '');

    this.setState({
      aptRecommParam5: value,
    });
  }
  onChangeAptRecommendParam6 = (e) => {
    const max = e.target.getAttribute('maxLength');
    let value = e.target.value;

    if (value.length > max) value = value.slice(0, max);
    e.target.value = value.replace(/[^0-9]/g, '');

    this.setState({
      aptRecommParam6: value,
    });
  }
  onChangeAptRecommendParam7 = (e) => {
    const max = e.target.getAttribute('maxLength');
    let value = e.target.value;

    if (value.length > max) value = value.slice(0, max);
    e.target.value = value.replace(/[^0-9]/g, '');

    this.setState({
      aptRecommParam7: value,
    });
  }

  handleChangeKeyword = _.debounce((keyword) => {

    this.setState({ keyword: keyword, aptRecommParam2Txt: keyword, aptRecommParam2: keyword });
    if (keyword) {
      searchBjd(keyword, (data) => {
        keyword && this.setState({ searchResult: data, recentResult: [], searchIndex: -1, recentFocus: false });
      })
    } else {
      let recentData = getRecentItem('bjdMap');
      this.setState({ searchResult: [], recentResult: recentData, searchIndex: -1, recentFocus: true });
    }

  }, 300);

  debounceChangeKeyword = e => {

    const { target: { value } } = e;
    this.setState({ keyword: value, searchVisible: true })
    this.handleChangeKeyword(value);
    this.onChangeAptRecommendParam2(e);
  }

  handleClearRecent = () => {

    clearRecentItem('bjdMap');
    this.setState({ recentResult: [] });
  }

  handleRemoveRecent = (searchId) => {
    let recentList = getRecentItem('bjdMap');
    if (recentList.some(f => f.searchId === searchId)) {
      recentList.splice(findIndex(recentList, f => f.searchId === searchId), 1);
    }
    setRecentList('bjdMap', recentList);
    this.setState({ recentResult: recentList });
  }

  handleSelectSearch = (search) => {
    addRecentItem('bjdMap', search);
    this.setState({ searchInfo: search, keyword: search.searchName, aptRecommParam2Txt: search.searchName, aptRecommParam2: search.searchId });
    this.props.onSearch(search);
    this.props.onPanTo({ lat: search.lat, lng: search.lng });

    if (this.state.aptRecommParam1 !== '' && search.searchId !== '' && search.searchName.length > 5
      && !(this.state.aptRecommParam3Min === -1 && this.state.aptRecommParam3Max === -1)
      && !(this.state.aptRecommParam4Min === -1 && this.state.aptRecommParam4Max === -1)) {
      $('ul._ty1').addClass('on');
      $('span.choice').addClass('on');
      $('div.btn_list').addClass('hide');
    }
    else {
      $('ul._ty1').removeClass('on');
      $('span.choice').removeClass('on');
      $('div.btn_list').removeClass('hide');

      this.apt_income_inputRef.focus();
    }
  }

  handleKeydownKeyword = (e) => {
    let keyCode = e.keyCode;
    if (!this.state.searchResult.length > 0) {
      return;
    }
    if (keyCode === 38) {     // uparrowKey
      this.setState({ searchIndex: this.state.searchIndex <= 0 ? 0 : this.state.searchIndex - 1 });
    } else if (keyCode === 40) {     // downarrowKey
      let maxIndex = this.state.searchResult.length - 1;
      this.setState({ searchIndex: this.state.searchIndex >= maxIndex ? maxIndex : this.state.searchIndex + 1 });
    } else if (keyCode === 13) {      // enterKey
      if (this.state.searchIndex === -1) {
        this.setState({ searchResult: [], searchIndex: -1, recentFocus: false, keyword: this.state.searchResult[0].searchName, aptRecommParam2Txt: this.state.searchResult[0].searchName, aptRecommParam2: this.state.searchResult[0].searchId, searchVisible: false });
        addRecentItem('bjdMap', this.state.searchResult[0]);
        this.handleSelectSearch(this.state.searchResult[0]);
        this.props.onPanTo({ lat: this.state.searchResult[0].lat, lng: this.state.searchResult[0].lng });

        if (this.state.aptRecommParam1 !== '' && this.state.searchResult[0].searchId !== '' && this.state.searchResult[0].searchName.length > 5
          && !(this.state.aptRecommParam3Min === -1 && this.state.aptRecommParam3Max === -1)
          && !(this.state.aptRecommParam4Min === -1 && this.state.aptRecommParam4Max === -1)) {
          $('ul._ty1').addClass('on');
          $('span.choice').addClass('on');
          $('div.btn_list').addClass('hide');
        }
        else {
          $('ul._ty1').removeClass('on');
          $('span.choice').removeClass('on');
          $('div.btn_list').removeClass('hide');
        }
      } else {
        let search = this.state.searchResult[this.state.searchIndex];
        this.setState({ searchResult: [], searchIndex: -1, recentFocus: false, keyword: search.searchName, aptRecommParam2Txt: search.searchName, aptRecommParam2: search.searchId, searchVisible: false });
        addRecentItem('bjdMap', search);
        this.handleSelectSearch(search);
        this.props.onPanTo({ lat: search.lat, lng: search.lng });
        //this.fetchResultList();

        if (this.state.aptRecommParam1 !== '' && search.searchId !== '' && search.searchName.length > 5
          && !(this.state.aptRecommParam3Min === -1 && this.state.aptRecommParam3Max === -1)
          && !(this.state.aptRecommParam4Min === -1 && this.state.aptRecommParam4Max === -1)) {
          $('ul._ty1').addClass('on');
          $('span.choice').addClass('on');
          $('div.btn_list').addClass('hide');
        }
        else {
          $('ul._ty1').removeClass('on');
          $('span.choice').removeClass('on');
          $('div.btn_list').removeClass('hide');
        }
      }

    }
  }

  openCreditAgreePop = () => {
    window.open('/apt/terms?type=10', 'apc', 'toolbar=no,scrollbars=yes,resizable=no,top=0,left=0,width=600,height=600');
  }

  fetchResultList = async () => {
    $(window).scrollTop(0);
    this.setState({
      aptRecommResultList: []
    });

    $('.panel_st1 .btn_basic').parents('.apt_wrap').removeClass('_2dep');
    $('.panel_st1 .btn_basic').parents('div[class^=panel_]').find('.btn_fold').removeClass('none');

    if (this.state.aptRecommParam1 === '' || this.state.aptRecommParam2 === ''
      || (this.state.aptRecommParam3Min === -1 && this.state.aptRecommParam3Max === -1)
      || (this.state.aptRecommParam4Min === -1 && this.state.aptRecommParam4Max === -1)
      || this.state.aptRecommParam5 === 0 || this.state.aptRecommParam6 === 0
      || this.state.aptRecommParam7 === 0) {
      alert("입력되지 않은 부분을 확인하여 주세요.");
      return;
    }

    if ($('#termsCreditAgree').prop('checked') === true) {

      const config = { headers: { 'Authorization': 'Bearer ' + localStorage.getItem('jwtToken') } };
      // AGREE.java 참고
      const agreePath = 'ETC_AGREE';
      const agreeName = 'ETCA_002_O';
  
      let param8 = `?agreePath=${agreePath}&agreeName=${agreeName}`;
      // 약관 동의 여부 저장
      const termsAgreeSave = await axios.get('apt/terms/agree/termsAgreeSave'+param8, config);

      if (termsAgreeSave) {
        switch (termsAgreeSave.data.code) {
          case 'E0000': {
            globalPopupClose('creditAgree', 'mw');

            break;
          }
          default : {
            alert(termsAgreeSave.data.message);
            
            return false;
          }
        }
      }

      const param1 = this.state.aptRecommParam1;
      const param2 = this.state.aptRecommParam2;
      const param3Min = this.state.aptRecommParam3Min;
      const param3Max = this.state.aptRecommParam3Max;
      const param4Min = this.state.aptRecommParam4Min;
      const param4Max = this.state.aptRecommParam4Max;
      const param5 = this.state.aptRecommParam5;
      const param6 = this.state.aptRecommParam6;
      const param7 = this.state.aptRecommParam7;
      
      const resultGetAptList = await axios.get(`apt/rcmd/aptList?&param1=${param1}&param2=${param2}&param3Min=${param3Min}&param3Max=${param3Max}&param4Min=${param4Min}&param4Max=${param4Max}&param5=${param5}&param6=${param6}&param7=${param7}`);
      if(resultGetAptList.data){
        if (resultGetAptList.data.response != null && resultGetAptList.data.response.aptList.length > 0) {
          $('.panel_st1 .btn_basic').parents('.apt_wrap').addClass('_2dep');
          $('.panel_st1 .btn_basic').parents('div[class^=panel_]').find('.btn_fold').addClass('none');
          this.props.onRcmd({ list: resultGetAptList.data.response.aptList, bjdName: param2 });

          this.setState({
            aptRecommResultList: resultGetAptList.data.response.aptList
          });

          uiRecommScroll.init();
        }
        else {
          alert('검색 결과가 없습니다.');
          return false;
        }
      }
    }
    else {
      alert('약관에 동의해 주세요.');
      return false;
    }
  }

  fetchAptDtl = (paramAptId) => {
    $(window).scrollTop(0);
    $('.apt_item').each(function () {
      $(this).removeClass('_active');
    });
    $('#apt_btn_' + paramAptId).parent().addClass('_active');
    $('.amenities .amenities_items').each(function () {
      $(this).removeClass('show');
    });
    this.setState({
      onRenderGraph: false,
    })

    const param1 = this.state.aptRecommParam1;
    const param3Min = this.state.aptRecommParam3Min;
    const param3Max = this.state.aptRecommParam3Max;
    const param5 = this.state.aptRecommParam5;
    const param6 = this.state.aptRecommParam6;
    const param7 = this.state.aptRecommParam7;
    axios
      .get(`apt/rcmd/aptDtl?aptId=${paramAptId}&param1=${param1}&param3Min=${param3Min}&param3Max=${param3Max}&param5=${param5}&param6=${param6}&param7=${param7}`)
      .then(res => {

        // $('.panel_st2').css('z-index','5');
        // $('.panel_st2').parent().find('.btn_fold').addClass('none');

        //  setTimeout( function() {
        //     $('.panel_st2').parent().addClass('_3dep');
        //  });

        this.props.onRcmdDtl({ aptDtl: res.data.response.aptDtl });
        this.fetchAptAreaDtl(res.data.response.aptDtl.aptId, res.data.response.aptDtl.aptAreaId, 'D', (res) => {
          const aptArea = res.data.response;
          $(".select_apt .btn_select").html(`<s>${aptArea.pubAreaPy}</s>평 (<s>${aptArea.pubArea}</s>㎡)`);
          this.setState({
            aptArea: aptArea,
            aptAreaId: aptArea.aptAreaId,
          });
        });

        this.depOpen();

        this.setState({
          aptDtl: res.data.response.aptDtl,
          graphList: res.data.response.aptDtl.graphList,
          onRenderGraph: true,
          yearPay: (this.state.aptRecommParam5 * 10000),
          assets: (this.state.aptRecommParam6 * 10000),
          dpsAmt: (res.data.response.aptDtl.latestDpsAmt * 10000),
          //realLoanAmount: (res.data.response.aptDtl.loanMap.ltvtype1.totalLoanAmount),
          realLoanAmount:
            ((res.data.response.aptDtl.latestDpsAmt * 10000) <= ((this.state.aptRecommParam6 * 10000) + res.data.response.aptDtl.loanMap.ltvtype1.totalLoanAmount) ?
              (((res.data.response.aptDtl.latestDpsAmt * 10000) - (this.state.aptRecommParam6 * 10000)) <= 0 ? 0 : ((res.data.response.aptDtl.latestDpsAmt * 10000) - (this.state.aptRecommParam6 * 10000))) :
              res.data.response.aptDtl.loanMap.ltvtype1.totalLoanAmount),
          rcmdPayGraphList: [
            { "field": "보유자산금액", "amt": (this.state.aptRecommParam6 * 10000) },
            {
              "field": "대출필요금액", "amt": ((res.data.response.aptDtl.latestDpsAmt * 10000) <= ((this.state.aptRecommParam6 * 10000) + res.data.response.aptDtl.loanMap.ltvtype1.totalLoanAmount) ?
                (((res.data.response.aptDtl.latestDpsAmt * 10000) - (this.state.aptRecommParam6 * 10000)) <= 0 ? 0 : ((res.data.response.aptDtl.latestDpsAmt * 10000) - (this.state.aptRecommParam6 * 10000))) :
                res.data.response.aptDtl.loanMap.ltvtype1.totalLoanAmount)
            }]
        });

        setTimeout(() => {
          $('div.sub_header > h3').attr('data-tit', '');
          $('div.sub_header > h3').text('');
          uiAptHeaderCustom.init();
          uiCalcSelect.init();
        }, 500);
      });
  }

  numberWithCommas = (x) => {
    if (x === null || x === undefined || x === NaN) return;
    else {
      return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    }
  }
  dateFormatMsg = (x) => {
    if (x === null || x === undefined || x === NaN) return '';
    else {
      return Moment(x).format('YYYY.MM.DD');
    }
  }
  dateFormatMsg2 = (x) => {
    if (x === null || x === undefined || x === NaN) return '';
    else {
      return Moment(x).format('YYYY.MM');
    }
  }
  mappingChartValue = (mapArr, objName) => mapArr.map(item => item[objName])
  fetchGraph = (e) => {
    let dataTab = e.target.parentNode.getAttribute('data-tab');
    this.setState({
      onRenderGraph: false,
    })
    axios
      .get(`apt/rcmd/aptGraph?aptAreaId=${this.state.aptAreaId}&graphTab=${dataTab}`)
      .then(res => {
        this.setState({
          graphList: res.data.response.graphList,
          onRenderGraph: true,
          // aptDtl: {
          //   ...this.state.aptDtl,
          //   graphList: res.data.response.graphList
          // }
        })
      });

    $('.tab_cont').addClass('current');
  }

  depOpen = function () {
    var _dep = $(".apt_wrap .btn_apt"),
      _depPrent = $(".apt_wrap .btn_apt").closest('.apt_wrap');

    if (_depPrent.hasClass('_2dep')) {
      _depPrent.find('.panel_st2').css('z-index', '5');
      _dep.parents('div[class^=panel_]').find('.btn_fold').addClass('none');
      setTimeout(function () {
        _depPrent.addClass('_3dep');
      });
    } else {
      _depPrent.addClass('_2dep');
      _dep.parents('div[class^=panel_]').find('.btn_fold').addClass('none');
    }
  }

  moreView = function (e) {
    $(e.target).parent().toggleClass('show');
    $(e.target).parent().find('li').eq(2).nextAll().toggleClass('hide');
  }

  fetchAptFavList = (favList) => {

    const param = favList.map(f => `aptId=${f.aptId}&aptAreaId=${f.aptAreaId}`).join('&');
    axios
      .get('/apt/map/getFavAptList?' + param)
      .then(res => {
        let resData = res.data;
        resData.forEach(v => {
          if (favList.some(x => x.aptId === v.aptId)) {
            const itemIdx = findIndex(favList, x => x.aptId === v.aptId);
            v.favTime = favList[itemIdx].favTime;
          }
        });
        this.setState({ favResult: orderBy(resData, ["favTime"], ["desc"]) });
      });
  }

  handleFavResult = () => {
    const favList = getFavList();
    if (!this.state.favDisplay) {
      this.fetchAptFavList(favList);
    }
    this.setState({ favDisplay: !this.state.favDisplay });
  }

  handleAddFav = () => {
    const favList = getFavList();
    this.fetchAptFavList(favList);
  }

  handleClearFav = (fav) => {
    clearFavItem(fav);
    const favList = getFavList();
    this.fetchAptFavList(favList);
  }

  handleFavCheck = (e) => {

    const { target: { checked } } = e;
    if (checked) {
      addFavItem({ aptId: this.state.aptDtl.aptId, aptAreaId: this.state.aptAreaId, favTime: new Date() });
    } else {
      clearFavItem({ aptId: this.state.aptDtl.aptId })
    }
    this.handleAddFav();
    this.forceUpdate();
  }

  handleFavListCheck = (e, paramAptId, paramAptAreaId) => {

    const { target: { checked } } = e;
    if (checked) {
      addFavItem({ aptId: paramAptId, aptAreaId: paramAptAreaId, favTime: new Date() });
    } else {
      clearFavItem({ aptId: paramAptId })
    }
    this.handleAddFav();
    this.forceUpdate();
  }

  handleSearch = ({ type, min, max, load }) => {
    switch (type) {
      case "PY": this.onChangeAptRecommendParam3(min, max); break;
      case "APR": this.onChangeAptRecommendParam4(min, max); break;
    }
  }

  handleClickSearch = (e) => {
    e && e.preventDefault();
    if (this.state.searchResult.length > 0) {
      this.handleSelectSearch(this.state.searchResult[0]);
    }
  }

  handleChangeArea = (aptAreaId) => {

    ////console.log(`${aptId}, ${aptAreaId}, ${trxType}`);

    this.fetchAptAreaDtl(this.state.aptDtl.aptId, aptAreaId, 'D', (res) => {
      this.setState({
        onRenderGraph: false,
      })
      const aptArea = res.data.response;
      $('ul.chart_tit').find('li').removeClass('current');
      $('ul.chart_tit').find('li').eq(0).addClass('current');
      $('.tab_cont').addClass('current');
      this.setState({
        aptArea: aptArea, aptAreaId: aptAreaId, trxDr: 'D', graphList: aptArea.graphDataList,
        onRenderGraph: true,
      });

    });

  }

  fetchAptAreaDtl = (aptId, aptAreaId, trxType, callback) => {
    axios
      .get(`/apt/map/aptAreaDtl?aptId=${aptId}&aptAreaId=${aptAreaId}&trxType=D`)
      .then(callback);

  }

  percenTageFix = (value) => {
    if (value >= 100) {
      return 100;
    }
    else if (value <= 0) {
      return 0;
    }
    else {
      return value;
    }
  }

  render() {
    const { searchResult, recentResult, recentFocus, searchIndex, keyword, searchVisible, aptArea } = this.state;
    const favorite = getFavList().some(v => v.aptId === this.state.aptDtl.aptId);

    return (
      <>
        <div className="panel_st1 _scroll on">
          <div className="header_wrap">
            <h2>아파트 추천</h2>
            <button type="button" className="btn_fold on"><i>접기</i></button>
          </div>
          <div className="content_list">
            <div className="recommend_area">
              <div className="q_mark_inner q_match_inr">
                <p className="tit">나의 연 소득액이란?</p>
                <p className="txt">
                  근로소득원천징수영수증 혹은 <br />
                  관할 세무서에서 발행한 <br />
                  소득금액증명원의  <br />
                  소득액 정보를 입력해주세요. <br />
                </p>
              </div>
              <div className="q_mark_inner q_match_inr">
                <p className="tit">현재 보유자산 이란?</p>
                <p className="txt">
                  아파트 구매시 사용할수 있는  <br />
                  보유현금(예/적금/주식 등)  <br />
                  정보를 입력해주세요. <br />
                </p>
              </div>
              <div className="q_mark_inner q_match_inr">
                <p className="tit">대출 월 상한액이란?</p>
                <p className="txt">
                  아파트 구매 예정자 명의 대출,사채 등  <br />
                  부채 총 금액을 입력해주세요. <br />
                  대상 대출:  <br />
                  주택담보대출, <br />
                  비주택담보대출, 신용대출,  <br />
                  전세보증금담보대출, <br />
                  예적금담보대출,  <br />
                  유가증권담보대출 <br />
                  제외 대출:<br />
                  서민금융상품, <br />
                  300만원 이하 소액신용대출,<br />
                  유가증권담보대출, <br />
                  전세자금대출,<br />
                  이주비중도금대출, <br />
                  주택연금(역모기지론),  <br />
                  보험약관대출, <br />
                  할부, <br />
                  리스 및 현금서비스, <br />
                  카드론 등은 계산에서 제외 <br />
                </p>
              </div>
              <div className="scroll_area mCustomScrollbar" data-mcs-theme="minimal">
                <div id="termsCheck" className="terms_list">
                  <ul className="all_list">
                    <li className="all">
                      <span className="checkbox i_all">
                        <input type="radio" name="house" id="house_O" value="Y" onChange={this.onChangeAptRecommendParam1} />
                        <label htmlFor="house_O"><i></i>주택소유 O</label>
                      </span>
                    </li>
                    <li className="all">
                      <span className="checkbox i_all">
                        <input type="radio" name="house" id="house_X" value="N" onChange={this.onChangeAptRecommendParam1} />
                        <label htmlFor="house_X"><i></i>주택소유 X</label>
                      </span>
                    </li>
                  </ul>
                </div>
                <div className="ip_wrap">
                  <ul className="form">
                    <li className="ip_search">
                      <div className="search_box focus">
                        <input type="text" autocomplete="off" id="search_box01" required
                          //onChange={this.handleChangeKeyword}
                          //onFocus={this.handleChangeKeyword}
                          onChange={this.debounceChangeKeyword}
                          onFocus={this.debounceChangeKeyword}
                          onKeyDown={this.handleKeydownKeyword}
                          value={keyword}
                        />
                        <label for="search_box01">지역을 검색해 주세요.</label>
                        {

                          recentFocus ?
                            <RecentResult
                              recentResult={recentResult}
                              onClearRecent={this.handleClearRecent}
                              onRemoveRecent={this.handleRemoveRecent}
                              onSelectRecent={(search) => this.handleSelectSearch(search)}
                              onChange={this.onChangeAptRecommendParam2}
                            ></RecentResult>
                            : <SearchResult
                              searchResult={searchResult}
                              searchKeyword={keyword}
                              onSelectSearch={(search) => this.handleSelectSearch(search)}
                              onKeyDown={this.handleKeydownKeyword}
                              searchIndex={searchIndex}
                              searchVisible={searchVisible}
                              onChange={this.onChangeAptRecommendParam2}></SearchResult>
                        }
                      </div>
                      <button type="button" className="btn_search"
                        onClick={this.handleClickSearch}><i>검색</i></button>
                    </li>
                  </ul>
                  <ul className="filter_area">
                    <MapAptRcmdSrchPy
                      onSearch={(searchFilter) => this.handleSearch(searchFilter)}
                      isRcmd={this.state.isRcmd}
                    ></MapAptRcmdSrchPy>
                    <MapAptRcmdSrchApr
                      onSearch={(searchFilter) => this.handleSearch(searchFilter)}
                      isRcmd={this.state.isRcmd}
                    ></MapAptRcmdSrchApr>
                  </ul>
                  <ul className="form _ty1">
                    <li>
                      <div className="ip_tit">
                        <span>나의 연 소득액</span>
                        <div className="q_mark q_match"></div>
                      </div>
                      <div className="ip_box">
                        <input type="text" pattern="[0-9]" id="apt_income" className="ip" name="apt_income" autocomplete="off" onChange={this.onChangeAptRecommendParam5} onFocus={this.onChangeAptRecommendParam5} ref = {(c) => {this.apt_income_inputRef = c;}} maxLength="8" required />
                        <label htmlFor="apt_income" className="lbl" style={{ "display": "inline" }}>연 소득액을 입력하세요.</label>
                        <span className="unit">만원</span>
                      </div>
                    </li>
                    <li>
                      <div className="ip_tit">
                        <span>현재 보유자산 (부채 제외)</span>
                        <div className="q_mark q_match"></div>
                      </div>
                      <div className="ip_box">
                        <input type="text" pattern="[0-9]" id="apt_property" className="ip" name="apt_property" autocomplete="off" onChange={this.onChangeAptRecommendParam6} maxLength="8" required />
                        <label htmlFor="apt_property" className="lbl" style={{ "display": "inline" }}>보유자산을 입력하세요.</label>
                        <span className="unit">만원</span>
                      </div>
                    </li>
                    <li>
                      <div className="ip_tit">
                        <span>대출 월 상한액 (대출 및 기타 부채) </span>
                        <div className="q_mark q_match"></div>
                      </div>
                      <div className="ip_box">
                        <input type="text" pattern="[0-9]" id="apt_loans" className="ip" name="apt_loans" autocomplete="off" onChange={this.onChangeAptRecommendParam7} maxLength="8" required />
                        <label htmlFor="apt_loans" className="lbl" style={{ "display": "inline" }}>대출 월 상한액을 입력하세요.</label>
                        <span className="unit">만원</span>
                      </div>
                    </li>
                  </ul>
                </div>
                <div className="btn_inner">
                  <button type="button" className="btn_basic blk" onClick={() => globalPopupOpen('creditAgree', 'mw')}>결과보기</button>
                </div>
                <p className="caption">
                  아파트청약케어는 고객님의 신용정보가 아닌,<br />
                  입력 정보만을 기준으로 결과를 산출해드립니다.
                            </p>

              </div>
            </div>
          </div>
        </div>
        <div className="panel_st2 _scroll">
          <div className="header_wrap">
            <h3 className="st_tit">추천결과</h3>
            <p className="list_num">{this.state.aptRecommResultList.length}<em>건</em></p>
            <button type="button" className="btn_close ty2" onClick={this.secondStepFold}><i>돌아가기</i></button>
          </div>
          <div className="content_list">
            <div className="list_area">
              <div className="scroll_area mCustomScrollbar" data-mcs-theme="minimal">
                <ul>
                  {
                    this.state.aptRecommResultList.length <= 0 ?
                      null
                      :
                      orderBy(this.state.aptRecommResultList, ["orderdDpsAmt"], ["desc"]).map((result, idx) => {
                        return (
                          <React.Fragment key={idx}>
                            <li className="apt_item">
                              <button type="button" className="btn_apt" id={"apt_btn_" + result.aptId} onClick={() => this.fetchAptDtl(result.aptId)}><i>아파트 리스트</i></button>
                              <div className="item_info">
                                <div className="check_form ck_star_w">
                                  <input type="checkbox" className="hide check-one" id={"star01-01_" + result.aptId}
                                    checked={getFavList().some(v => v.aptId === result.aptId)}
                                    onClick={(e) => this.handleFavListCheck(e, result.aptId, result.aptAreaId)} />
                                  <label htmlFor={"star01-01_" + result.aptId}></label>
                                </div>
                                <h4>{result.aptName}</h4>
                                <p className="price">{numberReplaceWithStag(numberWithCounts(result.dpsAmt * 10000))}</p>
                                <p>{result.pubAreaPy}평 (공급 {result.pubArea}㎡ / 전용 {result.prvArea}㎡)</p>
                                <p>{this.dateFormatMsg2(result.regYearMonth)} / {numberWithCommas(result.hhldCnt)}세대</p>
                              </div>
                            </li>
                          </React.Fragment>
                        )
                      })
                  }
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div className="panel_st3">
          <div className="apt_info_header">
            <div className="sub_header">
              <h3 data-tit></h3>
              <button type="button" className="btn_close"><i>닫기</i></button>
            </div>
            <div className="info_area">
              <div className="apt_name">
                <div className="tit">
                  <div className="check_form ck_star_w">
                    <input type="checkbox" className="hide check-one" id="star02"
                      checked={favorite}
                      onClick={this.handleFavCheck} />
                    <label for="star02"></label>
                  </div>
                  <h3>{this.state.aptDtl.aptName}</h3>
                </div>
                <p className="txt">{this.state.aptDtl.jibunAddr}</p>
              </div>
              <div className="keyword">
                <span><s>{this.state.aptDtl.regYear}</s>년</span>
                <span>{numberReplaceWithStag(numberWithCounts(this.state.aptDtl.dpsAmt * 10000))}</span>
                <span><s>{numberWithCommas(this.state.aptDtl.hhldCnt)}</s>세대</span>
                {
                  (this.state.aptDtl) &&
                    (this.state.aptDtl.loanRateCase == 1 || this.state.aptDtl.loanRateCase == 2) ?
                    <span>투기 과열</span>
                    :
                    (this.state.aptDtl.loanRateCase == 3 || this.state.aptDtl.loanRateCase == 4) ?
                      <span>조정 지역</span>
                      :
                      null
                }
              </div>
            </div>
          </div>
          <div className="content_list">
            <div className="aptinfo_area">
              <div className="scroll_area mCustomScrollbar" data-mcs-theme="minimal">
                <div className="info_list">
                  <div className="info_tit _on">
                    <p>아파트 추천 정보</p><i></i>
                  </div>
                  <div className="info_con apt_recommend" style={{ "display": "block" }}>
                    <table>
                      <caption>
                        <strong>아파트 추천 정보 part1</strong>
                        <p>거래일, 세금, 거래금액</p>
                      </caption>
                      <colgroup>
                        <col style={{ "width": "50px" }} />
                        <col style={{ "width": "100px" }} />
                      </colgroup>
                      <tbody>
                        <tr>
                          <th>최근 거래일</th>
                          <td className="_bold">{dateReplaceWithStag(this.state.aptDtl.latestTrxDate)}</td>
                        </tr>
                        <tr className="_ty1">
                          <th>최근 거래 금액</th>
                          <td className="_price_up">{numberReplaceWithStag(numberWithCounts(this.state.aptDtl.latestDpsAmt * 10000))}</td>
                        </tr>
                        <tr>
                          <th>예상 세금</th>
                          <td>
                            <a href="/realtyCalc" target="_blank">세금계산하기</a>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                    <div className="chart_loan">
                      <div className="label" style={{ "z-index": "1" }}>
                        <img src={process.env.REACT_APP_ENV_URL + "resources/images/chart_loan_label.png"} alt="임시 이미지" />
                      </div>
                      <div className="chart_info">
                        <div className="chart_area">
                          <div id="rMateDivHalfDonut" style={{ "width": "310px", "height": "130px" }}>
                            {this.state.onRenderGraph &&
                              <RMateHalfDonutType
                                parentDivId={'rMateDivHalfDonut'}
                                graphList={this.state.rcmdPayGraphList}
                                onRenderGraph={this.state.onRenderGraph}
                              ></RMateHalfDonutType>
                            }
                          </div>
                          <div className="total_price">
                            <span>{<><s>{numberWithCounts(this.state.dpsAmt)}</s></>}</span>
                            <p>최근 거래 금액</p>
                          </div>
                        </div>
                        <div className="chart_label">
                          <div className="percent">
                            <span style={{ "z-index": "1" }}>{<><s>{this.percenTageFix(parseInt(Math.round((this.state.assets / this.state.dpsAmt) * 100)))}%</s></>}</span>
                            <span style={{ "z-index": "1" }}>{<><s>{this.percenTageFix(parseInt(Math.round((this.state.realLoanAmount / this.state.dpsAmt) * 100)))}%</s></>}</span>
                          </div>
                          <div className="price">
                            <span style={{ "z-index": "1" }}>{<><s>{numberWithCounts(this.state.assets)}</s></>}</span>
                            <span style={{ "z-index": "1" }}>{<><s>{numberWithCounts(this.state.realLoanAmount)}</s></>}</span>
                          </div>
                        </div>
                      </div>
                    </div>
                    {/* {
                      this.state.aptDtl.loanMap &&
                      <HalfDonut
                        yearPay={(this.state.aptRecommParam5 * 10000)} //연소득
                        assets={(this.state.aptRecommParam6 * 10000)} //현재자산
                        totalAssets={(this.state.aptRecommParam5 * 10000 + this.state.aptRecommParam6 * 10000)} //총자산(연소득+현재자산))
                        dpsAmt={(this.state.aptDtl.latestDpsAmt * 10000)} //아파트가격
                        totalLoanAmount={(this.state.aptDtl.loanMap.ltvtype1.totalLoanAmount)} //총대출액(max)
                      ></HalfDonut>
                    } */}

                    <div class="recommend_info">
                      <ul class="detail_info">
                        <li class="item">
                          <strong class="th">연 소득액</strong>
                          <div class="td"><p>{numberWithCounts(this.state.aptRecommParam5 * 10000)}</p></div>
                        </li>
                        {
                          this.state.aptDtl.loanMap != null ?
                            this.state.aptDtl.loanMap && this.state.aptDtl.loanMap.ltvtype1.yearRepayment > 0 && this.state.aptDtl.loanMap.ltvtype1.monthlyRepayment > 0 &&
                            <>
                              <li class="item">
                                <strong class="th">상환액</strong>
                                <div class="td"><ul>
                                  <li><em>년 단위</em><span>{numberWithCounts(this.state.aptDtl.loanMap.ltvtype1.yearRepayment)}</span></li>
                                  <li><em>월 단위</em><span>{numberWithCounts(this.state.aptDtl.loanMap.ltvtype1.monthlyRepayment)}</span></li>
                                </ul>
                                </div>
                              </li>
                            </>
                            :
                            null
                        }
                        {
                          this.state.aptDtl.loanMap != null ?
                            this.state.aptDtl.loanMap && this.state.aptDtl.loanMap.ltvtype1.realDti != '-' &&
                            <>
                              <li class="item">
                                <strong class="th">연 대출액 (소득액 대비)</strong>
                                <div class="td"><p>{this.state.aptDtl.loanMap.ltvtype1.realDti} </p></div>
                              </li>
                            </>
                            :
                            null
                        }
                        {
                          this.state.aptDtl.loanMap != null ?
                            this.state.aptDtl.loanMap && this.state.aptDtl.loanMap.ltvtype1.totalLoanAmount > 0 &&
                            <>
                              <li class="item">
                                <strong class="th">LTV 기준 총 대출가능액</strong>
                                <div class="td"><p class="point">{numberWithCounts(this.state.aptDtl.loanMap.ltvtype1.totalLoanAmount)}</p></div>
                              </li>
                            </>
                            :
                            null
                        }
                      </ul>
                    </div>
                  </div>
                </div>
                <div className="info_list">
                  <div className="info_tit _on">
                    <p>최근 실거래가 매매 정보</p><i></i>
                  </div>
                  <div className="info_con" style={{ "display": "block" }}>
                    <table>
                      <caption>
                        <strong>아파트 거래가 목록</strong>
                        <p>최근 거래 금액부터 일자 등 거래 관련 목록</p>
                      </caption>
                      <colgroup>
                        <col style={{ "width": "50px" }} />
                        <col style={{ "width": "100px" }} />
                      </colgroup>
                      <tbody>
                        <tr>
                          <th class="_selcet_top">최근 거래 평형</th>
                          <td class="calc_form">
                            <div class="ip_wrap select_apt in">
                              <button class="btn_select"></button>
                              <div class="select_wrap mCustomScrollbar" data-mcs-theme="minimal">
                                <div class="con">
                                  <ul>
                                    {
                                      this.state.aptDtl.aptAreaList &&
                                      this.state.aptDtl.aptAreaList.map((result, idx) => {
                                        const liId = `select01_${idx}`
                                        return (
                                          <li id={liId} data-value={result.aptAreaId} onClick={() => this.handleChangeArea(result.aptAreaId)}>
                                            <span><s>{result.pubAreaPy}</s>평 (<s>{result.pubArea}</s>㎡)</span>
                                          </li>
                                        )
                                      })
                                    }
                                  </ul>
                                </div>
                              </div>
                            </div>
                          </td>
                        </tr>
                        {
                          aptArea &&
                          <>
                            {
                              aptArea.trxDate && aptArea.trxDate != '-' &&
                              <tr className="_ty2">
                                <th>최근 거래일</th>
                                <td>{dateReplaceWithStag(aptArea.trxDate)}</td>
                              </tr>
                            }
                            {
                              aptArea.dpsAmt && aptArea.dpsAmt != '-' &&
                              <tr>
                                <th>최근 거래 금액</th>
                                <td className={aptArea.prevDpsAmt === null ? null : (aptArea.dpsAmt * 10000) > (aptArea.prevDpsAmt * 10000) ? '_price_up' : '_price_down'}>{numberReplaceWithStag(numberWithCounts(aptArea.dpsAmt * 10000))}
                                  <span>{
                                    aptArea.prevDpsAmt === null ? null :
                                      (aptArea.dpsAmt * 10000) > (aptArea.prevDpsAmt * 10000) ?
                                        <>( {numberReplaceWithStag(numberWithCounts((aptArea.dpsAmt * 10000) - (aptArea.prevDpsAmt * 10000)))}<em> ▲ </em>)</> :
                                        (aptArea.dpsAmt * 10000) < (aptArea.prevDpsAmt * 10000) ?
                                          <>( {numberReplaceWithStag(numberWithCounts((aptArea.prevDpsAmt * 10000) - (aptArea.dpsAmt * 10000)))}<em> ▼ </em>)</> :
                                          null
                                  }</span></td>
                              </tr>
                            }
                          </>
                        }
                      </tbody>
                    </table>
                    <div className="chart_list">
                      <ul className="chart_tit">
                        <li data-tab="tab1" className="current"><a href="#" onClick={this.fetchGraph}>전체 기간</a></li>
                        <li data-tab="tab2"><a href="#" onClick={this.fetchGraph}>최근 1년</a></li>
                        <li data-tab="tab3"><a href="#" onClick={this.fetchGraph}>최근 3년</a></li>
                      </ul>
                      {/* <div className="tab_cont current">
                        {
                          this.state.aptDtl.graphList &&
                          <MultiType
                            graphList={this.state.aptDtl.graphList}
                          ></MultiType>
                        }
                      </div> */}
                      <div className="tab_cont current">
                        <div className="tab_inner" >
                          <div id="rMateDivAptRcmdDtl" style={{ "width": "310px", "height": "260px" }} className={!this.state.graphList || this.state.graphList.length <= 0 ? 'tab_inner dim' : ''}>
                            {this.state.onRenderGraph &&
                              <RMateCandleStickType
                                parentDivId={'rMateDivAptRcmdDtl'}
                                graphList={this.state.graphList}
                                onRenderGraph={this.state.onRenderGraph}
                              ></RMateCandleStickType>
                            }
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="info_list">
                  <div className="info_tit _on">
                    <p>단지정보</p><i></i>
                  </div>
                  <div className="info_con apt_info" style={{ "display": "block" }}>
                    <table>
                      <caption>
                        <strong>아파트단지 정보 리스트</strong>
                        <p>주소, 건축년도 및 아파트 정보 리스트</p>
                      </caption>
                      <colgroup>
                        <col style={{ "width": "50px" }} />
                        <col style={{ "width": "100px" }} />
                      </colgroup>
                      <tbody>
                        <tr>
                          <th>아파트명</th>
                          <td>{this.state.aptDtl.aptName}</td>
                        </tr>
                        <tr>
                          <th>주소</th>
                          <td>{this.state.aptDtl.jibunAddr}</td>
                        </tr>
                        <tr>
                          <th>건축년도</th>
                          <td>{this.state.aptDtl.regYear}년</td>
                        </tr>
                        <tr>
                          <th>세대수</th>
                          <td>{numberWithCommas(this.state.aptDtl.hhldCnt)}세대</td>
                        </tr>
                        <tr>
                          <th>층수</th>
                          <td>{this.state.aptDtl.minFlrCnt} ~ {this.state.aptDtl.maxFlrCnt}층</td>
                        </tr>
                        <tr>
                          <th>주차대수</th>
                          <td>총 {numberWithCommas(this.state.aptDtl.pkngCnt)}대</td>
                        </tr>
                        <tr>
                          <th>용적률</th>
                          <td>{this.state.aptDtl.vlRate}%</td>
                        </tr>
                        <tr>
                          <th>건폐율</th>
                          <td>{this.state.aptDtl.bcRate}%</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
                <div className="info_list">
                  <div className="info_tit _on">
                    <p>주변정보</p><i></i>
                  </div>
                  <div className="info_con apt_around" style={{ "display": "block" }}>
                    <ul className="amenities">
                      {
                        (this.state.aptDtl.stationList != null && this.state.aptDtl.stationList.length > 0) ?
                          this.state.aptDtl.stationList &&
                          <li className={this.state.aptDtl.stationList.length > 3 ? "amenities_items more" : "amenities_items"}>
                            <strong className="ico_subway">지하철역</strong>
                            <ul className="info">
                              {
                                orderBy(this.state.aptDtl.stationList, ["distance"], ["asc"]).map((result, idx) => {
                                  return (
                                    //idx < 3 && (    
                                    <li className={idx > 2 ? "hide" : ""}>
                                      <div className="name" onClick={() => this.props.onPanTo({ lat: result.lat, lng: result.lng })}><span>{result.locName}</span><s>({numberWithCommas(result.distance)}m)</s></div>
                                      <b className="time">도보 {parseInt(result.distance / 66, 10)} 분</b>
                                    </li>
                                    //)
                                  )
                                })
                              }
                            </ul>
                            {this.state.aptDtl.stationList.length > 3 && <i className="ico_more" onClick={this.moreView}></i>}
                          </li>
                          :
                          null
                      }
                      {
                        (this.state.aptDtl.schoolList != null && this.state.aptDtl.schoolList.length > 0) ?
                          this.state.aptDtl.schoolList &&
                          <li className={this.state.aptDtl.schoolList.length > 3 ? "amenities_items more" : "amenities_items"}>
                            <strong className="ico_school">학교</strong>
                            <ul className="info">
                              {
                                orderBy(this.state.aptDtl.schoolList, ["distance"], ["asc"]).map((result, idx) => {
                                  return (
                                    //idx < 3 && (
                                    <li className={idx > 2 ? "hide" : ""}>
                                      <div className="name" onClick={() => this.props.onPanTo({ lat: result.lat, lng: result.lng })}><span>{result.locName}</span><s>({numberWithCommas(result.distance)}m)</s></div>
                                      <b className="time">도보 {parseInt(result.distance / 66, 10)} 분</b>
                                    </li>
                                    //)
                                  )
                                })
                              }
                            </ul>
                            {this.state.aptDtl.schoolList.length > 3 && <i className="ico_more" onClick={this.moreView}></i>}
                          </li>
                          :
                          null
                      }
                      {
                        (this.state.aptDtl.departList != null && this.state.aptDtl.departList.length > 0) ?
                          this.state.aptDtl.departList &&
                          <li className={this.state.aptDtl.departList.length > 3 ? "amenities_items more" : "amenities_items"}>
                            <strong className="ico_mart">마트 / 백화점</strong>
                            <ul className="info">
                              {
                                orderBy(this.state.aptDtl.departList, ["distance"], ["asc"]).map((result, idx) => {
                                  return (
                                    //idx < 3 && (
                                    <li className={idx > 2 ? "hide" : ""}>
                                      <div className="name" onClick={() => this.props.onPanTo({ lat: result.lat, lng: result.lng })}><span>{result.locName}</span><s>({numberWithCommas(result.distance)}m)</s></div>
                                      <b className="time">도보 {parseInt(result.distance / 66, 10)} 분</b>
                                    </li>
                                    //)
                                  )
                                })
                              }
                            </ul>
                            {this.state.aptDtl.departList.length > 3 && <i className="ico_more" onClick={this.moreView}></i>}
                          </li>
                          :
                          null
                      }
                      {
                        (this.state.aptDtl.hospitalList != null && this.state.aptDtl.hospitalList.length > 0) ?
                          this.state.aptDtl.hospitalList &&
                          <li className={this.state.aptDtl.hospitalList.length > 3 ? "amenities_items more" : "amenities_items"}>
                            <strong className="ico_hospital">병원</strong>
                            <ul className="info">
                              {
                                orderBy(this.state.aptDtl.hospitalList, ["distance"], ["asc"]).map((result, idx) => {
                                  return (
                                    //idx < 3 && (
                                    <li className={idx > 2 ? "hide" : ""}>
                                      <div className="name" onClick={() => this.props.onPanTo({ lat: result.lat, lng: result.lng })}><span>{result.locName}</span><s>({numberWithCommas(result.distance)}m)</s></div>
                                      <b className="time">도보 {parseInt(result.distance / 66, 10)} 분</b>
                                    </li>
                                    //)
                                  )
                                })
                              }
                            </ul>
                            {this.state.aptDtl.hospitalList.length > 3 && <i className="ico_more" onClick={this.moreView}></i>}
                          </li>
                          :
                          null
                      }
                      {
                        (this.state.aptDtl.parkList != null && this.state.aptDtl.parkList.length > 0) ?
                          this.state.aptDtl.parkList &&
                          <li className={this.state.aptDtl.parkList.length > 3 ? "amenities_items more" : "amenities_items"}>
                            <strong className="ico_park">공원</strong>
                            <ul className="info">
                              {
                                orderBy(this.state.aptDtl.parkList, ["distance"], ["asc"]).map((result, idx) => {
                                  return (
                                    //idx < 3 && (
                                    <li className={idx > 2 ? "hide" : ""}>
                                      <div className="name" onClick={() => this.props.onPanTo({ lat: result.lat, lng: result.lng })}><span>{result.locName}</span><s>({numberWithCommas(result.distance)}m)</s></div>
                                      <b className="time">도보 {parseInt(result.distance / 66, 10)} 분</b>
                                    </li>
                                    //)
                                  )
                                })
                              }
                            </ul>
                            {this.state.aptDtl.parkList.length > 3 && <i className="ico_more" onClick={this.moreView}></i>}
                          </li>
                          :
                          null
                      }
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div id="mw-creditAgree" className="ly_cpinfo_pc ly_alert">
          <div className="wrap">
            <div className="content">
              <button type="button" onClick={() => globalPopupClose('creditAgree', 'mw')} className="close"><i>닫기</i></button>
              <strong className="tit">구매 가능 아파트 추천 기능<br /> 제공을 위해, 개인(신용)정보 수집 및<br /> 이용동의가 필요해요.</strong>
              <div className="terms_list">
                <ul className="all_list">
                  <li className="all">
                    <span className="checkbox i_all">
                      <input type="checkbox" name="termsCreditAgree" id="termsCreditAgree" value="" />
                      <label for="termsCreditAgree"><i></i></label>
                      <button type="button" onClick={() => this.openCreditAgreePop()} className="link">[선택] 개인(신용)정보 수집 및 이용 동의</button>
                    </span>
                  </li>
                </ul>
              </div>
            </div>
            <div className="btn_wrap">
              <button type="button" className="skip" onClick={this.fetchResultList}>구매 가능 아파트 보기</button>
            </div>
          </div>
        </div>
      </>
    );
  }
}
export default MapAptRcmdSrch;