import { findIndex } from 'common/ArrayUtil';

//// 컴포넌트 사용처 : 아파트 정보, 청약 정보 즐겨찾기
export const addFavItem = (addItem) => {

  if (!addItem) return;
  const lc = window.localStorage;
  const favList = getFavList();
  if (favList.some(f => f.aptId === addItem.aptId)) {
    favList.splice(findIndex(favList, f => f.aptId === addItem.aptId), 1);
  }
  favList.splice(favList.length, 0, addItem);
  lc.setItem('MapAptFav', JSON.stringify(favList));
}

export const addLcApplyFavItem = (addItem) => {

  if (!addItem) return;
  const lc = window.localStorage;
  const favList = getLcApplyFavList();
  if (favList.some(f => f.applyId === addItem.applyId)) {
    favList.splice(findIndex(favList, f => f.applyId === addItem.applyId), 1);
  }
  favList.splice(0, 0, addItem);
  lc.setItem('MapApplyFav', JSON.stringify(favList));
}

export const getFavList = () => {

  let lc = window.localStorage;
  let std = lc.getItem('MapAptFav') === null ? new Array() : JSON.parse(lc.getItem('MapAptFav'));
  return std;
}

export const getLcApplyFavList = () => {

  let lc = window.localStorage;
  let std = lc.getItem('MapApplyFav') === null ? new Array() : JSON.parse(lc.getItem('MapApplyFav'));
  return std;
}

export const clearFavItem = (deleteItem) => {

  if (!deleteItem) return;
  const lc = window.localStorage;
  const favList = getFavList();
  if (favList.some(f => f.aptId === deleteItem.aptId)) {
    favList.splice(findIndex(favList, f => f.aptId === deleteItem.aptId), 1);
  }
  lc.setItem('MapAptFav', JSON.stringify(favList));
}


export const clearLcApplyFavItem = (deleteItem) => {

  if (!deleteItem) return;
  const lc = window.localStorage;
  const favList = getLcApplyFavList();
  if (favList.some(f => f.applyId === deleteItem.applyId)) {
    favList.splice(findIndex(favList, f => f.applyId === deleteItem.applyId), 1);
  }
  lc.setItem('MapApplyFav', JSON.stringify(favList));
}
