import React, { Component } from 'react';
import $ from 'jquery';
import { addLsItem, getLsItem } from 'common/Search';

//// 컴포넌트 사용처 : 청약 정보
class MapApplySrchLocal extends Component {

  constructor(props) {
    super(props);
    this.state = {
      localList: [
        { name: "강원", visible: false, bjdCode: '4200000000' },
        { name: "경기", visible: false, bjdCode: '4100000000' },
        { name: "경남", visible: false, bjdCode: '4800000000' },
        { name: "경북", visible: false, bjdCode: '4700000000' },
        { name: "광주", visible: false, bjdCode: '2900000000' },
        { name: "대구", visible: false, bjdCode: '2700000000' },
        { name: "대전", visible: false, bjdCode: '3000000000' },
        { name: "부산", visible: false, bjdCode: '2600000000' },
        { name: "서울", visible: false, bjdCode: '1100000000' },
        { name: "세종", visible: false, bjdCode: '3611000000' },
        { name: "울산", visible: false, bjdCode: '3100000000' },
        { name: "인천", visible: false, bjdCode: '2300000000' },
        { name: "전남", visible: false, bjdCode: '4600000000' },
        { name: "전북", visible: false, bjdCode: '4500000000' },
        { name: "제주", visible: false, bjdCode: '4900000000' },
        { name: "충남", visible: false, bjdCode: '4400000000' },
        { name: "충북", visible: false, bjdCode: '4300000000' }
      ],

      selectedLocalTxtList: ["전체"],
    };
  }

  componentDidMount = () => {
    const current = this.state.localList.slice();
    let dataArr = new Array();

    current.filter(e => e.visible).forEach(v => dataArr.push(v.bjdCode));
    this.props.onLocals({ locals: dataArr });
    this.setState({ localList: current });

    if (this.props.loadFilter) {

      let applyLocalFilter = getLsItem('applyLocalFilter');
      if (applyLocalFilter && applyLocalFilter.filter) {

        const current = applyLocalFilter.filter.slice();
        let dataArr = new Array();

        current.filter(e => e.visible).forEach(v => dataArr.push(v.bjdCode));
        this.props.onLocals({ locals: dataArr });
        this.setState({ localList: applyLocalFilter.filter });
      }
    }

  }

  componentDidUpdate() {
    if (this.props.saveFilter) {
      addLsItem('applyLocalFilter', { filter: this.state.localList });
    }
  }

  selectLocal(idx) {
    const current = this.state.localList.slice();
    let dataArr = new Array();
    let selectedLocalTxtArr = new Array();

    if (current[idx].visible === false) {
      current[idx].visible = true;
    }
    else {
      current[idx].visible = false;
    }
    current.filter(e => e.visible).forEach(v => dataArr.push(v.bjdCode));
    if (current.filter(e => e.visible).length === current.length || current.filter(e => e.visible).length == 0) {
      selectedLocalTxtArr.push('전체');
    }
    else {
      current.filter(e => e.visible).forEach(v => selectedLocalTxtArr.push(v.name));
    }

    this.props.onLocals({ locals: dataArr });
    this.setState({ selectedLocalTxtList: selectedLocalTxtArr, localList: current });
  }

  render() {
    const { selectedLocalTxtList } = this.state;
    const current = this.state.localList.slice();
    const visibleList = current.filter(e => e.visible);
    return (
      <div className="filter_wrap">
        <div className="tit_wrap">
          <strong>지역</strong>
          <span className="choice">{
            selectedLocalTxtList.map((v, idx) =>
              (selectedLocalTxtList.length > 1 && (visibleList.length - 1) > idx) ?
                v + ', '
                :
                v
            )
          }</span>
        </div>
        <div className="filter_inner _line">
          {
            this.state.localList.map((v, idx) =>
              <button type="button"
                className={v.visible ? "_set" : ""}
                onClick={() => this.selectLocal(idx)}>{v.name}
              </button>
            )
          }
        </div>
      </div>
    );
  }
}
export default MapApplySrchLocal;