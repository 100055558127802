import React, { Component } from 'react';

import {dateFormatMsg, numberWithCommas, numberWithCounts, numberWithCountsDesc} from "../../../common/StringUtil";

//// 컴포넌트 사용처 : 청약 당첨예상 상세
class MarginPrice extends Component {

  render() {
    const { applyInfo, bjdInfo, findMarginPriceInfo } = this.props.calcResult;

    const findMarginPrice = findMarginPriceInfo[0] || [];

    const aptStatList = findMarginPrice.aptStatList || [];

    const marginPriceList = findMarginPrice.marginPriceList || [];

    const marginPricePy = (marginPriceList[0]) ? marginPriceList[0].marginPricePy : null;

    // const marginPricePy = (marginPriceList[0]) ? Math.round(Math.random() * (10000 + 10000) - 10000) : null;

    const key = (marginPricePy) ? ((marginPricePy >= 0) ? 'POSITIVE' : 'NEGATIVE') : 'UNKNOWN';

    const status = {
      NEGATIVE: { code: 'bad', priceDesc: '손실 금액' },
      POSITIVE: { code: 'good', priceDesc: '손익 금액' },
      UNKNOWN: { code: 'normal', priceDesc: '측정 불가' },
    };

    return (
      <>
        <h3>
          <s>2.</s>아파트 가격 적합성
        <div className="q_mark bt">
            <div className="q_mark_inner">
              <p className="tit">아파트 가격 적합성이란?</p>
              <p className="txt">가격 적합성은 2020년도 주택 청약 공고 및 주위 실거래 데이터를 기반으로 산정된 정보에요. <br />또한 부동산 흐름을 예측할 수 있는 자료를 제공하는 것으로 모든 책임은 투자자 분들께 있어요!</p>
            </div>
          </div>
        </h3>
        <div className="sec" id="sec12">
          <div className={ `ty_graph ${ status[key].code }` }>
            <div>
              <div className="graph">
                <div className="dount_area">
                  <div className="circle_chart full" data-percent="100" data-permax="100">
                    <div className="chart-box">
                      <svg>
                        <defs>
                          <linearGradient id={ `dount_${ status[key].code }` } x1="0" y1="0" x2="1" y2="1">
                            <stop className="color_1" offset="0%" />
                            <stop className="color_2" offset="100%" />
                          </linearGradient>
                        </defs>
                        <circle stroke={ `url(#dount_${ status[key].code })` } r="49%" cx="50%" cy="50%" className="circle_progress" stroke-width="15" stroke-linecap="round"></circle>
                      </svg>
                      <div className="per-point">
                        <span>{ status[key].priceDesc }</span>
                        <p>{ (key === 'UNKNOWN') ? '' : `${ numberWithCountsDesc(marginPricePy) }` }</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div>
              <div className="caption emoji">
                {
                  (() => {
                    switch (key) {
                      case 'NEGATIVE':  return <p>{ applyInfo.aptName }는 <em>주위 실거래가보다 가격이 다소 높게 측정되어 있어요.</em><br />신청하시기 전 주위 아파트 시세를 확인해보고 신청하시는 것을 추천드려요.</p>
                      case 'POSITIVE':  return <p>{ applyInfo.aptName }는 <em>당첨시 손익금을 확보할 수 있어요.</em><br />손익금을 확보할 수 있는 좋은 기회로 신청하시는 것을 추천드려요.</p>
                      default :         return <p>{ applyInfo.aptName }가 위치하는 { bjdInfo.bjdName } 지역은 <em>가격 적합성을 비교할 수 있는 아파트가 없어요.</em><br />주변 지역의 아파트 가격을 잘 확인해보고 신청하시는 것을 추천드려요.</p>
                    }
                  })()
                }
              </div>
              <table className="tb_apt ty02">
                <caption>
                  <strong>실거래 정보</strong>
                  <p>아파트, 분양권 실거래 정보와 1년대비 비교한 정보</p>
                </caption>
                <colgroup>
                  <col style={{ 'width': '20%' }} />
                  <col style={{ 'width': '26.6%' }} />
                  <col style={{ 'width': '26.6%' }} />
                  <col style={{ 'width': '26.6%' }} />
                </colgroup>
                <thead>
                  <tr>
                    <th colSpan={ 4 }>동일 지역 내 비교 아파트</th>
                    <td></td>
                    <td></td>
                    <td></td>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <th>아파트명</th>
                    <td>{ (aptStatList[0]) ? aptStatList[0].aptName : '-' }</td>
                    <td>{ (aptStatList[1]) ? aptStatList[1].aptName : '-' }</td>
                    <td>{ (aptStatList[2]) ? aptStatList[2].aptName : '-' }</td>
                  </tr>
                  <tr>
                    <th>전용 면적</th>
                    <td>{ (aptStatList[0]) ? `${ aptStatList[0].areaPy }평` : '-' }</td>
                    <td>{ (aptStatList[1]) ? `${ aptStatList[1].areaPy }평` : '-' }</td>
                    <td>{ (aptStatList[2]) ? `${ aptStatList[2].areaPy }평` : '-' }</td>
                  </tr>
                  <tr>
                    <th>사용 승인일</th>
                    <td>{ (aptStatList[0]) ? dateFormatMsg(aptStatList[0].useAprDay) : '-' }</td>
                    <td>{ (aptStatList[1]) ? dateFormatMsg(aptStatList[1].useAprDay) : '-' }</td>
                    <td>{ (aptStatList[2]) ? dateFormatMsg(aptStatList[2].useAprDay) : '-' }</td>
                  </tr>
                  <tr>
                    <th>최근 거래일</th>
                    <td>{ (aptStatList[0]) ? dateFormatMsg(aptStatList[0].trxDate) : '-' }</td>
                    <td>{ (aptStatList[1]) ? dateFormatMsg(aptStatList[1].trxDate) : '-' }</td>
                    <td>{ (aptStatList[2]) ? dateFormatMsg(aptStatList[2].trxDate) : '-' }</td>
                  </tr>
                  <tr>
                    <th>최근 거래가</th>
                    <td>{ (aptStatList[0]) ? `${ numberWithCountsDesc(aptStatList[0].amt) }` : '-' }</td>
                    <td>{ (aptStatList[1]) ? `${ numberWithCountsDesc(aptStatList[1].amt) }` : '-' }</td>
                    <td>{ (aptStatList[2]) ? `${ numberWithCountsDesc(aptStatList[2].amt) }` : '-' }</td>
                  </tr>
                  <tr>
                    <th>평단가</th>
                    <td>{ (aptStatList[0]) ? `${ numberWithCountsDesc(aptStatList[0].aptPricePy) }` : '-' }</td>
                    <td>{ (aptStatList[1]) ? `${ numberWithCountsDesc(aptStatList[1].aptPricePy) }` : '-' }</td>
                    <td>{ (aptStatList[2]) ? `${ numberWithCountsDesc(aptStatList[2].aptPricePy) }` : '-' }</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          <p className="noti">* 아파트청약케어는 부동산 흐름을 예측할 수 있는 참고자료를 제공하는 것이며, 최종 투자의 모든 책임은 투자자에게 있습니다.</p>
        </div>
      </>
    );
  }

}

export default MarginPrice;
