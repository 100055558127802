import React, { Component } from 'react';
import { uiMotion } from 'common/Design';
import $ from 'jquery';

//// 컴포넌트 사용처 : 가입소개
class JoinInfo extends Component {
  componentDidMount() {
    uiMotion.scroll();
  }

  registPop () {
    let href = "/apt/join?siteCode=AA&ctgCode=1";
    let w = 430;
    let h = 900;
    window.open(href, '_blank', 'toolbar=no,scrollbars=yes,resizable=no,top=0,left=0,width='+w+',height='+h+'');
  }

  render() {
    return (
      <div id="content" className="join_info_wrap">
        <div className="inner_wrap">
          <div className="con_tit_wrap">
            <h2 className="tit">아파트청약케어 프리미엄 가입</h2>
            <p className="sub_txt">아파트청약케어 프리미엄 회원만의 특별한 혜택을 만나보세요!</p>
          </div>
          <div className="con">
            <ul>
              <li className="item01 animate" id="sec1">
                <i className="bg"></i>
                <div className="txt_wrap">
                  <p className="tit">
                    <code>01</code>
                    <span>내 자산으로<br />구매가능한 아파트는 어디?</span>
                  </p>
                  <p className="txt">많은 아파트중에서 내가 <strong>보유한 자산</strong>으로 <strong>구매가능  아파트만<br />확인</strong> 할 순 없을까? <strong>보유자산 및 대출 가능 금액</strong>까지 포함하여<br />현실적으로 구매 가능한 아파트를 지금 확인해보세요.</p>
                </div>
              </li>
              <li className="item02 animate" id="sec2">
                <i className="bg"></i>
                <div className="txt_wrap">
                  <p className="tit">
                    <code>02</code>
                    <span>청약에 당첨 된다면<br />나는 얼마를 가지고 있어야 할까?</span>
                  </p>
                  <p className="txt">경쟁을 뚫고 청약에 당첨되었는데, 당장 <strong>계약금은 얼마</strong>를<br />내야할까? 입주 시 내가 대출이외에 내야하는 돈은 총 얼마지?<br /><strong>청약 당첨 후 필요한 금액들을 단계별</strong>로 확인하세요.</p>
                </div>
              </li>
              <li className="item03 animate" id="sec3">
                <i className="bg"></i>
                <div className="txt_wrap">
                  <p className="tit">
                    <code>03</code>
                    <span>청약 신청 시 당첨가능성은?</span>
                  </p>
                  <p className="txt">나는 지금 청약을 신청 할 수 있는 상황인가? <strong>어느 지역에 청약을<br />넣으면 당첨</strong> 될 수 있을까? 어떤 유형으로 신청해야 당첨 될 가능성이 높을까? <strong>청약 신청 전 청약 당첨 가능성</strong>을 먼저 계산하세요</p>
                </div>
              </li>
            </ul>
          </div>
          <div className="btn_wrap">
            {/*<a href='/join?siteCode=AA&ctgCode=1'><button type="button" className="btn">아파트청약케어 유료가입</button></a>*/}
            <a onClick={() => this.registPop()}><button type="button" className="btn">아파트청약케어 유료가입</button></a>
          </div>
        </div>
      </div>
    )
  }
}
export default JoinInfo;