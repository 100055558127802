import React, { Component } from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import { BrowserView, MobileView, isBrowser, isMobile } from "react-device-detect";

import Header from './routes/common/Header';
import Footer from './routes/common/Footer';
import JoinInfo from './routes/JoinInfo';
import Join from './routes/Join';
import Login from './routes/Login';
import Expire from './routes/Expire';
import Notice from './routes/Notice';
import Faq from './routes/Faq';
import ApplyCalc from './routes/applyCalc/ApplyCalc';
import ApplyPredict from './routes/applyPredict/ApplyPredict';
import RealtyCalc from './routes/RealtyCalc'
import RealtyNews from './routes/RealtyNews'
import RealtyPolicy from './routes/RealtyPolicy'
import MapApt from './routes/map/MapApt'
import MapAptRcmd from './routes/map/MapAptRcmd'
import MapApply from './routes/map/MapApply'
import MapApplyPay from './routes/map/MapApplyPay'
import NotFoundPage from './routes/error/NotFoundPage'
import InterestEvent from './routes/promotion/InterestEvent';
import axios from 'axios';
import { connect } from 'react-redux'
import store from './redux/store'
import { setToken } from './redux/reducer'
import 'react-app-polyfill/ie11';
import "whatwg-fetch";
import "abortcontroller-polyfill";
import "core-js/stable";
import { Redirect } from 'react-router-dom/cjs/react-router-dom';

class App extends Component {

  constructor(props) {
    super(props);
  }


  componentDidMount() {
    window.rMateChartH5License = process.env.REACT_APP_R_MATE_CHART_H5_LICENSE;
    // if (document.location.pathname.search('/apartRcmd' > -1)
    //   || document.location.pathname.search('/applyPredict' > -1)
    //   || document.location.pathname.search('/applyPay' > -1)) {\
    //   axios.get('apt/authorized/check');
    // }

    const exceptObj = [
      {
        code: 400,
        msg: 'Bad Request : 요청이 잘못되었습니다. 관리자에게 문의하세요.'
      },
      // {
      //   code: 404,
      //   msg: 'Page not found : 페이지를 찾을 수 없습니다. 관리자에게 문의하세요.'
      // },
      {
        code: 500,
        msg: 'Internal Server Error : 서버쪽 이상입니다. 관리자에게 문의하세요.'
      },
      // {
      //   code: 504,
      //   msg: 'Gateway Timeout : 서버쪽 이상입니다. 관리자에게 문의하세요.'
      // },
    ]

    axios.interceptors.request.use(
      function (config) {
        // console.log(document.location.pathname);

        // config.headers["Content-Type"] = "application/json; charset=utf-8";
        // if (document.location.pathname.search('/apartRcmd' > -1)
        //   || document.location.pathname.search('/applyPredict' > -1)
        //   || document.location.pathname.search('/applyPay' > -1)) {
        //   if (localStorage.getItem('jwtToken') != undefined && localStorage.getItem('jwtToken') != null) {
        //     config.headers["Authorization"] = 'Bearer ' + localStorage.getItem('jwtToken');
        //   }
        // }

        return config;
      },
      function (error) {
        console.log(error);
        return Promise.reject(error);
      }
    );

    axios.interceptors.response.use(
      function (response) {
        return response;
      },
      function (error) {
        // if (document.location.pathname.search('/apartRcmd' > -1)
        //   || document.location.pathname.search('/applyPredict' > -1)
        //   || document.location.pathname.search('/applyPay' > -1)) {
        //   if (error.response.status === 401 || error.response.status === 403) {
        //     window.location.href = '/login';
        //   }
        // }
        //else {
        if (typeof error.response.data !== 'undefined') {

          let errMsg;
          let errData = error.response.data;

          if (error.response.status === 401 || error.response.status === 403) {
          }
          else {

            if (errData !== null && errData.message !== null) {
              errMsg = errData.message;
              alert(errMsg);
            }
            else {
              exceptObj.filter((except) => {
                if (except.code === error.response.status) {
                  errMsg = except.msg;
                  alert(errMsg);
                }
              });
            }
          }
        }
        else {
          exceptObj.filter((except) => {
            if (except.code === error.response.status) {
              alert(except.msg);
            }
          });
        }
        //}
        return Promise.reject(error);
      }
    )
  }

  pathnameCheck = () => {
    if (document.location.pathname == '/login') {
      localStorage.removeItem('subA');
      localStorage.removeItem('token');
    }
  }

  mobileWebRedirectCheck = () => {
    if (isMobile) {
      // 모바일웹에서 접속시 모바일웹 홈페이지로 redirect
      window.location.href = '/apt/mw?siteCode=AA&ctgCode=1';
    }
  }

  catchPage = (src) => {
    const path = ((document.querySelector(`script[src="${src}"]`)) ? '/apartMap' : '/apply');

    return (<Redirect to={path} />);
  }

  render() {

    return (
      <Router>
        <header id="header" className="off">
          {
            this.mobileWebRedirectCheck(),
            this.pathnameCheck(),
            <Header />
          }
        </header>
        <Switch>
          <Route exact path="/" component={() => this.catchPage(`https://dapi.kakao.com/v2/maps/sdk.js?appkey=${process.env.REACT_APP_KAKAO_APPKEY}&libraries=services`)} />
          {/*<Route exact path="/" component={() => <Redirect to="/apartMap" />} />*/}
          <Route exact path="/apartMap" component={MapApt} />
          {/* [SOM.220513] 스타벅스 관심사 응답이벤트 팝업 ( 위치: 메인 위 ) */}
		      {/* <Route exact path="/InterestEvent" component={InterestEvent} />  */}
          {/* <Route exact path="/apartMap" render={(param1, param2, param3) => {
            console.log(param1);
            console.log(param2);
            console.log(param3);
            <MapApt />
          }}></Route> */}
          {/* //component={MapApt} 
            ///> */}
          <Route exact path="/apartRcmd" component={MapAptRcmd} />
          <Route exact path="/apply" component={MapApply} />
          <Route exact path="/applyCalc" component={ApplyCalc} />
          <Route exact path="/applyPredict" component={ApplyPredict} />
          <Route exact path="/applyPay" component={MapApplyPay} />
          <Route exact path="/realtyCalc" component={RealtyCalc} />
          <Route exact path="/realtyNews" component={RealtyNews} />
          <Route exact path="/realtyPolicy" component={RealtyPolicy} />
          <Route exact path="/notice" component={Notice} />
          <Route exact path="/faq" component={Faq} />
          <Route exact path="/expire" component={Expire} />
          <Route exact path="/login" component={Login} />
          <Route exact path="/joinInfo" component={JoinInfo} />
          <Route exact path="/join" component={Join} />
          {/* <Route exact path="/join?siteCode=AA&ctgCode=1" component={Join} /> */}
          <Route exact path="*" component={NotFoundPage} />
        </Switch>
        <footer id="footer" className={
          (document.location.pathname.search('/joinInfo') > -1 ||
            document.location.pathname.search('/join') > -1 ||
            document.location.pathname.search('/login') > -1 ||
            document.location.pathname.search('/expire') > -1 ||
            document.location.pathname.search('/notice') > -1 ||
            document.location.pathname.search('/faq') > -1 ||
            document.location.pathname.search('/realtyCalc') > -1 ||
            document.location.pathname.search('/realtyNews') > -1 ||
            document.location.pathname.search('/realtyPolicy') > -1 ||
            document.location.pathname.search('/applyCalc') > -1 ||
            document.location.pathname.search('/applyPredict') > -1) ? "" : "icon_footer"}>
          <Footer />
        </footer>
      </Router>
    );
  }
}
export default App;


// const mapStateToProps = (state) => ({
//   token: state.token
// })

// const mapDispatchToProps = (dispatch) => ({
//   setToken: token => dispatch(setToken(token))
// })

// export default connect(
//   mapStateToProps,
//   mapDispatchToProps
// )(App)