import React, { Component } from 'react';
import ApplyCalcRecent from './ApplyCalcRecent';
import Moment from 'moment';
import $ from 'jquery'
import axios from 'axios';
import { uiCalcSelect, uiInputFocus, asyncCounting, globalPopupOpen, globalPopupClose } from 'common/Design';

require('moment-timezone');

//// 컴포넌트 사용처 : 청약 가점계산기
class ApplyCalc extends Component {

  constructor(props) {
    super(props);
    this.state = {
      ownHouse: '',
      applyList: [],
      applyId: '',
      applyNotiDate: '',
      birthDate: '',
      marriage: '',
      marriageDate: '',
      ownHouseHist: '',
      ownHouseHistDate: '',
      family: '',
      myParents: 0,
      spouseParents: 0,
      children: 0,
      accountDate: '',

      step: 1,
      save: false
    }
    this.prevTotalScore = '00';
    this.totalScore = '00';

  }

  componentDidMount = () => {

    uiCalcSelect.init();

    $('.rdo_question input').change(function () {
      var _this = $(this),
        _next = _this.parents('.rdo_question').next('.rdo_answer');
      if ($(this).hasClass('ip_yes')) {
        _next.slideDown(300);
      } else {
        _next.slideUp(300);
      }
    });
    axios.get('/apt/apply/predict/applyWishList').then(res => {
      this.setState({ applyList: res.data.response });
    });
  }

  initializeData = () => {
    window.location.reload();
  }

  gotoApplyPredict = (e) => {
    e.preventDefault();
    if (this.state.save === true) {
      axios.get('apt/authorized/check',
        { headers: { 'Authorization': 'Bearer ' + localStorage.getItem('jwtToken') }, timeout: 1000 }).then(res => {
          if (res.data.response.isValid) {
            window.open(`/applyPredict?applyId=${this.state.applyId}`, '_blank');
          }
          else {
            window.location.href = '/login';
          }
        }).catch(err => {
          if (localStorage.getItem('isAutoLogin') == 'true') {
            // 자동로그인 적용시 사용
            if (err.response.status === 401 || err.response.status === 403) {
              axios.post('apt/authorized/reIssue',
                { 'ref': localStorage.getItem('ref') },
                { headers: { 'Authorization': 'Bearer ' + localStorage.getItem('jwtToken') } }).then(res => {
                  if (res.status === 200) {
                    localStorage.setItem('jwtToken', res.headers.authorization);
                    window.open(`/applyPredict?applyId=${this.state.applyId}`, '_blank');
                  }
                }).catch(err => {
                  window.location.href = '/login';
                });
            }
            else {
              window.location.href = '/login';
            }
          }
          else {
            window.location.href = '/login';
          }
        });
    }
    else {
      alert('가점을 저장해 주세요.');
      return false;
    }
  }

  selectApplyInfo = ({ applyId, notiDate }) => {
    this.setState({ applyId: applyId, applyNotiDate: notiDate });
  }

  decreaseStep = () => {
    this.setState({ step: this.state.step - 1, save: false });
  }

  increaseStep = () => {
    if (this.popupWording()) { //popupWording 타고 다음스텝가도록 추가
      this.setState({ step: this.state.step + 1 });
    }
  }

  openCreditAgreePop = () => {
    window.open('/apt/terms?type=9', 'apc', 'toolbar=no,scrollbars=yes,resizable=no,top=0,left=0,width=600,height=600');
  }

  // [가점 저장 후 청약 당첨 가능성 확인] 버튼
  beforeScoreSaved = () => {
    axios.get('apt/authorized/check',
      { headers: { 'Authorization': 'Bearer ' + localStorage.getItem('jwtToken') }, timeout: 1000 }).then(res => {
        if (res.data.response.isValid) {
          globalPopupOpen('creditAgree', 'mw')
        }
        else {
          alert('가점을 저장하기 위해서는 로그인이 필요해요.');
          window.location.href = '/login';
        }
      }).catch(err => {
        if (localStorage.getItem('isAutoLogin') == 'true') {
          // 자동로그인 적용시 사용
          if (err.response.status === 401 || err.response.status === 403) {
            axios.post('apt/authorized/reIssue',
              { 'ref': localStorage.getItem('ref') },
              { headers: { 'Authorization': 'Bearer ' + localStorage.getItem('jwtToken') } }).then(res => {
                if (res.status === 200) {
                  localStorage.setItem('jwtToken', res.headers.authorization);
                  globalPopupOpen('creditAgree', 'mw')
                }
              }).catch(err => {
                alert('가점을 저장하기 위해서는 로그인이 필요해요.');
                window.location.href = '/login';
              });
          }
          else {
            alert('가점을 저장하기 위해서는 로그인이 필요해요.');
            window.location.href = '/login';
          }
        }
        else {
          alert('가점을 저장하기 위해서는 로그인이 필요해요.');
          window.location.href = '/login';
        }
      });
  }

  // [나의 청약가점 계산 결과 저장] 버튼
  scoreSaved = async (houseScore, familyScore, accountScore, totalScore, e) => {
    const config = { headers: { 'Authorization': 'Bearer ' + localStorage.getItem('jwtToken') } };
    // AGREE.java 참고
    const agreePath = 'ETC_AGREE';
    const agreeName = 'ETCA_001_O';

    if ($('#termsCreditAgree').prop('checked') === true) {
      let param2 = `?agreePath=${agreePath}&agreeName=${agreeName}`;
      // 약관 동의 여부 저장
      const termsAgreeSave = await axios.get('apt/terms/agree/termsAgreeSave'+param2, config);

      if (termsAgreeSave) {
        switch (termsAgreeSave.data.code) {
          case 'E0000': {
            globalPopupClose('creditAgree', 'mw');

            break;
          }
          default : {
            alert(termsAgreeSave.data.message);
            
            return false;
          }
        }
      }

      let param = `?houseScore=${Number(houseScore)}&familyScore=${Number(familyScore)}&accountScore=${Number(accountScore)}&totalScore=${Number(totalScore)}`;

      // 나의 청약 가점 저장
      const saveApplyMyScore = await axios.get('apt/apply/applyCalc/saveApplyMyScore'+param, config);

      if (saveApplyMyScore.data) {
        switch (saveApplyMyScore.data.code) {
          case 'E0000': {
            this.setState((state) => { return { save: true } });
            
            alert('청약가점이 저장되었습니다.');
            
            this.gotoApplyPredict(e);   

            break;
          }
          default : {
            alert(saveApplyMyScore.data.message);
            
            return false;
          }
        }
      }

    } else {
      alert('약관에 동의해 주세요.');
      return false;
    }
  }

  setOwnHouse = (e) => {
    let isOwnHouse = e.target.value;
    if (isOwnHouse == '1') {//주택여부 있음일시 무주택기간 input hide
      $('ul.form').eq(0).children().eq(6).slideUp(300);
      $('ul.form').eq(0).children().eq(5).hide();
    } else {
      $('ul.form').eq(0).children().eq(5).show();
      if (this.state.ownHouseHist == '1') {
        $('ul.form').eq(0).children().eq(6).slideDown(300);
      }
      else {
        $('ul.form').eq(0).children().eq(6).slideUp(300);
      }
    }
    this.setState({ ownHouse: e.target.value });
  }

  setBirthDate = (e) => {

    let birthDate = e.target.value;
    const regex = new RegExp(/^[0-9]*$/);
    birthDate = birthDate.replace(/\./g, '');

    if (birthDate === '' || regex.test(birthDate)) {
      this.setState({ birthDate: birthDate });
    }
  }

  checkBirthDate = () => {
    const re = new RegExp(/^(19|20)[0-9]{2}(0[1-9]|1[0-2])(0[1-9]|[1-2][0-9]|3[0-1])$/);
    if (this.state.birthDate && !re.test(this.state.birthDate)) {
      alert('생년월일을 정확히 입력하세요.');
      this.setState({ birthDate: '' }, uiInputFocus.init);
    }
  }

  setMarriage = (e) => {

    if (e.target.value === '1') {
      this.setState({ marriage: e.target.value });
    } else {
      this.setState({ marriage: e.target.value, marriageDate: '' }, uiInputFocus.init);
    }
  }

  setMarriageDate = (e) => {
    let marriageDate = e.target.value;
    const regex = new RegExp(/^[0-9]*$/);
    marriageDate = marriageDate.replace(/\./g, '');
    if (marriageDate === '' || regex.test(marriageDate)) {
      this.setState({ marriageDate: marriageDate });
    }
  }

  checkMarriageDate = () => {
    const re = new RegExp(/^(19|20)[0-9]{2}(0[1-9]|1[0-2])(0[1-9]|[1-2][0-9]|3[0-1])$/);
    if (this.state.marriageDate && !re.test(this.state.marriageDate)) {
      alert('혼인신고일을 정확히 입력하세요.');
      this.setState({ marriageDate: '' }, uiInputFocus.init);
    }
  }

  setOwnHouseHist = (e) => {
    if (e.target.value === '1') {
      this.setState({ ownHouseHist: e.target.value });
    } else {
      this.setState({ ownHouseHist: e.target.value, ownHouseHistDate: '' }, uiInputFocus.init);
    }
  }

  setOwnHouseHistDate = (e) => {
    let ownHouseHistDate = e.target.value;
    const regex = new RegExp(/^[0-9]*$/);
    ownHouseHistDate = ownHouseHistDate.replace(/\./g, '');
    if (ownHouseHistDate === '' || regex.test(ownHouseHistDate)) {
      this.setState({ ownHouseHistDate: ownHouseHistDate });
    }
  }

  checkOwnHouseHistDate = () => {
    const re = new RegExp(/^(19|20)[0-9]{2}(0[1-9]|1[0-2])(0[1-9]|[1-2][0-9]|3[0-1])$/);
    if (this.state.ownHouseHistDate && !re.test(this.state.ownHouseHistDate)) {
      alert('무주택자가 된 날을 정확히 입력하세요.');
      this.setState({ ownHouseHistDate: '' }, uiInputFocus.init);
    }
  }

  setFamily = (e) => {
    this.setState({ family: e.target.value, myParents: 0, spouseParents: 0, children: 0 }, () => {
      uiCalcSelect.init();
    });
  }

  setMyParents = (value) => {
    this.setState({ myParents: value });
  }

  setSpouseParents = (value) => {
    this.setState({ spouseParents: value });
  }

  setChildren = (value) => {
    this.setState({ children: value });
  }

  setAccountDate = (e) => {
    let accountDate = e.target.value;
    const regex = new RegExp(/^[0-9]*$/);
    accountDate = accountDate.replace(/\./g, '');
    if (accountDate === '' || regex.test(accountDate)) {
      this.setState({ accountDate: accountDate });
    }
  }

  checkAccountDate = () => {
    const re = new RegExp(/^(19|20)[0-9]{2}(0[1-9]|1[0-2])(0[1-9]|[1-2][0-9]|3[0-1])$/);
    if (this.state.accountDate && !re.test(this.state.accountDate)) {
      alert('청약통장 가입일을 정확히 입력하세요.');
      this.setState({ accountDate: '' }, uiInputFocus.init);
    }
  }

  applyAge = (calcDate) => {
    const noti = this.state.applyNotiDate;

    if (!noti || noti.length !== 8) return 0;
    if (!calcDate || calcDate.length !== 8) return 0;

    const sYear = calcDate.substring(0, 4);
    const sMonth = calcDate.substring(4, 6);
    const sDate = calcDate.substring(6, 8);
    const calcDay = new Date(Number(sYear), Number(sMonth) - 1, Number(sDate));
    //const today = new Date();

    const sYearNoti = noti.substring(0, 4);
    const sMonthNoti = noti.substring(4, 6);
    const sDateNoti = noti.substring(6, 8);
    const notiDay = new Date(Number(sYearNoti), Number(sMonthNoti) - 1, Number(sDateNoti));

    let age = notiDay.getFullYear() - calcDay.getFullYear();
    calcDay.setFullYear(notiDay.getFullYear());

    if (notiDay < calcDay) {
      age--;
    }

    return age + 1;
  }

  lpadScore = (score) => {
    return parseInt(score) < 10 ? ('0' + score).toString() : score.toString();
  }


  componentDidUpdate = () => {
    //let perPrevScore = this.prevTotalScore;
    //let perTotalScore = this.totalScore;

    if (this.totalScore > 0) {
      //perPrevScore = perPrevScore > 10 ? ('0' + perPrevScore) : perPrevScore;
      //perTotalScore = perTotalScore > 10 ? ('0' + perTotalScore) : perTotalScore;

      //asyncCounting('point', .3, this.prevTotalScore, this.totalScore);
      asyncCounting('point', .3, this.lpadScore(this.prevTotalScore), this.lpadScore(this.totalScore));
    }
    this.prevTotalScore = this.lpadScore(this.totalScore);
  }

  calcFamilyScore = () => {

    const { marriage, marriageDate, family, myParents, spouseParents, children } = this.state;
    const me = (family === '0' || family === '1') ? 1 : 0;
    const spouse = (marriage === '1' && marriageDate != '') ? 1 : 0;
    const familyTotal = (me + spouse + myParents + spouseParents + children) * 5;
    return familyTotal > 35 ? 35 : familyTotal;
  }

  calcHouseScore = () => {

    let houseScore = 0;

    if (this.state.ownHouse === '1') {
      return 0;

    } else if (this.state.ownHouse === '0') {

      const age = this.applyAge(this.state.birthDate);
      let marriageScore = 0;

      if (this.state.marriage === '0') {
        if (age < 30) {
          return 0;
        } else {
          marriageScore = (age - 30) * 2;
        }
      } else if (this.state.marriage === '1') {
        marriageScore = (this.applyAge(this.state.marriageDate)) * 2;
        marriageScore = ((age - 30) * 2) > marriageScore ? ((age - 30) * 2) : marriageScore;
      }

      if (this.state.ownHouseHist === '1') {
        const ownHouseHistScore = (this.applyAge(this.state.ownHouseHistDate)) * 2;
        houseScore = marriageScore > ownHouseHistScore ? ownHouseHistScore : marriageScore;
      } else if (this.state.ownHouseHist === '0') {
        houseScore = marriageScore;
      }
    }
    return houseScore < 0 ? 0 : houseScore > 32 ? 32 : houseScore;
  }

  calcAccountScore = () => {

    const noti = this.state.applyNotiDate;
    let accountScore = 0;

    if (!noti || noti.length !== 8) return 0;
    if (!this.state.accountDate || this.state.accountDate.length !== 8) return 0;
    if (!this.state.birthDate || this.state.birthDate.length !== 8) return 0;

    const age = this.applyAge(this.state.birthDate);
    const baseScore = 1;

    const notiDate = new Date(Number(noti.substring(0, 4)), Number(noti.substring(4, 6)) - 1, Number(noti.substring(6, 8)));
    const accountDate = new Date(Number(this.state.accountDate.substring(0, 4)), Number(this.state.accountDate.substring(4, 6)) - 1, Number(this.state.accountDate.substring(6, 8)));
    const birthDate = new Date(Number(this.state.birthDate.substring(0, 4)), Number(this.state.birthDate.substring(4, 6)) - 1, Number(this.state.birthDate.substring(6, 8)));
    if (notiDate.getTime() < birthDate.getTime() || accountDate.getTime() < birthDate.getTime() || notiDate.getTime() < accountDate.getTime()) return 0;

    ////////////////////////////////////////////////////////////////////////////////
    // [SOM.230405] 청약홈 > 가점계산기 기준에 맞게 통장점수 계산 방식 변경
    // 1. (청약공고일 - 통장가입일) 날짜 차이 구하기
    let diffDate1 = notiDate - accountDate;           // 청약공고일 - 통장가입일

    let currDay = 24 * 60 * 60 * 1000;                // 시 * 분 * 초 * 밀리세컨
    let currMonth = currDay * 30;                     // 월 만듦
    let currYear = currMonth * 12;                    // 년 만듦
    
    let yearDf = parseInt( diffDate1 / currYear );    // 년수 차이 
    let monthDf = parseInt( diffDate1 / currMonth );  // 월수 차이 
    //let dayDf = parseInt( diffDate1 / currDay );     // 일수 차이 

    // console.log("-------------------------");
    // console.log("* 나이 : " + age + "<br/>");
    // console.log("* 청약공고일 / 통장가입일 : " + notiDate + " / " + accountDate + "<br/>");
    // console.log("* 년수 차이(yearDf) : " + yearDf + " 년<br/><br/>");
    // console.log("* 월수 차이(monthDf) : " + monthDf + " 월<br/>");
    // //console.log("* 일수 차이 : " + dayDf + " 일<br/>");
    // console.log("-------------------------");
    ////////////////////////////////////////////////////////////////////////////////

    ////////////////////////////////////////////////////////////////////////////////
    
    // 2. 가점 계산 
    // 18세 이상 성인
    if (age > 18) {
      
      // 통장가입일이 2년 미만
      if (yearDf < 2) {
        // 통장가입일이 1년 이상
        if (yearDf === 1) { 
          accountScore = 2 + baseScore;   // 3점
          //console.log("ApplyCalc > accountScore (1) : "+accountScore);
        
        // 통장가입일이 1년 미만 (0년)
        } else {
          // 통장가입일이 6개월 미만
          if (yearDf === 0 && monthDf < 6) { 
            accountScore = baseScore;     // 1점
            //console.log("ApplyCalc > accountScore (2) : "+accountScore);
          
          // 통장가입일이 6개월 이상
          } else {
            accountScore = 1 + baseScore; // 2점
            //console.log("ApplyCalc > accountScore (3) : "+accountScore);
          }
        }

      // 통장가입일이 2년 이상 
      } else {
        // 성년기준일
        const adDate = new Date(Number(this.state.birthDate.substring(0, 4)) + 18, Number(this.state.birthDate.substring(4, 6)) - 1, Number(this.state.birthDate.substring(6, 8)));

        // 미성년자때 통장개설한 경우
        if (adDate.getTime() > accountDate.getTime()) {

          // 1. 두 날짜 차이 구하기
          let diffDate2 = adDate - accountDate;               // 성년기준일 - 통장가입일
          let yearDiff = parseInt( diffDate2 / currYear );    // 년수 차이 
          let monthDiff = parseInt( diffDate2 / currMonth );  // 월수 차이 

          let diffDate3 = notiDate - adDate;                  // 청약공고일 - 성년기준일
          let yearDiff2 = parseInt( diffDate3 / currYear );   // 년수 차이 
          
          let partScore = 0;
          let partScore2 = yearDiff2;

          // console.log("-------------------------");
          // console.log("* 청약공고일 / 통장가입일 / 성년기준일 : " + notiDate + " / " + accountDate + " / " + adDate + "<br/>");
          // console.log("* 년수 차이 (yearDiff) : " + yearDiff + " 년<br/><br/>");
          // console.log("* 월수 차이 (monthDiff) : " + monthDiff + " 월<br/>");
          // console.log("* 년수 차이 (yearDiff2) : " + yearDiff2 + " 년<br/>");
          // console.log("-------------------------");

          // 미성년으로서 가입한 기간은 2년까지만 인정
          if (yearDiff > 2) {
            partScore = 2;
            //console.log("ApplyCalc >> partScore (1) : "+partScore);
          
          // 미성년으로 가입한 기간이 2년 미만일 때
          } else {
            // 통장가입일이 6개월 미만
            if (yearDiff === 0 && monthDiff < 6) {
              partScore = baseScore;      // 1점
              //console.log("ApplyCalc >> partScore (2) : "+partScore);
            
            // 통장가입일이 6개월 이상
            } else {
              partScore = 1 + baseScore;  // 2점
              //console.log("ApplyCalc >> partScore (3) : "+partScore);
            }
          }
          
          accountScore = partScore + partScore2;
          //console.log("ApplyCalc > accountScore (4) : "+accountScore);
        
        // 성인일때 통장 개설한 경우
        } else {
          accountScore = yearDf + 1 + baseScore;
          //console.log("ApplyCalc > accountScore (6) : "+accountScore);
        }
      }
    
    // 18세 미만 미성년자
    } else {
      // 통장가입일이 2년 미만
      if (yearDf < 2) { 
        // 통장가입일이 1년 이상
        if (yearDf === 1) { 
          accountScore = 1 + baseScore;   // 2점
          //console.log("ApplyCalc > accountScore (7) : "+accountScore);

        // 통장가입일이 1년 미만 (0년)
        } else {
          // 통장가입일이 6개월 미만
          if (yearDf === 0 && monthDf < 6) {
            accountScore = baseScore;     // 1점
            //console.log("ApplyCalc > accountScore (8) : "+accountScore);
          
          // 통장가입일이 6개월 이상
          } else {
            accountScore = 1 + baseScore; // 2점
            //console.log("ApplyCalc > accountScore (9) : "+accountScore);
          }
        }
      
      // 통장가입일이 2년 이상 
      } else {
        accountScore = 1 + baseScore;
        //console.log("ApplyCalc > accountScore (10) : "+accountScore);
      }
    }

    return accountScore > 17 ? 17 : accountScore;
  }

  printDate = paramDate => {

    let rtnDate = '';
    if (paramDate > 0 && paramDate.length <= 4) {
      rtnDate = paramDate;
    } else if (paramDate > 4 && paramDate.length <= 6) {
      rtnDate = paramDate.substr(0, 4) + "." + paramDate.substr(4, 2);
    } else if (paramDate > 6) {
      rtnDate = paramDate.substr(0, 4) + "." + paramDate.substr(4, 2) + "." + paramDate.substr(6, 2);
    }
    return rtnDate;
  }

  popupWording = () => {
    if (this.state.step === 1) {
      if (this.state.ownHouse === '') {
        alert('주택소유여부를 선택해주세요.');
        return false;
      }
      else if (this.state.applyId === '' && this.state.applyNotiDate === '') {
        alert('입주자모집공고를 선택해주세요.');
        return false;
      }
      else if (this.state.birthDate === '') {
        alert('생년월일을 입력해주세요.');
        return false;
      }
      else if (this.state.marriage === '' && this.state.marriageDate === '') {
        alert('혼인여부를 선택해주세요.');
        return false;
      }
      else if (this.state.ownHouse === '0' && this.state.ownHouseHist === '') { //주택소유여부가 없어야 선택가능
        alert('무주택기간을 선택해주세요.');
        return false;
      }
      else if (this.state.ownHouse === '0' && this.state.ownHouseHist === '1' && this.state.ownHouseHistDate === '') { //주택소유여부가 없고 현재무주택(과거소유) 여야 무주택기간 입력가능
        alert('무주택기간을 입력해주세요.');
        return false;
      }
      else {
        return true;
      }
    }
    else if (this.state.step === 2) {
      if (this.state.family === '') {
        alert('부양가족 유무를 선택해주세요.');
        return false;
      }
      else if (this.state.family === '1' && this.state.myParents === '') {
        alert('본인의 부모/조부모 인원을 선택해주세요.');
        return false;
      }
      else if (this.state.family === '1' && this.state.spouseParents === '') {
        alert('배우자의 부모/조부모 인원을 선택해주세요.');
        return false;
      }
      else if (this.state.family === '1' && this.state.children === '') {
        alert('자녀/손자녀 인원을 선택해주세요.');
        return false;
      }
      else {
        return true;
      }
    }
    else if (this.state.step === 3) {
      if (this.state.accountDate === '') {
        alert('청약통장 가입일을 입력해주세요.');
        return false;
      }
      else {
        return true;
      }
    }

  }


  render() {

    const houseScore = this.lpadScore(this.calcHouseScore());
    const familyScore = this.lpadScore(this.calcFamilyScore());
    const accountScore = this.lpadScore(this.calcAccountScore());
    const totalScore = this.lpadScore(Number(houseScore) + Number(familyScore) + Number(accountScore));
    this.totalScore = Number(totalScore);

    return (
      <>
        <div id="content">
          <div className="inner_wrap_ht">
            <div className="con_tit_wrap">
              <h2 className="tit">청약 가점 계산기</h2>
              <p className="sub_txt">무주택자 점수, 부양가족 점수, 청약통장점수를 계산하여 청약 가점을 미리 계산할 수 있어요.</p>
            </div>
            <div className="bbs_wrap">
              {
                this.state.step != '4'
                &&
                <>
                  <div className="point_calculator on">
                    <div className="calc_list_wrap">
                      <div className={`${this.state.step === 1 ? "calc_item on" : "calc_item"}`}>
                        <div className="calc_tit_wrap">
                          <h3><em>01.</em>무주택자 점수 계산</h3>
                          <div className="q_mark bt">
                            <div className="q_mark_inner">
                              <p className="tit">무주택자 점수 계산?</p>
                              <p className="txt">
                                * 입주자모집공고일 기준 청약통장 명의자와 주민등록등본에 등재된 명의자 및 세대원 전원이 무주택자여야 해당<br />
                                (세대원의 범위 : 배우자(주민등록이 분리된 배우자 및 그 세대원 포함), 직계존속(배우자의 직계존속 포함), 직계비속)<br />
                                <br />
                                * 청약통장 명의자 및 배우자를 대상으로 무주택기간 산정<br />
                                (무주택기간은 만 30세가 가산점이며, 30세 이전에 혼인한 경우 혼인신고 한 날부터 가산)<br />
                                <br />
                                * 만 30세 미만이면서 미혼인 무주택자의 가점은 '0'점
                        </p>
                            </div>
                          </div>
                          <span>미혼인 경우 만 30세부터 무주택 기간 산정이 가능해요!</span>
                        </div>
                        <div className="calc_con">
                          <section className="calc_form">
                            <ul className="form">
                              <li className="item">
                                <strong className="tit">주택소유여부</strong>
                                <div className="ip_wrap rdo_wrap">
                                  <div className="rdo_item">
                                    <input type="radio" id="rdo01-01" name="rdo01" value="1"
                                      checked={this.state.ownHouse === '1'}
                                      onClick={this.setOwnHouse} />
                                    <label htmlFor="rdo01-01" className="ico_house_o"><i>주택 있음</i></label>
                                  </div>
                                  <div className="rdo_item">
                                    <input type="radio" id="rdo01-02" name="rdo01" value="0"
                                      checked={this.state.ownHouse === '0'}
                                      onClick={this.setOwnHouse}
                                    />
                                    <label htmlFor="rdo01-02" className="ico_house_x"><i>주택 없음</i></label>
                                  </div>
                                </div>
                              </li>
                              <li className="item">
                                <strong className="tit">입주자 모집공고</strong>
                                <div className="ip_wrap select_apt">
                                  <button className="btn_select">모집 공고를 선택해주세요.</button>
                                  <div className="select_wrap">
                                    <div className="con">
                                      <ul>
                                        {
                                          this.state.applyList.map((v, idx) =>
                                            <li key={idx}
                                              onClick={(e) => { e.preventDefault(); this.selectApplyInfo(v); }} >
                                              <span>{v.aptName}</span>
                                              <span>{Moment(v.notiDate).format('YY. MM. DD')}</span>
                                            </li>
                                          )
                                        }
                                      </ul>
                                    </div>
                                  </div>
                                </div>
                              </li>
                              <li className="item">
                                <strong className="tit">생년월일</strong>
                                <div className="ip_wrap">
                                  <div className="ip_box">
                                    <label htmlFor="birth" className="lbl" ><em>ex. 1993.07.22</em></label>
                                    <input type="text" className="ip" name="birth" maxlength="10" autoComplete="off" required
                                      onChange={this.setBirthDate}
                                      onBlur={this.checkBirthDate}
                                      value={this.printDate(this.state.birthDate)}
                                    />
                                  </div>
                                </div>
                              </li>
                              <li className="item rdo_question">
                                <strong className="tit">혼인여부</strong>
                                <div className="ip_wrap rdo_wrap">
                                  <div className="rdo_item">
                                    <input className="ip_yes" type="radio" id="rdo02-01" name="rdo02" value="1"
                                      checked={this.state.marriage === '1'}
                                      onClick={this.setMarriage} />
                                    <label htmlFor="rdo02-01" className="ico_people_o"><i>기혼</i></label>
                                  </div>
                                  <div className="rdo_item">
                                    <input className="ip_no" type="radio" id="rdo02-02" name="rdo02" value="0"
                                      checked={this.state.marriage === '0'}
                                      onClick={this.setMarriage} />
                                    <label htmlFor="rdo02-02" className="ico_people_x"><i>미혼</i></label>
                                  </div>
                                </div>
                              </li>
                              <li className="rdo_answer">
                                <div className="item">
                                  <strong className="tit">혼인신고일</strong>
                                  <div className="ip_wrap">
                                    <div className="ip_box">
                                      <label htmlFor="marry" className="lbl" ><em>ex. 2020.07.22</em></label>
                                      <input type="text" className="ip" name="marry" maxlength="10" autoComplete="off"
                                        onChange={this.setMarriageDate}
                                        onBlur={this.checkMarriageDate}
                                        value={this.printDate(this.state.marriageDate)}
                                      />
                                    </div>
                                  </div>
                                </div>
                              </li>
                              <li className="item rdo_question">
                                <strong className="tit">무주택기간</strong>
                                <div className="ip_wrap rdo_wrap">
                                  <div className="rdo_item">
                                    <input className="ip_yes" type="radio" id="rdo03-01" name="rdo03" value="1"
                                      checked={this.state.ownHouseHist === '1'}
                                      onClick={this.setOwnHouseHist} />
                                    <label htmlFor="rdo03-01" className="ico_paper_o"><i>현재무주택 (과거 소유)</i></label>
                                  </div>
                                  <div className="rdo_item">
                                    <input className="ip_no" type="radio" id="rdo03-02" name="rdo03" value="0"
                                      checked={this.state.ownHouseHist === '0'}
                                      onClick={this.setOwnHouseHist} />
                                    <label htmlFor="rdo03-02" className="ico_paper_x"><i>소유이력 없음</i></label>
                                  </div>
                                </div>
                              </li>
                              <li className="rdo_answer">
                                <div className="item">
                                  <strong className="tit">무주택자가 된 날</strong>
                                  <div className="ip_wrap">
                                    <div className="ip_box">
                                      <label htmlFor="house" className="lbl" ><em>ex. 1993.07.22</em></label>
                                      <input type="text" className="ip" name="house" maxlength="10" autoComplete="off"
                                        onChange={this.setOwnHouseHistDate}
                                        onBlur={this.checkOwnHouseHistDate}
                                        value={this.printDate(this.state.ownHouseHistDate)}
                                      />
                                    </div>
                                  </div>
                                </div>
                              </li>
                            </ul>
                          </section>
                        </div>
                      </div>
                      <div className={`${this.state.step === 2 ? "calc_item on" : "calc_item"}`} >
                        <div className="calc_tit_wrap">
                          <h3><em>02.</em>부양가족 점수 계산</h3>
                          <div className="q_mark bt">
                            <div className="q_mark_inner">
                              <p className="tit">부양가족 점수 계산?</p>
                              <p className="txt">
                                * 부양가족 인정 대상자 : 입주자모집공고일 현재 청약통장 명의자의 주민등록등본에 등재된 세대원<br />
                                <br />
                                ※ (세대원의 범위 : 배우자(주민등록이 분리된 배우자 및 그 세대원 포함), 직계존속(배우자의 직계존속 포함), 직계비속(미혼인 자녀에 한하며, 부모가 사망한 경우에는 손자, 손녀를 포함))<br />
                                <br />
                                - 직계존속을 부양하는 경우에는 세대주로서 3년이상 계속 부양<br />
                                <br />
                                - 30세 이상의 미혼자녀는 입주자모집공고일 기준으로 최근 1년 이상 계속하여 동일한 주민등록표상에 등재시에만 부양가족 점수를 받음
                        </p>
                            </div>
                          </div>
                          <span>부양가족 기준을 확인 후 항목을 모두 선택해주세요. (본인은 제외)</span>
                        </div>
                        <div className="calc_con">
                          <section className="calc_form">
                            <ul className="form">
                              <li className="item rdo_question">
                                <strong className="tit">부양가족 유무</strong>
                                <div className="ip_wrap rdo_wrap">
                                  <div className="rdo_item">
                                    <input className="ip_yes" type="radio" id="rdo04-01" name="rdo04" value="1"
                                      checked={this.state.family === '1'}
                                      onClick={this.setFamily} />
                                    <label htmlFor="rdo04-01" className="ico_people_o"><i>부양가족 있음</i></label>
                                  </div>
                                  <div className="rdo_item">
                                    <input className="ip_no" type="radio" id="rdo04-02" name="rdo04" value="0"
                                      checked={this.state.family === '0'}
                                      onClick={this.setFamily} />
                                    <label htmlFor="rdo04-02" className="ico_people_x"><i>부양가족 없음</i></label>
                                  </div>
                                </div>
                              </li>
                              {
                                this.state.family === '1' ?
                                  <li className="rdo_answer">
                                    <div className="item">
                                      <strong className="tit">본인의 부모 / 조부모</strong>
                                      <div className="ip_wrap select_apt">
                                        <button className="btn_select">0명</button>
                                        <div className="select_wrap">
                                          <div className="con">
                                            <ul>
                                              <li onClick={() => this.setMyParents(0)}><span>0명</span></li>
                                              <li onClick={() => this.setMyParents(1)}><span>1명</span></li>
                                              <li onClick={() => this.setMyParents(2)}><span>2명</span></li>
                                              <li onClick={() => this.setMyParents(3)}><span>3명</span></li>
                                              <li onClick={() => this.setMyParents(4)}><span>4명</span></li>
                                              <li onClick={() => this.setMyParents(5)}><span>5명</span></li>
                                              <li onClick={() => this.setMyParents(6)}><span>6명 이상</span></li>
                                            </ul>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                    <div className="item">
                                      <strong className="tit">배우자의 부모 / 조부모</strong>
                                      <div className="ip_wrap select_apt">
                                        <button className="btn_select">0명</button>
                                        <div className="select_wrap">
                                          <div className="con">
                                            <ul>
                                              <li onClick={() => this.setSpouseParents(0)}><span>0명</span></li>
                                              <li onClick={() => this.setSpouseParents(1)}><span>1명</span></li>
                                              <li onClick={() => this.setSpouseParents(2)}><span>2명</span></li>
                                              <li onClick={() => this.setSpouseParents(3)}><span>3명</span></li>
                                              <li onClick={() => this.setSpouseParents(4)}><span>4명</span></li>
                                              <li onClick={() => this.setSpouseParents(5)}><span>5명</span></li>
                                              <li onClick={() => this.setSpouseParents(6)}><span>6명 이상</span></li>
                                            </ul>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                    <div className="item">
                                      <strong className="tit">자녀 / 손자녀</strong>
                                      <div className="ip_wrap select_apt">
                                        <button className="btn_select">0명</button>
                                        <div className="select_wrap">
                                          <div className="con">
                                            <ul>
                                              <li onClick={() => this.setChildren(0)}><span>0명</span></li>
                                              <li onClick={() => this.setChildren(1)}><span>1명</span></li>
                                              <li onClick={() => this.setChildren(2)}><span>2명</span></li>
                                              <li onClick={() => this.setChildren(3)}><span>3명</span></li>
                                              <li onClick={() => this.setChildren(4)}><span>4명</span></li>
                                              <li onClick={() => this.setChildren(5)}><span>5명</span></li>
                                              <li onClick={() => this.setChildren(6)}><span>6명 이상</span></li>
                                            </ul>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </li>
                                  : null
                              }

                            </ul>
                          </section>
                        </div>
                      </div>
                      <div className={`${this.state.step === 3 ? "calc_item on" : "calc_item"}`} >
                        <div className="calc_tit_wrap">
                          <h3><em>03.</em>청약통장 점수 계산</h3>
                          <div className="q_mark bt">
                            <div className="q_mark_inner">
                              <p className="tit">청약통장 점수 계산?</p>
                              <p className="txt">
                                입주자모집공고일 기준 청약자의 청약통장 가입기간이 기준<br />
                                <br />
                                - 청약통장 전환, 예치금액변경 및 명의변경을 한 경우, 최초가입일(순위기산일) 기준으로 가입기간 산정<br />
                                <br />
                                ※ 실제 인터넷청약 시, 청약통장 가입기간 및 해당 점수를 자동 산정하여 부여
                        </p>
                            </div>
                          </div>
                          <span>미성년자로서 가입한 기간은 2년만 인정됩니다.</span>
                        </div>
                        <div className="calc_con">
                          <section className="calc_form">
                            <ul className="form">
                              <li className="item">
                                <strong className="tit">청약통장 가입일</strong>
                                <div className="ip_wrap">
                                  <div className="ip_box">
                                    <label htmlFor="account" className="lbl" >통장가입일을 입력해주세요. <em>(ex. 1993.07.22)</em></label>
                                    <input id="account" type="text" className="ip" name="account" maxlength="10" required autoComplete="off"
                                      onChange={this.setAccountDate}
                                      onBlur={this.checkAccountDate}
                                      value={this.printDate(this.state.accountDate)}
                                    />
                                  </div>
                                </div>
                              </li>
                            </ul>
                          </section>
                        </div>
                      </div>
                      <div className="btn_inner">
                        <button className={`${this.state.step === 1 ? "btn_prev disabled" : "btn_prev"}`} type="button"
                          onClick={this.decreaseStep} >이전</button>
                        <button className={`${this.state.step === 4 ? "btn_prev hide" : "btn_next"}`} type="button"
                          onClick={this.increaseStep} >다음</button>
                        {/* <button className={`${this.state.step === 4 ? "btn_save" : "btn_save hide"}`} type="button"
                      onClick={() => { if (this.popupWording()) { globalPopupOpen('creditAgree', 'mw') } }} >저장</button> */}
                      </div>
                    </div>
                    <div className="menu_list_wrap">
                      <div className="result_wrap">
                        <ul className="result">
                          <li className={`${this.state.step === 1 ? "item focus" : "item done"}`} >
                            <button type="button"><i>무주택자 점수 버튼</i></button>
                            <div className="tit">
                              <i className="ico_house"></i>
                              <span><em>01.</em>무주택자 점수</span>
                            </div>
                            <span className="point"><em>{houseScore}</em>점</span>
                          </li>
                          <li className={`${this.state.step === 1 ? "item" : this.state.step === 2 ? "item focus" : "item done"}`}>
                            <button type="button"><i>부양가족 점수 버튼</i></button>
                            <div className="tit">
                              <i className="ico_people"></i>
                              <span><em>02.</em>부양가족 점수</span>
                            </div>
                            <span className="point"><em>{familyScore}</em>점</span>
                          </li>
                          <li className={`${this.state.save ? 'item done' : this.state.step === 3 ? "item focus" : "item"}`} >
                            <button type="button"><i>청약통장 점수 버튼</i></button>
                            <div className="tit">
                              <i className="ico_bank"></i>
                              <span><em>03.</em>청약통장 점수</span>
                            </div>
                            <span className="point"><em>{accountScore}</em>점</span>
                          </li>
                          <li className="sum on">
                            <h3 className="tit">청약점수 합계</h3>
                            <div className="point_wrap">
                              <strong className="point count_point" per={totalScore}></strong>
                              <i className="slash">/</i>
                              <span className="total">84<em>점</em></span>
                            </div>
                            <div className="btn_inner">
                              <button type="button" className="btn_second"
                                onClick={this.initializeData}>
                                {/* >{this.state.save ? '선택값 초기화' : '다시 계산하기'} */}
                                다시 계산하기</button>
                            </div>
                            <p className="noti">
                              아파트청약케어의 청약가점 계산기는 참고용이며,<br />
                              산출 된 결과에 책임을 지지 않습니다.
                      </p>
                          </li>
                        </ul>
                      </div>
                      {/* <a className={`${this.state.save ? "result_banner" : "result_banner hide"}`}
                        onClick={(e) => this.gotoApplyPredict(houseScore, familyScore, accountScore, e)}>
                        <img src={process.env.REACT_APP_ENV_URL + "resources/images/img_banner.png"} alt="당첨예상 바로가기 배너" />
                      </a> */}
                    </div>
                  </div>
                </>
              }
              {
                this.state.step === 4 &&
                <>
                  <div className="point_calculator_done">
                    <div className="tit">
                      <p>청약 가점 계산이 완료되었어요.</p>
                      <h2>나의 청약 가점은 <em><code>{totalScore}</code>점</em>이에요</h2>
                    </div>

                    <div className="graph_wrap">
                      <ul>
                        {
                          this.state.houseScore != 0 &&
                          <li>
                            <span className="tit"><i className="ico_apt">아파트</i>무주택 점수</span>
                            <div className="graph_bg"><div className="graph" style={{ "width": `${(houseScore / 32 * 100)}%` }}></div></div>
                            <span className="point"><code>{houseScore}</code>점</span>
                          </li>
                        }
                        {
                          this.state.familyScore != 0 &&
                          <li>
                            <span className="tit"><i className="ico_family">부양가족</i>부양가족 점수</span>
                            <div className="graph_bg"><div className="graph" style={{ "width": `${(familyScore / 35 * 100)}%` }}></div></div>
                            <span className="point"><code>{familyScore}</code>점</span>
                          </li>
                        }
                        {
                          this.state.accountScore != 0 &&
                          <li>
                            <span className="tit"><i className="ico_bank">청약통장</i>청약통장 점수</span>
                            <div className="graph_bg"><div className="graph" style={{ "width": `${(accountScore / 17 * 100)}%` }}></div></div>
                            <span className="point"><code>{accountScore}</code>점</span>
                          </li>
                        }
                      </ul>
                      <p>※ 아파트청약케어의 청약가점 계산기는 참고용이며, 산출 된 결과에 책임을 지지 않습니다.</p>
                    </div>

                    <div className="info_wrap">
                      <h2>내 청약 가점도 저장하고 당첨 예상도 확인해보세요</h2>

                      <ul className="btn_inner_half">
                        <li><button type="button" className="btn_cancle" onClick={this.initializeData}>취소</button></li>
                        <li><button type="button" onClick={() => this.beforeScoreSaved()}>가점 저장 후 청약 당첨 가능성 확인</button></li>
                      </ul>

                      <button type="button" className="btn_reset" onClick={this.initializeData}>다시 계산하기</button>
                    </div>
                  </div>
                </>
              }
            </div>
          </div>
          <div className={`${this.state.step === 4 ? "inner_wrap_full on" : "inner_wrap_full"}`} >
            <ApplyCalcRecent
              applyList={this.state.applyList}
              score={this.totalScore}
            ></ApplyCalcRecent>
          </div>
        </div>

        <div id="mw-creditAgree" className="ly_cpinfo_pc ly_alert">
          <div className="wrap">
            <div className="content">
              <button type="button" onClick={() => globalPopupClose('creditAgree', 'mw')} className="close"><i>닫기</i></button>
              <strong className="tit">청약가점 저장을 위해서<br /> 개인(신용)정보 수집 및 이용동의가<br /> 필요해요.</strong>
              <div className="terms_list">
                <ul className="all_list">
                  <li className="all">
                    <span className="checkbox i_all">
                      <input type="checkbox" name="termsCreditAgree" id="termsCreditAgree" value="" />
                      <label for="termsCreditAgree"><i></i></label>
                      <button type="button" onClick={() => this.openCreditAgreePop()} className="link">[선택] 개인(신용)정보 수집 및 이용 동의</button>
                    </span>
                  </li>
                </ul>
              </div>
            </div>
            <div className="btn_wrap">
              <button type="button" className="skip" onClick={(e) => this.scoreSaved(houseScore, familyScore, accountScore, totalScore, e)}>나의 청약가점 계산 결과 저장</button>
            </div>
          </div>
        </div>
      </>
    );
  }
}
export default ApplyCalc;