import React, { Component } from 'react';
import MapApplyPayCalc from './MapApplyPayCalc';
import axios from 'axios';
import $ from 'jquery'
import { searchApply, addRecentItem, getRecentItem, clearRecentItem, setRecentList, addLsItem, getLsItem } from 'common/Search';
import SearchResult from 'routes/common/SearchResult';
import RecentResult from 'routes/common/RecentResult';
import { orderBy } from 'lodash';
import { addLcApplyFavItem, getLcApplyFavList, clearLcApplyFavItem } from './MapAptFavUtil'
import { findIndex } from 'common/ArrayUtil';
import { dateDiffMsg, numberWithCounts, numberWithCommas, numberWithCommas2, dateFormatMsg, dateFormatMsg2, numberReplaceWithStag, textReplaceWithStag } from 'common/StringUtil';
import _ from 'lodash';
import { uiCostCard, uiCostScroll, uiCalcSelect, uiAptHeaderCustom, popupOpen, popupClose, uiInfoPopSwiper02 } from 'common/Design';

//// 컴포넌트 사용처 : 청약 비용 계산기
class MapApplyPaySrch extends Component {
  constructor(props) {
    super(props);
    this.state = {
      applyId: '',
      searchResult: [],
      searchIndex: -1,
      step: 1,
      recentResult: [],
      recentFocus: false,
      searchInfo: {},
      keyword: "",
      applyPayList: [], // 청약(분양정보)리스트
      applyPayDtl: [], // 청약(분양정보)상세
      applyDtl: [], // 청약(분양정보)상세
      flrNoList: [],
      hsTypeList: [],
      searchList: [],
      flrNo: '',
      applyDtlId: '',
      hsType: '',
      balconyYn: false,
      optYn: false,
      optAmt: 0,
      onRenderGraph: false,
    };
  }

  componentDidMount = () => {
    uiCostCard.init();
    this.fetchApplyPayRecent();

    if (localStorage.getItem('infoPop02Open') != 'false') {
      this.infoPopOpen();
    }

    // input val change > input display:block
    $('.rdo_question input').change(function () {
      ////console.log("$('.rdo_question input').change(function () {");
      var _this = $(this),
        _next = _this.parents('.rdo_question').next('.rdo_answer');
      if ($(this).hasClass('ip_yes')) {
        _next.slideDown(300);
      } else {
        _next.slideUp(300);
      }
    });
  }

  componentDidUpdate = () => {
    uiCostCard.init();

    if (localStorage.getItem('jwtToken') == undefined || localStorage.getItem('jwtToken') == null) {
      window.location.href = '/login';
      window.close();
    }

    if (this.props.mapApplyDtl != null && this.props.mapApplyDtl.applyId != null && this.props.mapApplyDtl.applyId != this.state.applyDtl.applyId) {
      const mapApplyDtl = this.props.mapApplyDtl;
      //this.fetchSelectApplyPayDtl(mapApplyDtl);
      //$('#apt_item_' + mapApplyDtl.applyId).click();
      this.setState({
        applyDtl: mapApplyDtl, flrNoList: [], flrNo: '', applyDtlId: '', balconyYn: false, optYn: false, optAmt: 0
      });
      $('#apt_item_' + mapApplyDtl.applyId).click();
    }
  }

  menuClick = (url) => {

    //window.location.href = ('/' + url);
    if (url.search('applyPredict') > -1 || url.search('applyPay') > -1) {
      axios.get('apt/authorized/check',
        { headers: { 'Authorization': 'Bearer ' + localStorage.getItem('jwtToken') }, timeout: 1000 }).then(res => {
          if (res.data.response.isValid) {
            window.open(('/' + url), "_blank");
          }
          else {
            window.location.href = '/login';
          }
        }).catch(err => {
          if (localStorage.getItem('isAutoLogin') == 'true') {
            // 자동로그인 적용시 사용
            if (err.response.status === 401 || err.response.status === 403) {
              axios.post('apt/authorized/reIssue',
                { 'ref': localStorage.getItem('ref') },
                { headers: { 'Authorization': 'Bearer ' + localStorage.getItem('jwtToken') } }).then(res => {
                  if (res.status === 200) {
                    localStorage.setItem('jwtToken', res.headers.authorization);
                    window.open(('/' + url), "_blank");
                  }
                }).catch(err => {
                  window.location.href = '/login';
                });
            }
            else {
              window.location.href = '/login';
            }
          }
          else {
            window.location.href = '/login';
          }
        });
    }
    else {
      window.open(('/' + url), "_blank");
    }

  }

  fetchApplyPayRecent = () => {
    axios.get('/apt/apply/recentList')
      .then(res => {
        this.setState({ applyPayList: res.data.response, step: 1 });
        this.props.onRenderMarkerList({ data: res.data.response });

      });
  }

  handleChangeFlrNo = (selectedFlrNo) => {
    let flrNoArr = selectedFlrNo.split('_');
    this.setState({ flrNo: flrNoArr[0], applyDtlId: flrNoArr[1] });
  }

  handleChangeHsType = (selectedHsType) => {
    $('.select_apt').removeClass('in');
    $('.select_apt .btn_select').eq(1).text('층수');

    if (selectedHsType != null) {
      $('.select_apt').eq(0).addClass('in');
      axios.get('/apt/apply/applyPayFlrNoList?applyId=' + this.state.applyId + '&hsType=' + selectedHsType)
        .then(res => {
          this.setState({ hsType: selectedHsType, flrNoList: res.data.response, flrNo: '', applyDtlId: '', });
        });
    }
  }

  handleChangeBalcony = (result) => {
    this.setState({ balconyYn: result });
  }

  handleChangeOptYn = (result) => {
    if (result === true) {
      this.setState({ optYn: result, optAmt: this.state.optAmt });
    }
    else {
      $('input[name=cost]').val('');
      this.setState({ optYn: result, optAmt: 0 });

    }
  }

  handleChangeOptAmt = (e) => {

    const max = e.target.getAttribute('maxLength');
    let value = e.target.value;

    if (value.length > max) value = value.slice(0, max);
    e.target.value = value.replace(/[^0-9]/g, '').replace(/(^0+)/, "");

    this.setState({ optAmt: value });
  }

  handleClickSearch = (e) => {
    //e && e.preventDefault();
    if (this.state.searchResult.length > 0) {
      this.setState({ searchResult: [], searchIndex: -1, recentFocus: false, searchVisible: false, keyword: this.state.searchResult[0].searchName });
      addRecentItem('applyMap', this.state.searchResult[0]);
      $('.apt_wrap').removeClass('dim');
      //this.props.onGotoFavApplyDtl(this.state.searchResult[0].searchId);
      $('#apt_item_' + this.state.searchResult[0].searchId).click();
    }
  }

  handleChangeKeyword = (e) => {

  }

  handleChangeKeyword = _.debounce((keyword) => {
    this.setState({ keyword: keyword });
    if (keyword) {
      this.fetchSearchApplyPayList(keyword);
    } else {
      let recentData = getRecentItem('applyMap');
      this.setState({ searchResult: [], recentResult: recentData, searchIndex: -1, recentFocus: true });
    }

  }, 300);

  debounceChangeKeyword = e => {
    const { target: { value } } = e;
    this.setState({ keyword: value, searchVisible: true, recentFocus: false })
    this.handleChangeKeyword(value);
  }

  handleBlurKeyword = (e) => {
    let recentData = getRecentItem('applyMap');
    this.setState({ searchResult: [], recentResult: recentData, searchIndex: -1, recentFocus: true });
  }

  handleClearRecent = () => {

    clearRecentItem('applyMap');
    this.setState({ recentResult: [] });
  }

  handleRemoveRecent = (searchId) => {
    let recentList = getRecentItem('applyMap');
    if (recentList.some(f => f.searchId === searchId)) {
      recentList.splice(findIndex(recentList, f => f.searchId === searchId), 1);
    }
    setRecentList('applyMap', recentList);
    this.setState({ recentResult: recentList });
  }

  handleSelectSearch = (search) => {
    addRecentItem('applyMap', search);
    this.setState({ searchInfo: search, keyword: search.searchName });
    this.fetchSearchApplyPayList(search.searchName);

  }

  handleKeydownKeyword = (e) => {
    let keyCode = e.keyCode;
    if (!this.state.searchResult.length > 0) {
      return;
    }
    if (keyCode === 38) {     // uparrowKey
      this.setState({ searchIndex: this.state.searchIndex <= 0 ? 0 : this.state.searchIndex - 1 });
    } else if (keyCode === 40) {     // downarrowKey
      let maxIndex = this.state.searchResult.length - 1;
      this.setState({ searchIndex: this.state.searchIndex >= maxIndex ? maxIndex : this.state.searchIndex + 1 });
    } else if (keyCode === 13) {      // enterKey
      if (this.state.searchIndex === -1) {
        this.setState({ searchResult: [], searchIndex: -1, recentFocus: false, searchVisible: false, keyword: this.state.searchResult[0].searchName });
        addRecentItem('applyMap', this.state.searchResult[0]);
        $('.apt_wrap').removeClass('dim');
        //this.props.onGotoFavApplyDtl(this.state.searchResult[0].searchId);
        $('#apt_item_' + this.state.searchResult[0].searchId).click();
      } else {
        let search = this.state.searchResult[this.state.searchIndex];
        this.setState({ searchResult: [], searchIndex: -1, recentFocus: false, searchVisible: false, keyword: search.searchName });
        addRecentItem('applyMap', search);
        $('.apt_wrap').removeClass('dim');
        //this.props.onGotoFavApplyDtl(search.searchId);
        $('#apt_item_' + search.searchId).click();
      }
    }
  }

  infoToggle = (e) => {
    e.stopPropagation();
    if ($($(e.target).parents(".toggle_tit")).hasClass('_on')) {
      $($(e.target).parents(".toggle_tit")).removeClass('_on').next().slideUp(200);
    } else {
      $($(e.target).parents(".toggle_tit")).addClass('_on').next().slideDown(200);
    }
  }

  fetchSearchApplyPayList = (aptName) => {
    axios.get('/apt/apply/paySearchList?aptName=' + aptName).then(res => {
      this.setState({ searchResult: res.data.response, applyPayList: res.data.response });
      this.props.onRenderMarkerList({ data: res.data.response });
    });
  }

  // 최근인기아파트 리스트 아이템 click
  fetchSelectApplyPayDtl = (detail, e) => {
    $('.select_apt').removeClass('in');
    $('.select_apt .btn_select').eq(0).text('유형');
    $('.select_apt .btn_select').eq(1).text('층수');
    $('.rdo_answer').hide();

    axios.get('/apt/apply/applyPayHsTypeList?applyId=' + detail.applyId)
      .then(res => {
        this.setState({ applyId: detail.applyId, keyword: detail.aptName, hsTypeList: res.data.response, step: 2 });

      });


    this.setState({ flrNoList: [], flrNo: '', applyDtlId: '', balconyYn: false, optYn: false, optAmt: 0 });

    // axios.get('/apt/apply/applyDtlList?applyId=' + detail.applyId)
    //   .then(res => {
    //     this.setState({ applyId: detail.applyId, keyword: detail.aptName, hsTypeList: res.data.response, step: 2 });
    //   });
    uiCalcSelect.init();

    if (this.state.applyDtl.applyId === undefined || this.state.applyDtl.applyId === null || detail.applyId != this.state.applyDtl.applyId) {
      this.fetchApplyDtl(detail.applyId);
    }
  }

  fetchApplyPayDtl = (e) => {

    const { applyId, flrNo, applyDtlId, hsType, balconyYn, optYn, optAmt } = this.state;
    if (this.popupWording()) {
      this.setState({
        onRenderGraph: false,
      })
      let param = `?applyId=${applyId}&flrNo=${flrNo}&applyDtlId=${applyDtlId}&hsType=${hsType}&balconyYn=${balconyYn}&optYn=${optYn}&optAmt=${optAmt}`;
      axios.get('/apt/apply/applyPayDetail' + param)
        .then(res => this.setState({ applyPayDtl: res.data.response, step: 3, onRenderGraph: true, }, () => {
          this.openCostCard();
          setTimeout(() => {
            $('div.sub_header > h3').attr('data-tit', '');
            $('div.sub_header > h3').text('');
            uiAptHeaderCustom.init();
          }, 100);
        }));
    }
  }

  // 비용계산기 상세정보 (유형) 목록 ( 최근 인기 아파트 리스트 아이템 클릭창의 (step2) 유형 값 )
  fetchApplyDtl = (paramApplyId) => {
    //$('.apt_wrap').removeClass('dim');
    axios
      .get(`apt/apply/dtl?applyId=${paramApplyId}`)
      .then(res => {
        this.setState({
          applyDtl: res.data.response.applyDtl
        });
        //this.props.onRenderMarker({ data: res.data.response.applyDtl });
        this.props.onPanTo({ lat: res.data.response.applyDtl.lat, lng: res.data.response.applyDtl.lng });

        // setTimeout(() => {
        //   $('div.sub_header > h3').attr('data-tit', '');
        //   $('div.sub_header > h3').text('');
        //   uiAptHeaderCustom.init();
        // }, 500);
      });

  }

  handleFavCheck = (e) => {
    const { target: { checked } } = e;
    if (checked) {
      addLcApplyFavItem({ applyId: this.state.applyDtl.applyId, favTime: new Date() });
    } else {
      clearLcApplyFavItem({ applyId: this.state.applyDtl.applyId })
    }
    this.handleAddFav();
  }

  handleAddFav = () => {
    const favList = getLcApplyFavList();
    this.fetchApplyFavList(favList);
  }

  fetchApplyFavList = (favList) => {
    const param = favList.map(f => `applyId=${f.applyId}`).join('&');
    axios
      .get('/apt/map/getFavApplyList?' + param)
      .then(res => {
        let resData = res.data;
        resData.forEach(v => {
          if (favList.some(x => x.applyId === v.applyId)) {
            const itemIdx = findIndex(favList, x => x.applyId === v.applyId);
            v.favTime = favList[itemIdx].favTime;
          }
        });
        this.setState({ favResult: orderBy(resData, ["favTime"], ["desc"]) });
      });
  }

  popupWording = () => {
    if (this.state.flrNo === '' && this.state.hsType === '' && this.state.balconyYn === null && this.state.optYn === null) {
      alert('아파트 비용 계산을 위해, 정보를 입력해주세요.');
      return false;
    }
    else if (this.state.flrNo === '' && this.state.hsType === '' && this.state.balconyYn != null && this.state.optYn != null) {
      alert('아파트 유형 및 층수를 선택해주세요.');
      return false;
    }
    else if (this.state.flrNo === '' && this.state.hsType != '' && this.state.balconyYn != null && this.state.optYn != null) {
      alert('아파트 층수를 선택해주세요.');
      return false;
    }
    else if (this.state.flrNo != '' && this.state.hsType === '' && this.state.balconyYn != null && this.state.optYn != null) {
      alert('아파트 유형을 선택해주세요.');
      return false;
    }
    else if (this.state.flrNo != '' && this.state.hsType != '' && this.state.balconyYn === null && this.state.optYn != null) {
      alert('발코니 확장여부를 선택해주세요.');
      return false;
    }
    else if (this.state.flrNo != '' && this.state.hsType != '' && this.state.balconyYn != null && this.state.optYn === null) {
      alert('유상옵션 금액을 선택해주세요.');
      return false;
    }
    else if (this.state.flrNo === '' && this.state.hsType === '' && this.state.balconyYn === null && this.state.optYn != null) {
      alert('아파트 유형 및 층수와 발코니 확장여부를 선택해주세요.');
      return false;
    }
    else if (this.state.flrNo != '' && this.state.hsType != '' && this.state.balconyYn === null && this.state.optYn === null) {
      alert('발코니 확장여부와 유상옵션 금액을 선택해주세요.');
      return false;
    }
    else if (this.state.flrNo === '' && this.state.hsType === '' && this.state.balconyYn != null && this.state.optYn === null) {
      alert('유형 및 층수와 유상옵션 금액을 선택해주세요.');
      return false;
    }
    else if (this.state.optYn === true && this.state.optAmt === 0) {
      alert('유상옵션 금액을 입력해주세요.');
      return false;
    }
    else {
      return true;
    }
  }

  openCostCard = () => {
    var parent = $('.apt_wrap'),
      panel = $('div[class^=panel_]');

    parent.addClass('_3dep');
    parent.find('.panel_st2').css('z-index', '5');
    parent.find('.panel_st2 .step_01').addClass('hide').siblings().removeClass('hide');
  }

  // [2021-09-23] 안내 팝업 오픈
  infoPopOpen = () => {
    popupOpen('costinfo', 'mw', 0);
    uiInfoPopSwiper02.init();
  }

  // [2021-09-23] 안내 팝업 닫기
  infoPopClose = () => {
    popupClose('costinfo', 'mw');
    // uiInfoPopSwiper02.destroy(false);
  }

  infoPopCloseNeverOpen = () => {
    localStorage.setItem('infoPop02Open', false);
    popupClose('costinfo', 'mw');

  }

  render() {
    const { searchResult, recentResult, searchIndex, recentFocus, searchInfo, keyword, limitListSize, searchVisible, applyPayList } = this.state;
    const favorite = getLcApplyFavList().some(v => v.applyId === this.state.applyDtl.applyId);
    return (<>
      <div className="panel_st1 on">
        <div className="header_wrap">
          <h2>청약 비용 계산기</h2>
          <button type="button" className="btn_fold on"><i>닫기</i></button>
        </div>
        <div className="content_fixed">
          <div className="ip_search">
            <div className="search_box focus">
              <input type="text" autocomplete="off" id="search_box01" required
                onChange={this.debounceChangeKeyword}
                onFocus={this.debounceChangeKeyword}
                onKeyDown={this.handleKeydownKeyword}
                value={keyword}
              />
              <label htmlFor="search_box01">청약 정보를 검색해주세요.</label>
              {
                recentFocus ?
                  <RecentResult
                    recentResult={recentResult}
                    onClearRecent={this.handleClearRecent}
                    onRemoveRecent={this.handleRemoveRecent}
                    onSelectRecent={(search) => this.handleSelectSearch(search)}
                  ></RecentResult>
                  : <SearchResult
                    searchResult={searchResult}
                    searchKeyword={keyword}
                    onKeyDown={this.handleKeydownKeyword}
                    searchIndex={searchIndex}
                    searchVisible={searchVisible}
                    onSelectSearch={(search) => this.handleSelectSearch(search)}></SearchResult>
              }
            </div>
            <button type="button" className="btn_search"
              onClick={this.handleClickSearch}><i>검색</i></button>
          </div>
        </div>

        <div className="content_list">
          <div className="list_area">
            <li className="list_sub_tit">
              <strong>최근 인기 아파트</strong>
            </li>
            {/* <ul className="scroll_area mCustomScrollbar" data-mcs-theme="minimal"> */}
            <ul>
              {
                applyPayList && applyPayList.map((v, idx) =>
                  <li className="apt_item_list" key={idx} >
                    <button type="button" id={"apt_item_" + v.applyId} onClick={(e) => this.fetchSelectApplyPayDtl(v, e)}><i>아파트 리스트</i></button>
                    <div className="item_info">
                      <div className="terms_list">
                        <span className="checkbox i_all">
                          <input type="checkbox" name="apt_list_01" id="ck01-01" value="" />
                          <label htmlFor="ck01-01"><i></i></label>
                        </span>
                      </div>
                      <div className="txt_area">
                        <h4>{v.aptName}</h4>
                        <p className="sub_txt">{v.juso}</p>
                      </div>
                    </div>
                  </li>
                )
              }
            </ul>
          </div>
        </div>
      </div>
      <div className="panel_st2 wide">
        <div className="step_01">
          <div className="header_wrap">
            <h3></h3>
            <button type="button" className="btn_close ty2"><i>돌아가기</i></button>
          </div>
          <div className="content_list">
            <div className="filter_area">
              <div className="filter_wrap_cost">
                <div className="tit_wrap">
                  <h3>유형 및 층수</h3>
                  <div className="q_mark bt">
                    <div className="q_mark_inner">
                      <p className="tit">유형 및 층수?</p>
                      <p className="txt">아파트 분양가는 같은 평수라도 동, 층수에 따라 분양가 차이가 발생할 수 있어요. 궁금하신 동 및 층수와 주택 유형을 선택해주세요</p>
                    </div>
                  </div>
                </div>
                <div className="filter_calc">
                  <div className="calc_form col_half">
                    <div className="ip_wrap select_apt">
                      <button className="btn_select">유형</button>
                      <div className="select_wrap mCustomScrollbar" data-mcs-theme="minimal">
                        <div className="con">
                          <ul>
                            {
                              this.state.hsTypeList && this.state.hsTypeList.map((v, idx) =>
                                <li key={idx} onClick={() => this.handleChangeHsType(v.hsType)} >
                                  <span>{v.hsType}</span>
                                </li>
                              )
                            }
                          </ul>
                        </div>
                      </div>
                    </div>
                    <div className="ip_wrap select_apt">
                      <button className="btn_select">층수</button>
                      <div className="select_wrap mCustomScrollbar" data-mcs-theme="minimal">
                        <div className="con">
                          <ul>
                            {
                              this.state.flrNoList && this.state.flrNoList.map((v, idx) =>
                                <li key={idx} onClick={() => this.handleChangeFlrNo(v.flrNo + '_' + v.applyDtlId)} >
                                  <span>{v.flrNo}</span>
                                </li>
                              )
                            }
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="filter_wrap_cost">
                <div className="tit_wrap">
                  <h3>발코니 확장여부</h3>
                  <div className="q_mark bt">
                    <div className="q_mark_inner">
                      <p className="tit">발코니 확장여부?</p>
                      <p className="txt">
                        분양가 이외에 발코니 확장 시 추가 비용이 발생해요. 비확장을 선택하셔도 분양가가 낮아지진 않아요.
											</p>
                    </div>
                  </div>
                </div>
                <div className="ip_wrap rdo_wrap">
                  <div className="rdo_item">
                    <input type="radio" id="rdo01-01" name="rdo01"
                      checked={this.state.balconyYn}
                      onClick={() => this.handleChangeBalcony(true)}
                    />
                    <label htmlFor="rdo01-01" className="balcony_x"><i>확장</i></label>
                  </div>
                  <div className="rdo_item">
                    <input type="radio" id="rdo01-02" name="rdo01"
                      checked={!this.state.balconyYn}
                      onClick={() => this.handleChangeBalcony(false)}
                    />
                    <label htmlFor="rdo01-02" className="balcony_o"><i>비확장</i></label>
                  </div>
                </div>
              </div>
              <div className="filter_wrap_cost">
                <div className="tit_wrap">
                  <h3>유상옵션 금액</h3>
                  <div className="q_mark bt">
                    <div className="q_mark_inner">
                      <p className="tit">유상옵션 금액?</p>
                      <p className="txt">청약 아파트도 자동차 옵션 처럼 다양한 옵션이 존재해요. 예상되는 유상옵션 비용을 직접 입력해주세요.</p>
                    </div>
                  </div>
                </div>
                <div className="ip_wrap rdo_wrap rdo_question">
                  <div className="rdo_item">
                    <input className="ip_yes" type="radio" id="rdo02-02" name="rdo02"
                      checked={this.state.optYn}
                      onClick={() => this.handleChangeOptYn(true)} />
                    <label htmlFor="rdo02-02" className="option_o"><i>선택</i></label>
                  </div>
                  <div className="rdo_item">
                    <input type="radio" id="rdo02-01" name="rdo02"
                      checked={!this.state.optYn}
                      onClick={() => this.handleChangeOptYn(false)} />
                    <label htmlFor="rdo02-01" className="option_x"><i>미선택</i></label>
                  </div>
                </div>
                <div className="calc_form rdo_answer">
                  <div className="ip_wrap">
                    <div className="ip_box blk">
                      <input type="text" pattern="[0-9]" autocomplete="off" className="ip" name="cost" maxLength="8" required="required"
                        onChange={this.handleChangeOptAmt}
                      />
                      <label htmlFor="cost" className="lbl" >금액을 입력하세요.</label>
                      <i>만원</i>
                    </div>
                  </div>
                </div>
              </div>
              <div className="filter_wrap_cost">
                <div className="btn_inner">
                  <button type="button" className="btn_basic blk cost_result"
                    onClick={this.fetchApplyPayDtl}
                  >결과보기</button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="step_02 hide">
          <div className="apt_info_header">
            <div className="sub_header">
              <button type="button" className="btn_back_w"><i>돌아가기</i></button>
              <h3 data-tit=""></h3>
            </div>
            <div className="info_area">
              <div className="apt_name">
                <div className="tit">
                  <div className="check_form ck_star_w">
                    <input type="checkbox" className="hide check-one" id="star02"
                      checked={favorite}
                      onClick={this.handleFavCheck} />
                    <label htmlFor="star02"></label>
                  </div>
                  <h3>{this.state.applyDtl.aptName}</h3>
                </div>
                <p className="txt">{this.state.applyDtl.address}</p>
              </div>
              <div className="keyword">
                <span>{dateFormatMsg(this.state.applyDtl.notiDate)}</span>
                <span>{numberWithCounts(this.state.applyDtl.maxApplyPrice * 10000)}</span>
                <span>{numberWithCommas(this.state.applyDtl.applyCnt)}세대</span>
                {
                  (this.state.applyDtl) &&
                    (this.state.applyDtl.loanRateCase == 1 || this.state.applyDtl.loanRateCase == 2) ?
                    <span>투기 과열</span>
                    :
                    (this.state.applyDtl.loanRateCase == 3 || this.state.applyDtl.loanRateCase == 4) ?
                      <span>조정 지역</span>
                      :
                      null
                }
              </div>
            </div>
          </div>
          <div className="content_list">
            <div className="aptinfo_area">
              <div className="scroll_area mCustomScrollbar" data-mcs-theme="minimal">
                <div className="info_list">
                  <div className="info_tit _on">
                    <p>단지정보</p><i></i>
                  </div>
                  <div className="info_con apt_info" style={{ "display": "block" }}>
                    <table>
                      <caption>
                        <strong>아파트단지 정보 리스트</strong>
                        <p>주소, 건축년도 및 아파트 정보 리스트</p>
                      </caption>
                      <colgroup>
                        <col style={{ "width": "50px" }} />
                        <col style={{ "width": "100px" }} />
                      </colgroup>
                      <tbody>
                        <tr>
                          <th>주소</th>
                          <td>{this.state.applyDtl.address}</td>
                        </tr>
                        <tr>
                          <th>세대수</th>
                          <td>{numberWithCommas(this.state.applyDtl.applyCnt)}세대</td>
                        </tr>
                      </tbody>
                    </table>
                    <div className="btn_inner col_half">
                      <button type="button" className="btn" onClick={() => this.menuClick('applyPay')}>단계별 필요 금액</button>
                      <button type="button" className="btn" onClick={() => this.menuClick('applyCalc')}>청약 가점 확인</button>
                    </div>
                  </div>
                </div>
                <div className="info_list">
                  <div className="info_tit _on">
                    <p>청약정보</p>
                    <i>화살표</i>
                  </div>
                  <div className="info_con apt_info" style={{ "display": "block" }}>
                    <table>
                      <caption>
                        <strong>청약정보</strong>
                        <p>공고일, 일정, 정보등 청약에 관련된 정보</p>
                      </caption>
                      <colgroup>
                        <col style={{ "width": "50px" }} />
                        <col style={{ "width": "100px" }} />
                      </colgroup>
                      <tbody>
                        <tr>
                          <th>주소</th>
                          <td>{this.state.applyDtl.address}</td>
                        </tr>
                        <tr>
                          <th>모집공고일</th>
                          <td className="_day">{dateFormatMsg(this.state.applyDtl.notiDate)}<span>{dateDiffMsg(this.state.applyDtl.notiDate)}</span></td>
                        </tr>
                      </tbody>
                    </table>
                    <div className="info_list_sub col3">
                      <div className="toggle_tit _on" onClick={this.infoToggle}>
                        <p>청약일정</p>
                        <i>화살표</i>
                      </div>
                      <div className="toggle_con" style={{ "display": "block" }}>
                        <table>
                          <caption>
                            <strong>청약일정</strong>
                            <p>순위나 계약일 등 일정에 관련된 테이블</p>
                          </caption>
                          <colgroup>
                            <col style={{ "width": "33.3%" }} />
                            <col style={{ "width": "33.3%" }} />
                            <col style={{ "width": "33.3%" }} />
                          </colgroup>
                          <thead>
                            <tr>
                              <th>분류</th>
                              <th>해당지역</th>
                              <th>기타지역</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <td>특별공급</td>
                              <td>{this.state.applyDtl.spLocalDate == '' ? '사업주체문의' : dateFormatMsg2(this.state.applyDtl.spLocalDate)}</td>
                              <td>{this.state.applyDtl.spEtcDate == '' ? '사업주체문의' : dateFormatMsg2(this.state.applyDtl.spEtcDate)}</td>
                            </tr>
                            <tr>
                              <td>1순위</td>
                              <td>{dateFormatMsg2(this.state.applyDtl.fstLocalDate)}</td>
                              <td>{dateFormatMsg2(this.state.applyDtl.fstEtcDate)}</td>
                            </tr>
                            <tr>
                              <td>2순위</td>
                              <td>{dateFormatMsg2(this.state.applyDtl.sndLocalDate)}</td>
                              <td>{dateFormatMsg2(this.state.applyDtl.sndEtcDate)}</td>
                            </tr>
                            <tr>
                              <td>당첨자발표일</td>
                              {this.state.applyDtl.pbshDate == null ? <td>사업주체문의</td> : <td>{dateFormatMsg2(this.state.applyDtl.pbshDate)}</td>}
                              {this.state.applyDtl.pbshDate == null ? <td>사업주체문의</td> : <td>{dateFormatMsg2(this.state.applyDtl.pbshDate)}</td>}
                            </tr>
                            <tr>
                              <td>계약일</td>
                              {this.state.applyDtl.contBeginDate == null ? <td>사업주체문의</td> : <td>{dateFormatMsg2(this.state.applyDtl.contBeginDate)}</td>}
                              {this.state.applyDtl.contBeginDate == null ? <td>사업주체문의</td> : <td>{dateFormatMsg2(this.state.applyDtl.contBeginDate)}</td>}
                            </tr>
                            <tr>
                              <td>입주예정일</td>
                              {this.state.applyDtl.expectDate == null ? <td>사업주체문의</td> : <td>{dateFormatMsg2(this.state.applyDtl.expectDate)}</td>}
                              {this.state.applyDtl.expectDate == null ? <td>사업주체문의</td> : <td>{dateFormatMsg2(this.state.applyDtl.expectDate)}</td>}
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                    {
                      (dateDiffMsg(this.state.applyDtl.pbshDate) != '완료')
                      &&
                      <div className="info_list_sub col5">
                        <div className="toggle_tit _on" onClick={this.infoToggle}>
                          <p>공급정보</p>
                          <i>화살표</i>
                        </div>
                        <div className="toggle_con" style={{ "display": "block" }}>
                          <table>
                            <caption>
                              <strong>공급정보</strong>
                              <p>유형, 평수, 공급수, 가격 등 방에 관련 된 정보들</p>
                            </caption>
                            <colgroup>
                              <col style={{ "width": "20%" }} />
                              <col style={{ "width": "20%" }} />
                              <col style={{ "width": "20%" }} />
                              <col style={{ "width": "20%" }} />
                              <col style={{ "width": "20%" }} />
                            </colgroup>
                            <thead>
                              <tr>
                                <th>주택유형</th>
                                <th>공급면적</th>
                                <th>분양가</th>
                                <th>특별공급</th>
                                <th>일반공급</th>
                              </tr>
                            </thead>
                            <tbody>
                              {
                                this.state.applyDtl.areaList &&
                                (this.state.applyDtl.areaList.length <= 0 ?
                                  <tr>
                                  </tr>
                                  :
                                  this.state.applyDtl.areaList.map((result, idx) => {
                                    return (
                                      <React.Fragment key={idx}>
                                        <tr>
                                          <td>{result.hsType}</td>
                                          <td>{result.hsTypePy}평</td>
                                          <td>{numberWithCounts(result.applyPrice * 10000)}</td>
                                          <td>{result.spCnt}</td>
                                          <td>{result.geCnt}</td>
                                        </tr>
                                      </React.Fragment>
                                    )
                                  })
                                )
                              }
                            </tbody>
                          </table>
                          <span className="table_tag">* 분양가 최고가 기준</span>
                        </div>
                      </div>
                    }

                    {
                      (dateDiffMsg(this.state.applyDtl.pbshDate) == '완료')
                      &&
                      <div className="info_list_sub col5">
                        <div className="toggle_tit _on" onClick={this.infoToggle}>
                          <p>청약경쟁률</p>
                          <i>화살표</i>
                        </div>
                        <div className="toggle_con" style={{ "display": "block" }}>
                          <table>
                            <caption>
                              <strong>청약완료시 필요한 정보</strong>
                              <p>주택유형, 공급면적, 분양가(최고가 기준), 1순위 경쟁률</p>
                            </caption>
                            <colgroup>
                              <col style={{ "width": "25%" }} />
                              <col style={{ "width": "25%" }} />
                              <col style={{ "width": "25%" }} />
                              <col style={{ "width": "25%" }} />
                            </colgroup>
                            <thead>
                              <tr>
                                <th>주택유형</th>
                                <th>공급면적</th>
                                <th>분양가</th>
                                <th>1순위경쟁률</th>
                              </tr>
                            </thead>
                            <tbody>
                              {
                                this.state.applyDtl.areaList &&
                                (this.state.applyDtl.areaList.length <= 0 ?
                                  <tr>
                                  </tr>
                                  :
                                  this.state.applyDtl.areaList.map((result, idx) => {
                                    return (
                                      <React.Fragment key={idx}>
                                        <tr>
                                          <td>{result.hsType}</td>
                                          <td>{result.hsTypePy}평</td>
                                          <td>{numberWithCounts(result.applyPrice * 10000)}</td>
                                          <td>{result.firstRate}</td>
                                        </tr>
                                      </React.Fragment>
                                    )
                                  })
                                )
                              }
                            </tbody>
                          </table>
                          <span className="table_tag">* 분양가 최고가 기준</span>
                        </div>
                      </div>
                    }
                    <div className="btn_inner">
                      <button type="button" className="btn_basic" onClick={() => this.menuClick('applyPredict')}>청약 당첨 예상 계산</button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {
        (this.state.step === 3 && this.state.applyPayDtl && this.state.applyPayDtl.applyPayCalc) &&
        <MapApplyPayCalc
          flrNo={this.state.flrNo}
          hsType={this.state.hsType}
          applyDtl={this.state.applyDtl}
          applyPayDtl={this.state.applyPayDtl}
          balconyYn={this.state.balconyYn}
          optYn={this.state.optYn}
          optAmt={this.state.optAmt}
          onRenderGraph={this.state.onRenderGraph}
        ></MapApplyPayCalc>
      }

      <div id="mw-costinfo" className="ly_cpinfo_pc ly_info_swiper ndim">
        <div className="wrap">
          <div className="pop_info_swiper02 swiper-container swiper_reset">
            <div className="swiper-pagination"></div>
            <div className="swiper-wrapper">
              <section className="swiper-slide slide01">
                <figure className="img_wrap st_4"></figure>
                <div className="ani_wrap">
                  <h1 className="slide_tit">아파트 청약 당첨 시<br /> <strong>해당아파트는 얼마일까?</strong></h1>
                  <p className="sub_txt">계약부터 입주까지,<br />총 비용이 궁금한 청약아파트를 검색해주세요.</p>
                  <div className="form_ani srch_ani">
                    <div className="box">
                      <p className="show01">청약정보를 검색해주세요.</p>
                      <p className="show02"><code>민앤지포레나퍼스트시티</code></p>
                    </div>
                  </div>
                  <div className="btn_wrap">
                    <button type="button" className="btn_next">다음<i className="cursor"></i></button>
                  </div>
                </div>
              </section>
              <section className="swiper-slide slide02">
                <figure className="img_wrap st_5"></figure>
                <div className="ani_wrap">
                  <h1 className="slide_tit">아파트 청약 당첨 시<br /> <strong>단계별 필요 금액은 얼마일까?</strong></h1>
                  <p className="sub_txt">타입 및 층수별로 총 비용이 달라져요!<br />검색한 아파트 타입 및 층수를 선택해주세요.</p>
                  <div className="select_ani_wrap">
                    <div className="select_ani ani01">
                      <div className="form_ani">
                        <div className="box">
                          <p className="show01">타입</p>
                          <p className="show02">59.7700B</p>
                        </div>
                      </div>
                      <div className="selet_detail">
                        <div className="inner_wrap">
                          <button type="button" className="item">
                            <span>59.7700B</span>
                          </button>
                          <button type="button" className="item">
                            <span>49.9400A</span>
                          </button>
                        </div>
                      </div>
                    </div>
                    <div className="select_ani ani02">
                      <div className="form_ani">
                        <div className="box">
                          <p className="show01">층수</p>
                          <p className="show02">10층</p>
                        </div>
                      </div>
                      <div className="selet_detail">
                        <div className="inner_wrap">
                          <button type="button" className="item">
                            <span>8층</span>
                          </button>
                          <button type="button" className="item">
                            <span>10층</span>
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="btn_wrap">
                    <button type="button" className="btn_next">다음<i className="cursor"></i></button>
                  </div>
                </div>
              </section>
              <section className="swiper-slide slide03">
                <figure className="img_wrap st_5"></figure>
                <div className="ani_wrap">
                  <h1 className="slide_tit">아파트 청약 당첨 시<br /> <strong>단계별 필요 금액은 얼마일까?</strong></h1>
                  <p className="sub_txt">발코니 확장 여부 선택 및 아파트 유상옵션 금액을<br />입력해주세요.</p>
                  <div className="select_ani_wrap">
                    <div className="select_ani ani01">
                      <div className="form_ani">
                        <div className="box">
                          <p className="show01">발코니 확장</p>
                          <p className="show02">확장</p>
                        </div>
                      </div>
                      <div className="selet_detail">
                        <div className="inner_wrap">
                          <button type="button" className="item">
                            <span>확장</span>
                          </button>
                          <button type="button" className="item">
                            <span>비확장</span>
                          </button>
                        </div>
                      </div>
                    </div>
                    <div className="select_ani ani02">
                      <div className="form_ani">
                        <div className="box">
                          <p className="show01">유상옵션 금액</p>
                          <p className="show02">미선택</p>
                        </div>
                      </div>
                      <div className="selet_detail">
                        <div className="inner_wrap">
                          <button type="button" className="item">
                            <span>선택</span>
                          </button>
                          <button type="button" className="item">
                            <span>미선택</span>
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="btn_wrap">
                    <button type="button" className="btn_next" onClick={this.infoPopClose}>당첨예상 계산하기<i className="cursor"></i></button>
                  </div>
                </div>
              </section>
            </div>
          </div>
          <button type="button" className="btn_txt" onClick={this.infoPopCloseNeverOpen}>다시보지않기</button>
          <button type="button" className="close" onClick={this.infoPopClose}><i>닫기</i></button>
        </div>
      </div>
    </>)
  }
}
export default MapApplyPaySrch;