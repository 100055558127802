import React, { Component } from 'react';
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import RMateColumnMSType from 'routes/rMateChart/RMateColumnMSType';

//// 컴포넌트 사용처 : 청약 당첨예상 상세
class AnaylyzePoint extends Component {

  gotoApplyCalc = (e) => {
    e.preventDefault();
    window.open(`/applyCalc`, '_blank');
  }

  render() {

    const { score, scoreCheckFlag } = this.props.calcResult;

    let scoreData = [0, 0, 0];

    if(score) {
      scoreData = [Number(score.houseScore), Number(score.familyScore), Number(score.accountScore)];
    }

    const options = {
      chart: {
        type: 'column',
        height: '250px'
      },
      title: {
        text: ''
      },
      xAxis: {
        categories: [
          // '<div className="chart_lbl"><span className="house">무주택</span></div>',
          // '<div className="chart_lbl"><span className="family">부양가족</span></div>',
          // '<div className="chart_lbl"><span className="account">청약통장</span></div>'
          '무주택', '부양가족', '청약통장'
        ],
        labels: {
          useHTML: true,
          padding: 1
        },
        align: 'center'
      },
      yAxis: {
        min: 0,
        title: {
          text: ''
        },
      },
      plotOptions: {
        column: {
          dataLabels: {
            enabled: true
          },
          groupPadding: 0.4
        }
      },
      series: [{
        color: 'rgb(225, 225, 225)',
        pointPadding: 0.2,
        name: '가점 최고점',
        data: [32, 35, 17],
        dataLabels: {
          color: 'gray'
        }

      }, {
        color: 'rgb(255, 73, 59)',
        name: '내 가점 점수',
        pointPadding: 0.2,
        data: scoreData,
        dataLabels: {
          color: 'rgb(255, 73, 59)'
        }
      }]
    }

    const scoreType = (
      (score.total < 26) ? 'bad' :
        (score.total >= 26 && score.total < 43) ? 'normal' :
          (score.total >= 43 && score.total < 68) ? 'good' :
            (score.total >= 68) ? 'best' : '');

    let graphList;

    if (score) {
      graphList = [
        { "xAxis": "무주택", "yAxis1": 32, "yAxis2": Number(score.houseScore) },
        { "xAxis": "부양가족", "yAxis1": 35, "yAxis2": Number(score.familyScore) },
        { "xAxis": "청약통장", "yAxis1": 17, "yAxis2": Number(score.accountScore) }
      ];
    }
    else {
      graphList = [
        { "xAxis": "무주택", "yAxis1": 32, "yAxis2": 0 },
        { "xAxis": "부양가족", "yAxis1": 35, "yAxis2": 0 },
        { "xAxis": "청약통장", "yAxis1": 17, "yAxis2": 0 }
      ];
    }

    return (
      <>
        <h3>
          <s>3.</s>나의 가점 분석
        <div className="q_mark bt">
            <div className="q_mark_inner">
              <p className="tit">나의 가점 분석이란?</p>
              <p className="txt">
                고객님께서 계산하시거나, 입력하신 청약가점을 분석하여 어느 가점 항목이 부족한지 안내해드려요.
            </p>
            </div>
          </div>
        </h3>
        <div className="sec" id="sec3">
          <div className={`ty_graph ani ${scoreType}`}>
            <div>
              <div className="graph">
                <div className="dount_area">
                  <div className="circle_chart" data-percent={score.total} data-permax="84">
                    <div className="chart-box">
                      <svg>
                        <defs>
                          <linearGradient id={`dount_${scoreType}`} x1="0" y1="0" x2="1" y2="1">
                            <stop className="color_1" offset="0%" />
                            <stop className="color_2" offset="100%" />
                          </linearGradient>
                        </defs>
                        <circle stroke={`url(#dount_${scoreType})`} r="49%" cx="50%" cy="50%" className="circle_progress" stroke-width="15" stroke-linecap="round"></circle>
                        <circle className="marker" r="8" cx="50%" cy="125" style={{ 'filter': 'url(#shadow)' }}></circle>
                        <filter id="shadow">
                          <feDropShadow dx="0" dy="0" stdDeviation="2"
                            flood-color="#CDCDCD" />
                        </filter>
                      </svg>
                      <div className="per-num"><span>{score.total}</span>/<s>84</s></div>
                    </div>
                  </div>
                </div>
                <div className="btn_inner">
                  <button type="button" className="btn_line" onClick={this.gotoApplyCalc}>가점 다시 계산하기</button>
                </div>
              </div>
            </div>
            <div>
              {
                scoreCheckFlag === true &&
                //   <div className="caption emoji">
                //     <p>
                //       고객님은 총점 <em>84점 중 {84 - score.total}점이 부족</em>하네요.  무주택, 부양가족, 청약통장 모든 부분이 부족해요.<br />
                //       혹시나 잘못 기입한 부분이 있나 확인해보시거나 추첨을 노려보시는 건 어떨까요?
                // </p>
                //   </div>
                //:
                <div className="caption emoji">
                  <p>
                    <em>본인의 청약가점을 정확하게 입력하셨나요?</em><br />
                    매년 1만명 이상이 본인의 청약가점을 잘못 입력하여 청약 당첨이 취소!<br />
                    부적격자로 당첨 취소 시 1년간 청약신청 불가!
										</p>
                </div>
              }
              <div className="chart my_point">
                {
                  scoreCheckFlag === true &&
                  <div className="nodata" style={{ "z-index": "1" }}>
                    <p>청약 신청 전, 내 가점을 정확하게 계산하세요!</p>
                    <button type="button" className="btn" onClick={this.gotoApplyCalc}>청약 가점 계산하기</button>
                  </div>
                }
                <div id="rMateDiv0" style={{ "width": "760px", "height": "247px" }}>
                  {
                    this.props.onRenderGraph &&
                    <RMateColumnMSType
                      parentDivId={'rMateDiv0'}
                      graphList={graphList}
                    ></RMateColumnMSType>
                  }
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default AnaylyzePoint;
