import React from 'react';
import CommonComponent from './Common';
import { Link } from 'react-router-dom';
import { Viewer } from "@toast-ui/react-editor";
import $ from 'jquery';

//// 컴포넌트 사용처 : 공지사항
class Notice extends CommonComponent {
  componentDidMount() {
    this.fetchNoticeList();
  }

  componentDidUpdate() {
    $('.bbs_list p').css('line-height','1.3');
  }

  renderPaging() {
    const beginPage = this.state.paginationObj.beginPage;
    const endPage = this.state.paginationObj.endPage;
    const totalPage = this.state.paginationObj.totalPage;

    let pageArr = [];

    for (let i = beginPage; i <= endPage; i++) {
      if (i === 1) {
        pageArr.push(1);
      }
      else {
        pageArr.push(i);
      }
    }
    return (
      pageArr.map((page, idx) => {
        if (page === this.state.currentPage) {
          return (
            <li key={idx}>
              <a href="#" className="on" onClick={() => this.moveNoticePage(page)} title="{page}번 페이지로 이동">{page}</a>
            </li>
          )
        }
        else {
          return (
            <li key={idx}>
              <a href="#" onClick={() => this.moveNoticePage(page)} title="{page}번 페이지로 이동">{page}</a>
            </li>
          )
        }
      })
    )
  }

  render() {
    return (
      <div id="content">
        <div className="inner_wrap">
          <h2 className="hide">고객센터</h2>
          <div className="con_tit_wrap">
            <h3 className="tit">공지사항</h3>
            <p className="sub_txt">아파트청약케어 공지사항 메뉴 입니다.</p>
          </div>
          <div className="bbs_wrap">
            <table className="bbs_list toggle_list">
              <caption>
                <strong>공지사항 목록</strong>
                <p>공지사항 목록으로 번호,제목,등록일 로 구성된 표</p>
              </caption>
              <colgroup>
                <col style={{ "width": "95px" }} />
                <col />
                <col style={{ "width": "120px" }} />
              </colgroup>
              <thead>
                <tr>
                  <th><code>NO</code></th>
                  <th>제목</th>
                  <th>등록일</th>
                </tr>
              </thead>
              <tbody>
                {
                  this.state.noticeList.length <= 0 ?
                    <tr className="nodata">
                      <td colSpan="3">게시글이 없습니다.</td>
                    </tr>
                    :
                    this.state.noticeList.map((notice, idx) => {
                      return (
                        <React.Fragment key={idx}>
                          <tr className="q">
                            <td className="num"><code>{notice.num}</code></td>
                            <td className="tit">
                              <div>
                                <button type="button" className="btn_detail">{notice.title}</button>
                              </div>
                            </td>
                            <td className="date"><code>{notice.regTime}</code></td>
                          </tr>
                          <tr className="a">
                            <td colSpan="3" className="view">
                              <div className="con">
                                {/* <pre dangerouslySetInnerHTML={{ __html: notice.body }}>
                                </pre> */}
                                <Viewer
                                  initialValue={notice.body}
                                />
                              </div>
                            </td>
                          </tr>
                        </React.Fragment>
                      )
                    })
                }
              </tbody>
            </table>
            {
              this.state.noticeList.length > 0 ?
                <div className="pagination">
                  <a href="#" className="btn_prve" title="이전 5개 목록으로 이동" onClick={() => this.moveNoticePrevPages()}></a>
                  <ul>
                    {this.renderPaging()}
                  </ul>
                  <a href="#" className="btn_next" title="다음 5개 목록으로 이동" onClick={() => this.moveNoticeNextPages()}></a>
                </div>
                : ''
            }
          </div>
        </div>
      </div>
    );
  }
}
export default Notice;