import React, { Component } from 'react';
import MapAptRcmdSrch from './MapAptRcmdSrch';
import axios from 'axios';
import Moment from 'moment';
import $ from 'jquery'
import { AroundOverlay } from 'routes/common/overLay/AroundOverlay.js';
import { RcmdAptOverlay } from 'routes/common/overLay/RcmdAptOverlay.js';

//// 컴포넌트 사용처 : 아파트 추천
class MapAptRcmd extends Component {

  constructor(props) {
    super(props);
    this.map = {};
    this.state = {
      aptId: "",
      areaPy: "",
      aptList: [],
      aptDtl: [],
      bjdName: "",
    };
    this.overLayList = [];
    this.aroundOverLayList = [];
    this.search = {
      type: "",
      id: ""
    };
  }

  componentDidUpdate = () => {
    if (localStorage.getItem('jwtToken') == undefined || localStorage.getItem('jwtToken') == null) {
      window.location.href = '/login';
      window.close();
    }
  }

  componentDidMount() {

    $('html').addClass('_full');

    let mapContainer = document.getElementById('mapview'); // 지도를 표시할 div 
    let mapOption = {
      center: new kakao.maps.LatLng(37.57100, 126.97696), // eslint-disable-line
      level: 3
    };

    let map = new kakao.maps.Map(mapContainer, mapOption); // eslint-disable-line
    this.map = map;

    kakao.maps.event.addListener(map, 'dragend', () => { // eslint-disable-line

    });
    kakao.maps.event.addListener(map, 'zoom_changed', () => {  // eslint-disable-line
    });

    // 메인 팝업 입력값 가져오기
    const search = this.props.location.search;
    const params = new URLSearchParams(search);
    const address = params.get('address');
    const yearPay = params.get('yearPay');

    if (address != null && yearPay != null) {
      $("#search_box01").val(address);
      $("#apt_income").val(yearPay);

      $("#search_box01").focus();
    }

  }

  overlayMap = (map) => {
    this.state.aptList.forEach(v => {
      let overlay = new RcmdAptOverlay(v);
      overlay.setMap(this.map);
      this.overLayList.push(overlay);

      $('#marker_' + v.aptId).click(() => {
        let moveLatLng = new kakao.maps.LatLng($('#lat_' + v.aptId).val(), $('#lng_' + v.aptId).val()); // eslint-disable-line
        map.panTo(moveLatLng);
        this.selectApt(v.aptId);
        $('#apt_btn_' + v.aptId).click();
      });

      $('#markerBjd_' + v.aptId).click(() => {
        let moveLatLng = new kakao.maps.LatLng($('#latBjd_' + v.aptId).val(), $('#lngBjd_' + v.aptId).val()); // eslint-disable-line
        map.panTo(moveLatLng);
      });
    });

    this.search = {
      type: "",
      id: ""
    };
  }

  clearOverlay = () => {
    this.overLayList.forEach(ov => ov.setMap(null));
    this.overLayList = [];
  }

  clearAroundOverlay = () => {
    this.aroundOverLayList.forEach(ov => ov.setMap(null));
    this.aroundOverLayList = [];
  }

  renderBjdMarker = () => {
    return `<div class="map_marker" id="markerBjd_${this.state.aptList[0].aptId}">
          <div class="marker_inner type_apt">
            <input type="hidden" id="latBjd_${this.state.aptList[0].aptId}" value="${this.state.aptList[0].lat}" >
            <input type="hidden" id="lngBjd_${this.state.aptList[0].aptId}" value="${this.state.aptList[0].lng}" >
						<div class="data">
							<p class="sqf">${this.state.bjdName}</p>
							<p class="price"><em></em></p>
							<p class="day">${this.state.aptList.length}건</p>
						</div>
					</div>
				</div>`;
  }

  handleRcmd = ({ list, bjdName }) => {
    this.clearOverlay();
    this.clearAroundOverlay();
    this.setState({
      aptList: list,
      bjdName: bjdName,
    })
    this.overlayMap(this.map);
  }

  handleRcmdDtl = ({ aptDtl }) => {
    this.clearAroundOverlay();
    this.renderingAroundDataIcons(aptDtl);
    this.map.setLevel(3);
    this.selectApt(aptDtl.aptId);
    this.handlePanTo({ lat: aptDtl.lat, lng: aptDtl.lng });
  }

  handleSearch = ({ search }) => {
    this.clearOverlay();
    this.clearAroundOverlay();

  }

  handlePanTo = ({ lat, lng }) => {
    let moveLatLng = new kakao.maps.LatLng(lat, lng); // eslint-disable-line
    this.map.panTo(moveLatLng);
  }

  comonentWillUnmount() {
    ////console.log('MAP_comonentWillUnmount');
    $('html').removeClass('_full');
  }

  renderingAroundDataIcons(aptDtl) {
    this.aroundOverLayList.forEach(ov => ov.setMap(null));
    this.aroundOverLayList = [];
    const dtl = aptDtl;

    dtl.schoolList.forEach(v => {
      v.type = 'school';
      let overlay = new AroundOverlay(v);
      overlay.setMap(this.map);
      this.aroundOverLayList.push(overlay);
    });

    dtl.departList.forEach(v => {
      v.type = 'ico_mart';
      let overlay = new AroundOverlay(v);
      overlay.setMap(this.map);
      this.aroundOverLayList.push(overlay);
    });

    dtl.stationList.forEach(v => {
      v.type = 'ico_subway';
      let overlay = new AroundOverlay(v);
      overlay.setMap(this.map);
      this.aroundOverLayList.push(overlay);
    });

    dtl.hospitalList.forEach(v => {
      v.type = 'ico_hospital';
      let overlay = new AroundOverlay(v);
      overlay.setMap(this.map);
      this.aroundOverLayList.push(overlay);
    });

    dtl.parkList.forEach(v => {
      v.type = 'ico_park';
      let overlay = new AroundOverlay(v);
      overlay.setMap(this.map);
      this.aroundOverLayList.push(overlay);
    });
  }

  selectApt = (aptId) => {

    $('.map_marker').removeClass('_active');

    if ($('#marker_' + aptId).children().hasClass('type_apt')) {

      if ($('#marker_' + aptId).hasClass('_active')) {
        $('#marker_' + aptId).removeClass('_active');
        $('.panel_marker').removeClass('on');
      } else {
        $('#marker_' + aptId).addClass('_active');
        $('.panel_fold.on').removeClass('on');
        $('.panel_fold').find('.btn_fold').addClass('on');
        $('.panel_fold').removeClass('fold');
        $('.panel_fold').removeClass('now');
        $('.panel_marker').removeClass('fold');
        $('.panel_marker').addClass('_first');
        $('.panel_marker').addClass('on');
        $('.panel_marker .btn_fold').addClass('on');
      }
    }

  }

  render() {
    return (<>
      <div id="content">
        <section className="apt_wrap">
          <MapAptRcmdSrch
            onRcmd={({ list, bjdName }) => this.handleRcmd({ list, bjdName })}
            onRcmdDtl={({ aptDtl }) => this.handleRcmdDtl({ aptDtl })}
            onPanTo={({ lat, lng }) => this.handlePanTo({ lat, lng })}
            onSearch={(search) => this.handleSearch(search)}
          ></MapAptRcmdSrch>
        </section>
        <div className="map_img" id="mapview" ></div>
      </div>
    </>);
  }
}
export default MapAptRcmd;