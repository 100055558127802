import React, { Component } from 'react';
import { formatYmd, formatAmt, numberWithCommas } from 'common/StringUtil';

//// 컴포넌트 사용처 : 아파트 정보 즐겨찾기
class MapAptFav extends Component {

  render() {

    return (
      <ul>
        <li class="bookmark_tit">
          <strong>시세 / 단지정보 즐겨찾기</strong>
        </li>
        {
          this.props.favResult.length === 0 ?
            <li class="info_none">
              <div class="img_area">
                <figure>
                  <img src={process.env.REACT_APP_ENV_URL + "resources/images/info_none_2.png"} alt="noti_img" />
                </figure>
                <p>아직 즐겨찾기된 아파트가 없어요</p>
              </div>
            </li>
            :
            this.props.favResult.map(v => {
              const amt = v.amt;
              const amtStr = amt > 10000 ? <><s>{formatAmt(amt, 'amt4')}</s> 억 <s>{formatAmt(amt, 'amt5')}</s></> : <><s>{formatAmt(amt, 'amt5')}</s></>;

              return <li class="apt_item">
                <button type="button" class="btn_apt"
                  onClick={(e) => this.props.onMoveDtl(e, v.aptId, v.aptAreaId)}
                ><i>아파트 리스트</i></button>
                <div class="item_info">
                  <i class="ico_close"
                    onClick={() => this.props.onClearFav(v)}
                  ></i>
                  <h4>{v.aptName}</h4>
                  <p class="address">{v.jibunAddr}</p>
                  <p class="price">{amtStr}</p>
                  <p>{formatYmd(v.trxDate, "comma1")}거래 / {v.useAprDay}년 / {numberWithCommas(v.hhldCnt)}세대
                                          {
                      (v.loanRateCase === '1' || v.loanRateCase === '2') ?
                        '/ 투기과열'
                        :
                        (v.loanRateCase === '3' || v.loanRateCase === '4') ?
                          '/ 조정지역'
                          :
                          null
                    }
                  </p>
                </div>
              </li>
            }
            )
        }
      </ul>
    );
  }
}
export default MapAptFav;