import $ from 'jquery'
import { TweenMax, TimelineMax, Linear, Power0 } from 'gsap'
import Swiper from 'swiper/bundle'

var UI = UI || {};

export const uiHeader = UI.header = {
  init: function () {
    var _li = $('#header li'),
      _header = $('#header'),
      _headerOn = $('#header.off');
    _li.mouseover(function () {
      _header.removeClass('off');
    });
    _headerOn.mouseleave(function () {
      _header.stop().addClass('off');
    });
    _li.on('click', function () {
      $(this).parents('.allMenuWrap').find('a').removeClass('on');
      $(this).find('> a').addClass('on')
    });
  }
}

UI.depOpenFirst = {
  init: function () {
    $(this).each(function (idx) {
      var _dep = $('.panel_st1 .btn_basic'),
        _depPrent = _dep.parents('.apt_wrap');

      _dep.on('click', function () {
        _depPrent.addClass('_2dep');
        _dep.parents('div[class^=panel_]').find('.btn_fold').addClass('none');
      });
    })
  }
}
UI.depOpenSec = {
  init: function () {
    $(this).each(function (idx) {
      var _dep = $('.apt_wrap .btn_apt'),
        _depPrent = _dep.parents('.apt_wrap');

      _dep.on('click', function () {
        _depPrent.find('.panel_st2').css('z-index', '5');
        _dep.parents('div[class^=panel_]').find('.btn_fold').addClass('none');
        setTimeout(function () {
          _depPrent.addClass('_3dep');
        });
      });
    })
  }
}
UI.depClose = {
  init: function () {
    var _dep = $('div[class^=panel_st] .btn_close'),
      _depPrent = $('div[class^=panel_st] .btn_close').closest('.apt_wrap');
    _dep.on('click', function () {
      if (_depPrent.hasClass('_3dep')) {
        _depPrent.removeClass('_3dep');
        _dep.parents('div[class^=panel_]').find('.btn_fold').removeClass('none');
        setTimeout(function () {
          _depPrent.find('.panel_st2').css('z-index', '2');
        }, 410);
      } else {
        _depPrent.removeClass('_2dep');
        _dep.parents('div[class^=panel_]').prev().find('.btn_fold').removeClass('none');
        _depPrent.find('.panel_st2').css('z-index', '2');
      }
    });
  }
}
export const uiCardfold = UI.cardfold = {
  init: function () {
    var _fold = $(".panel_card .btn_close"),
      _move = _fold.parents('div[class^=panel_]'),
      _set = _fold.parents('div[class^=panel_]').width();

    _fold.on('click', function () {
      if (_move.offset().left >= 0) {
        _move.css('left', + -_set);
        if (_fold.hasClass('on')) {
          _fold.removeClass('on');
          _move.removeClass('on');
        }
      } else if (_move.offset().left < -350) {
        _move.css('left', + 350);
        if (_fold.hasClass('btn_fold')) {
          _fold.addClass('on');
        }
      } else {
        _move.css('left', + 0);
        if (_fold.hasClass('btn_fold')) {
          _fold.addClass('on');
        }
      }
    });
  }
}
export const uiSearchVal = UI.searchVal = {
  init: function () {
    $(this).each(function (idx) {
      var _this = $(".ip_search input");
      _this.on('focus', function () {
        _this.siblings('.search_list').addClass('on');
        $(this).parent().addClass('focus');
        $(this).each(function (idx) {
          _this.siblings('.search_list').find('li').on('click', function (e) {
            e.preventDefault();
            var _this = $(this).find('a');
						/* if(_this.children().hasClass('core')) {
							// var value = _this.find('i').text();
							// $(this).parents('.search_list').siblings('input').val(value);
						} else {
							var value = $(this).text();
							$(this).parents('.search_list').siblings('input').val(value);
						} */
            $(this).parents('.search_list').removeClass('on');
          });
        });
      }).on('blur', function (e) {
        $(this).each(function (idx) {
          $(this).parent().removeClass('focus');

          if (!$.trim($(this).val()) == "") {
            $(this).parent().find('.lbl').hide();
            $(this).parent().addClass('in');
            setTimeout(function () {
              $(e.target).parent().find("button.pen").show();
            }, 250);
          } else {
            $(this).parent().removeClass('in');
            $(this).parent().find('.lbl').show();
          }

					/* _this.siblings('.search_list').find('li').on('click', function(e) {
						e.preventDefault();
						var _this = $(this).find('a');
						if(_this.children().hasClass('core')) {
							var value = _this.find('i').text();
							$(this).parents('.search_list').siblings('input').val(value);
						} else if(_this.children().has('s')) {
							var value = _this.find('s').text();
							$(this).parents('.search_list').siblings('input').val(value);
						}else {
							var value = $(this).text();
							$(this).parents('.search_list').siblings('input').val(value);
						}
						$(this).parents('.search_list').removeClass('on');
					}); */
        });
        _this.on("keyup", function () {
          if ($.trim($(this).val()) == "") {
            $(this).parent().find('.lbl').hide();
          }
        });
      });


      // search_list box over
      $(document).mouseup(function (e) {
        var selList = $(".search_list");
        $(this).each(function (idx) {
          if (selList.has(e.target).length === 0) {
            if (!$('.search_box').hasClass('focus')) {
              $('.search_list').removeClass('on');
            }
          }
        });
      });
    });
  }
}
export const uiCalcSelect = UI.calcSelect = {
  init: function () {
    var _this = $(".select_apt .btn_select");
    _this.each(function (idx) {
      var _this = $(this);
      _this.on('click', function () {
        $('.select_apt').removeClass('focus');
        if (_this.parents('.select_apt').hasClass('focus')) {
          _this.parents('.select_apt').removeClass('focus');
        } else {
          _this.parent().addClass('focus');
          _this.next().find('li').on('click', function (e) {
            e.preventDefault();
            var value = $(this).find('span:first').text();
            $(this).parents('.select_wrap').prev('.btn_select').text(value);
            $(this).parents('.select_apt').addClass('in');
            $(this).parents('.select_apt').removeClass('focus');
          });
        }
      });
    });
    // select box over
    $(document).mouseup(function (e) {
      var selBox = $(".select_apt");
      if (selBox.has(e.target).length === 0) {
        selBox.removeClass("focus");
      }
    });
  }
}
export const uiCardOpen = UI.cardOpen = {
  init: function () {
    var _cik = $(".panel_card div[class^=apt_card]"),
      _move = _cik.parents('div[class^=panel_]'),
      _set = _cik.parents('div[class^=panel_]').width(),
      _panel = $('div[class^=panel_]').width();
    _cik.on('click', function () {
      $('div[class^=panel_]').removeClass('on');
      _move.css('left', + -_set);
      setTimeout(function () {
        _move.prev().css('left', - _panel);
        _move.next().addClass('on');
      }, 400);
    });
  }
}
UI.cardClose = {
  init: function () {
    var _cik = $(".apt_card_back"),
      _move = _cik.parents('div[class^=panel_]'),
      _panel = $('div[class^=panel_]').width();
    _cik.on('click', function () {
      _move.removeClass('on');
      _move.prev().prev().css('left', + 0);
      if (_move.offset().left >= -360) {
        setTimeout(function () {
          _move.prev().css('left', + _panel);
        }, 100);
      }
    });
  }
}
UI.filter = {
  init: function () {
    $(document).off("click").on("click", "[class^='filter_'] > button", function (e) {
      e.preventDefault();
      if ($(this).hasClass('_set')) {
        $(this).removeClass('_set');
      } else {
        $(this).addClass('_set');
      }
    });
  }
}
export const uiAptToggle = UI.aptToggle = {
  init: function () {
    var tog = $(".content_list .info_list");
    tog.on("click", ".info_tit", function (e) {
      e.preventDefault();
      if ($(this).parent().hasClass('hold')) {

      } else {
        $(this).toggleClass('_on').next().slideToggle(200);
      }
    });
  }
}
export const uiCostList = UI.costList = {

  init: function () {
    $(".list_area .apt_item_list").each(function (idx) {
      var _this = $('.list_area .apt_item_list');
      //console.log('uiCostList init');
      _this.on('click', function (e) {
        //console.log(1);
        var text = $(this).find('h4').text();
        _this.addClass('_active').siblings().removeClass('_active');
        _this.parents('.list_area').addClass('hide').next().removeClass('hide');
        _this.parents('.panel_nomal').find('.search_box').find('input').val(text);
        //console.log(2);
      })
    });
  }
}
export const uiCostClose = UI.costClose = {
  init: function () {
    var _this = $('.cost_result_reset');

    $('.panel_card').css('left', '-983px');
    _this.on('click', function () {
      $('.content_list').removeClass('step_3');
      $('.panel_card').css('left', '-983px');
      setTimeout(function () {
        $('.panel_nomal .aptinfo_area').addClass('hide');
      });
    })
  }
}
export const btnSwitch = UI.btnSwitch = {
  init: function () {
    $(this).each(function (idx) {
      $(".result_wrap .item, .point_calculator .btn_inner button").on('click', function () {
        var _menu = $(".result_wrap .item").index(this),
          _next = $(".point_calculator .btn_inner .btn_next"),
          _prev = $(".point_calculator .btn_inner .btn_prev"),
          _dep = $(".calc_list_wrap .calc_item").eq(0),
          _this = $(this);

        $(document).scrollTop(80);
        if (_menu >= 1 || _this.hasClass('btn_next')) {
          _dep.removeClass('on').next().addClass('on');
          _next.addClass('disabled').siblings().removeClass('disabled');
          _next.text('완료');
          $(".result_wrap .item").first().removeClass('focus').siblings().addClass('focus');

        } else if (_menu <= 1 || _this.hasClass('btn_prev')) {
          _dep.addClass('on').next().removeClass('on');
          _prev.addClass('disabled').siblings().removeClass('disabled');
          _next.text('다음');
          $(".result_wrap .item").first().addClass('focus').siblings().removeClass('focus');
        }
      })
    });
  }
}
UI.chartTap = {
  init: function () {
    $(this).each(function (idx) {
      var _this = $('.chart_tit li');
      _this.click(function () {
        var tab = $(this).attr('data-tab');
        _this.removeClass('current');
        $('.tab_cont').removeClass('current');
        $(this).addClass('current');
        $('.' + tab).addClass('current');
      });
    })
  }
}
$(function () {
  $.fn.extend({
    // fold btn은 일괄수정 필요
		/* _foldDep: function() {
			$(this).each(function(idx){
				var _fold = $(this),
					_foldparent = _fold.parents('div[class^=panel_]'),
					_all =  $('apt_wrap');
				_fold.on('click', function(){
					

					if(_foldparent.hasClass('on') && _foldparent.hasClass('_first')) {
						_foldparent.removeClass('on');
						_fold.removeClass('on');
					} else if (_foldparent.hasClass('on')) {
						_foldparent.removeClass('on');
						_fold.removeClass('on');
					} else {
						_foldparent.addClass('on');
						_fold.addClass('on');
					}
				});
			});
		}, */
    // JQ plugin 안먹음 -- 다른걸구하거나 수정 후 재요청 필요
		/* count: function(){
			$(this).each(function(idx){
				$(this).numScroll({
					number: $(this).text(),
					symbol: true
				});
			});
		}, */
  });

  // btn_fold
  // $(".apt_wrap .btn_fold")._foldDep();

});


/************************************* hsw **********************************/



UI.toggleList = {
  init: function () {
    $(document).off("click").on("click", ".q", function (e) {
      e.preventDefault();
      if ($(this).hasClass('on')) {
        $('.a').find('.con').slideUp(200);
        $('html,body').animate({ scrollTop: 0 }, 200)
        $(this).removeClass('on');
      } else {
        $('.a').find('.con').slideUp(200);
        $('.q').removeClass('on');
        $(this).addClass('on').next().find('.con').slideToggle(
          200, function () {
            $('html,body').animate({
              scrollTop: $(this).offset().top - 120
            }, 200)
          });
      }
    });
  }
}
export const uiInputFocus = UI.inputFocus = {
  init: function () {
    $(this).each(function (idx) {
      var _input = $('.ip_box input');
      _input.on("focus", function () {
        $(this).parent().addClass('focus');
      }).on("blur", function (e) {
        $(this).parent().removeClass('focus');
        if ($(this).parent().children('textarea').length) {
          $(this).parent().addClass('textarea');
        }
        if (!$.trim($(this).val()) == "") {
          $(this).parent().find('.lbl').hide();
          $(this).parent().addClass('in');
          setTimeout(function () {
            $(e.target).parent().find("button.pen").show();
          }, 250);
        } else {
          $(this).parent().removeClass('in');
          $(this).parent().find('.lbl').show();
        }
      }).blur();
      _input.on("keyup", function () {
        if ($.trim($(this).val()) == "") {
          $(this).parent().find('.lbl').hide();
        }
      });
      $(this).parent().find("button.pen").on("click", function () {
        $(this).parent().find("input").trigger("change").focus();
      });
    })
  }
}
export const uiInfoToggle = UI.infoToggle = {
  init: function () {
    var tog = $('.toggle_tit');
    tog.on("click", function (e) {
      e.stopPropagation();
      if ($(this).hasClass('_on')) {
        $(this).removeClass('_on').next().slideUp(200);
      } else {
        $(this).addClass('_on').next().slideDown(200);
      }
    });
  }
}

//아파트 정보 스크롤
/* UI.scrollFix = {
	init: function () {
		var _this = $(".apt_wrap .info_list");
		var scroll_this = $('.aptinfo_area .mCustomScrollbar');
		scroll_this.mCustomScrollbar({
			callbacks:{
				whileScrolling :function(){
					accoScollFixd(this);
				},
				onOverflowYNone :function(){
					accoFixdNone(this);
				}
			}
		});
		function accoScollFixd(el){
			var fixH = $(".content_fixed").offset().top + 65;
			_this.each(function(idx){
				var offset = _this.eq(idx).offset().top;
				if(offset <= fixH) {
					var h = _this.eq(idx).find(".info_tit").outerHeight();
					_this.eq(idx).addClass("_fix").find(".info_tit").css("top",Math.ceil(fixH+(h*idx)));
					
				}else if(offset > (fixH + 40)) {
					_this.eq(idx).removeClass("_fix");
				}
			});
			if (el.mcs.top > -fixH ) {
				$(".apt_wrap .info_list").removeClass("_fix");
			}
		};
		function accoFixdNone(el){$(".apt_wrap .info_list").removeClass("_fix");
		}
		_this.each(function(idx){
			$(this).off("click").on("click",".info_tit", function(e){
				e.preventDefault();
				var _target = $(this);
				if(_target.hasClass('_on')){
					$(".apt_wrap .info_list").find(".info_con").slideUp(200,function(){
						scroll_this.mCustomScrollbar('scrollTo', 0);
					});
					_target.removeClass("_on");
				}else{
					$(".apt_wrap .info_list").find(".info_con").slideUp(200);
					$(".apt_wrap .info_list").find(".info_tit ").removeClass("_on");
					_target.addClass('_on').next().slideToggle(200,function(){
						var relativeEventTop = scroll_this.find(".mCSB_container").find(".info_list").eq(idx).position().top;
						if(idx == 0) {
							scroll_this.mCustomScrollbar('scrollTo', 0);
						}else {
							scroll_this.mCustomScrollbar('scrollTo', Math.max(0, relativeEventTop - 35));
						}
						scroll_this.mCustomScrollbar("update");
					});
				}
			});
		});
	}
} */

/*
UI.slide = {
	init:function(){
		var $container = $(".inner_wrap_full .swiper-container_apt");
		var sliderLen = $container.find(".swiper-slide").length;
		if($container.find(".swiper-slide").length > 1) {
			var swiper = new Swiper('.swiper-container_apt', {
				spaceBetween: 20,
				slidesPerView: 'auto',
				watchSlidesVisibility: true,
				observer: true,
    			observeParents: true,
				navigation: {
					nextEl: '.swiper-button-next',
					prevEl: '.swiper-button-prev',
				},
				on: {
					slideChange:function() {
						//$container.find(".swiper-slide").removeClass("prev");
					},
					reachEnd:function () {
						var currentSlide = this.realIndex;
						//$("#content .silder_wrap .silder_con .swiper-container_apt").css("width","100%");
						//swiper.update();
						$container.find(".swiper-slide").last().css("opacity",1);
					}
				}
			});
		}
	}
}
*/

export const uiMotion = UI.motion = {

  scroll: function () {

    UI.$window = $(window);
    UI.$body = $("body");

    var _this = this;
    _this.$animate = UI.$body.find(".animate");

    var sec1, sec2, sec3;
    var status;


    var handler = {
      sec1: function SEC1_ANIMATE() {
        var $container = $('#sec1'), tl = new TimelineMax();
        tl.add([
          TweenMax.to($container.find('.bg'), .7, { x: 0, opacity: 1, delay: .4 }),
          TweenMax.to($container.find('.txt_wrap'), .7, { x: 0, opacity: 1, delay: .6 })
        ])

      },
      sec2: function SEC2_ANIMATE() {
        var $container = $('#sec2'), tl = new TimelineMax();
        tl.add([
          TweenMax.to($container.find('.bg'), .7, { x: 0, opacity: 1, delay: .4 }),
          TweenMax.to($container.find('.txt_wrap'), .7, { x: 0, opacity: 1, delay: .6 })
        ])
      },
      sec3: function SEC3_ANIMATE() {
        var $container = $('#sec3'), tl = new TimelineMax();
        tl.add([
          TweenMax.to($container.find('.bg'), .7, { x: 0, opacity: 1, delay: .4 }),
          TweenMax.to($container.find('.txt_wrap'), .7, { x: 0, opacity: 1, delay: .6 }),
          TweenMax.to($(".join_info_wrap .btn_wrap"), .7, { y: 0, opacity: 1, delay: 1.2 })
        ])
      }
    }

    $(window).on("scroll", function () {
      var viewTop = $(this).scrollTop(),
        viewHeight = $(this).outerHeight(true) / 2,
        viewBottom = viewTop + viewHeight;

      _this.$animate.each(function () {
        var elementTop = $(this).offset().top,
          elementHeight = $(this).outerHeight(),
          elementBottom = elementTop + elementHeight;

        if (viewTop < elementBottom && viewBottom > elementTop) {
          var id = $(this).attr('id');
          handler[id]();
        }
      });
    }).trigger("scroll");
  }
}


export const uiCostCard = UI.costCard = {
  init: function () {
    $(this).each(function (idx) {
      var reset = $('.panel_card .btn_close_2, .btn_back_w'),
        list = $('.list_area .apt_item_list'),
        result = $('.filter_wrap_cost .cost_result'),
        close = $('.panel_st2 .btn_close_2');

      result.on('click', function () {
        var parent = $(this).parents('.apt_wrap'),
          panel = $(this).parents('div[class^=panel_]');

        parent.addClass('_3dep');
        parent.find('.panel_st2').css('z-index', '5');
        panel.find('.step_01').addClass('hide').siblings().removeClass('hide');
      })
      list.on('click', function () {
        var text = $(this).find('h4').text();
        $(this).addClass('_active').siblings().removeClass('_active');
        list.parents('.apt_wrap').addClass('_2dep');
        list.parents('div[class^=panel_]').find('.btn_fold').addClass('none');
        list.parents('div[class^=panel_]').next().find('.header_wrap').find('h3').text(text);
      })

      reset.on('click', function () {
        var parent = $(this).parents('.apt_wrap');

        parent.removeClass('_3dep');
        parent.find('.panel_st2').find('.step_01').removeClass('hide').siblings().addClass('hide');
        setTimeout(function () {
          parent.find('.panel_st2').css('z-index', '3');
        }, 450);
      })
      close.on('click', function () {
        var parent = $(this).parents('.apt_wrap');

        parent.removeClass('_2dep');
        parent.find('.panel_st2').css('z-index', '2');
      })
    });
  }
}
var scrollHeight = 0;
scrollHeight = $(document).scrollTop();
export const globalPopupOpen = function (obj, ty, timer) {
  var tar = $('#mw-' + obj);
  $('body').css('overflow', 'hidden');
  function mwOpen() {
    if (timer >= 0) {
      setTimeout(function () {
        if (tar.hasClass('popBtm')) {
          tar.addClass('show');
        } else {
          tar.show().addClass('show');
        }
      }, timer);
    } else {
      tar.show().addClass('show');
      if (tar.hasClass('ndim')) {
        tar.css('left', 0);
      }
      tar.find('.content').scrollTop(0);
    }
    // 영역 외 클릭 닫기
    tar.click(function (e) {
      if (!$(this).hasClass('ndim')) {
        if (!$('[class*="ly"]').find('.wrap').has(e.target).length) {
          if ($(this).hasClass('popBtm')) {
            $('body').css('overflow', '');
            $('[class*="ly"]').removeClass('show');
            window.onscroll = function () { };
          } else {
            globalPopupClose(obj, 'mw');
          }
        }
      }
    });
  }
  function mwOpenFix() {
    if (timer >= 0) {
      setTimeout(function () {
        if (tar.hasClass('popBtm')) {
          tar.addClass('show');
        } else {
          tar.show().addClass('show');
        }
      }, timer);
    } else {
      tar.show().addClass('show');
      if (tar.hasClass('ndim')) {
        tar.css('left', 0);
      }
      tar.find('.content').scrollTop(0);
    }
    // 영역 외 클릭 닫기
    tar.click(function (e) {
      if (!$(this).hasClass('ndim')) {
        if (!$('[class*="ly"]').find('.wrap').has(e.target).length) {
          globalPopupClose(obj, 'mwf');
        }
      }
    });
    scrollHeight = $(document).scrollTop();
    $('#wrap').css('position', 'fixed');
    $('#wrap').css('top', - scrollHeight);
  }
  if (ty === 'pc') { //pc
    tar.show();
  } else if (ty === 'mw') { //mobile 기본
    mwOpen();
    var x = window.scrollX, y = window.scrollY;
    window.onscroll = function () { window.scrollTo(x, y) };
  } else if (ty === 'mwb') {
    mwOpen();
    $('html,body').css('position', 'fixed');
  } else if (ty === 'mwf') { //mobile > #wrap을 fixed 시키는 방식
    mwOpenFix();
  }
}
export const globalPopupClose = function (obj, ty) {
  var tar = $('#mw-' + obj);
  $('body').css('overflow', '');
  if (ty === 'pc') {
    tar.hide().removeClass('show');
  } else if (ty === 'mw') {
    if (tar.hasClass('popBtm')) {
      tar.removeClass('show');
    } else {
      tar.hide().removeClass('show');;
    }
    $('html,body').css('position', '');
    window.onscroll = function () { };
  } else if (ty === 'mwf') {
    if (tar.hasClass('popBtm')) {
      tar.removeClass('show');
    } else {
      tar.hide().removeClass('show');;
    }
    $('#wrap').css('top', 0);
    $('#wrap').css('position', 'relative');
    $(document).scrollTop(scrollHeight);
  }
}

export const uiMoreView = UI.moreView = {
  init: function () {
    $('.amenities .amenities_items').each(function () {
      if ($('li', this).length > 3) {
        $(this).addClass('more');
        $(this).find('li').eq(2).nextAll().addClass('hide');
      }
    });
    $('.ico_more').on("click", function () {
      $(this).parent().toggleClass('show');
      $(this).parent().find('li').eq(2).nextAll().toggleClass('hide');
    });
  }
}

export const uiAptHeaderCustom = UI.aptHeaderCustom = {
  init: function () {
    setTimeout(function () {
      var $header = $(".apt_info_header"),
        tit = $header.find('.sub_header').find('h3'),
        aptName = $header.find('.apt_name').find('h3').text(),
        $scroll = $('div[class^=panel_]').find('.apt_info_header').siblings('.content_list').find('.scroll_area');

      var listTit = $(".apt_wrap .info_list");
      listTit.each(function (idx) {

        $(this).on('click', function () {
          if ($(this).hasClass('hold')) {
            let position = 0;

            if (idx === 0) {
              position = 0;
            }
            else if (idx === 1) {
              position = $('.info_con').eq(0).innerHeight() - $('.info_tit').eq(0).innerHeight();
            }
            else if (idx === 2) {
              position = ($('.info_con').eq(0).innerHeight() + $('.info_con').eq(1).innerHeight()) - ($('.info_tit').eq(0).innerHeight() + $('.info_tit').eq(1).innerHeight());
            }
            else if (idx === 3) {
              position = ($('.info_con').eq(0).innerHeight() + $('.info_con').eq(1).innerHeight() + $('.info_con').eq(2).innerHeight()) - ($('.info_tit').eq(0).innerHeight() + $('.info_tit').eq(1).innerHeight() + $('.info_tit').eq(2).innerHeight());
            }
            $scroll.mCustomScrollbar('scrollTo', position, { scrollInertia: 10 });
          }
        });

      });

      if (document.location.href.search('apply') > -1) {
        tit.attr('data-tit', '최근 청약정보');
        tit.text('최근 청약정보');
      }
      if (document.location.href.search('applyPay') > -1) {
        tit.attr('data-tit', '');
        tit.text('');
      }

      $scroll.mCustomScrollbar("destroy");
      $scroll.mCustomScrollbar({
        scrollButtons: {
          enable: true
        },
        callbacks: {
          whileScrolling: function () {
            if (this.mcs.top < -100) {
              $header.addClass('fixed');
              $header.siblings('.content_list').find('.scroll_area').addClass('fixed')
              tit.attr('data-tit', '');
              tit.text(aptName);
              //tit.attr('data-tit', aptName);
              // tit.text(aptName).addClass('apt_name');
            } else {
              $header.removeClass('fixed');
              $header.siblings('.content_list').find('.scroll_area').removeClass('fixed')
              if (document.location.href.search('apply') > -1) {
                tit.attr('data-tit', '최근 청약정보');
                tit.text('최근 청약정보');
              }
              else {
                tit.attr('data-tit', '');
                tit.text('');
              }
              //tit.text(tit.attr('data-tit'));
              // tit.text(tit.attr('data-tit')).removeClass('apt_name');
            }
            accoScollFixd(this);
          },
          onOverflowYNone: function () {
            accoFixdNone(this);
          },
          alwaysTriggerOffsets: false
        },
      });


      /* function depClick(el){
        var fixH = $(".apt_info_header").offset().top + 60;
        listTit.each(function(idx){
          var offset = listTit.eq(idx).offset().top;
          var b = listTit.eq(idx).find('.info_con').outerHeight();
          var h = listTit.eq(idx).find(".info_tit").outerHeight();
          // console.log(b + ' b', offset+b + ' offset+b', fixH+(h*idx) + ' fixH+(h*idx)');
          listTit.on('click', function() {
            if ($(this).parent().hasClass('hold')) {
              console.log('as');
              console.log($(this).parent().index())
              $('.scroll_area').mCustomScrollbar("scrollTo", $(this).parent().find('.info_con'));
            }
          })
          // if (offset+b < fixH+(h*idx)) {
          // 	// scroll 길이 - 컨텐츠bottom (offset.top + height) +h*idx
          // 	listTit.eq(idx).find(".info_tit").removeClass('_on');
          // 	// listTit.eq(idx).find(".info_tit").removeClass('_on');
          // 	// listTit.eq(idx).find(".info_con").slideUp();
          // 	// console.log('')
          // 	// listTit.eq(idx).find(".info_con").slideUp();
          // }
        	
        })
      }; */
      function accoScollFixd(el) {
        var fixH = $(".apt_info_header").offset().top + 60;
        listTit.each(function (idx) {
          var offset = listTit.eq(idx).offset().top;
          var h = listTit.eq(idx).find(".info_tit").outerHeight();
          var b = listTit.eq(idx).find('.info_con').outerHeight();
          if (offset < fixH + (h * idx)) {
            listTit.eq(idx).addClass("_fix").find(".info_tit").css("top", Math.ceil(fixH + (h * idx)));
          } else if (offset > fixH) {
            listTit.eq(idx).removeClass("_fix");
          }

          if (offset + b < fixH + (h * idx)) {
            listTit.eq(idx).find(".info_tit").parent().addClass('hold');
          } else if (offset + b > fixH) {
            listTit.eq(idx).find(".info_tit").parent().removeClass('hold');
          }
        });
        if (el.mcs.top > -fixH) {
          $(".apt_wrap .info_list").removeClass("_fix");
        }
      };
      function accoFixdNone(el) {
        $(".apt_wrap .info_list").removeClass("_fix");
        $(".apt_info_header").removeClass("fixed");
      };
    }, 100);
  }
}

export const uiCardMcsScroll = UI.cardMcsScroll = UI.saleScrollCustom = {
  init: function () {
    setTimeout(function () {
      //UI.SalesScroll 스크립트 일부 합침
      var $header = $(".header_wrap")
      var $content = $(".panel_card .content_list").find('.card_area')

      var scroll = $('.card_area .scroll_area');
      //var height = $('.card_area .scroll_area ul').innerHeight();
      if (scroll.hasClass('mCustomScrollbar')) {
        scroll.mCustomScrollbar("destroy");
      }
      scroll.mCustomScrollbar({
        callbacks: {
          whileScrolling: function () {
            if (this.mcs.top < -10) {
              $header.addClass('fixed');
              $content.addClass('_scroll');
            } else {
              $header.removeClass('fixed');
              $content.removeClass('_scroll');
            }
          },
          alwaysTriggerOffsets: false,
          onUpdate: function () {
            var scrollTop = scroll.find('.mCSB_dragger').position().top;
            var scrollHeight = scroll.find('.mCSB_dragger').css('height').replace('px', '');
            var scrollBottom = parseInt(scrollTop, 10) + parseInt(scrollHeight, 10);

            if (scrollBottom >= (scroll.height() - 30)) {
              scroll.parent().addClass('dim');
            } else {
              scroll.parent().removeClass('dim');
            }
          },
        },
      });
    }, 500);
  }
}

export const asyncCounting = (tar, delay, prevScore, completeScore) => {
  var _this = $('.count_' + tar),
    per = _this.attr('per');
  _this.attr('per', prevScore);

  setTimeout(function () {
    $({ animatedValue: prevScore }).animate({ animatedValue: per }, {
      duration: 900,
      step: function () {
        _this.attr('per', Math.floor(this.animatedValue));
      },
      complete: function () {
        _this.attr('per', completeScore);
      }
    });
  }, delay * 100);
}

function counting(tar, delay) {
  var _this = $('.count_' + tar),
    per = _this.attr('per');

  _this.attr('per', 0);
  setTimeout(function () {
    $({ animatedValue: 0 }).animate({ animatedValue: per }, {
      duration: 900,
      step: function () {
        _this.attr('per', Math.floor(this.animatedValue));
      },
      complete: function () {
        _this.attr('per', Math.floor(this.animatedValue));
      }
    });
  }, delay * 100);
}

export const uiCostScroll = UI.costScroll = {
  init: function () {
    var $header = $(".header_wrap .card_tit"),
      $content = $(".panel_st3.panel_card .content_list").find('.cost_area'),
      $scroll = $('.panel_st3.panel_card').find('.scroll_area');

    $scroll.mCustomScrollbar({
      scrollButtons: {
        enable: true
      },
      callbacks: {
        whileScrolling: function () {
          if (this.mcs.top < -50) {
            $header.parent().addClass('fixed');
            $content.addClass('fixed');
          } else {
            $header.parent().removeClass('fixed');
            $content.removeClass('fixed');
          }
        },
        alwaysTriggerOffsets: false
      },
    });
  }
}

export const uiRecommScroll = UI.recommScroll = {
  init: function () {
    var $header = $(".header_wrap"),
      $content = $(".panel_st1._scroll").find('.recommend_area'),
      $scroll = $('.recommend_area').find('.scroll_area');

    $scroll.mCustomScrollbar({
      scrollButtons: {
        enable: true
      },
      callbacks: {
        whileScrolling: function () {
          if (this.mcs.top < -10) {
            $header.addClass('fixed');
            $content.addClass('fixed');
          } else {
            $header.removeClass('fixed');
            $content.removeClass('fixed');
          }
        },
        alwaysTriggerOffsets: false
      },
    });
  }
}

export const predictAnalyze = {

  open: function () {

    var $this = $('.dep_analyze');
    var scroll = $('html, body');
    var st1 = $('.point_calculator');
    var tit = $('.con_tit_wrap');
    var tit2 = $('.info_inner');

    st1.parents('.bbs_wrap').addClass('pointPer');

    var num = $(this).attr("data-point");
    // [21.07.14] JIRA 이슈 수정 [APC-276]
    $("body").find(".multi").find('.point').not('.my').each(function (idx) {
      var num = $(this).attr("data-point");
      TweenMax.to($(this), .0, { left: $(this).attr("data-point") / 84 * 100 + "%" });
      $(this).find('span').find('s').text(num);
    });
    if ($(this).hasClass('btn_close')) {
      $this.parents('.point_calculator').next().slideUp(400);
      tit.removeClass('per_upper');
      scroll.animate({ scrollTop: $('.bbs_wrap').offset().top }, 600);
      tit2.parent().removeClass('fixed');
    } else {
      $this.parents('.point_calculator').next().slideDown(300);
      tit.addClass('per_upper');
      st1.parents('.bbs_wrap').addClass('pointPer');
      setTimeout(function () {
        scroll.animate({ scrollTop: $('.analyze_wrap').offset().top - 100 }, 400);
      }, 0);
    }

  },
  close: function () {

    var $this = $('.dep_analyze');
    var scroll = $('html, body');
    var tit = $('.con_tit_wrap');
    var tit2 = $('.info_inner');

    $this.parents('.point_calculator').next().slideUp(400);
    tit.removeClass('per_upper');
    scroll.animate({ scrollTop: $('.bbs_wrap').offset().top }, 600);
    tit2.parent().removeClass('fixed');

  }

}

export const uiAroundTab = UI.around_tab = {
  init: function () {
    var nav = $('.nav').find('li');
    nav.on('click', function () {
      $(this).addClass('on').siblings().removeClass('on');
    });
  }
}

export const ChartCircle = {

  call: function () {

    var chart_circle,
      intervals,
      per_num;
    intervals = [];
    per_num = [];

    $(this).each(function (idx) {
      chart_circle = {
        items: $('.circle_chart'),
        set: function (obj, per, max, index) {
          var path,
            pathLen,
            perLen;
          path = $('.circle_progress').get(index);
          pathLen = Math.round((127) * 3.14);
          perLen = Math.round(pathLen - (per * (pathLen / max)));
          path.setAttribute('stroke-dasharray', pathLen);
          path.setAttribute('stroke-dashoffset', pathLen);
          setTimeout(function () {
            chart_circle.num_evt(per, obj, index);
            chart_circle.init(obj, path, perLen);
          }, 1000);

        },
        init: function (obj, path, perLen) {
          obj.addClass('active');
          path.setAttribute('stroke-dashoffset', perLen);
        },
        num_evt: function (per, obj, index) {
          chart_circle.num_interval(per, obj, index);
        },
        num_interval: function (per, obj, index) {
          var interv, per_box;

          per_num[index] = 0;
          interv = 1000 / per;

          // very slowly interv
          if (interv > 50) {
            interv = 50;
          }
          clearInterval(intervals[index]);
          per_box = obj.find('.per-num span');
          if (per_box.attr('rank') == null) { //건설사순위는 num_set하지않는다
            intervals[index] = setInterval(function () {
              chart_circle.num_set(obj, per_box, per, index);
            }, interv);
          }
        },
        num_set: function (obj, per_box, per, index) {
          if (per_num[index] >= per) {
            clearInterval(intervals[index]);
          }

          per_box.text(per_num[index]);
          per_num[index]++;
        },
        call: function (idx) {
          var per, max;
          max = chart_circle.items.eq(idx).attr('data-permax');
          per = chart_circle.items.eq(idx).attr('data-percent');
          chart_circle.set(chart_circle.items.eq(idx), per, max, idx);
        }
      }
    });

    var _this = this;
    _this.$animate = $("body").find(".ty_graph");
    _this.$animateTit = $("body").find(".analyze_wrap");
    _this.$animateSec = $("body").find(".sec");

    var sec1, sec2, sec3, sec4, sec5, sec6, sec7, sec8, sec9, sec10, sec11;
    var status;
    var handler = {
      sec1: function SEC1_ANIMATE() {
        if (sec1) return;
        sec1 = true;
        var $container = $('#sec1'), tl = new TimelineMax();
        tl.add([
          TweenMax.to($container.find('h4'), .7, { y: 0, opacity: 1, delay: .4 }),
          TweenMax.to($container.find('.graph'), .7, { y: 0, opacity: 1, delay: .7 }),
          TweenMax.to($container.find('.caption'), .7, { y: 0, opacity: 1, delay: 1 }),
          TweenMax.to($container.find('.progress'), .7, { y: 0, opacity: 1, delay: 1.3 }),
          TweenMax.to($container.find('.people'), .7, { y: 0, opacity: 1, delay: 1.6 })
        ]);
        chart_circle.call(0);
      },
      sec2: function SEC2_ANIMATE() {
        if (sec2) return;
        sec2 = true;
        var $container = $('#sec2'), tl = new TimelineMax();
        tl.add([
          TweenMax.to($container.find('h4'), .7, { y: 0, opacity: 1, delay: .4 }),
          TweenMax.to($container.find('.graph'), .7, { y: 0, opacity: 1, delay: .7 }),
          TweenMax.to($container.find('.caption'), .7, { y: 0, opacity: 1, delay: 1 }),
          TweenMax.to($container.find('.people'), .7, { y: 0, opacity: 1, delay: 1.3 }),
          TweenMax.to($container.find('.noti'), .7, { y: 0, opacity: 1, delay: 1.6 })
        ]);
        counting('v1', 24);
        counting('v2', 24);
        chart_circle.call(1);
      },
      sec3: function SEC3_ANIMATE() {
        if (sec3) return;
        sec3 = true;
        var $container = $('#sec3'), tl = new TimelineMax();
        tl.add([
          TweenMax.to($container.find('.graph'), .7, { y: 0, opacity: 1, delay: .4 }),
          TweenMax.to($container.find('.caption'), .7, { y: 0, opacity: 1, delay: .7 }),
          TweenMax.to($container.find('.chart'), .7, { y: 0, opacity: 1, delay: 1 })
        ]);
        chart_circle.call(2);
      },
      sec4: function SEC4_ANIMATE() {
        if (sec4) return;
        sec4 = true;
        var $container = $('#sec4'), tl = new TimelineMax();
        tl.add([
          TweenMax.to($container.find('h4'), .7, { y: 0, opacity: 1, delay: .4 }),
          TweenMax.to($container.find('strong'), .7, { y: 0, opacity: 1, delay: .7 }),
          TweenMax.to($container.find('.graph'), .7, { y: 0, opacity: 1, delay: 1 }),
          TweenMax.to($container.find('.caption'), .7, { y: 0, opacity: 1, delay: 1.3 }),
          TweenMax.to($container.find('.progress'), .7, { y: 0, opacity: 1, delay: 1.6 })
        ]);
        chart_circle.call(3);
      },
      sec5: function SEC5_ANIMATE() {
        if (sec5) return;
        sec5 = true;
        var $container = $('#sec5'), tl = new TimelineMax();
        tl.add([
          TweenMax.to($container.find('.bubble_tit'), .7, { y: 0, opacity: 1, delay: .4 }),
          TweenMax.to($container.find('.bubble'), .7, { y: 0, opacity: 1, delay: .7 }),
          TweenMax.to($container.find('.tb_apt'), .7, { y: 0, opacity: 1, delay: 1 }),
          TweenMax.to($container.find('.noti'), .7, { y: 0, opacity: 1, delay: 1.3 })
        ]);
      },
      sec6: function SEC6_ANIMATE() {
        if (sec6) return;
        sec6 = true;
        var $container = $('#sec6'), tl = new TimelineMax();
        tl.add([
          TweenMax.to($container.find('.noti_around'), .7, { y: 0, opacity: 1, delay: .4 }),
          TweenMax.to($container.find('.graph'), .7, { y: 0, opacity: 1, delay: .7 }),
          TweenMax.to($container.find('.map_img'), .7, { y: 0, opacity: 1, delay: 1 }),
          TweenMax.to($container.find('.noti'), .7, { y: 0, opacity: 1, delay: 1.3 }),
        ]);
        chart_circle.call(4);
      },
      sec7: function SEC7_ANIMATE() {
        if (sec7) return;
        sec7 = true;
        var $container = $('#sec7'), tl = new TimelineMax();
        tl.add([
          TweenMax.to($container.find('.graph'), .7, { y: 0, opacity: 1, delay: .4 }),
          TweenMax.to($container.find('.nav'), .7, { y: 0, opacity: 1, delay: .7 }),
          TweenMax.to($container.find('.tab'), .7, { y: 0, opacity: 1, delay: 1 })
        ]);
      },
      sec8: function SEC8_ANIMATE() {
        if (sec8) return;
        sec8 = true;
        var $container = $('#sec8'), tl = new TimelineMax();
        tl.add([
          TweenMax.to($container.find('h4'), .7, { y: 0, opacity: 1, delay: .4 }),
          TweenMax.to($container.find('.graph'), .7, { y: 0, opacity: 1, delay: .7 }),
          TweenMax.to($container.find('.caption'), .7, { y: 0, opacity: 1, delay: 1 }),
          TweenMax.to($container.find('.tb_apt'), .7, { y: 0, opacity: 1, delay: 1.3 }),
          TweenMax.to($container.find('.chart'), .7, { y: 0, opacity: 1, delay: 1.6 })
        ]);
        chart_circle.call(5);
      },
      sec9: function SEC9_ANIMATE() {
        if (sec9) return;
        sec9 = true;
        var $container = $('#sec9'), tl = new TimelineMax();
        tl.add([
          TweenMax.to($container.find('h4'), .7, { y: 0, opacity: 1, delay: .4 }),
          TweenMax.to($container.find('.graph'), .7, { y: 0, opacity: 1, delay: .7 }),
          TweenMax.to($container.find('.caption'), .7, { y: 0, opacity: 1, delay: 1 }),
          TweenMax.to($container.find('.chart'), .7, { y: 0, opacity: 1, delay: 1.3 })
        ]);
        chart_circle.call(6);
      },
      sec10: function SEC10_ANIMATE() {
        if (sec10) return;
        sec10 = true;
        var $container = $('#sec10'), tl = new TimelineMax();
        tl.add([
          TweenMax.to($container.find('strong'), .7, { y: 0, opacity: 1, delay: .4 }),
          TweenMax.to($container.find('.graph'), .7, { y: 0, opacity: 1, delay: .7 }),
          TweenMax.to($container.find('.chart'), .7, { y: 0, opacity: 1, delay: 1 }),
          TweenMax.to($container.find('.progress'), .7, { y: 0, opacity: 1, delay: 1.3 })
        ]);
        chart_circle.call(7);
        chart_circle.call(8);
      },
      sec11: function SEC11_ANIMATE() {
        if (sec11) return;
        sec11 = true;
        var $container = $('#sec11'), tl = new TimelineMax();
        tl.add([
          TweenMax.to($container.find('h4'), .7, { y: 0, opacity: 1, delay: .4 }),
          TweenMax.to($container.find('.graph'), .7, { y: 0, opacity: 1, delay: .7 }),
          TweenMax.to($container.find('.caption'), .7, { y: 0, opacity: 1, delay: 1 }),
          TweenMax.to($container.find('.chart'), .7, { y: 0, opacity: 1, delay: 1.3 })
        ]);
      },
    }

    $(window).on("scroll", function () {
      var viewTop = $(this).scrollTop(),
        viewHeight = $(this).outerHeight(true),
        viewBottom = viewTop + viewHeight;
      if ($('.con_tit_wrap').hasClass('per_upper')) {
        _this.$animate.each(function () {
          var elementTop = $(this).offset().top,
            elementHeight = $(this).outerHeight(),
            elementBottom = elementTop + elementHeight;
          if (viewTop < elementBottom && viewBottom > elementTop) {
            if (($(this).find('.progress').hasClass('multi'))) {
              $(this).find('.my').each(function (idx) {
                TweenMax.to($(this), .8, { left: $(this).attr("data-point") + "%", delay: 1.8 });
                $(this).find('.point').find('span').find('s').text(num);
              });
            } else if (($(this).find('.progress').hasClass('single'))) {
              var num = $(this).find('.point').attr("data-point");
              TweenMax.to($(this).find('.point'), .7, { width: num + "%", delay: 1.6 });
              $(this).find('.point').find('span').text(num);
            }
          }
        });
        _this.$animateTit.each(function () {
          var elementTop = $(this).offset().top;
          if (viewTop < elementTop - 100) {
            $(this).find('.my_info_wrap').removeClass('fixed');
          } else {
            $(this).find('.my_info_wrap').addClass('fixed');
          }
        });
        _this.$animateSec.each(function () {
          var elementTop = $(this).offset().top,
            elementHeight = $(this).outerHeight(),
            elementBottom = elementTop + elementHeight;

          if (viewTop < elementBottom && viewBottom > elementTop) {
            var id = $(this).attr('id');
            handler[id]();
          }
        });
      }
    }).trigger("scroll");
  }
}

export const uiAcc = UI.acc = {
  init: function () {
    var _this = this;
    _this.$accWrap = $("body").find(".acc_wrap");
    _this.$accTit = _this.$accWrap.find(".tit");
    _this.$accCont = _this.$accWrap.find(".cont");
    _this.$accBtn = _this.$accTit.find(".btn");
    _this.addEvents();
  },
  addEvents: function () {
    var _this = this;
    function handleToggle() {
      if ($(this).hasClass('on')) {
        $(this).removeClass('on').next().slideUp(600);
        $(this).find('.btn').removeClass('on');
        $(this).parent('.acc_wrap').removeClass('on');
      } else {
        $(this).find('.btn').addClass('on');
        $(this).addClass('on').next().slideDown(600);
        $(this).parent('.acc_wrap').addClass('on');
      }
    }
    _this.$accTit.off('click.toggle').on('click.toggle', handleToggle);
  }
}


export const uiQOuter = UI.q_outer = {
  init: function () {
    $(this).each(function (idx) {
      $(".q_match").mouseover(function () {
        var eq_top = $(this).offset().top;
        if ($(this).parents('.total').hasClass('mark')) {
          var eq_left = $(this).offset().left;
          var eq_height = $('.q_match_inr').outerHeight();
          $('.q_match_inr').css('top', eq_top - eq_height - 60).show();
          $('.q_match_inr').css('left', eq_left - 335);
          $('.q_match_inr').mouseover(function () {
            $(this).show();
          });
          $('.q_match_inr').mouseleave(function () {
            $(this).hide();
          })
        } else {
          var eq = $(this).parents('li').index();
          var eq_height = $('.q_match_inr').eq(eq).outerHeight();
          $('.q_match_inr').eq(eq).css('top', eq_top - eq_height - 60).show();
          $('.q_match_inr').mouseover(function () {
            $(this).show();
          });
          $('.q_match_inr').mouseleave(function () {
            $(this).hide();
          });
        }
      });
      $(".q_match").mouseleave(function () {
        if ($(this).parents('.total').hasClass('mark')) {
          $('.q_match_inr').hide();
        } else {
          var eq = $(this).parents('li').index();
          $('.q_match_inr').eq(eq).hide();
        }
      });
    });
  }
}

// popup
var scrollHeight = 0;
scrollHeight = $(document).scrollTop();

export const popupOpen = (obj, ty, timer) => {
  var tar = $('#mw-' + obj);
  $('body').css('overflow', 'hidden');
  function mwOpen() {
    if (timer >= 0) {
      setTimeout(function () {
        if (tar.hasClass('popBtm')) {
          tar.addClass('show');
        } else {
          tar.show().addClass('show');
        }
      }, timer);
    } else {
      tar.show().addClass('show');
      if (tar.hasClass('ndim')) {
        tar.css('left', 0);
      }
      tar.find('.content').scrollTop(0);
    }
    // 영역 외 클릭 닫기
    tar.click(function (e) {
      if (!$(this).hasClass('ndim')) {
        if (!$('[class*="ly"]').find('.wrap').has(e.target).length) {
          if ($(this).hasClass('popBtm')) {
            $('body').css('overflow', '');
            $('[class*="ly"]').removeClass('show');
            window.onscroll = function () { };
          } else {
            popupClose(obj, 'mw');
          }
        }
      }
    });
  }
  function mwOpenFix() {
    if (timer >= 0) {
      setTimeout(function () {
        if (tar.hasClass('popBtm')) {
          tar.addClass('show');
        } else {
          tar.show().addClass('show');
        }
      }, timer);
    } else {
      tar.show().addClass('show');
      if (tar.hasClass('ndim')) {
        tar.css('left', 0);
      }
      tar.find('.content').scrollTop(0);
    }
    // 영역 외 클릭 닫기
    tar.click(function (e) {
      if (!$(this).hasClass('ndim')) {
        if (!$('[class*="ly"]').find('.wrap').has(e.target).length) {
          popupClose(obj, 'mwf');
        }
      }
    });
    scrollHeight = $(document).scrollTop();
    $('#wrap').css('position', 'fixed');
    $('#wrap').css('top', - scrollHeight);
  }
  if (ty === 'pc') { //pc
    tar.show();
  } else if (ty === 'mw') { //mobile 기본
    mwOpen();
    var x = window.scrollX, y = window.scrollY;
    window.onscroll = function () { window.scrollTo(x, y) };
  } else if (ty === 'mwb') {
    mwOpen();
    $('html,body').css('position', 'fixed');
  } else if (ty === 'mwf') { //mobile > #wrap을 fixed 시키는 방식
    mwOpenFix();
  }
}
export const popupClose = (obj, ty, timer) => {
  var tar = $('#mw-' + obj);
  $('body').css('overflow', '');
  if (ty === 'pc') {
    tar.hide().removeClass('show');
  } else if (ty === 'mw') {
    if (tar.hasClass('popBtm')) {
      tar.removeClass('show');
    } else {
      tar.hide().removeClass('show');;
    }
    $('html,body').css('position', '');
    window.onscroll = function () { };
  } else if (ty === 'mwf') {
    if (tar.hasClass('popBtm')) {
      tar.removeClass('show');
    } else {
      tar.hide().removeClass('show');;
    }
    $('#wrap').css('top', 0);
    $('#wrap').css('position', 'relative');
    $(document).scrollTop(scrollHeight);
  }
}


export const uiInfoPopSwiper01 = UI.infoPopSwiper01 = {
  init: function () {
    UI.$infoSwiper01 = $(".pop_info_swiper");
    UI.infoSwiper01 = new Swiper('.pop_info_swiper', {
      observer: true,
      observeParents: true,
      centeredSlides: true,
      allowTouchMove: false,
      slidesPerView: 1,
      speed: 1000,
      pagination: {
        el: '.swiper-pagination',
      },
      on: {
        init: function () {
          var _this = this;
          _this.tween01 = new TimelineMax();
          _this.tween02 = new TimelineMax();
          _this.tween03 = new TimelineMax();

          var currentSlide = this.realIndex;

          //reset
          UI.$infoSwiper01.find(".swiper-slide").find(".form_ani").removeClass("on");
          UI.$infoSwiper01.find(".swiper-slide").find(".selet_detail .item").removeClass("on");
          UI.$infoSwiper01.find(".swiper-slide").find(".form_ani .box").removeClass("on");
          // tween clear
          _this.tween01.set(".swiper-slide *", { clearProps: "all" });
          _this.tween02.set(".swiper-slide *", { clearProps: "all" });
          _this.tween03.set(".swiper-slide *", { clearProps: "all" });

          setTimeout(function () {
            UI.$infoSwiper01.find(".swiper-slide").eq(currentSlide).find(".form_ani").addClass("on");
          }, 800);
          _this.tween01.to(UI.$infoSwiper01.find(".swiper-slide").eq(currentSlide).find(".cursor"), .2, {
            opacity: .4, delay: 1.5, ease: Linear.easeIn, onComplete: function () {
              _this.tween01.to(UI.$infoSwiper01.find(".swiper-slide").eq(currentSlide).find(".cursor"), .25, {
                x: -50, y: -20, ease: Linear.easeIn, onComplete: function () {
                  _this.tween01.to(UI.$infoSwiper01.find(".swiper-slide").eq(currentSlide).find(".cursor"), .25, {
                    opacity: .6, scale: .98, ease: Linear.easeIn, onComplete: function () {
                      _this.tween01.to(UI.$infoSwiper01.find(".swiper-slide").eq(currentSlide).find(".cursor"), .25, {
                        opacity: .4, scale: 1, ease: Linear.easeIn, onComplete: function () {
                          UI.infoSwiper01.slideTo(1, 1000, false);
                        }
                      });
                    }
                  });
                }
              });
            }
          });
        },
        slideChange: function () {
          //reset
          UI.$infoSwiper01.find(".swiper-slide").find(".form_ani").removeClass("on");
          UI.$infoSwiper01.find(".swiper-slide").find(".selet_detail .item").removeClass("on");
          UI.$infoSwiper01.find(".swiper-slide").find(".form_ani .box").removeClass("on");

          var _this = this;
          var currentSlide = this.realIndex;
          if (currentSlide == 0) {
            setTimeout(function () {
              UI.$infoSwiper01.find(".swiper-slide").eq(0).find(".form_ani").addClass("on");
            }, 800);
            _this.tween01.restart();
          }
          if (currentSlide == 1) {
            setTimeout(function () {
              UI.$infoSwiper01.find(".swiper-slide").eq(currentSlide).find(".form_ani .box").addClass("on");
              UI.$infoSwiper01.find(".swiper-slide").eq(currentSlide).find(".selet_detail").slideDown(400);
            }, 800);
            _this.tween02.to(UI.$infoSwiper01.find(".swiper-slide").eq(currentSlide).find(".selet_detail .item:eq(0)"), .3, {
              className: "+=item on", delay: 1.5, onComplete: function () {
                UI.$infoSwiper01.find(".swiper-slide").eq(currentSlide).find(".form_ani .box").removeClass("on");
                UI.$infoSwiper01.find(".swiper-slide").eq(currentSlide).find(".selet_detail").slideUp(400, function () {
                  UI.$infoSwiper01.find(".swiper-slide").eq(currentSlide).find(".form_ani").addClass("on");
                  _this.tween02.to(UI.$infoSwiper01.find(".swiper-slide").eq(currentSlide).find(".cursor"), .2, {
                    opacity: .4, delay: .3, ease: Linear.easeIn, onComplete: function () {
                      _this.tween02.to(UI.$infoSwiper01.find(".swiper-slide").eq(currentSlide).find(".cursor"), .25, {
                        x: -50, y: -20, ease: Linear.easeIn, onComplete: function () {
                          _this.tween02.to(UI.$infoSwiper01.find(".swiper-slide").eq(currentSlide).find(".cursor"), .25, {
                            opacity: .6, scale: .98, ease: Linear.easeIn, onComplete: function () {
                              _this.tween02.to(UI.$infoSwiper01.find(".swiper-slide").eq(currentSlide).find(".cursor"), .25, {
                                opacity: .4, scale: 1, ease: Linear.easeIn, onComplete: function () {
                                  UI.infoSwiper01.slideTo(2, 1000, false);
                                }
                              });
                            }
                          });
                        }
                      });
                    }
                  });
                });
              }
            });
          }
          if (currentSlide == 2) {
            setTimeout(function () {
              UI.$infoSwiper01.find(".swiper-slide").eq(currentSlide).find(".form_ani .box").addClass("on");
              UI.$infoSwiper01.find(".swiper-slide").eq(currentSlide).find(".selet_detail").slideDown(400);
            }, 800);
            _this.tween03.to(UI.$infoSwiper01.find(".swiper-slide").eq(currentSlide).find(".selet_detail .item:eq(1)"), .3, {
              className: "+=item on", delay: 1.5, onComplete: function () {
                UI.$infoSwiper01.find(".swiper-slide").eq(currentSlide).find(".form_ani .box").removeClass("on");
                UI.$infoSwiper01.find(".swiper-slide").eq(currentSlide).find(".selet_detail").slideUp(400, function () {
                  UI.$infoSwiper01.find(".swiper-slide").eq(currentSlide).find(".form_ani").addClass("on");
                  _this.tween03.to(UI.$infoSwiper01.find(".swiper-slide").eq(currentSlide).find(".cursor"), .2, {
                    opacity: .4, delay: .3, ease: Linear.easeIn, onComplete: function () {
                      _this.tween03.to(UI.$infoSwiper01.find(".swiper-slide").eq(currentSlide).find(".cursor"), .25, {
                        x: -50, y: -20, ease: Linear.easeIn, onComplete: function () {
                          _this.tween03.to(UI.$infoSwiper01.find(".swiper-slide").eq(currentSlide).find(".cursor"), .25, {
                            opacity: .6, scale: .98, ease: Linear.easeIn, onComplete: function () {
                              _this.tween03.to(UI.$infoSwiper01.find(".swiper-slide").eq(currentSlide).find(".cursor"), .25, {
                                opacity: .4, scale: 1, ease: Linear.easeIn, onComplete: function () {
                                  UI.$infoSwiper01.parents(".ly_info_swiper").find(".btn_info_reset").show();
                                }
                              });
                            }
                          });
                        }
                      });
                    }
                  });
                });
              }
            });
          }
        }
      }
    });
    UI.$infoSwiper01.parents(".ly_info_swiper").find(".btn_info_reset").on("click", function (e) {
      e.stopImmediatePropagation();
      var _this = $(this);
      TweenMax.set(_this, { rotation: 0 });
      TweenMax.to(_this, .3, {
        rotation: 360, ease: Power0.easeNone, onComplete: function () {
          _this.hide();
        }
      });
      UI.infoSwiper01.destroy(false);
      UI.infoPopSwiper01.init();
    });
  }
}

export const uiInfoPopSwiper02 = UI.infoPopSwiper02 = {
  init: function () {
    UI.$infoSwiper02 = $(".pop_info_swiper02");
    UI.infoSwiper02 = new Swiper('.pop_info_swiper02', {
      observer: true,
      observeParents: true,
      centeredSlides: true,
      allowTouchMove: false,
      speed: 1000,
      pagination: {
        el: '.swiper-pagination',
      },
      on: {
        init: function () {
          var _this = this;
          _this.tween01 = new TimelineMax();
          _this.tween02 = new TimelineMax();
          _this.tween03 = new TimelineMax();

          var currentSlide = this.realIndex;

          //reset
          UI.$infoSwiper02.find(".swiper-slide").find(".form_ani").removeClass("on");
          UI.$infoSwiper02.find(".swiper-slide").find(".selet_detail .item").removeClass("on");
          UI.$infoSwiper02.find(".swiper-slide").find(".form_ani .box").removeClass("on");
          // tween clear
          _this.tween01.set(".swiper-slide *", { clearProps: "all" });
          _this.tween02.set(".swiper-slide *", { clearProps: "all" });
          _this.tween03.set(".swiper-slide *", { clearProps: "all" });

          setTimeout(function () {
            UI.$infoSwiper02.find(".swiper-slide").eq(currentSlide).find(".form_ani").addClass("on");
          }, 800);
          _this.tween01.to(UI.$infoSwiper02.find(".swiper-slide").eq(currentSlide).find(".cursor"), .2, {
            opacity: .4, delay: 3, ease: Linear.easeIn, onComplete: function () {
              _this.tween01.to(UI.$infoSwiper02.find(".swiper-slide").eq(currentSlide).find(".cursor"), .25, {
                x: -50, y: -20, ease: Linear.easeIn, onComplete: function () {
                  _this.tween01.to(UI.$infoSwiper02.find(".swiper-slide").eq(currentSlide).find(".cursor"), .25, {
                    opacity: .6, scale: .98, ease: Linear.easeIn, onComplete: function () {
                      _this.tween01.to(UI.$infoSwiper02.find(".swiper-slide").eq(currentSlide).find(".cursor"), .25, {
                        opacity: .4, scale: 1, ease: Linear.easeIn, onComplete: function () {
                          UI.infoSwiper02.slideTo(1, 1000, false);
                        }
                      });
                    }
                  });
                }
              });
            }
          });
        },
        slideChange: function () {
          //reset
          UI.$infoSwiper02.find(".swiper-slide").find(".form_ani").removeClass("on");
          UI.$infoSwiper02.find(".swiper-slide").find(".selet_detail .item").removeClass("on");
          UI.$infoSwiper02.find(".swiper-slide").find(".form_ani .box").removeClass("on");

          var _this = this;
          var currentSlide = this.realIndex;
          if (currentSlide == 0) {
            setTimeout(function () {
              UI.$infoSwiper02.find(".swiper-slide").eq(0).find(".form_ani").addClass("on");
            }, 1000);
            _this.tween01.restart();
          }
          if (currentSlide == 1) {
            setTimeout(function () {
              UI.$infoSwiper02.find(".swiper-slide").eq(currentSlide).find(".ani01 .box").addClass("on");
              UI.$infoSwiper02.find(".swiper-slide").eq(currentSlide).find(".ani01 .selet_detail").slideDown(400);
            }, 800);
            _this.tween02.to(UI.$infoSwiper02.find(".swiper-slide").eq(currentSlide).find(".ani01 .selet_detail .item:eq(0)"), .3, {
              className: "+=item on", delay: 1.5, onComplete: function () {
                UI.$infoSwiper02.find(".swiper-slide").eq(currentSlide).find(".ani01 .box").removeClass("on");
                UI.$infoSwiper02.find(".swiper-slide").eq(currentSlide).find(".ani01 .selet_detail").slideUp(400, function () {
                  _this.tween02.to(UI.$infoSwiper02.find(".swiper-slide").eq(currentSlide).find(".ani01 .form_ani"), .2, {
                    className: "+=form_ani on", onComplete: function () {
                      UI.$infoSwiper02.find(".swiper-slide").eq(currentSlide).find(".ani02 .box").addClass("on");
                      UI.$infoSwiper02.find(".swiper-slide").eq(currentSlide).find(".ani02 .selet_detail").slideDown(400);
                      _this.tween02.to(UI.$infoSwiper02.find(".swiper-slide").eq(currentSlide).find(".ani02 .selet_detail .item:eq(1)"), .3, {
                        className: "+=item on", delay: .5, onComplete: function () {
                          UI.$infoSwiper02.find(".swiper-slide").eq(currentSlide).find(".ani02 .selet_detail").slideUp(400, function () {
                            UI.$infoSwiper02.find(".swiper-slide").eq(currentSlide).find(".ani02 .box").removeClass("on");
                            _this.tween02.to(UI.$infoSwiper02.find(".swiper-slide").eq(currentSlide).find(".ani02 .form_ani"), .2, {
                              className: "+=form_ani on", onComplete: function () {
                                _this.tween02.to(UI.$infoSwiper02.find(".swiper-slide").eq(currentSlide).find(".cursor"), .2, {
                                  opacity: .4, delay: .1, ease: Linear.easeIn, onComplete: function () {
                                    _this.tween02.to(UI.$infoSwiper02.find(".swiper-slide").eq(currentSlide).find(".cursor"), .25, {
                                      x: -50, y: -20, ease: Linear.easeIn, onComplete: function () {
                                        _this.tween02.to(UI.$infoSwiper02.find(".swiper-slide").eq(currentSlide).find(".cursor"), .25, {
                                          opacity: .6, scale: .98, ease: Linear.easeIn, onComplete: function () {
                                            _this.tween02.to(UI.$infoSwiper02.find(".swiper-slide").eq(currentSlide).find(".cursor"), .25, {
                                              opacity: .4, scale: 1, ease: Linear.easeIn, onComplete: function () {
                                                UI.infoSwiper02.slideTo(2, 1000, false);
                                              }
                                            });
                                          }
                                        });
                                      }
                                    });
                                  }
                                });
                              }
                            });
                          });
                        }
                      });
                    }
                  });
                });
              }
            });
          }
          if (currentSlide == 2) {
            setTimeout(function () {
              UI.$infoSwiper02.find(".swiper-slide").eq(currentSlide).find(".ani01 .box").addClass("on");
              UI.$infoSwiper02.find(".swiper-slide").eq(currentSlide).find(".ani01 .selet_detail").slideDown(400);
            }, 800);
            _this.tween03.to(UI.$infoSwiper02.find(".swiper-slide").eq(currentSlide).find(".ani01 .selet_detail .item:eq(0)"), .3, {
              className: "+=item on", delay: 1.5, onComplete: function () {
                UI.$infoSwiper02.find(".swiper-slide").eq(currentSlide).find(".ani01 .box").removeClass("on");
                UI.$infoSwiper02.find(".swiper-slide").eq(currentSlide).find(".ani01 .selet_detail").slideUp(400, function () {
                  _this.tween03.to(UI.$infoSwiper02.find(".swiper-slide").eq(currentSlide).find(".ani01 .form_ani"), .2, {
                    className: "+=form_ani on", onComplete: function () {
                      UI.$infoSwiper02.find(".swiper-slide").eq(currentSlide).find(".ani02 .box").addClass("on");
                      UI.$infoSwiper02.find(".swiper-slide").eq(currentSlide).find(".ani02 .selet_detail").slideDown(400);
                      _this.tween03.to(UI.$infoSwiper02.find(".swiper-slide").eq(currentSlide).find(".ani02 .selet_detail .item:eq(1)"), .3, {
                        className: "+=item on", delay: .5, onComplete: function () {
                          UI.$infoSwiper02.find(".swiper-slide").eq(currentSlide).find(".ani02 .selet_detail").slideUp(400, function () {
                            UI.$infoSwiper02.find(".swiper-slide").eq(currentSlide).find(".ani02 .box").removeClass("on");
                            _this.tween03.to(UI.$infoSwiper02.find(".swiper-slide").eq(currentSlide).find(".ani02 .form_ani"), .2, {
                              className: "+=form_ani on", onComplete: function () {
                                _this.tween03.to(UI.$infoSwiper02.find(".swiper-slide").eq(currentSlide).find(".cursor"), .2, {
                                  opacity: .4, delay: .1, ease: Linear.easeIn, onComplete: function () {
                                    _this.tween03.to(UI.$infoSwiper02.find(".swiper-slide").eq(currentSlide).find(".cursor"), .25, {
                                      x: -50, y: -20, ease: Linear.easeIn, onComplete: function () {
                                        _this.tween03.to(UI.$infoSwiper02.find(".swiper-slide").eq(currentSlide).find(".cursor"), .25, {
                                          opacity: .6, scale: .98, ease: Linear.easeIn, onComplete: function () {
                                            _this.tween03.to(UI.$infoSwiper02.find(".swiper-slide").eq(currentSlide).find(".cursor"), .25, {
                                              opacity: .4, scale: 1, ease: Linear.easeIn, onComplete: function () {
                                                UI.$infoSwiper02.parents(".ly_info_swiper").find(".btn_info_reset").show();
                                              }
                                            });
                                          }
                                        });
                                      }
                                    });
                                  }
                                });
                              }
                            });
                          });
                        }
                      });
                    }
                  });
                });
              }
            });
          }
        }
      }
    });
    UI.$infoSwiper02.parents(".ly_info_swiper").find(".btn_info_reset").on("click", function (e) {
      e.stopImmediatePropagation();
      var _this = $(this);
      TweenMax.set(_this, { rotation: 0 });
      TweenMax.to(_this, .3, {
        rotation: 360, ease: Power0.easeNone, onComplete: function () {
          _this.hide();
        }
      });
      UI.infoSwiper02.destroy(false);
      UI.infoPopSwiper02.init();
    });
  }
}

// GA Script 호출
export const callGaTag = (objId) => {
    //console.log("frontend Design.js > callGaTag - objId : ["+objId+"]");
    window.gtag('event', objId, {
          'send_to': 'G-ZNLLSSXSB4'
    });
}

