import React, { Component } from 'react';
import Moment from 'moment';
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import $ from 'jquery';
import RMateCandleStickType from 'routes/rMateChart/RMateCandleStickType';
import RMateChartH5 from '../../rMateChart/RMateChartH5';

//// 컴포넌트 사용처 : 청약 당첨예상 상세
class PrevGeCnt extends Component {

  render() {
    const { bjdInfo, prevGeCnt } = this.props.calcResult;

    return (
      <div className="sec" id="sec11">
        <h4>
          과거 동일 청약 지역 <strong>신청 및 당첨 건수</strong>
          <div className="q_mark bt">
            <div className="q_mark_inner">
              <p className="tit">과거 동일 청약 지역 신청 및 당첨 건수란?</p>
              <p className="txt">
                청약아파트가 위치하는 지역에서 진행되었었던, 과거 청약 아파트들의 신청 및 당첨건수를 안내해드려요.<br />
                신청자수 및 당첨건수가 증가하는지, 감소하는지를 확인하여 청약 신청에 도움을 드려요.
            </p>
            </div>
          </div>
        </h4>
        <div className="ty_graph">
          <div>
            <div className="caption list">
              <ul>
                <li className="tit">{bjdInfo.bjdName} 청약 지역</li>
                <li>지역 : {bjdInfo.bjdName}</li>
                <li>형태 : 일반공급</li>
                <li>순위 : 1순위</li>
              </ul>
              <div className="noti">
                <p>* 최근 1년 기준</p>
              </div>
            </div>
          </div>
          <div>
            {/* <div className="chart"> */}
            <div id="rMateDiv4" className="chart" style={{ "width": "760px", "height": "400px" }}>
              {
                this.props.onRenderGraph && <RMateChartH5 chartId={'rMateDiv4'} layout={'COMBINATION_2D_CHART_2'} data={prevGeCnt} chartVars={''} width={'100%'} height={'100%'} />
              }
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default PrevGeCnt;
