import React from 'react';
import CommonComponent from './Common';
import { Link } from 'react-router-dom';

//// 컴포넌트 사용처 : Faq (자주묻는 질문)
class Faq extends CommonComponent {
  componentDidMount() {
    this.fetchFaqList();
  }

  renderPaging() {
    const beginPage = this.state.paginationObj.beginPage;
    const endPage = this.state.paginationObj.endPage;
    const totalPage = this.state.paginationObj.totalPage;

    let pageArr = [];

    for (let i = beginPage; i <= endPage; i++) {
      if (i === 1) {
        pageArr.push(1);
      }
      else {
        pageArr.push(i);
      }
    }
    return (
      pageArr.map((page, idx) => {
        if (page === this.state.currentPage) {
          return (
            <li key={idx}>
              <a href="#" className="on" onClick={() => this.moveFaqPage(page)} title="{page}번 페이지로 이동">{page}</a>
            </li>
          )
        }
        else {
          return (
            <li key={idx}>
              <a href="#" onClick={() => this.moveFaqPage(page)} title="{page}번 페이지로 이동">{page}</a>
            </li>
          )
        }
      })
    )
  }

  render() {
    return (
      <div id="content">
        <div className="inner_wrap">
          <h2 className="hide">고객센터</h2>
          <div className="con_tit_wrap">
            <h3 className="tit">FAQ</h3>
            <p className="sub_txt">아파트청약케어 FAQ 메뉴 입니다.</p>
          </div>
          <ul className="tab_wrap">
            <li><button type="button" className={this.state.faqType === '' ? "btn on" : "btn"} onClick={() => this.changeFaqType('')} >전체</button></li>
            <li><button type="button" className={this.state.faqType === 'J' ? "btn on" : "btn"} onClick={() => this.changeFaqType('J')} >가입</button></li>
            <li><button type="button" className={this.state.faqType === 'S' ? "btn on" : "btn"} onClick={() => this.changeFaqType('S')} >이용</button></li>
            <li><button type="button" className={this.state.faqType === 'E' ? "btn on" : "btn"} onClick={() => this.changeFaqType('E')} >해지</button></li>
          </ul>
          <div className="bbs_wrap">
            <table className="bbs_list toggle_list">
              <caption>
                <strong>FAQ 목록</strong>
                <p>공지사항 목록으로 번호,제목,등록일 로 구성된 표</p>
              </caption>
              <colgroup>
                <col style={{ "width": "95px" }} />
                <col />
                <col style={{ "width": "120px" }} />
              </colgroup>
              <thead>
                <tr>
                  <th><code>NO</code></th>
                  <th>제목</th>
                  <th>등록일</th>
                </tr>
              </thead>
              <tbody>
                {
                  this.state.faqList.length <= 0 ?
                    <tr className="nodata">
                      <td colSpan="3">게시글이 없습니다.</td>
                    </tr>
                    :
                    this.state.faqList.map((faq, idx) => {
                      return (
                        <React.Fragment key={idx}>
                          <tr className="q">
                            <td className="num"><code>{faq.num}</code></td>
                            <td className="tit">
                              <div>
                                <button type="button" className="btn_detail">{faq.question}</button>
                              </div>
                            </td>
                            <td className="date"><code>{faq.regTime}</code></td>
                          </tr>
                          <tr className="a">
                            <td colSpan="3" className="view">
                              <div className="con">
                                <pre dangerouslySetInnerHTML={{ __html: faq.answer }}>
                                </pre>
                              </div>
                            </td>
                          </tr>
                        </React.Fragment>
                      )
                    })
                }
              </tbody>
            </table>
            {
              this.state.faqList.length > 0 ?
                <div className="pagination">
                  <a href="#" className="btn_prve" title="이전 5개 목록으로 이동" onClick={() => this.moveFaqPrevPages()}></a>
                  <ul>
                    {this.renderPaging()}
                  </ul>
                  <a href="#" className="btn_next" title="다음 5개 목록으로 이동" onClick={() => this.moveFaqNextPages()}></a>
                </div>
                : ''
            }
          </div>
        </div>
      </div>
    );
  }
}
export default Faq;