import React, { Component, Fragment } from 'react';

import { dateDiffMsg, numberWithCounts, numberTextReplaceWithEmtag } from 'common/StringUtil';


class RMateDonutType extends Component {

  constructor(props) {
    super(props);
    this.state = {

    };
  }

  componentDidMount = () => {
    setTimeout(() => { this.renderCharts(this.props.graphList) }, 100)
  }


  componentDidUpdate = () => {

  }

  renderCharts = (graphList) => {
    const parentDivId = this.props.parentDivId;
    const rMateChartH5 = window.rMateChartH5;

    // -----------------------차트 설정 시작-----------------------

    // rMateChart 를 생성합니다.
    // 파라메터 (순서대로)
    //  1. 차트의 id ( 임의로 지정하십시오. )
    //  2. 차트가 위치할 div 의 id (즉, 차트의 부모 div 의 id 입니다.)
    //  3. 차트 생성 시 필요한 환경 변수들의 묶음인 chartVars
    //  4. 차트의 가로 사이즈 (생략 가능, 생략 시 100%)
    //  5. 차트의 세로 사이즈 (생략 가능, 생략 시 100%)
    rMateChartH5.create(("rMateDonutType" + parentDivId), parentDivId, "", "100%", "100%");





    // rMateChartH5.calls 함수를 이용하여 차트의 준비가 끝나면 실행할 함수를 등록합니다.
    //
    // argument 1 - rMateChartH5.create시 설정한 차트 객체 아이디 값
    // argument 2 - 차트준비가 완료되면 실행할 함수 명(key)과 설정될 전달인자 값(value)
    // 
    // 아래 내용은 
    // 1. 차트 준비가 완료되면 첫 전달인자 값을 가진 차트 객체에 접근하여
    // 2. 두 번째 전달인자 값의 key 명으로 정의된 함수에 value값을 전달인자로 설정하여 실행합니다.

    //if (parentDivId === 'rMateDonutDiv') {
    rMateChartH5.calls(("rMateDonutType" + parentDivId), {
      "setLayout": this.getChartBody('field', 'amt',
        '계약금', '#888888', //'rgb(136, 136, 136)',
        '중도금', '#15beb9', //'rgb(94, 206, 197)',
        '잔금', '#f16d63'
        , parentDivId), //'rgb(252, 77, 64)'),
      "setData": this.getChartData(graphList)
    });
    //}

    // -----------------------차트 설정 끝 -----------------------
  }

  getChartBody = (cateFieldStr, valFieldStr, disFieldStr1, valFieldColor1, disFieldStr2, valFieldColor2, disFieldStr3, valFieldColor3, parentDivId) => {

    var layoutStr =
      `<rMateChart backgroundColor="#FFFFFF" borderStyle="none">
      <Options>
          <Caption text=" "/>
        </Options>
      <Pie2DChart innerRadius="0.6" selectionMode="none">
          <series>
              <Pie2DSeries nameField="${cateFieldStr}" field="${valFieldStr}" startAngle="-90" renderDirection="clockwise">
                <stroke>
                  <Stroke color="#FFFFFF" weight="2"/>
                </stroke>
                  <fills>
                      ${parentDivId != 'rMateDivDonut3' ?
        `<SolidColor color="${valFieldColor1}"/>
                        <SolidColor color="${valFieldColor2}"/>
                        <SolidColor color="${valFieldColor3}"/>`
        :
        `<SolidColor color="${valFieldColor1}"/>
                        <SolidColor color="${valFieldColor3}"/>`
      }
                  </fills>
                  <showDataEffect>
                      <SeriesZoom duration="1000"/>
                  </showDataEffect>
              </Pie2DSeries>
          </series>
      </Pie2DChart>
  </rMateChart>`;

    return layoutStr;
  }

  getChartData = (graphList) => {
    // 차트 데이터
    var chartData =
      graphList;
    return chartData;
  }


  dataTipJsFunc = (seriesId, seriesName, index, xName, yName, data, values) => {

    // if (seriesName == "ROI") {
    //   return data['Month'] + "ROI: " + " < b > " + data['roi'] + " %</b > ";
    // }
    // else {
    //   return data['Month'] + "Sales: " + " < b > $" + data['sales'] + "M</b > ";
    // }
  }

  render() {


    return (
      <>

      </>

    );
  }
}
export default RMateDonutType;