import React, { Component, Fragment } from 'react';

import { dateDiffMsg, numberWithCounts, numberTextReplaceWithEmtag } from 'common/StringUtil';

//import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import $ from 'jquery';

var Highcharts = require('highcharts');
require('highcharts/highcharts-more')(Highcharts);

class MultiType extends Component {

  constructor(props) {
    super(props);
    this.state = {

    };
  }

  componentDidMount = () => {
    console.log(this.props);

    $('.highcharts-container').parent().css('overflow', '');
    $('.highcharts-tooltip').hide();

    const xAxisLength = $('.highcharts-xaxis-labels text').length;
    $('.highcharts-xaxis-labels text').each(function () {
      if (xAxisLength > 4) {
        if ($(this).index() % 3 != 0) {
          $(this).hide();
        }
        else {
          $(this).removeAttr('transform');
          $(this).attr('text-anchor', 'middle');
        }
      }
    });
    $('.highcharts-yaxis-labels text').eq(0).text('거래량');
  }

  componentDidUpdate = () => {
    $('.highcharts-container').parent().css('overflow', '');
    $('.highcharts-tooltip').hide();

    const xAxisLength = $('.highcharts-xaxis-labels text').length;
    $('.highcharts-xaxis-labels text').each(function () {
      if (xAxisLength > 4) {
        if ($(this).index() % 3 != 0) {
          $(this).hide();
        }
        else {
          $(this).removeAttr('transform');
          $(this).attr('text-anchor', 'middle');
        }
      }
    });
    $('.highcharts-yaxis-labels text').eq(0).text('거래량');
  }

  percenTageFix = (value) => {
    if (value >= 100) {
      return 100;
    }
    else if (value <= 0) {
      return 0;
    }
    else {
      return value;
    }
  }

  mappingChartValue = (mapArr, objName) => mapArr.map(item => item[objName])

  render() {
    const yearPay = this.yearPay;
    const assets = this.props.assets;
    const totalAssets = this.props.totalAssets;
    const dpsAmt = this.props.dpsAmt;
    const totalLoanAmount = this.props.totalLoanAmount;
    const realLoanAmount =
      (dpsAmt <= (assets + totalLoanAmount) ? ((dpsAmt - assets) <= 0 ? 0 : (dpsAmt - assets)) : totalLoanAmount);

    let yMinValue = this.props.graphList != null ? Math.min.apply(null, this.props.graphList.map(v => v["maxDpsAmt"])) : 0;
    let yMaxValue = this.props.graphList != null ? Math.max.apply(null, this.props.graphList.map(v => v["maxDpsAmt"])) : 0;
    let yInterval = this.props.graphList != null ? 10000 : 0;

    if (yMaxValue > 0 && yMaxValue <= 200000) {
      yMinValue = 0;
      yMaxValue = 200000;
      yInterval = parseInt(200000 / 4);
    }
    else if (yMaxValue > 200000 && yMaxValue <= 500000) {
      yMinValue = 200000;
      yMaxValue = 500000;
      yInterval = parseInt(300000 / 3);
    }
    else if (yMaxValue > 500000 && yMaxValue <= 1000000) {
      yMinValue = 500000;
      yMaxValue = 1000000;
      yInterval = parseInt(500000 / 2);
    }
    else if (yMaxValue > 1000000) {
      yMinValue = 1000000;
      yMaxValue = 1000000;
      yInterval = 1000000;
    }

    return (
      <div className={this.props.graphList && this.props.graphList.length > 0 ? "tab_inner" : "tab_inner dim"}>
        <svg>
          <defs>
            <linearGradient id="rect_grad" x1="0" y1="0" x2="1" y2="1">
              <stop offset="0%" stop-color="#2ebcb1" />
              <stop offset="100%" stop-color="#79cdcd" />
            </linearGradient>
          </defs>
        </svg>
        <span className="lbl_chart"></span>
        {
          <HighchartsReact highcharts={Highcharts}
            options={{
              chart: {
                //zoomType: 'xy',
                width: 310,
                height: 260,
              },
              credits: {
                enabled: false,
              },
              title: {
                text: '',
              },
              tooltip: {
                //enabled: false,
                shared: true,
                formatter: function () {
                  let priceStr = numberWithCounts((this.points[1].y * 10000)).toString();
                  let priceArr = priceStr.split(' ');
                  if (priceArr[1] === '') {
                    $('.lbl_chart').css('min-width', '135px');
                  }
                  if (priceArr[0].toString().length > 2) {
                    $('.lbl_chart').css('min-width', parseInt($('.lbl_chart').css('min-width')) + (parseInt(priceArr[0].toString().length) * 3));
                  }
                  if (this.points[0].y > 10) {
                    $('.lbl_chart').css('min-width', parseInt($('.lbl_chart').css('min-width')) + 10);
                  }
                  //return (this.x + " 최대 " + numberWithCounts((this.points[1].y * 10000)) + " (" + this.points[0].y + "건)");
                  $('.lbl_chart').html(("<s>" + this.x + "</s>" + " 최대 " + numberWithCounts((this.points[1].y * 10000)) + " (" + this.points[0].y + "건)"));
                },
                positioner: function (labelWidth, labelHeight, point) {
                  if (point.plotX > 150) {
                    $('.lbl_chart').css('left', (point.plotX - 90));
                  }
                  else {
                    $('.lbl_chart').css('left', point.plotX);
                  }
                  return {
                    x: point.plotX,
                    y: 0
                  };
                }
              },
              xAxis: {
                categories: this.props.graphList != null && this.mappingChartValue(this.props.graphList, "actYearMonth"),
                crosshair: {
                  width: 1,
                  color: 'black',
                },
                style: {
                  color: "#999999",
                },
                labels: {
                  //autoRotation: 0,
                }
              },
              yAxis: [{ // Primary yAxis
                title: '',
                //softMax: 10000,
                //min: yMinValue,
                //max: yMaxValue,
                //min: 0,
                //max: 1000000,
                alignTicks: true,
                minTickInterval: 1000,
                labels: {
                  //format: '{value}만원',
                  formatter: function () {
                    var label = this.axis.defaultLabelFormatter.call(this);
                    return parseFloat((this.value / 10000)).toFixed(1) + '억';
                    //return this.value + '만원';
                  },
                  style: {
                    color: "#999999"
                  }
                },
              }, { // Secondary yAxis
                title: '',
                softMax: 5,
                //min: 0,
                //max: 50,
                labels: {
                  //format: '{value}건',
                  formatter: function () {
                    var label = this.axis.defaultLabelFormatter.call(this);
                    return '';
                  },
                  style: {
                    color: "#999999"
                  }
                },
                opposite: false,

              }],
              plotOptions: {
                series: {
                  pointWidth: 5,
                  borderRadius: 3,
                  dataLabels: {
                    enabled: false,
                  },
                  point: {
                    events: {
                      mouseOver: function () {
                        $('.highcharts-tooltip').hide();
                        $('.lbl_chart').css('top', '0px');

                        $('.lbl_chart').css('min-width', '165px');
                        $('.lbl_chart').css('z-index', '1');
                        $('.lbl_chart').show();

                        //$('path.highcharts-point-hover').removeAttr('fill');
                        //$('path.highcharts-point-hover').attr('fill', '#2ebcb1');
                      },
                      mouseOut: function () {
                        $('.highcharts-tooltip').hide();
                        $('.lbl_chart').hide();

                        //$('path.highcharts-point').each(function () {
                        //$(this).removeAttr('fill');
                        //$(this).attr('fill', '#ccc');
                        //});
                      },
                    },
                  },
                  showInLegend: false,
                },
              },

              series: [
                {
                  name: '거래량',
                  type: 'column',
                  yAxis: 1,
                  //color: "#2ebcb1",
                  color: "url(#rect_grad)",
                  //color: "linear-gradient(90deg, rgba(2,0,36,1) 0%, rgba(0,212,255,1) 100%);",
                  data: this.props.graphList != null && this.mappingChartValue(this.props.graphList, "actCnt"),
                  tooltip: {
                    valueSuffix: '건'
                  },
                }, {
                  name: '매매최대가',
                  type: 'spline',
                  lineWidth: 1,
                  //yAxis: 0,
                  color: "#ccc",
                  data: this.props.graphList != null && this.mappingChartValue(this.props.graphList, "maxDpsAmt"),
                  tooltip: {
                    valueSuffix: '만원'
                  },
                  // marker: {
                  //   fillColor: '#2ebcb1',
                  // }
                },
              ],
            }} />
        }
      </div>

    );
  }
}
export default MultiType;