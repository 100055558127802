import React from 'react';
import CommonComponent from './Common'
import { popupClose } from 'common/Design';

//// 컴포넌트 사용처 : 해지
class Expire extends CommonComponent {
  componentDidMount() {
    this.telcoClick(1);
  }

  render() {
    const { phoneNo2, captchaAnswer, authCode } = this.state;
    return (
      <div id="content" className="close">
        <div className="inner_wrap">
          <div className="con_tit_wrap">
            <h2 className="tit">서비스 해지</h2>
            <ul className="sub_txt">
              <li>- 서비스 해지 시 아파트청약케어의 프리미엄 서비스 이용이 불가합니다.</li>
              <li>-  아파트청약케어는 <span className="fc">월 3,300원(부가세포함)의 유료서비스</span>로, 사용 일 수 만큼 일할 계산되어 과금됩니다.</li>
            </ul>
          </div>
          <div className="form_wrap join_wrap">
            <section className="form_inner">
              <ul className="form">
                <li className="item">
                  <strong className="tit">통신사</strong>
                  <div className="ip_wrap rdo_wrap">
                    <div className="rdo_item">
                      <input type="radio" id="telcoCode1" name="telcoCode" onClick={() => this.telcoClick(1)} />
                      <label htmlFor="telcoCode1">SKT</label>
                    </div>
                    <div className="rdo_item">
                      <input type="radio" id="telcoCode2" name="telcoCode" onClick={() => this.telcoClick(2)} />
                      <label htmlFor="telcoCode2">KT</label>
                    </div>
                    <div className="rdo_item">
                      <input type="radio" id="telcoCode3" name="telcoCode" onClick={() => this.telcoClick(3)} />
                      <label htmlFor="telcoCode3">LGU+</label>
                    </div>
                  </div>
                </li>
                <li className="item">
                  <strong className="tit">휴대폰번호</strong>
                  <div className="ip_wrap">
                    <div className="select_box">
                      <select name="phoneNo1" id="phoneNo1" title="휴대폰 번호 앞자리 선택" className="code" onChange={this.onChangePhoneNo1}>
                        <option value="010">010</option>
                        <option value="011">011</option>
                        <option value="016">016</option>
                        <option value="017">017</option>
                        <option value="018">018</option>
                        <option value="019">019</option>
                      </select>
                    </div>
                    <div className="ip_box">
                      <label htmlFor="phoneNo2" className="lbl" >휴대폰번호를 입력해주세요.</label>
                      <input type="number" pattern="[0-9]+" id="phoneNo2" className="ip ip_phone code" name="phoneNo2" maxLength="8" onChange={this.onChangePhoneNo2} />
                    </div>
                  </div>
                  <button id="btn_setend" type="button" className="btn btn01" disabled={!phoneNo2} onClick={this.expireCheckPhone}>인증요청</button>
                </li>
                <li id="section_auth" className="item" style={{ "display": "none" }}>
                  <strong className="tit">인증번호</strong>
                  <div className="ip_wrap">
                    <div className="ip_box">
                      <label htmlFor="authCode" className="lbl">인증번호를 입력해주세요.</label>
                      <input type="number" id="authCode" className="ip code" name="authCode" maxLength="6" onChange={this.onChangeAuthCode} disabled />
                      <span id="auth_timer" className="time" style={{ display: 'none' }}>03:00</span>
                    </div>
                  </div>
                  <button id="btn_reque" type="button" className="btn btn02" disabled={!phoneNo2} onClick={this.expireCheckPhone} style={{ display: 'none' }}>재발송</button>
                </li>
                <li id="section_stepB" className="item" style={{ display: 'none' }}>
                  <strong className="tit">보안문자</strong>
                  <div className="security_wrap">
                    <div className="img" ><img id="img_captcha" src={process.env.REACT_APP_ENV_URL + "resources/images/temp01.png"} alt="" /></div>
                    <button id="newSafeNum" type="button" className="btn_reset">새로고침</button>
                  </div>
                  <div className="ip_wrap">
                    <div className="ip_box">
                      <label htmlFor="answer_captcha" className="lbl">보안문자(숫자)입력</label>
                      <input type="number" id="answer_captcha" className="ip code" name="security" maxLength="6" onChange={this.onChangeCaptchaAnswer} />
                    </div>
                  </div>
                  <button id="submit_captcha" type="button" className="btn btn03">다음</button>
                </li>
              </ul>
            </section>
          </div>
          <div className="btn_wrap">
            <button type="button" className="btn" disabled={!authCode} onClick={this.expireSubmit}>서비스 해지</button>
          </div>
        </div>

        {/* [SOM.230922] LGU+ 가입/해지 작업 공지 팝업 (LGU+ 선택시) */}
        <div id="mw-notice3" className="ly_cpinfo_pc ly_notice ly_main">
          <div className="notice-lgu" role="alertdialog" aria-labelledby="lb-notice-title" aria-describedby="lb-notice-desc-01 lb-notice-desc-02 lb-notice-desc-03">
            <div className="notice-lgu-content">
              <h2 id="lb-notice-title" className="notice-lgu-title">이동통신사 시스템 점검 안내</h2>
              <p id="lb-notice-desc-01" className="notice-lgu-desc-01">현재 LGU+ 시스템 점검으로<br/>서비스를 해지하실 수 없습니다.<br/><br/><em>시스템 점검 후 해지 처리가 되며,<br/>휴대폰 인증을 완료 하시면 점검 후 해지가<br/>진행됩니다.</em><br/>불편을 드려 죄송합니다.</p>
              <p id="lb-notice-desc-02" className="notice-lgu-desc-02"><em>점검일시</em><br/>10월 20일(금) 22시 ~ 10월 23일(월) 08시</p>
              <p id="lb-notice-desc-03" className="notice-lgu-desc-03">서비스 재개 시점은 상황에 따라 지연될 수 있습니다.</p>
              <button type="button" className="notice-lgu-btn" aria-label="닫기" onClick={() => popupClose('notice3', 'mwf')}></button>
            </div>
          </div>
        </div>

      </div>
    );
  }
}
export default Expire;