import React, { Component } from 'react';

class RecentResult extends Component {

  render() {
    return (
      <div className="search_list record on">
        <strong>최근검색어</strong>
        <ul>
          {
            this.props.recentResult.length > 0 ?
              this.props.recentResult.map((v, idx) => {
                const searchName = v.info1 ? v.info1 + ' ' + v.searchName : v.searchName;
                return (
                  <li key={idx} >
                    <a href="#">
                      <div className="tit" onClick={(e) => { e.preventDefault(); this.props.onSelectRecent(v); }} >{searchName}</div>
                      <s className="ico_close" onClick={(e) => { e.preventDefault(); this.props.onRemoveRecent(v.searchId); }}></s>
                    </a>
                  </li>
                )
              })
              :
              null

          }
        </ul>
        {
          this.props.recentResult.length > 0 ?
            <div className="btn_area">
              <button type="button" className="btn_recent"
                onClick={this.props.onClearRecent} >최근 검색기록 삭제</button>
            </div>
            :
            <p className="noti">최근 검색어가 없습니다.</p>
        }
      </div>
    );
  }
}
export default RecentResult;