export const AXIOS_TYPE = Object.freeze({
  AUTH: 'auth',
  BASIC: 'basic',
});

export const AXIOS_AUTH_RESULT = Object.freeze({
  CHECK_SUCCESS: 'checkSuccess',
  CHECK_FAIL: 'checkFail',
  RE_ISSUE_SUCCESS: 'reIssueSuccess',
  RE_ISSUE_FAIL: 'reIssueFail',
});

export const AXIOS_EXCEPTION = Object.freeze([
  { code: 400, msg: 'Bad Request : 요청이 잘못되었습니다. 관리자에게 문의하세요.' }
  , { code: 404, msg: 'Page not found : 페이지를 찾을 수 없습니다. 관리자에게 문의하세요.' }
  , { code: 500, msg: 'Internal Server Error : 서버쪽 이상입니다. 관리자에게 문의하세요.' }
  , { code: 504, msg: 'Gateway Timeout : 서버쪽 이상입니다. 관리자에게 문의하세요.' }
]);