export function BjdOverlay (data, level) {
  this.level = level;
  if (data.bjdName != undefined || data.bjdName != null) {
    this.position = new kakao.maps.LatLng(data.lat, data.lng);    // eslint-disable-line
    ////console.log(this.position);
    let node = this.node = document.createElement('div');
    node.className = 'map_marker';
    node.id = 'marker_' + data.markerId;

    node.innerHTML = `<div class="map_marker" href="#" id="markerBjd_${data.markerId}">
                    <div class="marker_inner type_apt">
                    <input type="hidden" id="latBjd_${data.markerId}" value="${data.lat}" >
                    <input type="hidden" id="lngBjd_${data.markerId}" value="${data.lng}" >
                    <div class="data">
                        <p class="sqf">${data.bjdName}</em></p>
                        <p class="price">${data.amt >= 1 ? `<em>${data.amt}억</em>` : `<em>1억 미만</em>`}</p>
                        <p class="day">${data.cnt}건 매매</p>
                    </div>
                    </div>
                </div>`;
  }
}

if (window.kakao) {
  BjdOverlay.prototype = new window.kakao.maps.AbstractOverlay;

  BjdOverlay.prototype.onAdd = function () {
    if (this.level >= 5 && this.node != undefined) {
      var panel = this.getPanels().overlayLayer;
      panel.appendChild(this.node);
    }
  }

  BjdOverlay.prototype.onRemove = function () {
    if (this.level >= 5 && this.node != undefined) {
      this.node.parentNode.removeChild(this.node);
    }
  }

  BjdOverlay.prototype.draw = function () {
    if (this.level >= 5 && this.node != undefined) {
      // 화면 좌표와 지도의 좌표를 매핑시켜주는 projection객체
      var projection = this.getProjection();

      // overlayLayer는 지도와 함께 움직이는 layer이므로
      // 지도 내부의 위치를 반영해주는 pointFromCoords를 사용합니다.
      var point = projection.pointFromCoords(this.position);

      // 내부 엘리먼트의 크기를 얻어서
      var width = this.node.offsetWidth;
      var height = this.node.offsetHeight;

      // 해당 위치의 정중앙에 위치하도록 top, left를 지정합니다.
      this.node.style.left = (point.x - width / 2) + "px";
      this.node.style.top = (point.y - height / 2) + "px";
    }
  }

  // 좌표를 반환하는 메소드
  BjdOverlay.prototype.getPosition = function () {
    return this.position;
  }
}

// export class BjdOverlay extends kakao.maps.AbstractOverlay {    // eslint-disable-line
//
//   constructor(data, level) {
//     super();
//     this.level = level;
//     if (data.bjdName != undefined || data.bjdName != null) {
//       this.position = new kakao.maps.LatLng(data.lat, data.lng);    // eslint-disable-line
//       ////console.log(this.position);
//       let node = this.node = document.createElement('div');
//       node.className = 'map_marker';
//       node.id = 'marker_' + data.markerId;
//
//       node.innerHTML = `<div class="map_marker" href="#" id="markerBjd_${data.markerId}">
//                     <div class="marker_inner type_apt">
//                     <input type="hidden" id="latBjd_${data.markerId}" value="${data.lat}" >
//                     <input type="hidden" id="lngBjd_${data.markerId}" value="${data.lng}" >
//                     <div class="data">
//                         <p class="sqf">${data.bjdName}</em></p>
//                         <p class="price">${data.amt >= 1 ? `<em>${data.amt}억</em>` : `<em>1억 미만</em>`}</p>
//                         <p class="day">${data.cnt}건 매매</p>
//                     </div>
//                     </div>
//                 </div>`;
//     }
//
//   }
//
//   onAdd() {
//     if (this.level >= 5 && this.node != undefined) {
//       var panel = super.getPanels().overlayLayer;
//       panel.appendChild(this.node);
//     }
//   }
//
//   onRemove() {
//     if (this.level >= 5 && this.node != undefined) {
//       this.node.parentNode.removeChild(this.node);
//     }
//   };
//
//   draw() {
//     if (this.level >= 5 && this.node != undefined) {
//       // 화면 좌표와 지도의 좌표를 매핑시켜주는 projection객체
//       var projection = super.getProjection();
//
//       // overlayLayer는 지도와 함께 움직이는 layer이므로
//       // 지도 내부의 위치를 반영해주는 pointFromCoords를 사용합니다.
//       var point = projection.pointFromCoords(this.position);
//
//       // 내부 엘리먼트의 크기를 얻어서
//       var width = this.node.offsetWidth;
//       var height = this.node.offsetHeight;
//
//       // 해당 위치의 정중앙에 위치하도록 top, left를 지정합니다.
//       this.node.style.left = (point.x - width / 2) + "px";
//       this.node.style.top = (point.y - height / 2) + "px";
//     }
//   };
//
//   // 좌표를 반환하는 메소드
//   getPosition() {
//     return this.position;
//   };
// }