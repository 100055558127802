import React, { Component } from 'react';
import { globalPopupClose } from 'common/Design';

//// 컴포넌트 사용처 : 청약 당첨예상 상세
class ConstructRankList extends Component {

  render() {

    const { buildRank, constructList } = this.props.calcResult;
    const selectedRank = buildRank === null ? 0 : buildRank.rank;

    return (
      <div id="mw-rank" class="ly_cpinfo_pc ly_rank">
        <div class="wrap">
          <div class="content">
            <button type="button" class="btn_close" onClick={() => globalPopupClose('rank', 'mw')}><i>닫기</i></button>
            <h4>건설협회 시공능력 평가순위</h4>
            <section class="rank_area">
              <table>
                <caption>
                  <strong>건설협회 시공 능력 순위 1~20</strong>
                  <p>순위와 회사이름 랭킹 순 정렬</p>
                </caption>
                <colgroup>
                  <col style={{ 'width': '40px' }} />
                  <col />
                </colgroup>
                <tbody>
                  {
                    constructList.filter(v => v.rank <= 20).map(v =>
                      <tr class={selectedRank === v.rank ? "point" : ""}>
                        <th>{v.rank}</th>
                        <td>{v.name}</td>
                      </tr>
                    )
                  }
                </tbody>
              </table>

              <table>
                <caption>
                  <strong>건설협회 시공 능력 순위 21~40</strong>
                  <p>순위와 회사이름 랭킹 순 정렬</p>
                </caption>
                <colgroup>
                  <col style={{ 'width': '40px' }} />
                  <col />
                </colgroup>
                <tbody>
                  {
                    constructList.filter(v => v.rank > 20 && v.rank <= 40).map(v =>
                      <tr class={selectedRank === v.rank ? "point" : ""}>
                        <th>{v.rank}</th>
                        <td>{v.name}</td>
                      </tr>
                    )
                  }
                </tbody>
              </table>

              <table>
                <caption>
                  <strong>건설협회 시공 능력 순위 41~60</strong>
                  <p>순위와 회사이름 랭킹 순 정렬</p>
                </caption>
                <colgroup>
                  <col style={{ 'width': '40px' }} />
                  <col />
                </colgroup>
                <tbody>
                  {
                    constructList.filter(v => v.rank > 40 && v.rank <= 60).map(v =>
                      <tr class={selectedRank === v.rank ? "point" : ""}>
                        <th>{v.rank}</th>
                        <td>{v.name}</td>
                      </tr>
                    )
                  }
                </tbody>
              </table>

              <table>
                <caption>
                  <strong>건설협회 시공 능력 순위 61~80</strong>
                  <p>순위와 회사이름 랭킹 순 정렬</p>
                </caption>
                <colgroup>
                  <col style={{ 'width': '40px' }} />
                  <col />
                </colgroup>
                <tbody>
                  {
                    constructList.filter(v => v.rank > 60 && v.rank <= 80).map(v =>
                      <tr class={selectedRank === v.rank ? "point" : ""}>
                        <th>{v.rank}</th>
                        <td>{v.name}</td>
                      </tr>
                    )
                  }
                </tbody>
              </table>

              <table>
                <caption>
                  <strong>건설협회 시공 능력 순위 81~100</strong>
                  <p>순위와 회사이름 랭킹 순 정렬</p>
                </caption>
                <colgroup>
                  <col style={{ 'width': '40px' }} />
                  <col />
                </colgroup>
                <tbody>
                  {
                    constructList.filter(v => v.rank > 80 && v.rank <= 100).map(v =>
                      <tr>
                        <th>{v.rank}</th>
                        <td>{v.name}</td>
                      </tr>
                    )
                  }
                </tbody>
              </table>
            </section>
          </div>
        </div>
      </div>
    );
  }
}
export default ConstructRankList;