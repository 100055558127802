import { formatAmt, numberWithCommas } from 'common/StringUtil';

export function AptOverlay (data, level) {
  this.level = level;
  if (data.areaPy != undefined || data.areaPy != null) {
    this.position = new kakao.maps.LatLng(data.lat, data.lng);    // eslint-disable-line
    let node = this.node = document.createElement('div');
    node.className = 'map_marker';
    node.setAttribute('data-area-id', data.aptAreaId);
    node.id = 'marker_' + data.markerId;

    const amt = data.amt >= 10000 ? `${formatAmt(data.amt, 'amt4')}<em>억</em>${formatAmt(data.amt, 'amt5')}` : `${formatAmt(data.amt, 'amt5')}`;

    node.innerHTML = `<div class="marker_inner type_apt">
            <div class="marker_info">
                <p class="name">${data.aptName}</p>
                <div class="info">
                    <p class="family">${numberWithCommas(data.hhldCnt)}<em>세대</em></p>
                    /
                    <p class="build">${data.useAprDay}<span>년</span><em>준공</em></p>
                </div>
            </div>
            <div class="data">
                <p class="sqf">${data.areaPy}<em>평</em></p>
                <p class="price">${amt}</em></p>
                <p class="day">${data.trxDate}</p>
            </div>
            <div class="marker_shadow"></div>
        </div>`;
  }
}

if (window.kakao) {
  AptOverlay.prototype = new window.kakao.maps.AbstractOverlay;

  AptOverlay.prototype.onAdd = function () {
    if (this.level < 5 && this.node != undefined) {
      var panel = this.getPanels().overlayLayer;
      panel.appendChild(this.node);
    }
  }

  AptOverlay.prototype.onRemove = function () {
    if (this.level < 5 && this.node != undefined) {
      this.node.parentNode && this.node.parentNode.removeChild(this.node);
    }
  }

  AptOverlay.prototype.draw = function () {
    if (this.level < 5 && this.node != undefined) {
      // 화면 좌표와 지도의 좌표를 매핑시켜주는 projection객체
      var projection = this.getProjection();

      // overlayLayer는 지도와 함께 움직이는 layer이므로
      // 지도 내부의 위치를 반영해주는 pointFromCoords를 사용합니다.
      var point = projection.pointFromCoords(this.position);

      // 내부 엘리먼트의 크기를 얻어서
      var width = this.node.offsetWidth;
      var height = this.node.offsetHeight;

      // 해당 위치의 정중앙에 위치하도록 top, left를 지정합니다.
      // marker 특성 고려 말풍선꼭지부분이 중앙에 오도록 재조정 (left+40, top-40)
      this.node.style.left = (point.x - width / 2) + 40 + "px";
      this.node.style.top = (point.y - height / 2) - 40 + "px";
    }
  }

  // 좌표를 반환하는 메소드
  AptOverlay.prototype.getPosition = function () {
    return this.position;
  }
}

// export class AptOverlay extends kakao.maps.AbstractOverlay {    // eslint-disable-line
//
//   constructor(data, level) {
//     super();
//     this.level = level;
//     if (data.areaPy != undefined || data.areaPy != null) {
//       this.position = new kakao.maps.LatLng(data.lat, data.lng);    // eslint-disable-line
//       let node = this.node = document.createElement('div');
//       node.className = 'map_marker';
//       node.setAttribute('data-area-id', data.aptAreaId);
//       node.id = 'marker_' + data.markerId;
//
//       const amt = data.amt >= 10000 ? `${formatAmt(data.amt, 'amt4')}<em>억</em>${formatAmt(data.amt, 'amt5')}` : `${formatAmt(data.amt, 'amt5')}`;
//
//       node.innerHTML = `<div class="marker_inner type_apt">
//             <div class="marker_info">
//                 <p class="name">${data.aptName}</p>
//                 <div class="info">
//                     <p class="family">${numberWithCommas(data.hhldCnt)}<em>세대</em></p>
//                     /
//                     <p class="build">${data.useAprDay}<span>년</span><em>준공</em></p>
//                 </div>
//             </div>
//             <div class="data">
//                 <p class="sqf">${data.areaPy}<em>평</em></p>
//                 <p class="price">${amt}</em></p>
//                 <p class="day">${data.trxDate}</p>
//             </div>
//             <div class="marker_shadow"></div>
//         </div>`;
//     }
//
//   }
//
//   onAdd() {
//     if (this.level < 5 && this.node != undefined) {
//       var panel = super.getPanels().overlayLayer;
//       panel.appendChild(this.node);
//     }
//   }
//
//   onRemove() {
//     if (this.level < 5 && this.node != undefined) {
//       this.node.parentNode && this.node.parentNode.removeChild(this.node);
//     }
//   };
//
//   draw() {
//     if (this.level < 5 && this.node != undefined) {
//       // 화면 좌표와 지도의 좌표를 매핑시켜주는 projection객체
//       var projection = super.getProjection();
//
//       // overlayLayer는 지도와 함께 움직이는 layer이므로
//       // 지도 내부의 위치를 반영해주는 pointFromCoords를 사용합니다.
//       var point = projection.pointFromCoords(this.position);
//
//       // 내부 엘리먼트의 크기를 얻어서
//       var width = this.node.offsetWidth;
//       var height = this.node.offsetHeight;
//
//       // 해당 위치의 정중앙에 위치하도록 top, left를 지정합니다.
//       // marker 특성 고려 말풍선꼭지부분이 중앙에 오도록 재조정 (left+40, top-40)
//       this.node.style.left = (point.x - width / 2) + 40 + "px";
//       this.node.style.top = (point.y - height / 2) - 40 + "px";
//     }
//   };
//
//   // 좌표를 반환하는 메소드
//   getPosition() {
//     return this.position;
//   };
// }