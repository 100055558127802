import React, { Component } from 'react'; 

class SearchResult extends Component { 

  render() { 
    return (
      <div className={`${this.props.searchVisible ? "search_list on" : "search_list"}`}>
        <ul>
          {

            this.props.searchResult.length === 0 && this.props.searchKeyword.trim() !== '' ? 
              <li class="no_search">
                <a href="#;">'{this.props.searchKeyword}'에 대한 검색결과가 없어요.</a>
              </li>
              : this.props.searchResult && this.props.searchResult.map((v, idx) => {

                let searchName = v.info1 ? v.info1 + ' ' + v.searchName : v.searchName;
                this.props.searchKeyword.split(' ').forEach(v2 => {
                  searchName = searchName.replace(v2, `<i class="core">${v2}</i>`);
                });

                return (
                  <li key={idx} 
                  onClick={(e) => {e.preventDefault(); this.props.onSelectSearch(v);}} 
                      className={`${idx == this.props.searchIndex ? "focus" : ""}`}  >
                      <a href="#" dangerouslySetInnerHTML={{ __html: searchName }} ></a>
                  </li>
                )
              })
          }
        </ul>
      </div>
    ); 
  } 
} 
export default SearchResult;