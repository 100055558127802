import Moment from 'moment';
import { numberWithCommas, numberWithCounts } from 'common/StringUtil';

export function RcmdAptOverlay (data) {
  this.position = new kakao.maps.LatLng(data.lat, data.lng);    // eslint-disable-line
  let node = this.node = document.createElement('div');
  node.className = 'map_marker';
  node.id = 'marker_' + data.aptId;

  node.innerHTML = `<div class="marker_inner type_apt">
                        <input type="hidden" id="lat_${data.aptId}" value="${data.lat}" >
                        <input type="hidden" id="lng_${data.aptId}" value="${data.lng}" >
                          <div class="marker_info">
                            <p class="name">${data.aptName}</p>
                            <div class="info">
                              <p class="family">${numberWithCommas(data.hhldCnt)}<em>세대</em></p>
                              /
                              <p class="build">${this.dateFormatMsg3(data.regYearMonth)}<span>년</span><em>준공</em></p>
                            </div>
                          </div>
                          <div class="data">
                            <p class="sqf">${data.pubAreaPy}<em>평</em></p>
                            <p class="price">${numberWithCounts(data.dpsAmt * 10000)}<em></em></p>
                            <p class="day">${this.dateFormatMsg2(data.dpsAmtTrxDate)}</p>
                          </div>
                          <div class="marker_shadow"></div>
                        </div>`;
}

if (window.kakao) {
  RcmdAptOverlay.prototype = new window.kakao.maps.AbstractOverlay;

  RcmdAptOverlay.prototype.onAdd = function () {
    var panel = this.getPanels().overlayLayer;
    panel.appendChild(this.node);
  }

  RcmdAptOverlay.prototype.onRemove = function () {
    this.node.parentNode && this.node.parentNode.removeChild(this.node);
  }

  RcmdAptOverlay.prototype.draw = function () {
    // 화면 좌표와 지도의 좌표를 매핑시켜주는 projection객체
    var projection = this.getProjection();

    // overlayLayer는 지도와 함께 움직이는 layer이므로
    // 지도 내부의 위치를 반영해주는 pointFromCoords를 사용합니다.
    var point = projection.pointFromCoords(this.position);

    // 내부 엘리먼트의 크기를 얻어서
    var width = this.node.offsetWidth;
    var height = this.node.offsetHeight;

    // 해당 위치의 정중앙에 위치하도록 top, left를 지정합니다.
    // marker 특성 고려 말풍선꼭지부분이 중앙에 오도록 재조정 (left+40, top-40)
    this.node.style.left = (point.x - width / 2) + 40 + "px";
    this.node.style.top = (point.y - height / 2) - 40 + "px";
  }

  // 좌표를 반환하는 메소드
  RcmdAptOverlay.prototype.getPosition = function () {
    return this.position;
  }

  RcmdAptOverlay.prototype.dateFormatMsg2 = function (x) {
    if (x === null || x === undefined || x === NaN) return '';
    else {
      return Moment(x).format('YYYY.MM');
    }
  }

  RcmdAptOverlay.prototype.dateFormatMsg3 = function (x) {
    if (x === null || x === undefined || x === NaN) return '';
    else {
      return Moment(x).format('YYYY');
    }
  }
}

// export class RcmdAptOverlay extends kakao.maps.AbstractOverlay {    // eslint-disable-line
//
//   constructor(data) {
//     super();
//     this.position = new kakao.maps.LatLng(data.lat, data.lng);    // eslint-disable-line
//     let node = this.node = document.createElement('div');
//     node.className = 'map_marker';
//     node.id = 'marker_' + data.aptId;
//
//     node.innerHTML = `<div class="marker_inner type_apt">
//                         <input type="hidden" id="lat_${data.aptId}" value="${data.lat}" >
//                         <input type="hidden" id="lng_${data.aptId}" value="${data.lng}" >
//                           <div class="marker_info">
//                             <p class="name">${data.aptName}</p>
//                             <div class="info">
//                               <p class="family">${numberWithCommas(data.hhldCnt)}<em>세대</em></p>
//                               /
//                               <p class="build">${this.dateFormatMsg3(data.regYearMonth)}<span>년</span><em>준공</em></p>
//                             </div>
//                           </div>
//                           <div class="data">
//                             <p class="sqf">${data.pubAreaPy}<em>평</em></p>
//                             <p class="price">${numberWithCounts(data.dpsAmt * 10000)}<em></em></p>
//                             <p class="day">${this.dateFormatMsg2(data.dpsAmtTrxDate)}</p>
//                           </div>
//                           <div class="marker_shadow"></div>
//                         </div>`;
//   }
//
//   onAdd() {
//     var panel = super.getPanels().overlayLayer;
//     panel.appendChild(this.node);
//   }
//
//   onRemove() {
//     this.node.parentNode && this.node.parentNode.removeChild(this.node);
//   };
//
//   draw() {
//     // 화면 좌표와 지도의 좌표를 매핑시켜주는 projection객체
//     var projection = super.getProjection();
//
//     // overlayLayer는 지도와 함께 움직이는 layer이므로
//     // 지도 내부의 위치를 반영해주는 pointFromCoords를 사용합니다.
//     var point = projection.pointFromCoords(this.position);
//
//     // 내부 엘리먼트의 크기를 얻어서
//     var width = this.node.offsetWidth;
//     var height = this.node.offsetHeight;
//
//     // 해당 위치의 정중앙에 위치하도록 top, left를 지정합니다.
//     // marker 특성 고려 말풍선꼭지부분이 중앙에 오도록 재조정 (left+40, top-40)
//     this.node.style.left = (point.x - width / 2) + 40 + "px";
//     this.node.style.top = (point.y - height / 2) - 40 + "px";
//   };
//
//   // 좌표를 반환하는 메소드
//   getPosition() {
//     return this.position;
//   };
//
//   dateFormatMsg2 = (x) => {
//     if (x === null || x === undefined || x === NaN) return '';
//     else {
//       return Moment(x).format('YYYY.MM');
//     }
//   }
//   dateFormatMsg3 = (x) => {
//     if (x === null || x === undefined || x === NaN) return '';
//     else {
//       return Moment(x).format('YYYY');
//     }
//   }
// }