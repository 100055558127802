import React, { Component } from 'react';

class Footer extends Component {
  agreeClickPop = () => {
    window.open('/apt/terms/group?type=1&loc=N&telcoCode=1', 'sub_apc', 'toolbar=no,scrollbars=yes,resizable=no,top=0,left=0,width=600,height=600');
  }

  locationClickPop = () => {
    window.open('/apt/terms?type=6&loc=N', 'sub_apc', 'toolbar=no,scrollbars=yes,resizable=no,top=0,left=0,width=600,height=600');
  }

  privacyClickPop = () => {
    window.open('https://www.hectoinnovation.co.kr/sterm/privacy', 'sub_apc', 'toolbar=no,scrollbars=yes,resizable=no,top=0,left=0,width=600,height=600');
  }

  companyClickPop = () => {
    window.open(process.env.REACT_APP_ENV_URL + 'resources/global/terms/company.html', 'apc', 'toolbar=no,scrollbars=yes,resizable=no,top=0,left=0,width=385,height=600');
  }

  render() {
    return (
      <div className="inner_wrap">
        <button type="button" className="btn_gotop" style={{ "display": "none" }}>상단으로 바로가기</button>
        <ul className="link_wrap">
          <li><button type="button" className="btn_link" onClick={this.agreeClickPop}>이용약관</button></li>
          <li><button type="button" className="btn_link" onClick={this.locationClickPop}>위치기반서비스 이용약관</button></li>
          <li><button type="button" className="btn_link type_01" onClick={this.privacyClickPop}>개인정보처리방침</button></li>
          <li><button type="button" className="btn_link btn_info" onClick={this.companyClickPop}>(주) 헥토이노베이션 사업자 정보</button></li>
        </ul>
        <ul className="txt_wrap">
          <li>(주)헥토이노베이션</li>
          <li>대표이사  이현철</li>
          <li>사업자등록번호  214-88-39117 </li>
          <li>통신판매업신고  2015-서울강남-00012</li>
          <li>서울특별시 강남구 테헤란로 34길6, 태광타워 12, 13층</li>
          <li className="clear">고객센터 1599-2985</li>
          <li>E-mail  in.help@hecto.co.kr</li>
          <li>FAX  02-929-4424</li>
          <li>Copyright © Hecto Innovation Co., Ltd. All rights reserved.</li>
        </ul>
      </div>
    );
  }
}

export default Footer;
