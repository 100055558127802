
import Moment from 'moment';
import 'moment-timezone';

export const formatYmd = (ymd, type) => {

  let rtnStr = "";
  if (!ymd) return "";
  switch (type) {
    case "comma1": rtnStr = `${ymd.substr(2, 2)}. ${ymd.substr(4, 2)}. ${ymd.substr(6, 2)}`; break;
    case "dash1": rtnStr = `${ymd.substr(0, 4)}-${ymd.substr(4, 2)}-${ymd.substr(6, 2)}`; break;
  }
  return rtnStr;
}

export const formatAmt = (amt, type) => {

  let rtnAmt = "";
  if (!amt) return "";

  switch (type) {
    case "amt1": {
      let hm = Math.floor(Number(amt) / 10000);
      let tm = Math.floor((Number(amt) - (hm * 10000)) / 1000)
      rtnAmt = `${hm}억 ${numberWithCommas2(tm)} 천만`
    }
    case "amt2": {
      rtnAmt = Math.floor(Number(amt) / 100000000);
      break;
    }
    case "amt3": {
      let hm = Math.floor(Number(amt) / 100000000);
      let tm = Math.floor(((Number(amt) - (hm * 100000000)) / 10000));
      rtnAmt = `${numberWithCommas2(tm)}`;
      break;
    }
    case "amt4": {
      rtnAmt = Math.floor(Number(amt) / 10000);
      break;
    }
    case "amt5": {
      let hm = Math.floor(Number(amt) / 10000);
      let tm = Math.floor((Number(amt) - (hm * 10000)));
      rtnAmt = `${numberWithCommas2(tm)}`;
      rtnAmt = rtnAmt == '0' ? '' : rtnAmt
      break;
    }
  }
  return rtnAmt;
}

export const dateDiff = (_date1, _date2) => {
  var diffDate_1 = _date1 instanceof Date ? _date1 : new Date(_date1);
  var diffDate_2 = _date2 instanceof Date ? _date2 : new Date(_date2);

  diffDate_1 = new Date(diffDate_1.getFullYear(), diffDate_1.getMonth() + 1, diffDate_1.getDate());
  diffDate_2 = new Date(diffDate_2.getFullYear(), diffDate_2.getMonth() + 1, diffDate_2.getDate());

  var diff = Math.abs(diffDate_2.getTime() - diffDate_1.getTime());
  diff = Math.ceil(diff / (1000 * 3600 * 24));

  return diff;
}

export const numberWithCommas = (x) => {
  if (x === null || x === undefined || x === NaN) return;
  else {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }
}

export const numberWithCommas2 = (x) => {
  if (x === null || x === undefined || x === NaN) return;
  else {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",").split(',');
  }
}

export const numberWithCounts = (x) => {
  if (x === null || x === undefined || x === isNaN()) return;
  else {
    const numbers = x.toString().replace(/\B(?=(\d{4})+(?!\d))/g, ",").split(',');
    let number1 = '';
    let number2 = '';

    number1 = numbers[0].toString().replace(/(^0+)/, "");
    if (numbers.length > 1) {
      number2 = numbers[1].toString().replace(/(^0+)/, "");
    }

    if (x.toString().length > 8 && x.toString().length <= 12) {
      return numberWithCommas(number1) +
        (number1 === '' ? '' : '억 ') +
        numberWithCommas(number2);
    }
    else if (x.toString().length <= 8 && x.toString().length > 4) {
      return numberWithCommas(number1) +
        (number1 === '' ? '' : '만 ') +
        numberWithCommas(number2);
    }

  }
}

export const numberWithCountsDesc = (x, f) => {
  if (x || x !== isNaN()) {
    const numberAbs = Math.abs(x.toString());
    const numbers   = x.toString().replace(/\B(?=(\d{4})+(?!\d))/g, ',').split(',');

    let number1 = numberWithCommas(numbers[0].toString().replace(/(^0+)/, ''));
    let number2 = '';

    if (numbers.length > 1) {
      number2 = numberWithCommas(numbers[1].toString().replace(/(^0+)/, ''));
    }

    if (numberAbs.toString().length > 4) {
      if (numberAbs.toString().length > 8) {
        f = true;
      }

      if (numberAbs.toString().length <= 8)       return `${ number1 + ((number1) ? '만' : '') + ((f) ? (' ' + number2) : '') }원`;
      else if (numberAbs.toString().length <= 12) return `${ number1 + ((number1) ? '억' : '') + ((f) ? (' ' + number2 + '만') : '') }원`;
      else if (numberAbs.toString().length <= 16) return `${ number1 + ((number1) ? '조' : '') + ((f) ? (' ' + number2 + '억') : '') }원`;
    } else {
      return `${ number1 }원`;
    }
  }
}

export const numberReplaceWithStag = (x) => {
  if (x === null || x === undefined) return;
  const valueArr = x.toString().match(/[0-9,]{1,5}/g);
  const counts = x.toString().match(/[^0-9,]/g);
  if (valueArr.length === 2) {
    return (<><s>{valueArr[0]}</s>{counts[0]} <s>{valueArr[1]}</s></>);
  }
  else if (valueArr.length === 1) {
    return (<><s>{valueArr[0]}</s>{counts[0]}</>);
  }
}


export const numberTextReplaceWithEmtag = (x) => {
  if (x === null || x === undefined) return;
  const valueArr = x.toString().match(/[0-9,]{1,5}/g);
  const counts = x.toString().match(/[^0-9,]/g);
  if (valueArr.length === 2) {
    return (<>{valueArr[0]}<em>{counts[0]}</em> {valueArr[1]}</>);
  }
  else if (valueArr.length === 1) {
    return (<>{valueArr[0]}<em>{counts[0]}</em></>);
  }
}

export const filtersNumberReplaceWithStag = (x) => {
  if (x === null || x === undefined) return;
  const valueArr = x.toString().match(/[0-9.]{1,5}/g);
  //const counts = x.toString().match(/[^0-9.]/g);
  return <><s>{valueArr[0]}</s>{x.toString().replace(valueArr[0], '')}</>

}

export const textReplaceWithStag = (text) => {
  return (<><s>{text}</s></>);
}


export const dateFormatMsg = (x) => {
  if (x === null || x === undefined || x === NaN) return '';
  else {
    return Moment(x).format('YYYY. MM. DD');
  }
}

export const dateFormatMsg2 = (x) => {
  if (x === null || x === undefined || x === NaN) return '';
  else {
    return Moment(x).format('YY. MM. DD');
  }
}
export const dateFormatMsgKor = (x) => {
  if (x === null || x === undefined || x === NaN) return '';
  else {
    return Moment(x).format('YYYY년 MM월 DD일');
  }
}
export const dateReplaceWithStag = (x) => {
  if (x === null || x === undefined || x === NaN) return '';
  else {
    const dateStr = Moment(x).format('YYYY년 MM월 DD일');
    const valueArr = dateStr.toString().match(/[0-9]{1,4}/g);
    const counts = dateStr.toString().match(/[^0-9]/g);
    return (<><s>{valueArr[0]}</s>{counts[0]} <s>{valueArr[1]}</s>{counts[2]} <s>{valueArr[2]}</s>{counts[4]}</>);
  }
}
export const date2ReplaceWithStag = (x) => {
  if (x === null || x === undefined || x === NaN) return '';
  else {
    const dateStr = Moment(x).format('YY. MM. DD');
    const valueArr = dateStr.toString().match(/[0-9]{1,4}/g);
    const counts = dateStr.toString().match(/[^0-9]/g);
    return (<><s>{valueArr[0]}</s>{counts[0]} <s>{valueArr[1]}</s>{counts[2]} <s>{valueArr[2]}</s>{counts[4]}</>);
  }
}
export const dateDiffMsg = (x) => {
  if (x === null || x === undefined || x === NaN) return;
  else {
    const currDate = Moment().tz("Asia/Seoul");
    currDate.set({ hour: 0, minute: 0, second: 0, millisecond: 0 });

    const targetDateStr = `${x.substr(0, 4)}-${x.substr(4, 2)}-${x.substr(6, 2)}`;
    const targetDate = Moment(new Date(targetDateStr)).tz("Asia/Seoul");
    targetDate.set({ hour: 0, minute: 0, second: 0, millisecond: 0 });

    if (currDate.diff(targetDate, 'days') > 0) {
      return '완료';
    }
    else if (currDate.diff(targetDate, 'days') === 0) {
      return 'D - DAY';
    }
    else {
      return 'D ' + (currDate.diff(targetDate, 'days')).toString().replace('-', '- ');
    }
  }
}