import React, { Component } from 'react';
import { uiCardfold, uiCardTab, uiCostCard, uiCostScroll, uiQOuter } from 'common/Design';
import { formatAmt } from 'common/StringUtil';
import { dateDiffMsg, numberWithCounts, numberTextReplaceWithEmtag } from 'common/StringUtil';
import $ from 'jquery'
import Donut from '../chart/Donut'
import RMateDonutType from 'routes/rMateChart/RMateDonutType';

//// 컴포넌트 사용처 : 청약 비용 계산기
class MapApplyPayCalc extends Component {
  constructor(props) {
    super(props);
  }

  componentDidMount = () => {
    uiCostCard.init();
    uiCostScroll.init();
    uiQOuter.init();
  }

  render() {
    const flrNo = this.props.flrNo;
    const hsType = this.props.hsType;

    const balconyYn = this.props.balconyYn;
    const optYn = this.props.optYn;

    const optAmt = this.props.optAmt;
    const applyDtl = this.props.applyDtl;
    const { applyInfo, applyPayCalc, applyPayAmtList, applyPayBalconyAmtList } = this.props.applyPayDtl;

    const fstAmt = applyPayCalc.fstAmt;
    const sndAmt = applyPayCalc.sndAmt;
    const trdAmt = applyPayCalc.trdAmt;
    const totAmt = fstAmt + sndAmt + trdAmt;
    //const snd1Amt = parseInt(totAmt * (applyPayCalc.ltv / 100));
    const snd1Amt = parseInt(sndAmt * (applyPayCalc.ltv / 100));
    const snd2Amt = sndAmt - snd1Amt;

    const fstAmtRate = Math.floor((fstAmt / totAmt) * 100);
    const sndAmtRate = Math.floor((sndAmt / totAmt) * 100);
    const trdAmtRate = Math.floor((trdAmt / totAmt) * 100);

    const balconyAmtObj = applyPayBalconyAmtList != null && applyPayBalconyAmtList.length > 0 ? applyPayBalconyAmtList[0] : null;

    const balconyTotAmt = balconyAmtObj != null ? balconyAmtObj.totAmt : 0;
    const balconyContAmt = balconyAmtObj != null ? balconyAmtObj.contAmt : 10;
    const balconyProgAmt = balconyAmtObj != null ? balconyAmtObj.progAmt : 40;
    const balconyRemAmt = balconyAmtObj != null ? balconyAmtObj.remAmt : 50;

    const balconyContAmtRate = balconyAmtObj != null ? Math.floor((balconyContAmt / balconyTotAmt) * 100) : '??';
    const balconyProgAmtRate = balconyAmtObj != null ? Math.floor((balconyProgAmt / balconyTotAmt) * 100) : '??';
    const balconyRemAmtRate = balconyAmtObj != null ? Math.floor((balconyRemAmt / balconyTotAmt) * 100) : '??';

    const applyPayAmtType1List = applyPayAmtList.filter(v => v.payType == 'A');
    const applyPayAmtType2List = applyPayAmtList.filter(v => v.payType == 'B');
    const applyPayAmtType3List = applyPayAmtList.filter(v => v.payType == 'C');

    //let amtGraphList = new Array();
    //let balconyAmtGraphList = new Array();
    //let etcAmtGraphList = new Array();

    let amtGraphList = [
      { "field": "계약금", "amt": fstAmt },
      { "field": "중도금", "amt": sndAmt },
      { "field": "잔금", "amt": trdAmt }];

    let balconyAmtGraphList = [
      { "field": "계약금", "amt": balconyContAmt },
      { "field": "중도금", "amt": balconyProgAmt },
      { "field": "잔금", "amt": balconyRemAmt }];

    let etcAmtGraphList = [
      { "field": "계약금", "amt": (optYn === true && optAmt > 0) ? parseInt((optAmt * 10000) * 0.1) : 10 },
      //{ "field": "중도금", "amt": 0 },
      { "field": "잔금", "amt": (optYn === true && optAmt > 0) ? parseInt((optAmt * 10000) * 0.9) : 90 }];

    // 계약금/잔금/중도금 % 계산값 0일 경우 발코니 그래프 표시 안되는 문제로 defualt값 설정
    if (balconyContAmtRate === 0 && balconyProgAmtRate === 0 && balconyRemAmtRate === 0) {
      balconyAmtGraphList = [
        { "field": "계약금", "amt": 10 },
        { "field": "중도금", "amt": 40 },
        { "field": "잔금", "amt": 50 }];
    }

    return (<>
      <div className="panel_st3 panel_card">
        <div className="header_wrap card">
          <div className="card_tit">
            <h4>{applyDtl.aptName}</h4>
            <p className="sub_txt"><s className="address">{applyDtl.address} /</s> {hsType}  / {parseInt(parseInt(hsType) / 3.3)}평({parseFloat(hsType).toFixed(2)}㎡) / {flrNo}</p>
          </div>
          <button type="button" className="btn_close_2"><i>돌아가기</i></button>
        </div>
        <div className="content_list">
          <div className="cost_area">
            <div className="analyze_wrap">
              <div className="q_mark_inner q_match_inr">
                <p className="tit">유상옵션이란?</p>
                <p className="txt">청약 공고문에 따라 비율은 상이할 수 있습니다.</p>
              </div>
              <div className="scroll_area mCustomScrollbar" data-mcs-theme="minimal">
                <div className="con_list">
                  <ul>
                    <li className="item">
                      <strong className="tit"><b>01.</b>단계별 총 금액 요약</strong>
                      <div className="col_max">
                        <div className="content_wrap">
                          <div className="progress_step_main">
                            <div className="total_cost">
                              <span><em>총 금액</em> {numberTextReplaceWithEmtag(numberWithCounts(totAmt))}</span>
                            </div>
                            <div className="progress_bar">
                              <div className="step_01">
                                <span>{fstAmtRate}<em>%</em></span>
                                <div className="step_lbl">
                                  <div className="dec_dash"></div>
                                  <div className="text_area">
                                    <p>{numberTextReplaceWithEmtag(numberWithCounts(fstAmt))}</p>
                                    <p className="sub_txt">계약금</p>
                                  </div>
                                </div>
                              </div>
                              <div className="step_02">
                                <span>{sndAmtRate}<em>%</em></span>
                                <div className="step_lbl">
                                  <div className="dec_dash"></div>
                                  <div className="text_area">
                                    <p>{numberTextReplaceWithEmtag(numberWithCounts(sndAmt))}</p>
                                    <p className="sub_txt">중도금</p>
                                  </div>
                                </div>
                              </div>
                              <div className="step_03">
                                <span>{trdAmtRate}<em>%</em></span>
                                <div className="step_lbl">
                                  <div className="dec_dash"></div>
                                  <div className="text_area">
                                    <p>{numberTextReplaceWithEmtag(numberWithCounts(trdAmt))}</p>
                                    <p className="sub_txt">잔금</p>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </li>
                    <li className="item">
                      <strong className="tit"><b>02.</b>비용별 상세</strong>
                      <div className="col_3">
                        <div className="desc_wrap">
                          <div className="graph">
                            {/* <img src="resources/images/chart_donut.png" alt="임시 이미지" /> */}
                            {/* <Donut
                              fstAmt={fstAmt}
                              sndAmt={sndAmt}
                              trdAmt={trdAmt}
                            ></Donut> */}
                            <div className="pct_wrap dash_tl" style={{ "bottom": "197px", "left": "135px", "z-index": "1" }}>
                              <strong className="tit">계약금</strong>
                              <p>{fstAmtRate}%</p>
                            </div>
                            <div className="pct_wrap dash_l" style={{ "bottom": "120px", "left": "180px", "z-index": "1" }}>
                              <strong className="tit">중도금</strong>
                              <p>{sndAmtRate}%</p>
                            </div>
                            <div className="pct_wrap dash_r" style={{ "bottom": "135px", "left": "18px", "z-index": "1" }}>
                              <strong className="tit">잔금</strong>
                              <p>{trdAmtRate}%</p>
                            </div>
                            <figure>
                              <div id="rMateDivDonut1" style={{ "width": "230px", "height": "133px" }}>
                                {
                                  this.props.onRenderGraph &&
                                  <RMateDonutType
                                    parentDivId={'rMateDivDonut1'}
                                    graphList={amtGraphList}
                                  ></RMateDonutType>
                                }
                              </div>
                            </figure>
                            <div className="total">
                              <strong>분양가</strong>
                              <span>
                                {numberTextReplaceWithEmtag(numberWithCounts(totAmt))}
                              </span>
                            </div>
                          </div>
                          <div className="price_detail">
                            <ul>
                              <li className="price_fir">
                                <strong>계약금</strong>
                                <span>
                                  {numberTextReplaceWithEmtag(numberWithCounts(fstAmt))}
                                </span>
                              </li>
                              {
                                applyPayAmtType1List.map((v, idx) =>
                                  <li>
                                    {idx === 0 &&
                                      <strong>납입일자</strong>
                                    }
                                    <span>{v.payDate}</span>
                                  </li>
                                )
                              }
                            </ul>
                            <ul>
                              <li className="price_sec">
                                <strong>중도금</strong>
                                <span>{numberTextReplaceWithEmtag(numberWithCounts(sndAmt))}</span>
                              </li>
                              {
                                applyPayAmtType2List.map((v, idx) =>
                                  <li>
                                    {idx === 0 &&
                                      <strong>납입일자</strong>
                                    }
                                    <span>{v.payDate}</span>
                                  </li>
                                )
                              }
                            </ul>
                            <ul>
                              <li className="price_thr">
                                <strong>잔금</strong>
                                <span>{numberTextReplaceWithEmtag(numberWithCounts(trdAmt))}</span>
                              </li>
                              {
                                applyPayAmtType3List.map((v, idx) =>
                                  <li>
                                    {idx === 0 &&
                                      <strong>납입일자</strong>
                                    }
                                    <span>{v.payDate}</span>
                                  </li>
                                )
                              }
                            </ul>
                          </div>
                        </div>
                        <div className={balconyYn === true ? `desc_wrap` : (optYn === true && optAmt > 0) ? `desc_wrap nodata` : `desc_wrap nodata_all`}>
                          <div className="graph">
                            {/* <img src="./resources/images/chart_donut.png" alt="임시 이미지" /> */}
                            {/* <Donut
                              fstAmt={balconyContAmt}
                              sndAmt={balconyProgAmt}
                              trdAmt={balconyRemAmt}
                            ></Donut> */}
                            <div className="pct_wrap dash_tl" style={{ "bottom": "197px", "left": "135px", "z-index": "1" }}>
                              <strong className="tit">계약금</strong>
                              <p>{balconyContAmtRate}%</p>
                            </div>
                            <div className="pct_wrap dash_l" style={{ "bottom": "120px", "left": "180px", "z-index": "1" }}>
                              <strong className="tit">중도금</strong>
                              <p>{balconyProgAmtRate}%</p>
                            </div>
                            <div className="pct_wrap dash_r" style={{ "bottom": "135px", "left": "18px", "z-index": "1" }}>
                              <strong className="tit">잔금</strong>
                              <p>{balconyRemAmtRate}%</p>
                            </div>
                            <figure>
                              <div id="rMateDivDonut2" style={{ "width": "230px", "height": "133px" }}>
                                {
                                  this.props.onRenderGraph &&
                                  <RMateDonutType
                                    parentDivId={'rMateDivDonut2'}
                                    graphList={balconyAmtGraphList}
                                  ></RMateDonutType>
                                }
                              </div>
                            </figure>
                            <div className="total">
                              <strong>발코니</strong>
                              <span>{balconyYn === true ? numberTextReplaceWithEmtag(numberWithCounts(balconyTotAmt)) : '?억 ????'}</span>
                            </div>
                          </div>
                          {
                            balconyYn === true ?
                              <div className="price_detail">
                                <ul>
                                  <li className="price_fir">
                                    <strong>계약금</strong>
                                    <span>{numberTextReplaceWithEmtag(numberWithCounts(balconyContAmt))}</span>
                                  </li>
                                </ul>
                                <ul>
                                  <li className="price_sec">
                                    <strong>중도금</strong>
                                    <span>{numberTextReplaceWithEmtag(numberWithCounts(balconyProgAmt))}</span>
                                  </li>
                                </ul>
                                <ul>
                                  <li className="price_thr">
                                    <strong>잔금</strong>
                                    <span>{numberTextReplaceWithEmtag(numberWithCounts(balconyRemAmt))}</span>
                                  </li>
                                </ul>
                              </div>
                              :
                              <div className="price_detail">
                                <ul>
                                  <li className="price_fir">
                                    <strong>계약금</strong>
                                    <span>?억 ????</span>
                                  </li>
                                </ul>
                                <ul>
                                  <li className="price_sec">
                                    <strong>중도금</strong>
                                    <span>?억 ????</span>
                                  </li>
                                </ul>
                                <ul>
                                  <li className="price_thr">
                                    <strong>잔금</strong>
                                    <span>?억 ????</span>
                                  </li>
                                </ul>
                              </div>
                          }
                        </div>
                        <div className={(optYn === true && optAmt > 0) ? `desc_wrap` : balconyYn === true ? `desc_wrap nodata` : `desc_wrap`}>
                          <div className="graph">
                            {/* <img src="./resources/images/chart_donut.png" alt="임시 이미지" /> */}
                            {/* <Donut
                              fstAmt={(optYn === true && optAmt > 0) ? parseInt((optAmt * 10000) * 0.1) : 0}
                              sndAmt={0}
                              trdAmt={(optYn === true && optAmt > 0) ? parseInt((optAmt * 10000) * 0.9) : 0}
                            ></Donut> */}
                            <div className="pct_wrap dash_tl" style={{ "bottom": "197px", "left": "135px", "z-index": "1" }}>
                              <strong className="tit">계약금</strong>
                              <p>{(optYn === true && optAmt > 0) ? `10` : `??`}%</p>
                            </div>
                            {/* <div className="pct_wrap dash_l" style={{ "bottom": "120px", "left": "180px", "z-index": "1" }}>
                              <strong className="tit">중도금</strong>
                              <p>0%</p>
                            </div> */}
                            <div className="pct_wrap dash_r" style={{ "bottom": "135px", "left": "18px", "z-index": "1" }}>
                              <strong className="tit">잔금</strong>
                              <p>{(optYn === true && optAmt > 0) ? `90` : `??`}%</p>
                            </div>
                            <figure>
                              <div id="rMateDivDonut3" style={{ "width": "230px", "height": "133px" }}>
                                {
                                  this.props.onRenderGraph &&
                                  <RMateDonutType
                                    parentDivId={'rMateDivDonut3'}
                                    graphList={etcAmtGraphList}
                                  ></RMateDonutType>
                                }
                              </div>
                            </figure>
                            <div className="total mark">
                              <strong>유상옵션<div className="q_mark bt q_match"></div></strong>
                              <span>{(optYn === true && optAmt > 0) ? numberTextReplaceWithEmtag(numberWithCounts((optAmt * 10000))) : '?억 ????'}</span>
                            </div>
                          </div>
                          {
                            (optYn === true && optAmt > 0) ?
                              <div className="price_detail">
                                <ul>
                                  <li className="price_fir">
                                    <strong>계약금</strong>
                                    <span>{numberTextReplaceWithEmtag(numberWithCounts(parseInt((optAmt * 10000) * 0.1)))}</span>
                                  </li>
                                </ul>
                                <ul>
                                  <li className="price_sec">
                                    <strong>중도금</strong>
                                    <span>0</span>
                                  </li>
                                </ul>
                                <ul>
                                  <li className="price_thr">
                                    <strong>잔금</strong>
                                    <span>{numberTextReplaceWithEmtag(numberWithCounts(parseInt((optAmt * 10000) * 0.9)))}</span>
                                  </li>
                                </ul>
                              </div>
                              :
                              <div className="price_detail">
                                <ul>
                                  <li className="price_fir">
                                    <strong>계약금</strong>
                                    <span>?억 ????</span>
                                  </li>
                                </ul>
                                <ul>
                                  <li className="price_sec">
                                    <strong>중도금</strong>
                                    <span>?억 ????</span>
                                  </li>
                                </ul>
                                <ul>
                                  <li className="price_thr">
                                    <strong>잔금</strong>
                                    <span>?억 ????</span>
                                  </li>
                                </ul>
                              </div>
                          }
                        </div>
                      </div>
                    </li>
                    <li className="item">
                      <strong className="tit"><b>03.</b>단계별 금액 상세</strong>
                      <div className="col_half">
                        <div className="desc_wrap">
                          <ul className="noti_info">
                            <li>
                              <strong>즉시 납입 금액이란?</strong>
                              <p>
                                청약 당첨 후 정당 계약 시 즉시 납입이 필요해요.<br />
                                청약 당첨 이후 계약 미진행 시 1년간 청약을 신청할 수 없어요.<br />
                                꼭! 감당이 가능한 금액인지 먼저 확인 후 청약을 신청하세요.
															</p>
                            </li>
                            <li>
                              <strong>중도금이란?</strong>
                              <p>
                                분양가의 일부를 6회차에 걸쳐 분납하는 금액이에요.<br />
                                규제 지역 별 대출 가능한 금액이 다르니, 청약 전 확인하세요.<br />
                                대출 가능 금액 이외에는 자납으로 진행하시면 되요.
															</p>
                            </li>
                            <li>
                              <strong>잔금이란?</strong>
                              <p>
                                계약금 및 중도금 납입 이후 최종적으로 납입해야하는 금액이에요.<br />
                                입주 날짜에 필요한 잔금으로, 실제 입주일에 적용되는 규제 및 대출정보를<br />
                                입주 시점에 확인 가능해요
															</p>
                            </li>
                          </ul>
                        </div>
                        <div className="content_wrap">
                          <div className="progress_step_detail">
                            <ul className="progress_bar">
                              <li>
                                <strong>즉시<br />납입액</strong>
                                <div className="progress_bg">
                                  <div className="step_01">
                                    <span className="step_lbl">
                                      {numberTextReplaceWithEmtag(numberWithCounts(fstAmt))}
                                    </span>
                                  </div>
                                </div>
                              </li>
                              <li>
                                <strong>중도금</strong>
                                <div className="progress_bg_md">
                                  <div className="step_02_1">
                                    <span className="step_lbl">
                                      {numberTextReplaceWithEmtag(numberWithCounts(snd1Amt))}
                                    </span>
                                  </div>
                                  <div className="step_02_2">
                                    <span className="step_lbl">
                                      {snd1Amt >= sndAmt ? 0 : numberTextReplaceWithEmtag(numberWithCounts(snd2Amt))}
                                    </span>
                                  </div>
                                </div>
                              </li>
                              <li>
                                <strong>잔금</strong>
                                <div className="progress_bg">
                                  <div className="step_03">
                                    <span className="step_lbl">
                                      {numberTextReplaceWithEmtag(numberWithCounts(trdAmt))}
                                    </span>
                                  </div>
                                </div>
                              </li>
                            </ul>
                          </div>
                          <div className="cost_data">
                            <ul>
                              <li className="core">
                                <strong>분양가</strong>
                                <p>
                                  {numberTextReplaceWithEmtag(numberWithCounts(totAmt))}
                                </p>
                              </li>
                              <li>
                                <strong>즉시납입액</strong>
                                <p>
                                  {numberTextReplaceWithEmtag(numberWithCounts(fstAmt))}
                                </p>
                              </li>
                              <li>
                                <strong>중도금</strong>
                                <p>
                                  {numberTextReplaceWithEmtag(numberWithCounts(sndAmt))}
                                </p>
                              </li>
                              <ul className="medium">
                                <li>
                                  <strong>- 대출필요금액</strong>
                                  <p className="td_pri">
                                    {numberTextReplaceWithEmtag(numberWithCounts(snd1Amt))} (<em>중도금</em> {applyPayCalc.ltv}%)
                                    </p>
                                </li>
                                <li>
                                  <strong>- 자납필요금액</strong>
                                  <p>
                                    {snd1Amt >= sndAmt ? 0 : numberTextReplaceWithEmtag(numberWithCounts(snd2Amt))} (<em>중도금</em> {100 - (applyPayCalc.ltv)}%)
                                    </p>
                                </li>
                              </ul>
                              <li>
                                <strong>잔금</strong>
                                <p>
                                  {numberTextReplaceWithEmtag(numberWithCounts(trdAmt))}
                                </p>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    </li>
                    <li className="item_button">
                      <div className="btn_area">
                        <a href="/applyPay" className="btn_reset">다시 계산하기</a>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>)
  }
}
export default MapApplyPayCalc;