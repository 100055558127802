import React, { Component } from 'react';
import MapApplyPaySrch from './MapApplyPaySrch';
import axios from 'axios';
import $ from 'jquery';
import queryString from 'query-string';
import { AroundOverlay } from 'routes/common/overLay/AroundOverlay.js';
import { ApplyOverlay } from 'routes/common/overLay/ApplyOverlay';
import { uiAptHeaderCustom } from 'common/Design';

//// 컴포넌트 사용처 : 청약 비용 계산기
class MapApplyPay extends Component {

  constructor(props) {
    super(props);
    this.map = {};
    this.state = {
      applyId: '',
      mapApplyDtl: [],
      favResult: [],
    };
    this.overLayList = [];
    this.aroundOverLayList = [];
    this.search = {
      type: "",
      id: ""
    };

  }

  componentDidUpdate = () => {
    if (localStorage.getItem('jwtToken') == undefined || localStorage.getItem('jwtToken') == null) {
      window.location.href = '/login';
      window.close();
    }
  }

  componentDidMount() {
    $('html').addClass('_full');


    let mapContainer = document.getElementById('mapview'); // 지도를 표시할 div 
    let mapOption = {
      center: new kakao.maps.LatLng(37.57100, 126.97696), // eslint-disable-line
      level: 3
    };

    let map = new kakao.maps.Map(mapContainer, mapOption); // eslint-disable-line
    this.map = map;

    kakao.maps.event.addListener(map, 'dragend', () => { }); // eslint-disable-line
    kakao.maps.event.addListener(map, 'zoom_changed', () => {  // eslint-disable-line

    });

    const { search } = this.props.location;	// 문자열 형식으로 결과값이 반환된다.
    const queryObj = queryString.parse(search);	// 문자열의 쿼리스트링을 Object로 변환

    if (queryObj.applyId !== undefined && queryObj.applyId !== '') {
      this.clearOverlay();
      this.clearAroundOverlay();
      $('.apt_wrap').removeClass('dim');
      map.setLevel(3);
      //if ($('.panel_fold').attr('class').search('on') <= -1) {
      //this.cardToggle();
      //}
      //setTimeout(() => {
      //this.fetchMapApplyDtl(queryObj.applyId, queryObj.lat, queryObj.lng);
      //}, 300);
      //$('#apt_card_' + queryObj.applyId).click();

    }

  }

  overlayMap = (map) => {

    let param = `&trxMin=${this.filter.trx.trxMin}&trxMax=${this.filter.trx.trxMax}` +
      `&pyMin=${this.filter.py.pyMin}&pyMax=${this.filter.py.pyMax}` +
      `&hhldMin=${this.filter.hhld.hhldMin}&hhldMax=${this.filter.hhld.hhldMax}` +
      `&aprMin=${this.filter.apr.aprMin}&aprMax=${this.filter.apr.aprMax}` +
      `&pkMin=${this.filter.pk.pkMin}&pkMax=${this.filter.pk.pkMax}`;

    let isZoomEvent = this.overLayList.length === 0 ? true : false;
    let latlng = map.getCenter();

  }

  handleRenderMarkerList = ({ data }) => {

    this.clearOverlay();
    this.clearAroundOverlay();
    data.forEach(v => {

      let overlay = new ApplyOverlay(v);
      overlay.setMap(this.map);
      this.overLayList.push(overlay);

      $('#markerApply_' + v.applyId).click((e) => {
        //e.preventDefault();
        $('.apt_wrap').removeClass('dim');
        this.map.setLevel(3);
        this.fetchMapApplyDtl(v.applyId, null, null);
      });
    });
  }

  clearOverlay = () => {
    this.overLayList.forEach(ov => ov.setMap(null));
    this.overLayList = [];
  }

  clearAroundOverlay = () => {
    this.aroundOverLayList.forEach(ov => ov.setMap(null));
    this.aroundOverLayList = [];
  }

  handlePanTo = ({ lat, lng }) => {
    this.map.setLevel(3);
    let moveLatLng = new kakao.maps.LatLng(lat, lng); // eslint-disable-line
    this.map.panTo(moveLatLng);
  }

  comonentWillUnmount() {
    ////console.log('MAP_comonentWillUnmount');
    $('html').removeClass('_full');
  }

  renderingAroundDataIcons(data) {
    this.aroundOverLayList.forEach(ov => ov.setMap(null));
    this.aroundOverLayList = [];

    const dtl = data;

    dtl.schoolList.forEach(v => {
      v.type = 'school';
      let overlay = new AroundOverlay(v);
      overlay.setMap(this.map);
      this.aroundOverLayList.push(overlay);
    });

    dtl.departList.forEach(v => {
      v.type = 'ico_mart';
      let overlay = new AroundOverlay(v);
      overlay.setMap(this.map);
      this.aroundOverLayList.push(overlay);
    });

    dtl.stationList.forEach(v => {
      v.type = 'ico_subway';
      let overlay = new AroundOverlay(v);
      overlay.setMap(this.map);
      this.aroundOverLayList.push(overlay);
    });

    dtl.hospitalList.forEach(v => {
      v.type = 'ico_hospital';
      let overlay = new AroundOverlay(v);
      overlay.setMap(this.map);
      this.aroundOverLayList.push(overlay);
    });

    dtl.parkList.forEach(v => {
      v.type = 'ico_park';
      let overlay = new AroundOverlay(v);
      overlay.setMap(this.map);
      this.aroundOverLayList.push(overlay);
    });
  }

  fetchMapApplyDtl = (paramApplyId, paramLat, paramLng) => {
    $('.apt_wrap').removeClass('dim');
    axios
      .get(`apt/apply/dtl?applyId=${paramApplyId}`)
      .then(res => {
        this.setState({
          mapApplyDtl: res.data.response.applyDtl
        });
        //this.handleRenderMarker({ data: res.data.response.applyDtl });
        if (paramLat != null && paramLng != null) {
          this.handlePanTo({ lat: paramLat, lng: paramLng });
        }
        else {
          this.handlePanTo({ lat: res.data.response.applyDtl.lat, lng: res.data.response.applyDtl.lng });
        }
      })
      .then(() => {
        setTimeout(() => {
          $('div.sub_header > h3').attr('data-tit', '최근 청약 정보');
          $('div.sub_header > h3').text('최근 청약 정보');
          uiAptHeaderCustom.init();
        }, 50);

        this.setState({
          mapApplyDtl: []
        });
      });
  }

  handleGoToFavApplyDtl = (favApplyId) => {

  }

  render() {
    return (
      <div id="content">
        <section className="apt_wrap">
          <MapApplyPaySrch
            location={this.props.location}
            mapApplyDtl={this.state.mapApplyDtl}
            onRenderMarkerList={({ data }) => this.handleRenderMarkerList({ data })}
            onRenderMarker={({ data }) => this.handleRenderMarker({ data })}
            onPanTo={({ lat, lng }) => this.handlePanTo({ lat, lng })}
          ></MapApplyPaySrch>
        </section>

        <div className="map_img" id="mapview" ></div>

      </div>
    )
  }
}
export default MapApplyPay