import React, { Component } from 'react';
import axios from 'axios';
import { Link } from 'react-router-dom';
import $ from 'jquery';

class Header extends Component {

  constructor(props) {
    super(props);

  }

  menuClick = (url, subA) => {
    if (url === '' && subA === 0) {
      localStorage.removeItem('subA');
      window.location.href = '/';
    }
    else {
      localStorage.setItem('subA', subA);

      if (url === 'apartRcmd' || url === 'applyPredict' || url === 'applyPay') {
        axios
          .get('apt/authorized/check',
            { headers: { 'Authorization': 'Bearer ' + localStorage.getItem('jwtToken') }, timeout: 1000 })
          .then(res => {
            if (res.data.response.isValid) {
              window.location.href = ('/' + url);
            }
            else {
              window.location.href = '/login';
            }
          }).catch(err => {
            if (localStorage.getItem('isAutoLogin') == 'true') {
              // 자동로그인 적용시 사용
              if (err.response.status === 401 || err.response.status === 403) {
                axios.post('apt/authorized/reIssue',
                  { 'ref': localStorage.getItem('ref') },
                  { headers: { 'Authorization': 'Bearer ' + localStorage.getItem('jwtToken') } }).then(res => {
                    if (res.status === 200) {
                      localStorage.setItem('jwtToken', res.headers.authorization);
                      window.location.href = ('/' + url);
                    }
                  })
                  .catch(err => {
                    window.location.href = '/login';
                  });
              }
              else {
                window.location.href = '/login';
              }
            }
            else {
              window.location.href = '/login';
            }
          });
        // .catch(err => {
        //   console.log(err);
        //   if (err.response.status === 401 || err.response.status === 403) {
        //     window.location.href = '/login';
        //   }
        // });
      }
      else {
        window.location.href = ('/' + url);
      }
    }
  }

  logout = () => {
    localStorage.removeItem('subA');
    localStorage.removeItem('jwtToken');
    localStorage.removeItem('ref');
    localStorage.removeItem('isAutoLogin');
    window.location.href = '/';
  }

  render() {
    return (
      <div className="innerWrap">
        <h1 className="logo"><a href="#" onClick={() => this.menuClick('', 0)}><img src={process.env.REACT_APP_ENV_URL + "resources/images/logo_apt.png"} alt="logo_아파트청약케어" /></a></h1>
        <nav className="allMenuWrap">
          <div className="menus">
            <ul>
              <li>
                <a className={localStorage.getItem('subA') === '1' ? 'on' : ''} href="#">아파트 정보</a>
                <div className="sub_menus">
                  <ul>
                    <li><a href="#" onClick={() => this.menuClick('apartMap', 1)} >아파트 정보</a></li>
                    <li><a href="#" onClick={() => this.menuClick('apartRcmd', 1)} >아파트 추천</a></li>
                  </ul>
                </div>
              </li>
              <li>
                <a className={localStorage.getItem('subA') === '2' ? 'on' : ''} href="#">청약정보</a>
                <div className="sub_menus">
                  <ul>
                    <li><a href="#" onClick={() => this.menuClick('apply', 2)}>아파트 청약정보</a></li>
                    <li><a href="#" onClick={() => this.menuClick('applyCalc', 2)}>청약 가점계산기</a></li>
                    <li><a href="#" onClick={() => this.menuClick('applyPredict', 2)}>청약 당첨 예상</a></li>
                    <li><a href="#" onClick={() => this.menuClick('applyPay', 2)}>청약 비용계산기</a></li>
                  </ul>
                </div>
              </li>
              <li>
                <a className={localStorage.getItem('subA') === '3' ? 'on' : ''} href="#">부동산계산기</a>
                <div className="sub_menus">
                  <ul>
                    <li><a href="#" onClick={() => this.menuClick('realtyCalc', 3)}>부동산계산기</a></li>
                  </ul>
                </div>
              </li>
              <li>
                <a className={localStorage.getItem('subA') === '4' ? 'on' : ''} href="#">부동산 정보</a>
                <div className="sub_menus">
                  <ul>
                    <li><a href="#" onClick={() => this.menuClick('realtyNews', 4)}>부동산 뉴스</a></li>
                    <li><a href="#" onClick={() => this.menuClick('realtyPolicy', 4)}>부동산 정책</a></li>
                  </ul>
                </div>
              </li>
            </ul>
          </div>
          <div className="menus user">
            <ul>
              <li>
                <a className={localStorage.getItem('subA') === '5' ? 'on' : ''} href="#">고객센터</a>
                <div className="sub_menus">
                  <ul>
                    <li><a href="#" onClick={() => this.menuClick('notice', 5)}>공지사항</a></li>
                    <li><a href="#" onClick={() => this.menuClick('faq', 5)}>FAQ</a></li>
                    <li><a href="#" onClick={() => this.menuClick('expire', 5)}>서비스해지</a></li>
                  </ul>
                </div>
              </li>
              {/* <li><a className={localStorage.getItem('subA') === '6' ? 'on' : ''} href="#" onClick={() => this.menuClick('login', 6)}>로그인</a></li> 
              <li><a className={localStorage.getItem('subA') === '7' ? 'on' : ''} href="#" onClick={() => this.menuClick('joinInfo', 7)}>회원가입</a></li>*/}
              {
                localStorage.getItem('jwtToken') != null ?
                  <li><a href="#" onClick={() => this.logout()}>로그아웃</a></li>
                  :
                  <>
                    <li><a className={localStorage.getItem('subA') === '6' ? 'on' : ''} href="#" onClick={() => this.menuClick('login', 6)}>로그인</a></li>
                    <li><a className={localStorage.getItem('subA') === '7' ? 'on' : ''} href="#" onClick={() => this.menuClick('joinInfo', 7)}>회원가입</a></li>
                  </>
              }
            </ul>
          </div>

        </nav>
      </div>
    );
  }
}

export default Header;
