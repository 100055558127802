import React, { Component } from 'react';

import RMateChartH5 from "../../rMateChart/RMateChartH5";
import axiosRequest from "../../../common/axios/services/AxiosRequest";

//// 컴포넌트 사용처 : 청약 당첨예상 상세
class AptTrxMonthly extends Component {

  constructor(props) {
    super(props);
    this.state = {
      findAptTrxMonthlyCnt: [],
      findAptTrxMonthlyCntCompare: [],
    }
  }

  componentDidMount = async () => {
    const { data } = await axiosRequest.findAptTrxMonthlyGroup({ config: { params: { applyId: this.props.calcResult.applyInfo.applyId } } });

    if (data) {
      this.setState({ findAptTrxMonthlyCnt: data.response.findAptTrxMonthlyCnt, findAptTrxMonthlyCntCompare: data.response.findAptTrxMonthlyCntCompare });
    }
  }

  render() {
    const { applyInfo, bjdInfo } = this.props.calcResult;

    const totalCnt        = (this.state.findAptTrxMonthlyCnt || []).map((item) => item['trxDateMonthlyCnt']).reduce((a, c) => a + c, 0);
    const totalCntCompare = (this.state.findAptTrxMonthlyCntCompare || []).map((item) => item['trxDateMonthlyCnt']).reduce((a, c) => a + c, 0);
    const percent         = (this.state.findAptTrxMonthlyCnt.length && this.state.findAptTrxMonthlyCntCompare.length) ? Math.round((((totalCnt / this.state.findAptTrxMonthlyCnt.length ) / (totalCntCompare / this.state.findAptTrxMonthlyCntCompare.length)) - 1) * 100) : 0;
    // const percent         = (findAptTrxMonthlyCnt.length && findAptTrxMonthlyCntCompare.length) ? Math.round(Math.random() * (1000 + 1000) -1000) : 0;

    const key = (this.state.findAptTrxMonthlyCnt.length && this.state.findAptTrxMonthlyCntCompare.length) ? ((percent >= 0) ? 'POSITIVE' : 'NEGATIVE') : 'UNKNOWN';

    const status = {
      NEGATIVE: { code: 'bad', priceDesc: '손실 금액' },
      POSITIVE: { code: 'good', priceDesc: '손익 금액' },
      UNKNOWN: { code: 'normal', priceDesc: '측정 불가' },
    };

    return (
        <>
          <div className="sec" id="sec13" >
            <strong>{ bjdInfo.bjdName } 아파트 거래</strong>
            <div className={ `ty_graph ${ status[key].code }` }>
              <div>
                <div className="graph">
                  <div className="dount_area">
                    <div className="circle_chart full" data-percent="100" data-permax="100">
                      <div className="chart-box">
                        <svg>
                          <defs>
                            <linearGradient id={ `dount_${ status[key].code }` } x1="0" y1="0" x2="1" y2="1">
                              <stop className="color_1" offset="0%" />
                              <stop className="color_2" offset="100%" />
                            </linearGradient>
                          </defs>
                          <circle stroke={ `url(#dount_${ status[key].code })` } r="50%" cx="50%" cy="50%" className="circle_progress" stroke-width="15" stroke-linecap="round" />
                        </svg>
                        <div className={ `per-point ${ (key === 'UNKNOWN') ? '' : 'per' }` }><span><s>{ (key === 'UNKNOWN') ? status[key].priceDesc : `${ (percent > 0) ? '+' : ((percent) ? '-' : '') } ${ Math.abs(percent) }%` }</s></span></div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div>
                <div className="caption emoji">
                  {
                    (() => {
                      switch (key) {
                        case 'NEGATIVE':  return <p>{ applyInfo.aptName }가 위치하는 { bjdInfo.bjdName } 지역은 <em>최근 1년간 아파트 거래량이 감소중이에요.</em><br />낮은 거래량은 아파트 가격 하락의 요인으로 해석될 수 있어요.</p>
                        case 'POSITIVE':  return <p>{ applyInfo.aptName }가 위치하는 { bjdInfo.bjdName } 지역은 <em>최근 1년간 아파트 거래량이 상승중이에요.</em><br />높은 거래량은 아파트 가격 상승의 요인의 긍정적인 요소로 해석될 수 있어요.</p>
                        default :         return <p>{ applyInfo.aptName }가 위치하는 { bjdInfo.bjdName } 지역은 <em>최근 1년간 거래가 없는 지역이에요.</em><br />주변 지역의 아파트 거래량을 잘 확인해보고 신청해 보세요..</p>
                      }
                    })()
                  }
                </div>
                <div id="rMateDiv13_1" className="chart" style={{ "width": "760px", "height": "400px" }}>
                  {
                    this.props.onRenderGraph && <RMateChartH5 chartId={'rMateDiv13_1'} layout={'LINE_2D_CHART_1'} data={this.state.findAptTrxMonthlyCnt} chartVars={''} width={'100%'} height={'100%'} />
                  }
                </div>
              </div>
            </div>
          </div >
        </>
    );
  }

}

export default AptTrxMonthly;
