import React, { Component } from 'react';
import { ApplyOverlay } from 'routes/common/overLay/ApplyOverlay';
import { AroundOverlay } from 'routes/common/overLay/AroundOverlay';

//// 컴포넌트 사용처 : 청약 당첨예상 상세
class LocMap extends Component {

  constructor(props) {
    super(props);
    this.map = {};
    this.overlayList = [];
    this.aroundOverlayList = [];
    this.state = {
      locType: ''
    }
  }

  componentDidMount() {
    let { applyInfo, locList } = this.props.calcResult;

    setTimeout(() => {

      this.clearOverlay();
      this.clearAroundOverlay();

      const mapContainer = document.getElementById('around_map');
      const mapOption = {
        center: new kakao.maps.LatLng(applyInfo.lat, applyInfo.lng),   // eslint-disable-line
        level: 5
      };

      let map = new kakao.maps.Map(mapContainer, mapOption);   // eslint-disable-line
      this.map = map;

      kakao.maps.event.addListener(map, 'dragend', () => { }); // eslint-disable-line
      kakao.maps.event.addListener(map, 'zoom_changed', () => { });  // eslint-disable-line

      //renderMarker(applyCalendar);
      this.onPanTo(applyInfo.lat, applyInfo.lng);
      this.renderingAroundDataIcons(locList);


      // const markerPosition  = new kakao.maps.LatLng(applyInfo.lat, applyInfo.lng);    // eslint-disable-line
      // const marker = new kakao.maps.Marker({   // eslint-disable-line
      //     position: markerPosition
      // });

      // marker.setMap(map);

      // locList.forEach(v => {

      //   if(v.locType === 'SC4' || v.locType === 'SW8' || v.locType === 'MPARK' ){

      //     v.type = v.locType === 'SC4' ? 'school' :
      //             v.locType === 'SW8' ? 'ico_hospital' : 
      //             v.locType === 'MPARK' ? 'ico_park' :  null;

      //     const overlay = new AroundOverlay(v);
      //     overlay.setMap(map);  
      //   }
      // })
    }, 500);


  }

  clearOverlay = () => {
    this.overlayList.forEach(ov => ov.setMap(null));
    this.overlayList = [];
  }

  clearAroundOverlay = () => {
    this.aroundOverlayList.forEach(ov => ov.setMap(null));
    this.aroundOverlayList = [];
  }

  renderMarker = (data) => {
    let overlay = new ApplyOverlay(data);
    overlay.setMap(this.map);
  }

  onPanTo = (paramLat, paramLng) => {
    let moveLatLng = new kakao.maps.LatLng(paramLat, paramLng); // eslint-disable-line
    this.map.panTo(moveLatLng);
  }

  renderingAroundDataIcons = (data) => {
    this.aroundOverlayList.forEach(ov => ov.setMap(null));
    this.aroundOverlayList = [];

    const dtl = data;

    //console.log(data);

    data.filter(v => v.locType === 'SC4').forEach(v => {
      v.type = 'school';
      let overlay = new AroundOverlay(v);
      overlay.setMap(this.map);
      this.aroundOverlayList.push(overlay);
    });

    data.filter(v => v.locType === 'MDEPT').forEach(v => {
      v.type = 'ico_mart';
      let overlay = new AroundOverlay(v);
      overlay.setMap(this.map);
      this.aroundOverlayList.push(overlay);
    });

    data.filter(v => v.locType === 'SW8').forEach(v => {
      v.type = 'ico_subway';
      let overlay = new AroundOverlay(v);
      overlay.setMap(this.map);
      this.aroundOverlayList.push(overlay);
    });

    data.filter(v => v.locType === 'HP8').forEach(v => {
      v.type = 'ico_hospital';
      let overlay = new AroundOverlay(v);
      overlay.setMap(this.map);
      this.aroundOverlayList.push(overlay);
    });

    data.filter(v => v.locType === 'MPARK').forEach(v => {
      v.type = 'ico_park';
      let overlay = new AroundOverlay(v);
      overlay.setMap(this.map);
      this.aroundOverlayList.push(overlay);
    });

    data.filter(v => v.locType === 'MMONT').forEach(v => {
      v.type = 'ico_park';
      let overlay = new AroundOverlay(v);
      overlay.setMap(this.map);
      this.aroundOverlayList.push(overlay);
    });

    data.filter(v => v.locType === 'MMCND' || v.locType === 'MSTBK').forEach(v => {
      v.type = 'ico_etc';
      let overlay = new AroundOverlay(v);
      overlay.setMap(this.map);
      this.aroundOverlayList.push(overlay);
    });
  }

  selectLocType = (locType) => {
    this.setState({ locType: locType });
  }

  getAroundCount = type => {
    const { locCnt } = this.props.calcResult;
    return Number(locCnt.findIndex(v => v.locType === type) > -1 ? locCnt.filter(v => v.locType === type).map(v => v.locCnt) : 0);
  }

  render() {
    const { locCnt, locList, applyScore } = this.props.calcResult;

    const totalAroundScore = (applyScore != null ?
      (applyScore.elementarySchoolScore + applyScore.middleSchoolScore +
        applyScore.subwayScore + applyScore.departmentScore +
        applyScore.parkScore + applyScore.woodsScore + applyScore.mdconaldScore + applyScore.starbucksScore + applyScore.hospitalScore)
      //+ (applyScore.applyId == '2021000362' ? 37 : 0))
      //+ (applyScore.applyId == '2021000362' ? 67 : 0))
      //+ (applyScore.applyId == '2021000362' ? 118 : 0))
      : 0);

    const scoreType = (
      (totalAroundScore < 70) ? 'bad' :
        (totalAroundScore >= 70 && totalAroundScore < 100) ? 'normal' :
          (totalAroundScore >= 100 && totalAroundScore < 151) ? 'good' :
            (totalAroundScore >= 151) ? 'best' : '');

    //const { locList, applyScore } = this.props.calcResult;

    //{applyScore != null ? applyScore.elementarySchoolScore + applyScore.middleSchoolScore : 0} 학교
    //{applyScore != null ? applyScore.subwayScore : 0} 지하철
    //{applyScore != null ? applyScore.hospitalScore : 0} 병원
    //{locList.filter(v => v.locType === 'MDEPT').length === 0 ? 0 : (applyScore != null ? applyScore.departmentScore : 0)} 백화점
    //{applyScore != null ? applyScore.parkScore : 0} 공원
    //{applyScore != null ? applyScore.woodsScore : 0}  숲
    //{applyScore != null ? (applyScore.mdconaldScore + applyScore.starbucksScore) : 0} 기타

    const schoolScore = (applyScore != null ? applyScore.elementarySchoolScore + applyScore.middleSchoolScore : 0);
    const subwayScore = (applyScore != null ? applyScore.subwayScore : 0);
    const hospitalScore = (applyScore != null ? applyScore.hospitalScore : 0);
    const departScore = (locList.filter(v => v.locType === 'MDEPT').length === 0 ? 0 : (applyScore != null ? applyScore.departmentScore : 0));
    const parkScore = (applyScore != null ? applyScore.parkScore : 0);
    const forestScore = (applyScore != null ? applyScore.woodsScore : 0);
    const etcScore = (applyScore != null ? (applyScore.mdconaldScore + applyScore.starbucksScore) : 0);

    return (
      <>
        <div className="sec map" id="sec6">
          <div className="noti_around">
            <h4>주변정보 <strong>종합 점수</strong></h4>
            <p>
              : 해당 점수는 아파트 단지 정보와 분양 정보, 아파트가 위치하는 지역의 주변정보들을 종합하여 산출 한 점수에요.<br />
              해당 점수는 참고용이며, 투자 및 청약신청에 대한 책임은 이용자 본인에게 있습니다.
          </p>
          </div>
          <div className={`ty_graph ${scoreType}`}>
            <div>
              <div className="graph">
                <div className="dount_area">
                  <div className="circle_chart" data-percent={totalAroundScore} data-permax="170">
                    <div className="chart-box">
                      <svg>
                        <defs>
                          <linearGradient id={`dount_${scoreType}`} x1="0" y1="0" x2="1" y2="1">
                            <stop className="color_1" offset="0%" />
                            <stop className="color_2" offset="100%" />
                          </linearGradient>
                        </defs>
                        <circle stroke={`url(#dount_${scoreType})`} r="49%" cx="50%" cy="50%" className="circle_progress" stroke-width="15" stroke-linecap="round"></circle>
                        <circle className="marker" r="8" cx="50%" cy="125" style={{ 'filter': 'url(#shadow)' }}></circle>
                        <filter id="shadow">
                          <feDropShadow dx="0" dy="0" stdDeviation="2"
                            flood-color="#CDCDCD" />
                        </filter>
                      </svg>
                      <div className="per-num"><span>{totalAroundScore}</span>/<s>170</s></div>
                    </div>
                  </div>
                </div>
                <ul className="apt_around">
                  <li className="school">
                    <strong>학교</strong>
                    <span>{locCnt.findIndex(v => v.locType === 'SC4') > -1 ? locCnt.filter(v => v.locType === 'SC4').map(v => v.locCnt) : 0}개</span>
                  </li>
                  <li className="subway">
                    <strong>지하철</strong>
                    <span>{locCnt.findIndex(v => v.locType === 'SW8') > -1 ? locCnt.filter(v => v.locType === 'SW8').map(v => v.locCnt) : 0}개</span>
                  </li>
                  <li className="hospital">
                    <strong>병원</strong>
                    <span>{locCnt.findIndex(v => v.locType === 'HP8') > -1 ? locCnt.filter(v => v.locType === 'HP8').map(v => v.locCnt) : 0}개</span>
                  </li>
                  <li className="mart">
                    <strong>백화점</strong>
                    <span>{locCnt.findIndex(v => v.locType === 'MDEPT') > -1 ? locCnt.filter(v => v.locType === 'MDEPT').map(v => v.locCnt) : 0}개</span>
                  </li>
                  <li className="park">
                    <strong>공원</strong>
                    <span>{locCnt.findIndex(v => v.locType === 'MPARK') > -1 ? locCnt.filter(v => v.locType === 'MPARK').map(v => v.locCnt) : 0}개</span>
                  </li>
                  <li className="mountain">
                    <strong>산</strong>
                    <span>{locCnt.findIndex(v => v.locType === 'MMONT') > -1 ? locCnt.filter(v => v.locType === 'MMONT').map(v => v.locCnt) : 0}개</span>
                  </li>
                  <li className="park">
                    <strong>기타</strong>
                    <span>{locCnt.findIndex(v => v.locType === 'MMCND' || v.locType === 'MSTBK') > -1 ? locCnt.filter(v => v.locType === 'MMCND' || v.locType === 'MSTBK').map(v => v.locCnt) : 0}개</span>
                  </li>
                </ul>
              </div>
            </div>
            <div>
              <div className="map_img" id="around_map" >
              </div>
            </div>
          </div>
        </div>

        <div className="sec" id="sec7">
          <div className="ty_graph">
            <div>
              <div className="graph">
                <h4>주변정보 <strong>상세 점수</strong></h4>
                <div className="calc_form">
                  <div className="ip_wrap select_around select_apt">
                    <button className="btn_select">전체<s>({this.getAroundCount('SC4') + this.getAroundCount('SW8') + this.getAroundCount('HP8') + this.getAroundCount('MDEPT') + this.getAroundCount('MPARK') + this.getAroundCount('MMONT') + this.getAroundCount('MMCND') + this.getAroundCount('MSTBK')})</s></button>
                    <div className="select_wrap mCustomScrollbar" data-mcs-theme="minimal">
                      <div className="con">
                        <ul>
                          <li onClick={() => this.selectLocType('')}>
                            <span>전체<s>({this.getAroundCount('SC4') + this.getAroundCount('SW8') + this.getAroundCount('HP8') + this.getAroundCount('MDEPT') + this.getAroundCount('MPARK') + this.getAroundCount('MMONT') + this.getAroundCount('MMCND') + this.getAroundCount('MSTBK')})</s></span>
                          </li>
                          <li onClick={() => this.selectLocType('school')}>
                            <span>학교<s>({this.getAroundCount('SC4')})</s></span>
                          </li>
                          <li onClick={() => this.selectLocType('subway')}>
                            <span>지하철<s>({this.getAroundCount('SW8')})</s></span>
                          </li>
                          <li onClick={() => this.selectLocType('hospital')}>
                            <span>병원<s>({this.getAroundCount('HP8')})</s></span>
                          </li>
                          <li onClick={() => this.selectLocType('mart')}>
                            <span>백화점<s>({this.getAroundCount('MDEPT')})</s></span>
                          </li>
                          <li onClick={() => this.selectLocType('park')}>
                            <span>공원<s>({this.getAroundCount('MPARK')})</s></span>
                          </li>
                          <li onClick={() => this.selectLocType('mountain')}>
                            <span>산<s>({this.getAroundCount('MMONT')})</s></span>
                          </li>
                          <li onClick={() => this.selectLocType('etc')}>
                            <span>기타<s>({this.getAroundCount('MMCND') + this.getAroundCount('MSTBK')})</s></span>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="around_tab" >

              <div className="tab">
                {
                  (this.state.locType === '' || this.state.locType === 'school') &&
                  <div className="around_con school">
                    <span className={schoolScore < 5 ? "score low" : "score"}><b>{schoolScore}</b>점</span>
                    <div className="con">
                      <strong>학교</strong>
                      <ul>
                        {
                          locList.filter(v => v.locType === 'SC4').length > 0 ?

                            locList.filter(v => v.locType === 'SC4').map((v, idx) => {

                              return <li key={idx}>
                                <span onClick={() => this.onPanTo(v.lat, v.lng)}>{v.locName} {v.locDistance}m</span>
                                <span>도보 {Math.round(v.locDistance / 66)}분</span>
                              </li>;
                            })
                            :
                            <li><span>1500m 근처에 위치하고 있는 학교가 없어요.</span></li>
                        }
                      </ul>
                    </div>
                  </div>
                }
                {
                  (this.state.locType === '' || this.state.locType === 'subway') &&
                  <div className="around_con subway">
                    <span className={subwayScore < 5 ? "score low" : "score"}><b>{subwayScore}</b>점</span>
                    <div className="con">
                      <strong>역세권</strong>
                      <ul>
                        {
                          locList.filter(v => v.locType === 'SW8').length > 0 ?

                            locList.filter(v => v.locType === 'SW8').map((v, idx) => {

                              return <li key={idx}>
                                <span onClick={() => this.onPanTo(v.lat, v.lng)}>{v.locName} {v.locDistance}m</span>
                                <span>도보 {Math.round(v.locDistance / 66)}분</span>
                              </li>;
                            })
                            :
                            <li><span>1500m 근처에 위치하고 있는 지하철역이 없어요.</span></li>
                        }
                      </ul>
                    </div>
                  </div>
                }
                {
                  (this.state.locType === '' || this.state.locType === 'hospital') &&
                  <div className="around_con hospital">
                    <span className={hospitalScore < 5 ? "score low" : "score"}><b>{hospitalScore}</b>점</span>
                    <div className="con">
                      <strong>병원</strong>
                      <ul>
                        {

                          locList.filter(v => v.locType === 'HP8').length > 0 ?

                            locList.filter(v => v.locType === 'HP8').map((v, idx) => {

                              return <li key={idx}>
                                <span onClick={() => this.onPanTo(v.lat, v.lng)}>{v.locName} {v.locDistance}m</span>
                                <span>도보 {Math.round(v.locDistance / 66)}분</span>
                              </li>;
                            })
                            :
                            <li><span>3000m 근처에 위치하고 있는 대형병원이 없어요.</span></li>
                        }
                      </ul>
                    </div>
                  </div>
                }
                {
                  (this.state.locType === '' || this.state.locType === 'mart') &&
                  <div className="around_con mart">
                    <span className={departScore < 5 ? "score low" : "score"}><b>{departScore}</b>점</span>
                    <div className="con">
                      <strong>백화점</strong>
                      <ul>
                        {
                          locList.filter(v => v.locType === 'MDEPT').length > 0 ?

                            locList.filter(v => v.locType === 'MDEPT').map((v, idx) => {

                              return <li key={idx}>
                                <span onClick={() => this.onPanTo(v.lat, v.lng)}>{v.locName} {v.locDistance}m</span>
                                <span>도보 {Math.round(v.locDistance / 66)}분</span>
                              </li>;
                            })
                            :
                            <li><span>1500m 근처에 위치하고 있는 백화점이 없어요.</span></li>
                        }
                      </ul>
                    </div>
                  </div>
                }
                {
                  (this.state.locType === '' || this.state.locType === 'park') &&
                  <div className="around_con park">
                    <span className={parkScore < 5 ? "score low" : "score"}><b>{parkScore}</b>점</span>
                    <div className="con">
                      <strong>공세권</strong>
                      <ul>
                        {
                          locList.filter(v => v.locType === 'MPARK').length > 0 ?

                            locList.filter(v => v.locType === 'MPARK').map((v, idx) => {

                              return <li key={idx}>
                                <span onClick={() => this.onPanTo(v.lat, v.lng)}>{v.locName} {v.locDistance}m</span>
                                <span>도보 {Math.round(v.locDistance / 66)}분</span>
                              </li>;
                            })
                            :
                            <li><span>1500m 근처에 위치하고 있는 공원이 없어요.</span></li>
                        }
                      </ul>
                    </div>
                  </div>
                }
                {
                  (this.state.locType === '' || this.state.locType === 'mountain') &&
                  <div className="around_con mountain">
                    <span className={forestScore < 5 ? "score low" : "score"}><b>{forestScore}</b>점</span>
                    <div className="con">
                      <strong>산세권</strong>
                      <ul>
                        {
                          locList.filter(v => v.locType === 'MMONT').length > 0 ?

                            locList.filter(v => v.locType === 'MMONT').map((v, idx) => {

                              return <li key={idx}>
                                <span onClick={() => this.onPanTo(v.lat, v.lng)}>{v.locName} {v.locDistance}m</span>
                                <span>도보 {Math.round(v.locDistance / 66)}분</span>
                              </li>;
                            })
                            :
                            <li><span>1500m 근처에 위치하고 있는 산/숲이 없어요.</span></li>
                        }
                      </ul>
                    </div>
                  </div>
                }
                {
                  (this.state.locType === '' || this.state.locType === 'etc') &&
                  <div className="around_con">
                    <span className={etcScore < 5 ? "score low" : "score"}><b>{etcScore}</b>점</span>
                    <div className="con">
                      <strong>기타</strong>
                      <ul>
                        {
                          locList.filter(v => v.locType === 'MMCND' || v.locType === 'MSTBK').length > 0 ?

                            locList.filter(v => v.locType === 'MMCND' || v.locType === 'MSTBK').map((v, idx) => {

                              return <li key={idx}>
                                <span onClick={() => this.onPanTo(v.lat, v.lng)}>{v.locName} {v.locDistance}m</span>
                                <span>도보 {Math.round(v.locDistance / 66)}분</span>
                              </li>;
                            })
                            :
                            <li><span>1500m 근처에 위치하고 있는 기타장소가 없어요.</span></li>
                        }
                      </ul>
                    </div>
                  </div>
                }
                <div className="around_con noti">
                  <p className="con">* 개인별로 실제 도보 소요시간은 상이 할 수 있으며, 실제 주변 정보와 상이 할 수 있어요.<br />실제와 상이한 주변 정보는 고객센터로 접수 부탁드려요. (고객센터 1599-2985) </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default LocMap;
