import React, { Component, Fragment } from 'react';

import { dateDiffMsg, numberWithCounts, numberTextReplaceWithEmtag, numberWithCommas } from 'common/StringUtil';


class RMateCandleStickType extends Component {

  constructor(props) {
    super(props);
    this.state = {

    };
  }

  componentDidMount = () => {
    setTimeout(() => { this.renderCharts(this.props.graphList) }, 100)
    // this.props.onRenderGraph &&
    //   (this.props.parentDivId === 'rMateDivAptRcmdDtl' ?
    //     this.props.onRenderGraph && setTimeout(() => { this.renderCharts(this.props.graphList) }, 300)
    //     : this.props.parentDivId === 'rMateDivAptDtl' ?
    //       this.props.onRenderGraph && setTimeout(() => { this.renderCharts(this.props.graphList) }, 300)
    //       : setTimeout(() => { this.renderCharts(this.props.graphList) }, 100))
  }


  componentDidUpdate = () => {

  }

  renderCharts = (graphList) => {
    const parentDivId = this.props.parentDivId;
    const rMateChartH5 = window.rMateChartH5;

    // -----------------------차트 설정 시작-----------------------

    // rMateChart 를 생성합니다.
    // 파라메터 (순서대로)
    //  1. 차트의 id ( 임의로 지정하십시오. )
    //  2. 차트가 위치할 div 의 id (즉, 차트의 부모 div 의 id 입니다.)
    //  3. 차트 생성 시 필요한 환경 변수들의 묶음인 chartVars
    //  4. 차트의 가로 사이즈 (생략 가능, 생략 시 100%)
    //  5. 차트의 세로 사이즈 (생략 가능, 생략 시 100%)
    rMateChartH5.create(("rMateCandleStickType" + parentDivId), parentDivId, "", "100%", "100%");





    // rMateChartH5.calls 함수를 이용하여 차트의 준비가 끝나면 실행할 함수를 등록합니다.
    //
    // argument 1 - rMateChartH5.create시 설정한 차트 객체 아이디 값
    // argument 2 - 차트준비가 완료되면 실행할 함수 명(key)과 설정될 전달인자 값(value)
    // 
    // 아래 내용은 
    // 1. 차트 준비가 완료되면 첫 전달인자 값을 가진 차트 객체에 접근하여
    // 2. 두 번째 전달인자 값의 key 명으로 정의된 함수에 value값을 전달인자로 설정하여 실행합니다.
    if (parentDivId === 'rMateDivAptDtl' || parentDivId === 'rMateDivAptRcmdDtl') {
      let newGraphList = new Array();
      graphList.map(v => {
        //newGraphList.push({ 'actYearMonth': v.actYearMonth, 'maxDpsAmt': (v.maxDpsAmt * 10000), 'actCnt': v.actCnt, 'maxDpsAmtCount': numberWithCounts(v.maxDpsAmt * 10000) });
        newGraphList.push({ 'actYearMonth': v.actYearMonth, 'maxDpsAmt': (v.maxDpsAmt / 10000), 'actCnt': v.actCnt, 'maxDpsAmtCount': numberWithCounts(v.maxDpsAmt * 10000) });
      });

      rMateChartH5.calls(("rMateCandleStickType" + parentDivId), {
        "setLayout": this.getChartBody('actYearMonth', 'actCnt', '거래량', 'maxDpsAmt', '매매최대가', 'maxDpsAmtCount'),
        //"setData": this.getChartData(graphList)
        "setData": this.getChartData(newGraphList)
      });
    }
    else if (parentDivId === 'rMateDiv4') {
      rMateChartH5.calls(("rMateCandleStickType" + parentDivId), {
        "setLayout": this.getChartBody('notiDate', 'totalReqCnt', '신청건수', 'totalGeCnt', '당첨건수', ''),
        "setData": this.getChartData(graphList)
      });
    }

    // -----------------------차트 설정 끝 -----------------------
  }

  getChartBody = (cateFieldStr, yFieldStr1, disFieldStr1, yFieldStr2, disFieldStr2, cateFieldStr2) => {

    // 차트에 출력할 아이템 갯수
    var visibleItemSize = 14;

    // 기준 값
    var baseValue = 840000;

    // 스트링 형식으로 레이아웃 정의.
    var layoutStr =
      `<rMateChart backgroundColor="#FFFFFF" borderStyle="none">
        <Options>
          <Caption text=" " />
        </Options>
        <NumberFormatter id="nft" precision="0" />
        <CurrencyFormatter id="cft" alignSymbol="right" currencySymbol="억원" />
        <DateFormatter id="dateFmt" formatString="YY. MM" />
        <DualChart leftGutterSyncEnable="true" rightGutterSyncEnable="true">
          <mainChart>
            <CandleLine2DChart showDataTips="true" paddingBottom="0">
              <horizontalAxis>
                <CategoryAxis id="hAxis" categoryField="${cateFieldStr}" formatter="{dateFmt}" padding="0.5" />
              </horizontalAxis>
              <verticalAxis>
    ${
      this.props.parentDivId === "rMateDiv4" ?
        `<LinearAxis id="vAxis" baseAtZero="false" formatter="{nft}" />`
        :
        // `<CategoryAxis id="vAxis3" categoryField="${cateFieldStr2}" />`
        `<LinearAxis id="vAxis" baseAtZero="false" formatter="{cft}" />`
      }
    </verticalAxis>
              <series>
                <CandleLine2DSeries yField="${yFieldStr2}" displayName="${disFieldStr2}" >
                  <lineStroke>
                    <Stroke color="#f14b4b" weight="3" />
                  </lineStroke>
                  <lineDeclineStroke>
                    <Stroke color="#4452aa" weight="3" />
                  </lineDeclineStroke>
                  <declineStroke>
                    <SolidColor color="#4452aa" />
                  </declineStroke>
                </CandleLine2DSeries>
              </series>
              <horizontalAxisRenderers>
                <Axis2DRenderer placement="bottom" axis="{hAxis}" showLine="true" tickLength="0" />
              </horizontalAxisRenderers>
              <backgroundElements>
                <GridLines />
                <AxisMarker>
                  <lines>
                    <AxisLine>
                      <stroke>
                        <Stroke color="#cccccc" />
                      </stroke>
                    </AxisLine>
                  </lines>
                </AxisMarker>
              </backgroundElements>
              <annotationElements>
                <CrossRangeZoomer id="candleCRZ" enableZooming="false" syncCrossRangeZoomer="{columnCRZ}" zoomType="both" horizontalLabelFormatter="{nft}" />
              </annotationElements>
            </CandleLine2DChart>
          </mainChart>
          <subChart>
            <Column2DChart showDataTips="true" height="20%" paddingTop="0" paddingBottom="0" gutterTop="6" gutterBottom="6">
              <horizontalAxis>
                <CategoryAxis id="hAxis2" categoryField="date" padding="0.5" />
              </horizontalAxis>
              <verticalAxis>
                <LinearAxis id="vAxis2" formatter="{nft}" />
              </verticalAxis>
              <series>
                <Column2DSeries yField="${yFieldStr1}" displayName="${disFieldStr1}" showValueLabels="[]" columnWidthRatio="0.2" itemRenderer="BoxItemRenderer">
                  <fill>
                    <SolidColor color="#5487a2" />
                  </fill>
                </Column2DSeries>
              </series>
              <horizontalAxisRenderers>
                <Axis2DRenderer axis="{hAxis2}" showLabels="false" showLine="true" tickLength="0" />
              </horizontalAxisRenderers>
              <annotationElements>
                <CrossRangeZoomer id="columnCRZ" syncCrossRangeZoomer="{candleCRZ}" zoomType="both" verticalLabelPlacement="top" horizontalLabelFormatter="{nft}" enableZooming="false" />'
      </annotationElements>
            </Column2DChart>
          </subChart>
          <dataSelector>
            <DualScrollBar inverted="false"/>
          </dataSelector>
        </DualChart>
        <Box horizontalAlign="center" width="100%">
          <SubLegend direction="horizontal" borderStyle="none">
            <LegendItem fill="#5487a2" label="${disFieldStr1}" itemRenderer="CircleItemRenderer"/>
            <LegendItem fill="#f14b4b" label="${disFieldStr2}" itemRenderer="CircleItemRenderer"/>
          </SubLegend>
        </Box>
      </rMateChart>`;

    return layoutStr;
  }

  getChartData = (graphList) => {
    // 차트 데이터
    var chartData =
      graphList;
    return chartData;
  }


  dataTipJsFunc = (seriesId, seriesName, index, xName, yName, data, values) => {

    // if (seriesName == "ROI") {
    //   return data['Month'] + "ROI: " + " < b > " + data['roi'] + " %</b > ";
    // }
    // else {
    //   return data['Month'] + "Sales: " + " < b > $" + data['sales'] + "M</b > ";
    // }
  }

  render() {


    return (
      <>
        {/* {this.props.parentDivId === 'rMateDivAptRcmdDtl' ?
          this.props.toggleRcmdDtl && setTimeout(() => { this.renderCharts(this.props.graphList) }, 300)
          : this.props.parentDivId === 'rMateDivAptDtl' ?
            this.props.toggleAptDtl && setTimeout(() => { this.renderCharts(this.props.graphList) }, 300)
            : setTimeout(() => { this.renderCharts(this.props.graphList) }, 100)
        } */}
      </>

    );
  }
}
export default RMateCandleStickType;