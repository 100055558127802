import React, { Component } from 'react';
import axios from 'axios';
import Moment from 'moment';
import $ from 'jquery';
import { Link } from 'react-router-dom'

//// 컴포넌트 사용처 : 메인팝업
class PopDiv extends Component {

  constructor(props) {
    super(props);
    this.state = {
      applyList: [], // 청약(분양정보)리스트
    };
  }

  componentDidMount() {
    // this.fetchApplyList();
    // this.popupOpen('notice','pc');  // [SOM.220809] 침수 피해 공지 팝업 오픈
    // this.popupOpen('notice2','pc'); // [SOM.230922] LGU+ 가입/해지 작업 공지 팝업 오픈
    // this.popupOpen('notice3','pc'); // [SOM.231117] SKT 시스템 장애 공지 팝업 오픈
  }

  menuClick = (url) => {
    window.location.href = ('/' + url);
  }

  popupValidOpen = (obj, ty, timer) => {
    let valid = false;
    if (obj === 'sample-find') {
      if ($('#address').val() === '') {
        alert('지역을 입력해 주세요.');
        return false;
      }
      else if ($('#yearPay').val() === '') {
        alert('연 소득액을 입력해 주세요.');
        return false;
      }
      else {
        const payCost = ($('#yearPay').val() * 10000);
        const payCostFirst = payCost.toString().substr(0, 1);

        $('#yearPayFirst').text(payCostFirst +
          (
            payCost.toString().length == 5 ? '만' :
              payCost.toString().length == 6 ? '십만' :
                payCost.toString().length == 7 ? '백만' :
                  payCost.toString().length == 8 ? '천만' :
                    payCost.toString().length == 9 ? '억' :
                      payCost.toString().length == 10 ? '십억' :
                        payCost.toString().length == 11 ? '백억' :
                          payCost.toString().length == 12 ? '천억' :
                            ''
          )
        );
        valid = true;
      }
    }
    else if (obj === 'sample-pct') {
      if ($('#score').val() === '') {
        alert('청약 가점 포인트를 입력해 주세요.');
        return false;
      }
      else if ($('#aptName').val() === '') {
        alert('청약 아파트명을 입력해 주세요.');
        return false;
      }
      else {
        $('#scoreFirst').text($('#score').val() + '점');

        valid = true;
      }
    }

    if (valid) {
      this.popupOpen(obj, ty, timer);
    }
  }

  // popup
  popupOpen = (obj, ty, timer) => {
    var scrollHeight = 0;
    scrollHeight = $(document).scrollTop();
    var tar = $('#mw-' + obj);
    $('body').css('overflow', 'hidden');

    function mwOpen() {
      if (timer >= 0) {
        setTimeout(function () {
          if (tar.hasClass('popBtm')) {
            tar.addClass('show');
          } else {
            tar.show().addClass('show');
          }
        }, timer);
      } else {
        tar.show().addClass('show');
        if (tar.hasClass('ndim')) {
          tar.css('left', 0);
        }
        tar.find('.content').scrollTop(0);
      }
      // 영역 외 클릭 닫기
      tar.click(function (e) {
        if (!$(this).hasClass('ndim')) {
          if (!$('[class*="ly"]').find('.wrap').has(e.target).length) {
            if ($(this).hasClass('popBtm')) {
              $('body').css('overflow', '');
              $('[class*="ly"]').removeClass('show');
              window.onscroll = function () { };
            } else {
              this.popupClose(obj, 'mw');
            }
          }
        }
      });
    }

    function mwOpenFix() {
      if (timer >= 0) {
        setTimeout(function () {
          if (tar.hasClass('popBtm')) {
            tar.addClass('show');
          } else {
            tar.show().addClass('show');
          }
        }, timer);
      } else {
        tar.show().addClass('show');
        if (tar.hasClass('ndim')) {
          tar.css('left', 0);
        }
        tar.find('.content').scrollTop(0);
      }
      // 영역 외 클릭 닫기
      tar.click(function (e) {
        if (!$(this).hasClass('ndim')) {
          if (!$('[class*="ly"]').find('.wrap').has(e.target).length) {
            this.popupClose(obj, 'mwf');
          }
        }
      });
      scrollHeight = $(document).scrollTop();
      $('#wrap').css('position', 'fixed');
      $('#wrap').css('top', - scrollHeight);
    }

    if (ty === 'pc') { //pc
      if (obj === 'sample-find') {
        this.moveAptRcmd(); // 아파트 추천 페이지로 이동
      } else {
        tar.show(); // '추천 아파트 확인' 샘플 팝업 (mw-sample-find) 오픈
      }
    } else if (ty === 'mw') { //mobile 기본
      mwOpen();
      var x = window.scrollX, y = window.scrollY;
      window.onscroll = function () { window.scrollTo(x, y) };
    } else if (ty === 'mwb') {
      mwOpen();
      $('html,body').css('position', 'fixed');
    } else if (ty === 'mwf') { //mobile > #wrap을 fixed 시키는 방식
      mwOpenFix();
    }
  }

  popupClose = (obj, ty) => {
    var scrollHeight = 0;
    scrollHeight = $(document).scrollTop();
    var tar = $('#mw-' + obj);
    $('body').css('overflow', '');
    if (ty === 'pc') {
      tar.hide().removeClass('show');
    } else if (ty === 'mw') {
      if (tar.hasClass('popBtm')) {
        tar.removeClass('show');
      } else {
        tar.hide().removeClass('show');
      }
      $('html,body').css('position', '');
      window.onscroll = function () { };
    } else if (ty === 'mwf') {
      if (tar.hasClass('popBtm')) {
        tar.removeClass('show');
      } else {
        tar.hide().removeClass('show');
      }
      $('#wrap').css('top', 0);
      $('#wrap').css('position', 'relative');
      $(document).scrollTop(scrollHeight);
    }
    $('.panel_fold').addClass('on');
    $('.btn_fold').addClass('on');
  }

  popTabToggle = (e) => {

    var _parents = $(e.target).parents('.main_switch');

    _parents.find('.on').removeClass('on');
    setTimeout(function () {
      _parents.toggleClass('on');
    });

    setTimeout(function () {
      if (_parents.hasClass('on')) {
        _parents.find('.switch_01').children('.con_sub').addClass('on');
        _parents.find('.switch_02').children('.con_main').addClass('on');
      } else {
        _parents.find('.switch_01').children('.con_main').addClass('on');
        _parents.find('.switch_02').children('.con_sub').addClass('on');
      }
    }, 600);
  }

  fetchApplyList = () => {
    this.setState({
      applyList: []
    });

    axios
      .get(`apt/apply/list`)
      .then(res => {
        if (res.data.response != null && res.data.response.applyList.length > 0) {
          this.setState({
            applyList: res.data.response.applyList
          });
        }

      });
  }

  dateDiffMsg = (x) => {
    if (x === null || x === undefined || x === NaN) return;
    else {
      const currDate = Moment().utcOffset(0);
      currDate.set({ hour: 0, minute: 0, second: 0, millisecond: 0 });

      const targetDateStr = `${x.substr(0, 4)}-${x.substr(4, 2)}-${x.substr(6, 2)}`;
      const targetDate = Moment(new Date(targetDateStr));

      if (currDate.diff(targetDate, 'days') > 0) {
        return '완료';
      }
      else if (currDate.diff(targetDate, 'days') === 0) {
        return 'D - DAY';
      }
      else {
        return 'D ' + currDate.diff(targetDate, 'days');
      }
    }
  }

  onChangeCost = (e) => {
    const max = e.target.getAttribute('maxLength');
    let value = e.target.value;

    if (value.length > max) value = value.slice(0, max);
    e.target.value = value.replace(/[^0-9]/g, '');
  }

  removeValue = (e) => {
    e.target.value = '';
  }

  moveAptRcmd = () => {
    this.openAptRcmdSrch( $('#address').val(), $('#yearPay').val() );
  }

  openAptRcmdSrch = (address, yearPay) => {
    axios.get('apt/authorized/check',
      { headers: { 'Authorization': 'Bearer ' + localStorage.getItem('jwtToken') }, timeout: 1000 }).then(res => {
        if (res.data.response.isValid) {
          // 메인팝업 입력값 가지고 아파트 추천 페이지로 이동 
          let paramStr = '';
          if (address != null && yearPay != null) {
            paramStr = "?address=" + address + "&yearPay=" + yearPay;
          }
          window.location.href = '/apartRcmd' + paramStr;
        }
        else {
          window.location.href = '/login';
        }
      }).catch(err => {
        if (localStorage.getItem('isAutoLogin') == 'true') {
          // 자동로그인 적용시 사용
          if (err.response.status === 401 || err.response.status === 403) {
            axios.post('apt/authorized/reIssue',
              { 'ref': localStorage.getItem('ref') },
              { headers: { 'Authorization': 'Bearer ' + localStorage.getItem('jwtToken') } }).then(res => {
                if (res.status === 200) {
                  localStorage.setItem('jwtToken', res.headers.authorization);
                  window.location.href = '/apartRcmd';
                }
              }).catch(err => {
                window.location.href = '/login';
              });
          }
          else {
            window.location.href = '/login';
          }
        }
        else {
          window.location.href = '/login';
        }
      });
  }

  openApplyPredict = () => {
    axios.get('apt/authorized/check',
      { headers: { 'Authorization': 'Bearer ' + localStorage.getItem('jwtToken') }, timeout: 1000 }).then(res => {
        if (res.data.response.isValid) {
          window.location.href = '/applyPredict';
        }
        else {
          window.location.href = '/login';
        }
      }).catch(err => {
        if (localStorage.getItem('isAutoLogin') == 'true') {
          // 자동로그인 적용시 사용
          if (err.response.status === 401 || err.response.status === 403) {
            axios.post('apt/authorized/reIssue',
              { 'ref': localStorage.getItem('ref') },
              { headers: { 'Authorization': 'Bearer ' + localStorage.getItem('jwtToken') } }).then(res => {
                if (res.status === 200) {
                  localStorage.setItem('jwtToken', res.headers.authorization);
                  window.location.href = '/applyPredict';
                }
              }).catch(err => {
                window.location.href = '/login';
              });
          }
          else {
            window.location.href = '/login';
          }
        }
        else {
          window.location.href = '/login';
        }
      });
  }

  tagInput = (tagNum) => {
    let tagStr = '';
    if (tagNum == 1) {
      tagStr = '서울특별시 강남구 역삼동';
    }
    else if (tagNum == 2) {
      tagStr = '서울특별시 송파구 송파동';
    }
    else if (tagNum == 3) {
      tagStr = '서울특별시 성동구 성수동1가';
    }
    else if (tagNum == 4) {
      tagStr = '서울특별시 강남구 압구정동';
    }
    $('#address').val(tagStr);
    $('#address').focus();

  }

  render() {
    return (
      <>
        {/* [SOM.231117] SKT 시스템 장애 공지 팝업 오픈 (메인) */}
        <div id="mw-notice3" className="ly_cpinfo_pc ly_notice ly_main">
            <div className="notice-lgu" role="alertdialog" aria-labelledby="lb-notice-title" aria-describedby="lb-notice-desc-01 lb-notice-desc-02 lb-notice-desc-03">
              <div className="notice-lgu-content">
                <h2 id="lb-notice-title" className="notice-lgu-title">SKT 시스템 일시 오류 안내</h2>
                <p id="lb-notice-desc-01" className="notice-lgu-desc-01">
                  안녕하세요. 헥토이노베이션입니다.<br/><br/>
                  SKT 시스템 일시적인 오류로 인해, 현재 고객님들의 서비스 가입/해지/이용이 일시 중단되고 있습니다.<br/>
                  오류 사항에 대하여 빠르게 확인 중에 있습니다.<br/><br/>
                  오류 기간 동안 해지를 요청하신 고객은 서비스 정상화 이후 일괄 해지처리 될 예정입니다.<br/><br/>
                  서비스 이용에 불편을 끼쳐드려 죄송합니다.<br/><br/>
                  더 나은 서비스를 제공하기 위해 최선을 다하겠습니다. 감사합니다.
                </p>
                <button type="button" className="notice-lgu-btn" aria-label="닫기" onClick={() => this.popupClose('notice3', 'mwf')}></button>
              </div>
            </div>
        </div>

        {/* [SOM.230922] LGU+ 가입/해지 작업 공지 팝업 (메인) */}
        <div id="mw-notice2" className="ly_cpinfo_pc ly_notice ly_main">
            <div className="notice-lgu" role="alertdialog" aria-labelledby="lb-notice-title" aria-describedby="lb-notice-desc-01 lb-notice-desc-02 lb-notice-desc-03">
              <div className="notice-lgu-content">
                <h2 id="lb-notice-title" className="notice-lgu-title">이동통신사 시스템 점검 안내</h2>
                <p id="lb-notice-desc-01" className="notice-lgu-desc-01">LGU+ 시스템 점검으로 인하여<br/><em>LGU+ 고객님들의 서비스 해지가 일시 중단 됩니다.</em><br/>불편을 드려 죄송합니다.</p>
                <p id="lb-notice-desc-02" className="notice-lgu-desc-02"><em>점검일시</em><br/>10월 20일(금) 22시 ~ 10월 23일(월) 08시</p>
                <p id="lb-notice-desc-03" className="notice-lgu-desc-03">서비스 재개 시점은 상황에 따라 지연될 수 있습니다.</p>
                <button type="button" className="notice-lgu-btn" aria-label="닫기" onClick={() => this.popupClose('notice2', 'mwf')}></button>
              </div>
            </div>
        </div>

        {/* [SOM.220809] 침수피해 공지 팝업 */}
        <div id="mw-notice" className="ly_cpinfo_pc ly_notice ly_main">
          <div className="wrap">
            <section>
              <h1 className="tit">안녕하세요. 헥토이노베이션입니다.</h1>
              <p className="txt">고객센터 침수로 인해 현재 상담원 연결이 불가합니다.<br/> 서비스 해지는 홈페이지 및 ARS 고객센터 연결을<br className="m_show"/> 통해<br className="m_hide"/> 바로 가능합니다.<br/> 이용에 불편을 드려 죄송합니다.<br/> 감사합니다.</p>
              <a href="/expire" className="btn">해지 바로가기</a>
            </section>
            <button type="button" className="btn_close" onClick={() => this.popupClose('notice', 'mwf')}><span className="blind">닫기</span></button>
          </div>
        </div>
        {/* 2022.05.12 주석 */}
        {/* <div id="mw-main" className="ly_cpinfo_pc ly_main" style={{ "display": "block" }}>
          <div className="wrap">
            <div className="content">
              <section className="main_switch">
                <button type="button" onClick={() => this.popupClose('main', 'mw')} className="pop_close"><i>지도로 보기</i></button>
                <div className="switch_02">
                  <div className="con_main">
                    <div className="main_area">
                      <div className="form_area">
                        <div className="main_form">
                          <div className="ip_wrap num">
                            <div className="ip_box blk">
                              <label htmlFor="place" className="lbl">청약 가점 포인트</label>
                              <input type="text" className="ip" name="place" id="score" maxlength="2" />
                              <i>점</i>
                            </div>
                          </div>
                          <div className="ip_wrap">
                            <div className="ip_box blk">
                              <label htmlFor="cost" className="lbl">청약 아파트명</label>
                              <input type="text" className="ip" name="cost" id="aptName" />
                              <i className="ico_search"></i>
                            </div>
                          </div>
                        </div>
                        <div className="btn_inner">
                          <button type="button" className="btn_main" onClick={() => this.popupValidOpen('sample-pct', 'pc')}>당첨 예상 보기</button>
                        </div>
                      </div>
                      <div className="tit_area">
                        <figure className="img_area">
                          <img className="img_people_03" src={process.env.REACT_APP_ENV_URL + "resources/images/main_img05.png"} alt="main_people_01" />
                          <img className="img_people_04" src={process.env.REACT_APP_ENV_URL + "resources/images/main_img06.png"} alt="main_people_02" />
                          <i className="img_point_03"></i>
                          <i className="img_point_04"></i>
                        </figure>
                        <span className="main_txt">
                          나의<i>청</i><i>약</i><br />
                          <em>당첨예상을 먼저</em><br />
                          확인해보세요.
                                            </span>
                        <p className="sub_txt">내가 청약에 당첨 될 가능성은?</p>
                      </div>
                    </div>
                    <div className="card_area">
                      <div className="tit_area">
                        <h4>청약 예정 아파트</h4>
                        <div className="btn_inner">
                          <button type="line" className="btn_line" onClick={() => this.menuClick('apply')}>더보기</button>
                        </div>
                      </div>
                      <ul>
                        {
                          this.state.applyList.length <= 0 ?
                            <li>
                            </li>
                            :
                            this.state.applyList.map((result, idx) => {
                              return (
                                idx < 3 && (
                                  <li className="card_item" onClick={() => this.menuClick(`apply?menuNm=popDiv&applyId=${result.applyId}&lat=${result.lat}&lng=${result.lng}`)}>
                                    <div className="apt_card_mini">
                                      <span className="date_yet">
                                        {
                                          this.dateDiffMsg(result.notiDate) != '완료' ? '모집공고 ' + '(' + this.dateDiffMsg(result.notiDate) + ')' :
                                            (
                                              result.spLocalDate != '' && this.dateDiffMsg(result.spLocalDate) != '완료' ? '특별공급 ' + '(' + this.dateDiffMsg(result.spLocalDate) + ')' :
                                                (
                                                  this.dateDiffMsg(result.fstLocalDate) != '완료' ? '일반공급 ' + '(' + this.dateDiffMsg(result.fstLocalDate) + ')' :
                                                    (
                                                      this.dateDiffMsg(result.pbshDate) != '완료' ? '당첨자발표 ' + '(' + this.dateDiffMsg(result.pbshDate) + ')' :
                                                        '완료'
                                                    )
                                                )
                                            )
                                        }
                                      </span>
                                      <div className="tit_wrap">
                                        <div className="tit_box">
                                          <h4>{result.aptName}</h4>
                                        </div>
                                        <p>{result.address}</p>
                                      </div>
                                    </div>
                                  </li>
                                )
                              )
                            }
                            )
                        }
                      </ul>
                    </div>
                  </div>
                  <div className="con_sub on" >
                    <div className="under_tit">
                      <span>
                        내청약<br />
                        <em>당첨예상은<br />
                          몇 점?</em>
                      </span>
                      <figure>
                        <img src={process.env.REACT_APP_ENV_URL + "resources/images/arrow_right.png"} alt="arrow_right" />
                      </figure>
                    </div>
                    <p className="bg_point">%</p>
                  </div>
                </div>
                <div className="switch_01">
                  <div className="con_main on">
                    <div className="main_area">
                      <div className="tit_area">
                        <figure className="img_area">
                          <img className="img_people_01" src={process.env.REACT_APP_ENV_URL + "resources/images/main_img01.png"} alt="main_people_01" />
                          <img className="img_people_02" src={process.env.REACT_APP_ENV_URL + "resources/images/main_img02.png"} alt="main_people_02" />
                          <i className="img_point_01"></i>
                          <i className="img_point_02"></i>
                        </figure>
                        <span className="main_txt">
                          <i>영</i><i>끌</i>하지 않고<br />
                          <em>살 수 있는 3억원대</em><br />
                          아파트는 어디?
                                            </span>
                        <p className="sub_txt">나의 자산으로 구매 가능한 아파트를 확인해보세요.</p>
                      </div>
                      <div className="form_area">
                        <div className="main_form">
                          <div className="ip_wrap">
                            <div className="ip_box blk">
                              <label htmlFor="place" className="lbl" >지역을 입력해주세요.</label>
                              <input type="text" className="ip" name="place" id="address" />
                              <i className="ico_search"></i>
                            </div>
                            <div className="hashtag">
                              <button type="button" onClick={() => this.tagInput(1)}># 역삼동</button>
                              <button type="button" onClick={() => this.tagInput(2)}># 송파동</button>
                              <button type="button" onClick={() => this.tagInput(3)}># 성수동</button>
                              <button type="button" onClick={() => this.tagInput(4)}># 압구정동</button>
                            </div>
                          </div>
                          <div className="ip_wrap num">
                            <div className="ip_box blk">
                              <label htmlFor="cost" className="lbl" >연 소득액</label>
                              <input type="text" className="ip" name="cost" id="yearPay" onChange={(e) => this.onChangeCost(e)} maxlength="8" />
                              <i>만원</i>
                            </div>
                          </div>
                        </div>
                        <div className="btn_inner">
                          <button type="button" className="btn_main" onClick={() => this.popupValidOpen('sample-find', 'pc')}>아파트 찾기</button>
                        </div>
                      </div>
                    </div>
                    <div className="card_area">
                      <div className="tit_area">
                        <h4>인기 아파트 단지</h4>
                        <div className="btn_inner">
                          <button type="line" className="btn_line" onClick={() => this.menuClick('apartMap')}>더보기</button>
                        </div>
                      </div>
                      <ul>
                        {
                          this.state.applyList.length <= 0 ?
                            <li>
                            </li>
                            :
                            this.state.applyList.map((result, idx) => {

                              return (
                                idx < 3 && (

                                  <li className="card_item" onClick={() => this.menuClick(`apply?menuNm=popDiv&applyId=${result.applyId}&lat=${result.lat}&lng=${result.lng}`)}>
                                    <div className="apt_card_mini">
                                      <span className="date_yet">
                                        {
                                          this.dateDiffMsg(result.notiDate) != '완료' ? '모집공고 ' + '(' + this.dateDiffMsg(result.notiDate) + ')' :
                                            (
                                              result.spLocalDate != '' && this.dateDiffMsg(result.spLocalDate) != '완료' ? '특별공급 ' + '(' + this.dateDiffMsg(result.spLocalDate) + ')' :
                                                (
                                                  this.dateDiffMsg(result.fstLocalDate) != '완료' ? '일반공급 ' + '(' + this.dateDiffMsg(result.fstLocalDate) + ')' :
                                                    (
                                                      this.dateDiffMsg(result.pbshDate) != '완료' ? '당첨자발표 ' + '(' + this.dateDiffMsg(result.pbshDate) + ')' :
                                                        '완료'
                                                    )
                                                )
                                            )
                                        }
                                      </span>
                                      <div className="tit_wrap">
                                        <div className="tit_box">
                                          <h4>{result.aptName}</h4>
                                        </div>
                                        <p>{result.address}</p>
                                      </div>
                                    </div>
                                  </li>
                                )
                              )
                            }
                            )
                        }
                      </ul>
                    </div>
                  </div>
                  <div className="con_sub" >
                    <div className="under_tit">
                      <span>
                        내 자산으로<br />
                        <em>구매가능한<br />
                          아파트는?</em>
                      </span>
                      <figure>
                        <img src={process.env.REACT_APP_ENV_URL + "resources/images/arrow_left.png"} alt="arrow_left" />
                      </figure>
                    </div>
                    <p className="bg_point">&#65510;</p>
                  </div>
                </div>
              </section>
            </div>
          </div>
        </div> */}
        {/* 2022.05.12 추가 */}
        <div id="mw-main" className="ly_cpinfo_pc ly_interest ly_main show" style={{ display: 'none' }}>
          <div className="wrap">
            <img src={process.env.REACT_APP_ENV_URL + "resources/images/promotion/interest/img_top.png"} alt="부동산 관심사 체크하고 스벅 아메리카노 받자" />
            <img src={process.env.REACT_APP_ENV_URL + "resources/images/promotion/interest/img_top2.png"} alt="부동산 관심사 체크하고 스벅 아메리카노 받자" style={{ display: 'none' }} />
            <img src={process.env.REACT_APP_ENV_URL + "resources/images/promotion/interest/img_bean_1.png"} alt="커피콩" className="img_bean1" aria-hidden="true" />
            <img src={process.env.REACT_APP_ENV_URL + "resources/images/promotion/interest/img_bean_2.png"} alt="커피콩" className="img_bean2" aria-hidden="true" />
            <div className="btn_wrap">
              {/* 로그인 상태에 따라... */}
              { localStorage.getItem('jwtToken') !== null ? 
                <Link to={{ pathname: '/InterestEvent', state: { login: true }}}><img src={process.env.REACT_APP_ENV_URL + "resources/images/promotion/interest/btn_join.png"} alt="회원 참여하기" /></Link>
                :
                <Link to={{ pathname: '/InterestEvent', state: { login: false }}}><img src={process.env.REACT_APP_ENV_URL + "resources/images/promotion/interest/btn_join.png"} alt="비회원 참여하기" /></Link>
              }
            </div>
            <img src={process.env.REACT_APP_ENV_URL + "resources/images/promotion/interest/img_notice.png"} alt="유의사항" />
            <button type="button" className="btn_close" onClick={ () => this.popupClose('main', 'mw')}><span className="blind">닫기</span></button>
          </div>
        </div>
        <div id="mw-sample-find" className="ly_cpinfo_pc ly_sample">
          <div className="wrap">
            <button type="button" onClick={() => this.popupClose('sample-find', 'mw')} className="pop_close"><i>닫기</i></button>
            <div className="content">
              <h4>내 <em>연봉 <s id="yearPayFirst"></s></em> 구입가능 아파트?</h4>
              <section className="sample_area">
                <table className="table_apt">
                  <caption>
                    <strong>샘플 - 구입가능아파트리스트</strong>
                    <p>아파트 거래관련 정보 (최근거래금액, 연소득액, 대출가능금액 등)</p>
                  </caption>
                  <colgroup>
                    <col style={{ "width": "30%" }} />
                    <col style={{ "width": "70%" }} />
                  </colgroup>
                  <tbody>
                    <tr className="main">
                      <th><s className="name_01">**</s> 아파트</th>
                      <td>6<s>억</s> 6,000</td>
                    </tr>
                    <tr>
                      <th>최근 거래 금액</th>
                      <td>6<s>억</s> 6,000</td>
                    </tr>
                    <tr>
                      <th>나의 연 소득액</th>
                      <td>5,000<s>만원</s></td>
                    </tr>
                    <tr>
                      <th>대출 가능 금액</th>
                      <td>3<s>억원</s></td>
                    </tr>
                    <tr>
                      <th>월 대출 상환액</th>
                      <td>130<s>만원</s></td>
                    </tr>
                  </tbody>
                  <tbody>
                    <tr className="main">
                      <th><s className="name_02"></s>아파트</th>
                      <td>6<s>억</s> 6,000</td>
                    </tr>
                    <tr>
                      <th>최근 거래 금액</th>
                      <td>6<s>억</s> 6,000</td>
                    </tr>
                    <tr>
                      <th>나의 연 소득액</th>
                      <td>5,000<s>만원</s></td>
                    </tr>
                    <tr>
                      <th>대출 가능 금액</th>
                      <td>3<s>억원</s></td>
                    </tr>
                    <tr>
                      <th>월 대출 상환액</th>
                      <td>130<s>만원</s></td>
                    </tr>
                  </tbody>
                  <tbody>
                    <tr className="main">
                      <th><s className="name_03"></s>아파트</th>
                      <td>6<s>억</s> 6,000</td>
                    </tr>
                    <tr>
                      <th>최근 거래 금액</th>
                      <td>6<s>억</s> 6,000</td>
                    </tr>
                    <tr>
                      <th>나의 연 소득액</th>
                      <td>5,000<s>만원</s></td>
                    </tr>
                    <tr>
                      <th>대출 가능 금액</th>
                      <td>3<s>억원</s></td>
                    </tr>
                    <tr>
                      <th>월 대출 상환액</th>
                      <td>130<s>만원</s></td>
                    </tr>
                  </tbody>
                </table>
              </section>
            </div>
            <footer className="sample_footer">
              <p className="noti">
                위 내용은 이해를 돕기 위한 페이지이며,<br />
                자세한 결과는 <b>회원가입 후 확인</b>하실 수 있습니다.
                        </p>
              <div className="btn_inner">
                <button type="button" className="btn_main" onClick={() => this.openAptRcmdSrch()}>추천 아파트 확인</button>
              </div>
            </footer>
          </div>
        </div>
        <div id="mw-sample-pct" className="ly_cpinfo_pc ly_sample">
          <div className="wrap">
            <button type="button" onClick={() => this.popupClose('sample-pct', 'mw')} className="pop_close"><i>닫기</i></button>
            <div className="content">
              <h4>내 <em><s id="scoreFirst"></s></em>으로 청약 당첨 가능성은?</h4>
              <section className="sample_area">
                <table className="table_apt_pct">
                  <caption>
                    <strong>샘플 - 아파트청약당첨예상</strong>
                    <p>가점점수와 당첨예상 퍼센트 및 그래프</p>
                  </caption>
                  <colgroup>
                    <col style={{ "width": "65%" }} />
                    <col style={{ "width": "35%" }} />
                  </colgroup>
                  <tbody>
                    <tr className="point">
                      <th><s className="name_01">**</s> 아파트</th>
                      <td>58<s>점</s></td>
                    </tr>
                    <tr>
                      <th>
                        <div className="graph"><div className="bar_st01"></div></div>
                      </th>
                      <td>94<s>%</s></td>
                    </tr>
                  </tbody>
                  <tbody>
                    <tr className="point">
                      <th><s className="name_02"></s>아파트</th>
                      <td>30<s>점</s></td>
                    </tr>
                    <tr>
                      <th>
                        <div className="graph"><div className="bar_st02"></div></div>
                      </th>
                      <td>100<s>%</s></td>
                    </tr>
                  </tbody>
                  <tbody>
                    <tr className="point">
                      <th><s className="name_03"></s>아파트</th>
                      <td>75<s>점</s></td>
                    </tr>
                    <tr>
                      <th>
                        <div className="graph"><div className="bar_st03"></div></div>
                      </th>
                      <td>71<s>%</s></td>
                    </tr>
                  </tbody>
                </table>
              </section>
            </div>
            <footer className="sample_footer">
              <p className="noti">
                위 내용은 이해를 돕기 위한 페이지이며,<br />
                자세한 결과는 <b>회원가입 후 확인</b>하실 수 있습니다.
                        </p>
              <div className="btn_inner">
                <button type="button" className="btn_main" onClick={() => this.openApplyPredict()}>청약 당첨예상 확인</button>
              </div>
            </footer>
          </div>
        </div>
      </>
    )
  }
}
export default PopDiv;