import React, { Component } from 'react';
import { addLsItem, getLsItem } from 'common/Search';
import { findIndex } from 'common/ArrayUtil';
import $ from 'jquery';
import 'jquery-ui-bundle'
import { filtersNumberReplaceWithStag } from 'common/StringUtil';

//// 컴포넌트 사용처 : 아파트 정보
class MapAptSrchTrx extends Component {
  constructor(props) {
    super(props);
    this.state = {
      trxList: [
        { name: "전체", visible: true, min: -1, max: 9999, minTxt: "전체", maxTxt: "전체" },
        { name: "1억 미만", visible: false, min: 0, max: 1, minTxt: "1억 미만", maxTxt: "1억 미만" },
        { name: "1~2억대", visible: false, min: 1, max: 3, minTxt: "1", maxTxt: "3억 미만" },
        { name: "3억대", visible: false, min: 3, max: 4, minTxt: "3", maxTxt: "4억 미만" },
        { name: "4억대", visible: false, min: 4, max: 5, minTxt: "4", maxTxt: "5억 미만" },
        { name: "5억대", visible: false, min: 5, max: 6, minTxt: "5", maxTxt: "6억 미만" },
        { name: "6억대", visible: false, min: 6, max: 7, minTxt: "6", maxTxt: "7억 미만" },
        { name: "7억대", visible: false, min: 7, max: 8, minTxt: "7", maxTxt: "8억 미만" },
        { name: "8억대", visible: false, min: 8, max: 9, minTxt: "8", maxTxt: "9억 미만" },
        { name: "9억대", visible: false, min: 9, max: 10, minTxt: "9", maxTxt: "10억 미만" },
        { name: "10억대", visible: false, min: 10, max: 20, minTxt: "10", maxTxt: "20억 미만" },
        { name: "20억 이상", visible: false, min: 20, max: 9999, minTxt: "20억 이상", maxTxt: "20억 이상" }
      ],

      selectedMinIdx: 0,
      selectedMaxIdx: 0,
      slideMinTxt: '',
      slideMaxTxt: '',
    };
  }

  componentDidMount() {

    let self = this;
    $(".sliderTrx").slider({
      range: true,
      min: 0,
      max: 21,
      values: [0, 21],
      slide: function (event, ui) {
        self.setState({
          selectedMinIdx: -1, selectedMaxIdx: -1,
          slideMinTxt: ui.values[0],
          slideMaxTxt: ui.values[1] === 21 ? '20억 이상' : ui.values[1] + '억 미만'
        });
      },
      stop: function (event, ui) {
        const current = self.state.trxList.slice();
        current.forEach(v => v.visible = false)
        self.setState(({ trxList: current }));
        self.props.onSearch({ type: 'TRX', min: ui.values[0], max: ui.values[1] > 20 ? 9999 : ui.values[1], load: true });
      }
    });

    if (this.props.loadFilter) {

      let aptTrxFilter = null;
      if (this.props.isApply) {
        aptTrxFilter = getLsItem('applyTrxFilter');
      }
      else {
        aptTrxFilter = getLsItem('aptTrxFilter');
      }
      if (aptTrxFilter && aptTrxFilter.filter && aptTrxFilter.sliderValue) {
        $(".sliderTrx").slider("values", aptTrxFilter.sliderValue);
        this.setState({ trxList: aptTrxFilter.filter });

        if (aptTrxFilter.filter.some(f => f.visible)) {
          const minValue = aptTrxFilter.filter.filter(f => f.visible).reduce((p, c) => p > c ? c : p).min;
          const maxValue = aptTrxFilter.filter.filter(f => f.visible).reduce((p, c) => p > c ? p : c).max;
          this.props.onSearch({ type: 'TRX', min: minValue, max: maxValue, load: false });
        } else {
          this.props.onSearch({ type: 'TRX', min: aptTrxFilter.sliderValue[0], max: aptTrxFilter.sliderValue[1], load: false });
        }
      }
    }
  }

  componentDidUpdate() {
    if (this.props.saveFilter) {
      if (this.props.isApply) {
        addLsItem('applyTrxFilter', { filter: this.state.trxList, sliderValue: $(".sliderTrx").slider("option", "values") });
      }
      else {
        addLsItem('aptTrxFilter', { filter: this.state.trxList, sliderValue: $(".sliderTrx").slider("option", "values") });
      }
    }
  }

  selectTrx(idx) {
    const current = this.state.trxList.slice();
    const checkedCnt = current.filter(e => e.visible).length;
    let visibleIndex = findIndex(this.state.trxList, i => i.visible);

    let minc = 0;
    let maxc = 0;

    let minIdx = 0;
    let maxIdx = 0;

    if (visibleIndex !== 0 && checkedCnt === 1 && idx > visibleIndex) {
      current.filter((v, i) => i >= visibleIndex && i <= idx).forEach(v => v.visible = true);
      minc = current[visibleIndex].min;
      maxc = current[idx].max;

      minIdx = visibleIndex;
      maxIdx = idx;
    } else {
      current.forEach(c => c.visible = false);
      current[idx].visible = true;
      minc = current[idx].min;
      maxc = current[idx].max;

      minIdx = idx;
      maxIdx = idx;
    }

    this.props.onSearch({ type: 'TRX', min: minc, max: maxc, load: true });

    $(".sliderTrx").slider("values", [minc, maxc]);
    this.setState({ trxList: current, selectedMinIdx: minIdx, selectedMaxIdx: maxIdx });
  }

  render() {
    const { trxList, selectedMinIdx, selectedMaxIdx, slideMinTxt, slideMaxTxt } = this.state;
    let minTxt = selectedMinIdx != -1 ? trxList[selectedMinIdx].minTxt : '';
    let maxTxt = selectedMaxIdx != -1 ? trxList[selectedMaxIdx].maxTxt : '';

    return (
      <div class="filter_wrap">
        <div class="tit_wrap">
          <strong>{this.props.isApply ? '분양가' : '실거래가'}</strong>
          <span className="choice">{
            (selectedMinIdx !== -1 && selectedMaxIdx !== -1) ? (
              maxTxt == "전체" ? "전체" :
                (minTxt == maxTxt || minTxt == "1억 미만") ? filtersNumberReplaceWithStag(maxTxt) :
                  <>{filtersNumberReplaceWithStag(minTxt)} ~ {filtersNumberReplaceWithStag(maxTxt)}</>
            )
              :
              (selectedMinIdx === -1 && selectedMaxIdx === -1) && (
                (slideMinTxt === slideMaxTxt || slideMinTxt === 0 || slideMinTxt > 20) ? filtersNumberReplaceWithStag(slideMaxTxt) :
                  <>{filtersNumberReplaceWithStag(slideMinTxt)} ~ {filtersNumberReplaceWithStag(slideMaxTxt)}</>
              )
          }</span>
        </div >
        <div class="filter_inner _line">
          <div class="slider sliderTrx"></div>
          <ul class="slider_lbl">
            <li>0</li>
            <li>5억</li>
            <li>10억</li>
            <li>15억</li>
            <li>20억</li>
          </ul>
          {
            this.state.trxList.map((v, idx) =>
              <button type="button"
                className={v.visible ? "_set" : ""}
                onClick={() => this.selectTrx(idx)}>{v.name}
              </button>
            )
          }
        </div>
      </div >
    );
  }
}
export default MapAptSrchTrx;