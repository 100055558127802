import React, { Component } from 'react';
import Moment from 'moment';
import { dateDiffMsg, numberWithCounts, numberWithCommas, numberWithCommas2, dateFormatMsg, dateFormatMsg2, numberReplaceWithStag, textReplaceWithStag } from 'common/StringUtil';
import axios from 'axios';

//// 컴포넌트 사용처 : 청약 가점계산기
class ApplyCalcRecent extends Component {

  calcHref = (e, applyId) => {
    e.stopPropagation();
    axios.get('apt/authorized/check',
      { headers: { 'Authorization': 'Bearer ' + localStorage.getItem('jwtToken') }, timeout: 1000 }).then(res => {
        if (res.data.response.isValid) {
          applyId && window.open('/applyPay?applyId=' + applyId, '_blank');
        }
        else {
          window.location.href = '/login';
        }
      }).catch(err => {
        if (localStorage.getItem('isAutoLogin') == 'true') {
          // 자동로그인 적용시 사용
          if (err.response.status === 401 || err.response.status === 403) {
            axios.post('apt/authorized/reIssue',
              { 'ref': localStorage.getItem('ref') },
              { headers: { 'Authorization': 'Bearer ' + localStorage.getItem('jwtToken') } }).then(res => {
                if (res.status === 200) {
                  localStorage.setItem('jwtToken', res.headers.authorization);
                  applyId && window.open('/applyPay?applyId=' + applyId, '_blank');
                }
              }).catch(err => {
                window.location.href = '/login';
              });
          }
          else {
            window.location.href = '/login';
          }
        }
        else {
          window.location.href = '/login';
        }
      });
  }

  predictHref = (e, applyId) => {
    e.stopPropagation();
    axios.get('apt/authorized/check',
      { headers: { 'Authorization': 'Bearer ' + localStorage.getItem('jwtToken') }, timeout: 1000 }).then(res => {
        if (res.data.response.isValid) {
          applyId && window.open('/applyPredict?applyId=' + applyId + '&score=' + this.props.score, '_blank');
        }
        else {
          window.location.href = '/login';
        }
      }).catch(err => {
        if (localStorage.getItem('isAutoLogin') == 'true') {
          // 자동로그인 적용시 사용
          if (err.response.status === 401 || err.response.status === 403) {
            axios.post('apt/authorized/reIssue',
              { 'ref': localStorage.getItem('ref') },
              { headers: { 'Authorization': 'Bearer ' + localStorage.getItem('jwtToken') } }).then(res => {
                if (res.status === 200) {
                  localStorage.setItem('jwtToken', res.headers.authorization);
                  applyId && window.open('/applyPredict?applyId=' + applyId + '&score=' + this.props.score, '_blank');
                }
              }).catch(err => {
                window.location.href = '/login';
              });
          }
          else {
            window.location.href = '/login';
          }
        }
        else {
          window.location.href = '/login';
        }
      });
  };

  applyHref = (e, applyId) => {
    e.stopPropagation();
    applyId && window.open('/apply?applyId=' + applyId, '_blank');
  };

  render() {

    return (

      <div className="inner_wrap_full on">
        <div className="silder_wrap">
          <div class="silder_tit">
            <h3>최근 청약정보</h3>
            <div class="card_btn_wrap">
              <div class="btn_inner">
                <button type="button" class="btn_scroll swiper-button-prev"><i class="hide">pre</i></button>
                <button type="button" class="btn_scroll swiper-button-next"><i class="hide">next</i></button>
              </div>
            </div>
          </div>
          <div class="silder_con">

            <div class="card_area swiper-container_apt">
              <div class="swiper-wrapper">
                {
                  this.props.applyList.map((result, idx) => {
                    return (
                      <React.Fragment key={idx}>
                        <div class="swiper-slide">
                          <div className="card_item">
                            <span className={result.notiYn === 'N' ? 'date_soon' : (dateDiffMsg(result.pbshDate) == '완료' ? 'date_end' : 'date_ing')}>
                              {
                                result.notiYn === 'N' ?
                                  '청약예정 '
                                  :
                                  (
                                    dateDiffMsg(result.notiDate) != '완료' ? <>모집공고 <em>{dateDiffMsg(result.notiDate)}</em></> :
                                      (
                                        result.spLocalDate != '' && dateDiffMsg(result.spLocalDate) != '완료' ? <>특별공급 <em>{dateDiffMsg(result.spLocalDate)}</em></> :
                                          (
                                            dateDiffMsg(result.fstLocalDate) != '완료' ? <>일반공급 <em>{dateDiffMsg(result.fstLocalDate)}</em></> :
                                              (
                                                dateDiffMsg(result.pbshDate) != '완료' ? <>당첨자발표 <em>{dateDiffMsg(result.pbshDate)}</em></> :
                                                  '완료'
                                              )
                                          )
                                      )
                                  )
                              }
                            </span>
                            <div className="apt_card" id={"apt_card_" + result.applyId} onClick={e => this.applyHref(e, result.applyId)}>
                              <div className="tit_wrap">
                                <div className="tit_box">
                                  <h4>{result.aptName}</h4>
                                  {/* <span className="noti">인기</span> */}
                                </div>
                                <p>{result.juso}</p>
                              </div>
                              {
                                result.notiYn === 'N' ?
                                  <div className="info_none">
                                    <div className="img_area">
                                      <figure>
                                        <img src={process.env.REACT_APP_ENV_URL + "resources/images/info_none_2.png"} alt="noti_img" />
                                      </figure>
                                      <p>아직 입주공고문이 올라오지 않았어요.</p>
                                    </div>
                                  </div>
                                  :
                                  <div className="info_wrap">
                                    <table className="tb_pri">
                                      <caption>
                                        <strong>청약 요약 정보</strong>
                                        <p>분양가, 세대, 면적 등 청약 핵심정보</p>
                                      </caption>
                                      <colgroup>
                                        <col style={{ "width": "30%" }} />
                                        <col style={{ "width": "70%" }} />
                                      </colgroup>
                                      <tbody>
                                        <tr>
                                          <th>분양가</th>
                                          <td className="_price">
                                            {numberReplaceWithStag(numberWithCounts(result.minApplyPrice * 10000))} ~ {numberReplaceWithStag(numberWithCounts(result.maxApplyPrice * 10000))}</td>
                                        </tr>
                                        <tr>
                                          <th>규제정보</th>
                                          <td>{
                                            (result.loanRateCase == 1 || result.loanRateCase == 2) ? '투기과열/투기지역' :
                                              (
                                                (result.loanRateCase == 3) ? '조정대상지역' :
                                                  (
                                                    (result.loanRateCase == 4) ? '조정대상지역외수도권' :
                                                      (result.loanRateCase == 5) ? '일반지역' : ''
                                                  )
                                              )
                                          }</td>
                                        </tr>
                                        <tr>
                                          <th>총 세대 / 청약세대</th>
                                          <td>{textReplaceWithStag(numberWithCommas(result.applyCnt))}세대</td>
                                        </tr>
                                        <tr>
                                          <th>전용면적</th>
                                          <td>{textReplaceWithStag(result.minHsTypePy)} ~ {textReplaceWithStag(result.maxHsTypePy)}평 ({textReplaceWithStag(result.minHsType)} ~ {textReplaceWithStag(result.maxHsType)}㎡)</td>
                                        </tr>
                                      </tbody>
                                    </table>
                                    <table className="tb_sec">
                                      <caption>
                                        <strong>청약 요약 정보</strong>
                                        <p>공고일 / 발표일 리스트</p>
                                      </caption>
                                      <colgroup>
                                        <col style={{ "width": "40%" }} />
                                        <col style={{ "width": "60%" }} />
                                      </colgroup>
                                      <tbody>
                                        <tr>
                                          <th>입주자모집공고일</th>
                                          <td><s>{dateFormatMsg(result.notiDate)} ({dateDiffMsg(result.notiDate)})</s></td>
                                        </tr>
                                        <tr>
                                          <th>특별공급 청약일</th>
                                          <td><s>{result.spLocalDate == '' ? '사업주체문의' : dateFormatMsg(result.spLocalDate) + ' (' + dateDiffMsg(result.spLocalDate) + ')'}</s></td>
                                        </tr>
                                        <tr>
                                          <th>일반공급 청약일</th>
                                          <td><s>{dateFormatMsg(result.fstLocalDate)} ({dateDiffMsg(result.fstLocalDate)})</s></td>
                                        </tr>
                                        <tr>
                                          <th>당첨자 발표일</th>
                                          <td><s>{dateFormatMsg(result.pbshDate)} ({dateDiffMsg(result.pbshDate)})</s></td>
                                        </tr>
                                      </tbody>
                                    </table>
                                  </div>
                              }
                            </div>
                            {
                              result.notiYn === 'Y' &&
                              <div className="btn_wrap">
                                <button type="button" className="btn_point" onClick={(e) => this.calcHref(e, result.applyId)}>비용계산</button>
                                <button type="button" className="btn_per" onClick={(e) => this.predictHref(e, result.applyId)}>청약당첨계산</button>
                              </div>
                            }
                          </div>
                        </div>
                      </React.Fragment>
                    )
                  })
                }
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
export default ApplyCalcRecent;