import React, { Component } from 'react';
import Moment from 'moment';
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import { numberWithCommas, numberWithCounts, dateReplaceWithStag, numberReplaceWithStag, textReplaceWithStag, date2ReplaceWithStag } from 'common/StringUtil';
import RMateColumnType from 'routes/rMateChart/RMateColumnType';

//// 컴포넌트 사용처 : 청약 당첨예상 상세
class PrevTrx extends Component {

  render() {

    const { applyInfo, bjdInfo, applyDtl, prevTrx, aptRecentAreaPyTrxData, aptOneYearAgoAreaPyTrxData, applyRecentAreaPyTrxData } = this.props.calcResult;

    const options = {
      chart: {
        type: 'column',
      },
      title: {
        text: ''
      },
      xAxis: {
        categories: prevTrx.map(v => Moment(v.trxDate).format('YY. MM'))
      },
      yAxis: {
        title: {
          text: ''
        },
        labels: {
          formatter: function () {
            return numberWithCounts(this.value * 10000);
          }
        }
      },
      tooltip: {
        formatter: function () {
          return numberWithCounts(this.y * 10000);
        }
      },
      plotOptions: {
        column: {
          dataLabels: {
            enabled: true
          }
        }
      },
      series: [{
        name: "실거래가",
        data: prevTrx.map(v => v.amt),
        color: 'rgb(94, 206, 197)'
      }]
    }

    const minData = prevTrx.length > 0 ? prevTrx.filter((v, idx) => idx === 0)[0] : { amt: 0 };
    const maxData = prevTrx.length > 0 ? prevTrx.filter((v, idx) => idx === prevTrx.length - 1)[0] : { amt: 0 };
    const scoreType = maxData.amt > minData.amt ? 'good' : 'bad';
    const percent = prevTrx.length > 0 ? Math.round((maxData.amt - minData.amt) * 100 / maxData.amt) : 0;

    let graphList = new Array();

    prevTrx.map(v => {
      const date = v.trxDate;

      const yyyy = date.substr(2, 2).replace('-', '');
      const mm = date.substr(4, 2).replace('-', '');

      const targetDateStr = `${yyyy}. ${mm}`;
      graphList.push({ 'trxDate': targetDateStr, 'amt': v.amt, 'counts': '억원', 'amtCounts': numberWithCounts(v.amt * 10000) });
      //graphList.push({ 'trxDate': targetDateStr, 'amt': (v.amt / 10000), 'amtCounts': numberWithCounts(v.amt * 10000) });
    });

    return (
      <>
        <div className="sec" id="sec8">
          <h4>
            <strong>{bjdInfo.bjdName} 지역 {Math.round(applyDtl.hsArea * 0.3025)}평대</strong> 실거래가
            <div className="q_mark bt">
              <div className="q_mark_inner">
                <p className="tit">실거래가란?</p>
                <p className="txt">
                  청약 아파트 지역의 최근 1년간의 실거래가 발생한 아파트 정보를 안내해드려요.<br />
                  해당 정보를 바탕으로, 청약 아파트가 위치한 지역의 시세가 상승 중인지, 하락 중인지를 확인 할 수 있어요.
                </p>
              </div>
            </div>
          </h4>
          <div className={`ty_graph ${scoreType}`}>
            <div>
              <div className="graph">
                <div className="dount_area">
                  <div className="circle_chart full" data-percent="100" data-permax="100">
                    <div className="chart-box">
                      <svg>
                        <defs>
                          <linearGradient id={`dount_${scoreType}`} x1="0" y1="0" x2="1" y2="1">
                            <stop className="color_1" offset="0%" />
                            <stop className="color_2" offset="100%" />
                          </linearGradient>
                        </defs>
                        <circle stroke={`url(#dount_${scoreType})`} r="49%" cx="50%" cy="50%" className="circle_progress" stroke-width="15" stroke-linecap="round"></circle>
                      </svg>
                      <div className="per-point per"><span><s>{percent > 0 ? `+ ${Math.abs(percent)}%` : `- ${Math.abs(percent)}%`}</s></span></div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div>
              <div className="caption emoji">
                <p>
                  {applyInfo.aptName}가 위치하는 {bjdInfo.bjdName} 지역은 <em>최근 1년간 아파트 시세가 {scoreType === 'good' ? '상승' : '하락'} 중</em>이에요.<br />
                  주변 아파트가 시세가 상승한다는 건 {scoreType === 'good' ? '긍정' : '부정'}적인 시그널로 해석 될 수 있어요.
                </p>
                {/* <strong>민앤지포레스트 : 10년 전매 제한 아파트</strong> */}
              </div>
              <table className="tb_apt ty02">
                <caption>
                  <strong>실거래 정보</strong>
                  <p>아파트, 분양권 실거래 정보와 1년대비 비교한 정보</p>
                </caption>
                <colgroup>
                  <col style={{ 'width': '20%' }} />
                  <col style={{ 'width': '26.6%' }} />
                  <col style={{ 'width': '26.6%' }} />
                  <col style={{ 'width': '26.6%' }} />
                </colgroup>
                <thead>
                  <tr>
                    <th></th>
                    <th>아파트 실거래</th>
                    <th>1년대비 비교</th>
                    <th>분양권 실거래</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <th>아파트명</th>
                    <td>{aptRecentAreaPyTrxData[0] ? aptRecentAreaPyTrxData[0].aptName : '-'}</td>
                    <td>{aptOneYearAgoAreaPyTrxData[0] ? aptOneYearAgoAreaPyTrxData[0].aptName : '-'}</td>
                    <td>{applyRecentAreaPyTrxData[0] ? applyRecentAreaPyTrxData[0].aptName : '-'}</td>
                  </tr>
                  <tr>
                    <th>전용면적</th>
                    <td>{aptRecentAreaPyTrxData[0] ? <><s>{aptRecentAreaPyTrxData[0].areaPy}</s>평</> : '-'}</td>
                    <td>{aptOneYearAgoAreaPyTrxData[0] ? <><s>{aptOneYearAgoAreaPyTrxData[0].areaPy}</s>평</> : '-'}</td>
                    <td>{applyRecentAreaPyTrxData[0] ? <><s>{applyRecentAreaPyTrxData[0].areaPy}</s>평</> : '-'}</td>
                  </tr>
                  <tr>
                    <th>최근 거래가</th>
                    <td>{aptRecentAreaPyTrxData[0] ? numberReplaceWithStag(numberWithCounts(aptRecentAreaPyTrxData[0].dpsAmt * 10000)) : '-'}</td>
                    <td>{aptOneYearAgoAreaPyTrxData[0] ? numberReplaceWithStag(numberWithCounts(aptOneYearAgoAreaPyTrxData[0].dpsAmt * 10000)) : '-'}</td>
                    <td>{applyRecentAreaPyTrxData[0] ? numberReplaceWithStag(numberWithCounts(applyRecentAreaPyTrxData[0].dpsAmt * 10000)) : '-'}</td>
                  </tr>
                  <tr>
                    <th>공고일</th>
                    <td>{aptRecentAreaPyTrxData[0] ? date2ReplaceWithStag(aptRecentAreaPyTrxData[0].useAprDay) : '-'}</td>
                    <td>{aptOneYearAgoAreaPyTrxData[0] ? date2ReplaceWithStag(aptOneYearAgoAreaPyTrxData[0].useAprDay) : '-'}</td>
                    <td>{applyRecentAreaPyTrxData[0] ? date2ReplaceWithStag(applyRecentAreaPyTrxData[0].notiDate) : '-'}</td>
                  </tr>
                  {/* <tr>
                    <th>거래가</th>
                    <td>-</td>
                    <td>변동없음</td>
                    <td>-</td>
                  </tr> */}
                </tbody>
              </table>
              <div id="rMateDiv1" className="chart" style={{ "width": "760px", "height": "400px" }}>
                {
                  this.props.onRenderGraph &&
                  <RMateColumnType
                    parentDivId={'rMateDiv1'}
                    graphList={graphList}
                  ></RMateColumnType>
                }
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default PrevTrx;
