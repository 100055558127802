import React, { Component } from 'react';
import Moment from 'moment';
import axios from 'axios';
import { uiCalcSelect, predictAnalyze, ChartCircle, uiAroundTab, uiAcc, uiPointPerBtn, popupOpen, popupClose, uiInfoPopSwiper01 } from 'common/Design';
import ApplyPredictDtl from './ApplyPredictDtl';
import ConstructRankList from './ConstructRankList';
import queryString from 'query-string';
import $ from 'jquery';

//// 컴포넌트 사용처 : 청약 당첨예상
class ApplyPredict extends Component {

  constructor(props) {
    super(props);
    this.state = {
      applyWishList: [], // 청약 희망 아파트 목록
      applyDtlList: [],  // 선택 아파트 상세 목록
      scoreCheckFlag: false,  // 청약가점점수 null값 여부 체크 flag
      score: {},
      applyInfo: {},
      applyDtl: {},
      calcResult: null,
      onRenderGraph: false,
    }
  }

  componentDidUpdate = () => {
    if (localStorage.getItem('jwtToken') == undefined || localStorage.getItem('jwtToken') == null) {
      window.location.href = '/login';
      window.close();
    }
  }

  componentDidMount = () => {
    const { search } = this.props.location;
    const queryObj = queryString.parse(search);

    // 나의 청약가점 가져오기 (DB)
    axios
      .get('apt/apply/applyCalc/getApplyMyScore', { headers: { 'Authorization': 'Bearer ' + localStorage.getItem('jwtToken') } })
      .then(res => {
        if(res.data.code === "E0000") {
          if(res.data.response != null) {
            $("#point").attr('readonly', 'readonly');
            this.setState({ score: { houseScore: res.data.response.houseScore, familyScore: res.data.response.familyScore, accountScore: res.data.response.accountScore, total: res.data.response.totalScore } });
            $('#point').attr('disabled', true);
          } else {
            // 저장된 내 가점점수가 없을 경우
            this.setState({ scoreCheckFlag: true });
          }
          uiCalcSelect.init();
        } else {
          alert(res.data.message);
          return false;
        } 
      })

    axios
      .get('/apt/apply/predict/applyWishList')
      .then(res => {
        this.setState({
          applyWishList: res.data.response
        }, () => {
          this.handleChangeApplyId(res.data.response.find(v => v.applyId === queryObj.applyId));
          if (queryObj.applyId) {
            $('#applyCalendar').click();
            $(`#applyId_${queryObj.applyId}`).click();
          }
        }
        )
      })

    // [2021-09-23] 안내 팝업 오픈
    if (localStorage.getItem('infoPop01Open') != 'false') {
      this.infoPopOpen();
    }
  }

  validApplyScore = (e) => {
    const max = e.target.getAttribute('maxLength');
    let value = e.target.value;

    if (value.length > max) value = value.slice(0, max);
    e.target.value = value.replace(/[^0-9]/g, '').replace(/(^0+)/, "");

    if (value > 84) {
      e.target.value = 84;
    }
    this.setState({ score: { total: e.target.value } });
  }

  handleChangeApplyId = applyInfo => {

    if (!applyInfo) return;

    axios
      .get(`/apt/apply/predict/applyDtlList?applyId=${applyInfo.applyId}`)
      .then(res => {
        this.setState({
          applyDtlList: res.data.response, applyInfo: applyInfo
        }, () => {
          $("#hsType").removeClass('in');
          $("#hsTypeDefaultMessage").text('희망 타입을 선택해주세요.');
          //uiCalcSelect.init();
        }
        );
      });
  }

  handleChangeApplyDtlId = applyDtl => {
    this.setState({ applyDtl: applyDtl });
  }

  submitApplyPredict = (e) => {

    if (!this.state.score || !this.state.score.total) {
      alert("청약 점수를 입력하세요.");
      $("#point").focus();
      e.stopPropagation();
      return false;
    }

    if (!this.state.applyInfo || !this.state.applyInfo.applyId) {
      alert('청약 희망 아파트를 선택하세요');
      return false;
    }
    if (!this.state.applyDtl || !this.state.applyDtl.applyDtlId) {
      alert('희망 타입을 선택하세요');
      return false;
    }
    axios
      .get(`/apt/apply/predict/calc?applyId=${this.state.applyInfo.applyId}&applyDtlId=${this.state.applyDtl.applyDtlId}`)
      .then(res => {

        this.setState({
          calcResult: res.data.response,
          onRenderGraph: true,
        }, () => {
          predictAnalyze.open();
          //ChartCircle.call();
          window.chartCall();
          //uiPointPerBtn.init();
          uiAroundTab.init();
          uiAcc.init();
          uiCalcSelect.init();
        }
        );
      });
  }

  moveApplyCalc = () => {
    window.location.href = "/applyCalc";
  }

  handleClosePredictDtl = (e) => {
    e.preventDefault();
    predictAnalyze.close();
    uiCalcSelect.init();
    this.setState({ calcResult: null, applyDtl: {} });
  }

  // [2021-09-23] 안내 팝업 오픈
  infoPopOpen = () => {
    popupOpen('saleinfo', 'mw', 0);
    uiInfoPopSwiper01.init();
  }

  // [2021-09-23] 안내 팝업 닫기
  infoPopClose = () => {
    popupClose('saleinfo', 'mw');
    //uiInfoPopSwiper01.destroy(false);
  }

  infoPopCloseNeverOpen = () => {
    localStorage.setItem('infoPop01Open', false);
    popupClose('saleinfo', 'mw');

  }

  render() {

    const totalScore = this.state.score.total;

    return (
      <>
        <div id="content">
          <div className="inner_wrap_ht">
            <div className="con_tit_wrap">
              <h2 className="tit">청약 당첨 예상</h2>
              <p className="sub_txt">고객님이 입력하신 가점 정보와 과거 청약결과 데이터, 주변정보를 조합하여 당첨 예상 가점을 산출합니다.</p>
            </div>
            <div className="bbs_wrap pointPer" >
              <div className="point_calculator point_ct on" style={{ 'display': this.state.calcResult ? 'none' : 'flex' }}>
                <div className="calc_list_wrap">
                  <section className="calc_form">
                    <ul className="form">
                      <li className="item col2">
                        <strong className="tit">나의 청약 가점</strong>
                        <div className="ip_wrap">
                          <div className="ip_box blk">
                            <label for="point" className="lbl" >청약 가점을 입력해주세요.</label>
                            <input id="point" type="text" autocomplete="off" className="ip" name="point" maxlength="2" value={totalScore} onChange={this.validApplyScore} required />
                            <i>점</i>
                          </div>
                        </div>
                        <div className="ip_col">
                          <div className="btn_inner">
                            <button type="button" className="btn_calc" onClick={this.moveApplyCalc} >청약가점계산</button>
                          </div>
                        </div>
                      </li>
                      <li className="item">
                        <strong className="tit">청약 희망 아파트</strong>
                        <div className="ip_wrap select_apt" >
                          <button className="btn_select" id="applyCalendar" >청약 일정을 확인해주세요.</button>
                          <div className="select_wrap" >
                            <div className="con">
                              <ul>
                                {
                                  this.state.applyWishList.map((v, idx) =>
                                    <li id={`applyId_${v.applyId}`} onClick={() => this.handleChangeApplyId(v)}>
                                      <span>{v.aptName}</span>
                                      <span>{Moment(v.notiDate).format('YY. MM. DD')}</span>
                                    </li>
                                  )
                                }
                              </ul>
                            </div>
                          </div>
                        </div>
                      </li>
                      <li className="item">
                        <strong className="tit">희망 타입</strong>
                        <div id="hsType" className="ip_wrap select_apt">
                          <button className="btn_select" id="hsTypeDefaultMessage">희망 타입을 선택해주세요.</button>
                          <div className="select_wrap">
                            <div className="con">
                              <ul>
                                {
                                  this.state.applyDtlList.map((v, idx) =>
                                    <li onClick={() => this.handleChangeApplyDtlId(v)}>
                                      <span>{v.hsType} ({Math.round(v.hsArea * 0.3025)}평형)</span>
                                    </li>
                                  )
                                }
                              </ul>
                            </div>
                          </div>
                        </div>
                      </li>
                      <li className="item">
                        <div className="btn_inner">
                          <button type="button"
                            className="dep_analyze"
                            onClick={this.submitApplyPredict} >청약 당첨 예상</button>
                        </div>
                      </li>
                    </ul>
                  </section>
                </div>
              </div>
              {
                this.state.calcResult &&
                <ApplyPredictDtl
                  scoreCheckFlag={this.state.scoreCheckFlag}
                  score={this.state.score}
                  calcResult={this.state.calcResult}
                  applyDtl={this.state.applyDtl}
                  closePredictDtl={this.handleClosePredictDtl}
                  onRenderGraph={this.state.onRenderGraph}
                ></ApplyPredictDtl>
              }
            </div>
          </div>
        </div>
        {
          this.state.calcResult &&
          <ConstructRankList
            calcResult={this.state.calcResult}
          ></ConstructRankList>
        }

        <div id="mw-saleinfo" className="ly_cpinfo_pc ly_info_swiper ndim">
          <div className="wrap">
            <div className="pop_info_swiper swiper-container swiper-container_apt">
              <div className="swiper-pagination"></div>
              <div className="swiper-wrapper">
                <section className="swiper-slide slide01">
                  <figure className="img_wrap st_1"></figure>
                  <div className="ani_wrap">
                    <h1 className="slide_tit">나의 <strong>청약가점</strong>을 알고 있다면<br /> <strong>직접 입력</strong>해주세요.</h1>
                    <p className="sub_txt">아파트청약케어에서 가점을 계산하셨다면,<br />청약 가점이 자동으로 입력되요!</p>
                    <div className="form_ani">
                      <div className="box">
                        <p className="show01">청약 가점을 입력해주세요.</p>
                        <p className="show02"><code>50</code></p>
                        <em>점</em>
                      </div>
                    </div>
                    <div className="btn_wrap">
                      <button type="button" className="btn_next">다음<i className="cursor"></i></button>
                    </div>
                  </div>
                </section>
                <section className="swiper-slide slide02">
                  <figure className="img_wrap st_2"></figure>
                  <div className="ani_wrap">
                    <h1 className="slide_tit">청약가점을 입력하셨다면<br /> <strong>희망 아파트</strong>를 선택해주세요.</h1>
                    <p className="sub_txt">아파트청약케어에서는 청약 예정인 아파트만<br />청약당첨예상 확인이 가능해요.</p>
                    <div className="select_ani">
                      <div className="form_ani">
                        <div className="box">
                          <p className="show01">청약 희망 아파트를 선택해주세요.</p>
                          <p className="show02">민앤지포레나퍼스트시티</p>
                        </div>
                      </div>
                      <div className="selet_detail">
                        <div className="inner_wrap">
                          <button type="button" className="item">
                            <span>민앤지포레나퍼스트시티</span>
                            <code>2021. 08. 13</code>
                          </button>
                          <button type="button" className="item">
                            <span>민앤지힐스테이트</span>
                            <code>2021. 08. 13</code>
                          </button>
                        </div>
                      </div>
                    </div>
                    <div className="btn_wrap">
                      <button type="button" className="btn_next">다음<i className="cursor"></i></button>
                    </div>
                  </div>
                </section>
                <section className="swiper-slide slide03">
                  <figure className="img_wrap st_3"></figure>
                  <div className="ani_wrap">
                    <h1 className="slide_tit">아파트를 선택하셨다면,<br /> <strong>희망 타입</strong>을 선택해주세요.</h1>
                    <p className="sub_txt">아파트청약케어에서는 희망하는 타입(평수)에 따라<br />청약당첨예상 결과가 달라져요!</p>
                    <div className="select_ani">
                      <div className="form_ani">
                        <div className="box">
                          <p className="show01">청약 희망 아파트를 선택해주세요.</p>
                          <p className="show02">59.6481A / 25평형</p>
                        </div>
                      </div>
                      <div className="selet_detail">
                        <div className="inner_wrap">
                          <button type="button" className="item">
                            <span>105.3774A / 46평형</span>
                          </button>
                          <button type="button" className="item">
                            <span>59.6481A / 25평형</span>
                          </button>
                        </div>
                      </div>
                    </div>
                    <div className="btn_wrap">
                      <button type="button" className="btn_next" onClick={this.infoPopClose}>당첨예상 계산하기<i className="cursor"></i></button>
                    </div>
                  </div>
                </section>
              </div>
            </div>
            <button type="button" className="close" onClick={this.infoPopClose}><i>닫기</i></button>
            <button type="button" className="btn_txt" onClick={this.infoPopCloseNeverOpen}>다시보지않기</button>
          </div>
        </div>
      </>
    );
  }
}
export default ApplyPredict;