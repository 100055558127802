import React, { useEffect } from 'react'
import CommonComponent from './Common';
import $ from 'jquery'

window.jQuery = $;
window.jquery = $;
window.$ = $;

//// 컴포넌트 사용처 : 부동산뉴스
class RealtyNews extends CommonComponent {
  componentWillMount() {
    function receiveMessage(event) {
      if (event.origin.indexOf("portal.r114.co.kr") < 0) {
        return;
      }
      if (isNaN(event.data)) {
        return;
      }
      document.getElementById('iframe_con').style.height = String(parseInt(event.data)) + 'px';
      window.scrollTo(0, 0);
    }

    $(document).ready(function () {
      if ('addEventListener' in window) {
        window.addEventListener('message', receiveMessage, false);
      } else if ('attachEvent' in window) {
        window.attachEvent('onmessage', receiveMessage);
      }
    });
  }

  iframeNews() {
    return (
      <>
        <iframe
          src="https://portal.r114.co.kr/std/board/research_list.asp?only=8001&bno=200&nkind=7&newstype=11"
          title="부동산 뉴스 컨텐츠"
          id="iframe_con"
          frameBorder="0"
          scrolling="no"
          style={{
            'overflowX': 'hidden',
            'overflowY': 'auto',
            'width': 100 + '%',
            'height': 100 + '%',
            'minHeight': 1900 + 'px'
          }}
        ></iframe>
      </>
    );
  }
  render() {
    return (
      <div id="content">
        <div className="inner_wrap">
          <div className="con_tit_wrap">
            <h2 className="tit">부동산뉴스</h2>
          </div>
          <div className="bbs_wrap">
            <div className="iframe_wrap">
              {this.iframeNews()}
            </div>
          </div>
        </div>
      </div>
    );
  }
}
export default RealtyNews;