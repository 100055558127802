import React, { Component } from 'react';
import MapAptSrchDR from './MapAptSrchDR';
import MapAptSrchTrx from './MapAptSrchTrx';
import MapAptSrchPy from './MapAptSrchPy';
import MapAptSrchHhld from './MapAptSrchHhld';
import MapAptSrchApr from './MapAptSrchApr';
import MapAptSrchPk from './MapAptSrchPk';
import MapAptFav from './MapAptFav';
import { searchAptBjd, addRecentItem, getRecentItem, clearRecentItem, setRecentList, addLsItem, getLsItem, clearLsItem } from 'common/Search';
import SearchResult from 'routes/common/SearchResult';
import RecentResult from 'routes/common/RecentResult';
import Moment from 'moment';
import { findIndex } from 'common/ArrayUtil';
import _ from 'lodash';
import $ from 'jquery'
// import 'jquery-mousewheel'
// import 'malihu-custom-scrollbar-plugin'


//// 컴포넌트 사용처 : 아파트 정보
class MapAptSrch extends Component {

  constructor(props) {
    super(props);
    this.state = {
      isDR: false,
      isDeal: true,
      isTrx: false,
      isPy: false,
      isHhld: false,
      isApr: false,
      isPk: false,
      searchResult: [],
      searchIndex: -1,
      recentResult: [],
      recentFocus: false,
      keyword: "",
      saveFilter: false,
      loadFilter: false,
      searchVisible: true
    };

  }

  componentDidMount = () => {
    let aptSrchFilter = getLsItem('aptSrchFilter');
    if (aptSrchFilter && Object.keys(aptSrchFilter).length > 0) {

      this.props.onDR(aptSrchFilter.isDeal ? 'D' : 'R');

      this.setState({
        isDR: aptSrchFilter.isDR,
        isDeal: aptSrchFilter.isDeal,
        isTrx: aptSrchFilter.isTrx,
        isPy: aptSrchFilter.isPy,
        isHhld: aptSrchFilter.isHhld,
        isApr: aptSrchFilter.isApr,
        isPk: aptSrchFilter.isPk,
        loadFilter: true
      }, () => {
        this.setState({ loadFilter: false });
      });
    }

  }

  refresh = () => {
    this.setState({ isDR: false, isDeal: true, isTrx: false, isPy: false, isHhld: false, isApr: false, isPk: false });
    clearLsItem('aptSrchFilter', { isDR: false, isDeal: true, isTrx: false, isPy: false, isHhld: false, isApr: false, isPk: false });
    this.handleSearch({ type: "ALL", min: -1, max: -1, load: true });
  }

  visibleCondition = (type) => {

    switch (type) {
      case "TRX": this.setState({ isTrx: !this.state.isTrx }); break;
      case "PY": this.setState({ isPy: !this.state.isPy }); break;
      case "HHLD": this.setState({ isHhld: !this.state.isHhld }); break;
      case "APR": this.setState({ isApr: !this.state.isApr }); break;
      case "PK": this.setState({ isPk: !this.state.isPk }); break;
      default: break;
    }

    this.handleSearch({ type: type, min: -1, max: -1, load: true });
  }

  handleSearch = ({ type, min, max, load }) => {

    this.props.onChange({ type, min, max });
    load && this.props.onSrchLoad();
  }

  handleDR = (value) => {

    this.setState({ isDeal: value });
    this.props.onDR(value ? 'D' : 'R');
    this.props.onSrchLoad();
  }

  handleChangeKeyword = _.debounce((keyword) => {

    if (keyword) {
      searchAptBjd(keyword, (data) => {
        keyword && this.setState({ searchResult: data, recentResult: [], searchIndex: -1, recentFocus: false });
      })
    } else {
      let recentData = getRecentItem('apartMap');
      this.setState({ searchResult: [], recentResult: recentData, searchIndex: -1, recentFocus: true });
    }
  }, 300);

  debounceChangeKeyword = e => {

    const { target: { value } } = e;
    this.setState({ keyword: value, searchVisible: true });
    this.handleChangeKeyword(value);
  }

  handleKeydownKeyword = (e) => {

    let keyCode = e.keyCode;

    if (!this.state.searchResult.length > 0) {
      return;
    }
    if (keyCode === 38) {     // uparrowKey
      this.setState({ searchIndex: this.state.searchIndex <= 0 ? 0 : this.state.searchIndex - 1 });
    } else if (keyCode === 40) {     // downarrowKey
      let maxIndex = this.state.searchResult.length - 1;
      this.setState({ searchIndex: this.state.searchIndex >= maxIndex ? maxIndex : this.state.searchIndex + 1 });
    } else if (keyCode === 13) {      // enterKey
      if (this.state.searchIndex === -1) {
        this.handleSelectSearch(this.state.searchResult[0]);
      } else {
        let search = this.state.searchResult[this.state.searchIndex];
        this.handleSelectSearch(search);
      }
    }
  }

  handleClearRecent = () => {
    clearRecentItem('apartMap');
    this.setState({ recentResult: [] });
  }

  handleRemoveRecent = (searchId) => {
    let recentList = getRecentItem('apartMap');
    if (recentList.some(f => f.searchId === searchId)) {
      recentList.splice(findIndex(recentList, f => f.searchId === searchId), 1);
    }
    setRecentList('apartMap', recentList);
    this.setState({ recentResult: recentList });
  }

  handleSelectSearch = (search) => {
    search.regTime = Moment(new Date()).format('MM. DD');
    addRecentItem('apartMap', search);

    searchAptBjd(search.searchName, (data) => {
      search.searchName && this.setState({ searchResult: data, keyword: search.searchName, searchVisible: false });
    })

    this.props.onSearch(search);
  }

  handleSaveFilter = () => {

    addLsItem('aptSrchFilter', {
      isDR: this.state.isDR,
      isDeal: this.state.isDeal,
      isTrx: this.state.isTrx,
      isPy: this.state.isPy,
      isHhld: this.state.isHhld,
      isApr: this.state.isApr,
      isPk: this.state.isPk
    });

    this.setState({ saveFilter: true }, () => {
      this.setState({ saveFilter: false });
    });
  }

  handleGotoDtl = (e, aptId, aptAreaId) => {
    e.preventDefault();
    this.props.onGotoDtl(aptId, aptAreaId);
  }

  handleClickSearch = (e) => {
    e && e.preventDefault();
    if (this.state.searchResult.length > 0) {
      this.handleSelectSearch(this.state.searchResult[0]);
    }
  }

  render() {
    const { isDR, isDeal, isTrx, isPy, isHhld, isApr, isPk, searchResult, searchIndex, recentResult, recentFocus, keyword, searchVisible } = this.state;

    return (
      <div className={this.props.pathname === "/" ? "panel_fold" : "panel_fold on"}>
        <div className="header_wrap">
          <button type="button" className={this.props.pathname === "/" ? "btn_fold" : "btn_fold on"} style={{ "display": "block" }}><i>접기</i></button>
          <h2>아파트 정보</h2>
        </div>
        <div className="content_fixed">
          <div className="ip_search">
            <div className="search_box focus">
              <input type="text" autocomplete="off" id="search_box01" required
                onChange={this.debounceChangeKeyword}
                onFocus={this.debounceChangeKeyword}
                onKeyDown={this.handleKeydownKeyword}
                value={keyword}
              />
              <label for="search_box01">아파트 또는 지역을 검색해 주세요.</label>
              {
                recentFocus ?
                  <RecentResult
                    recentResult={recentResult}
                    onClearRecent={this.handleClearRecent}
                    onRemoveRecent={this.handleRemoveRecent}
                    onSelectRecent={this.handleSelectSearch}
                  ></RecentResult>
                  : <SearchResult
                    searchResult={searchResult}
                    searchIndex={searchIndex}
                    searchKeyword={keyword}
                    searchVisible={searchVisible}
                    onSelectSearch={this.handleSelectSearch}></SearchResult>
              }
            </div>
            <button type="button"
              className="btn_search"
              onClick={this.handleClickSearch}
            ><i>검색</i></button>
          </div>
          <div className="list_filter">
            <div className="filter_upper">
              <button type="button"
                className={`${isDR ? "btn_first _set" : "btn_first"}`}
                onClick={() => this.setState({ isDR: !this.state.isDR })}>거래유형
              </button>
              <button type="button"
                className={`${isTrx ? "btn_first _set" : "btn_first"}`}
                onClick={() => this.visibleCondition("TRX")}>실거래가
              </button>
              <button type="button"
                className={`${isPy ? "btn_first _set" : "btn_first"}`}
                onClick={() => this.visibleCondition("PY")}>평형
              </button>
              <button type="button"
                className={`${isHhld ? "_set" : ""}`}
                onClick={() => this.visibleCondition("HHLD")}>세대수
              </button>
              <button type="button"
                className={`${isApr ? "_set" : ""}`}
                onClick={() => this.visibleCondition("APR")}>입주년차
              </button>
              <button type="button"
                className={`${isPk ? "_set" : ""}`}
                onClick={() => this.visibleCondition("PK")}>주차대수
              </button>
            </div>
            <div className="filter_control">
              <div className="item_star">
                <div className="check_form">
                  <input type="checkbox" className="hide check-one" id="star"
                    defaultChecked={this.props.favDisplay}
                    onClick={this.props.onFavResult} />
                  <label for="star">즐겨찾기</label>
                </div>
              </div>
              <div className="item_save">
                <div class="check_form">
                  <input type="checkbox" class="hide check-one" id="save"
                    onClick={this.handleSaveFilter} />
                  <label for="save">필터저장</label>
                </div>
              </div>
              <div class="item_reset">
                <p onClick={this.refresh} ><i ></i>선택 초기화</p>
              </div>
            </div>
          </div>
        </div>
        <div className="content_list">{
          this.props.favDisplay ?
            <div class="list_area bookmark">
              <div class="scroll_area mCustomScrollbar" data-mcs-theme="minimal">
                <div id="scroll_div_fav">
                  <MapAptFav
                    favResult={this.props.favResult}
                    onClearFav={this.props.onFavClear}
                    onMoveDtl={this.handleGotoDtl}
                  ></MapAptFav>
                </div>
              </div>
            </div>
            :
            <div className="filter_area">
              <div className="scroll_area mCustomScrollbar" data-mcs-theme="minimal">
                <div className="scroll">
                  {isDR ? <MapAptSrchDR onDR={this.handleDR} isDeal={isDeal} ></MapAptSrchDR> : null}
                  {isTrx ?
                    <MapAptSrchTrx
                      onSearch={this.handleSearch}
                      saveFilter={this.state.saveFilter}
                      loadFilter={this.state.loadFilter}
                    ></MapAptSrchTrx> : null}
                  {isPy ? <MapAptSrchPy
                    onSearch={this.handleSearch}
                    saveFilter={this.state.saveFilter}
                    loadFilter={this.state.loadFilter}
                  ></MapAptSrchPy> : null}
                  {isHhld ? <MapAptSrchHhld
                    onSearch={this.handleSearch}
                    saveFilter={this.state.saveFilter}
                    loadFilter={this.state.loadFilter}
                  ></MapAptSrchHhld> : null}
                  {isApr ? <MapAptSrchApr
                    onSearch={this.handleSearch}
                    saveFilter={this.state.saveFilter}
                    loadFilter={this.state.loadFilter}
                  ></MapAptSrchApr> : null}
                  {isPk ? <MapAptSrchPk
                    onSearch={this.handleSearch}
                    saveFilter={this.state.saveFilter}
                    loadFilter={this.state.loadFilter}
                  ></MapAptSrchPk> : null}
                </div>
              </div>
            </div>
        }
        </div>
      </div>

    );
  }
}
export default MapAptSrch;