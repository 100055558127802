import React, { Component } from 'react';
import MapAptSrchHhld from './MapAptSrchHhld';
import MapAptSrchPy from './MapAptSrchPy';
import MapAptSrchTrx from './MapAptSrchTrx';
import MapApplySrchLocal from './MapApplySrchLocal';
import MapApplyFav from './MapApplyFav';
import axios from 'axios';
import $ from 'jquery';
import { searchApply, addRecentItem, getRecentItem, clearRecentItem, setRecentList, addLsItem, getLsItem } from 'common/Search';
import SearchResult from 'routes/common/SearchResult';
import RecentResult from 'routes/common/RecentResult';
import queryString from 'query-string';
import { orderBy } from 'lodash';
import { addLcApplyFavItem, getLcApplyFavList, clearLcApplyFavItem } from './MapAptFavUtil'
import { uiAptHeaderCustom, uiCardMcsScroll, callGaTag } from 'common/Design';
import { findIndex } from 'common/ArrayUtil';
import { dateDiffMsg, numberWithCounts, numberWithCommas, numberWithCommas2, dateFormatMsg, dateFormatMsg2, numberReplaceWithStag, textReplaceWithStag } from 'common/StringUtil';
import _ from 'lodash';

//// 컴포넌트 사용처 : 청약 정보
class MapApplySrch extends Component {

  constructor(props) {
    super(props);
    this.state = {
      isStatus: false,
      isLocal: false,
      isPy: false,
      isHhld: false,
      isTrx: false,
      isPk: false,
      searchResult: [],
      searchIndex: -1,
      recentResult: [],
      recentFocus: false,
      searchInfo: {},
      keyword: "",
      applyList: [], // 청약(분양정보)리스트
      applyDtl: [], // 청약(분양정보)상세
      status: "1",
      locals: [],
      pyMin: -1,
      pyMax: -1,
      trxMin: -1,
      trxMax: -1,
      hhldCntMin: -1,
      hhldCntMax: -1,
      saveFilter: false,
      loadFilter: false,
      favResult: [],
      favDisplay: false,
      isApply: true,
      limitListSize: 12,
      searchVisible: true
    };
  }

  componentDidMount = () => {
    this.refresh();

    let applySrchFilter = getLsItem('applySrchFilter');
    let applyStatusFilter = getLsItem('applyStatusFilter');

    if (applySrchFilter) {

      this.setState({
        isStatus: applySrchFilter.isStatus,
        isLocal: applySrchFilter.isLocal,
        isTrx: applySrchFilter.isTrx,
        isPy: applySrchFilter.isPy,
        isHhld: applySrchFilter.isHhld,
        loadFilter: true
      }, () => {
        this.setState({ loadFilter: false });
      });
      if (applySrchFilter.isStatus && applyStatusFilter) {
        this.setState({
          status: applyStatusFilter.filter
        });
      }
    }


    setTimeout(() => {
      const keyword = this.state.keyword;
      const status = this.state.status;
      const locals = this.state.locals;
      let pyMin = this.state.pyMin;
      let pyMax = this.state.pyMax;
      let trxMin = this.state.trxMin;
      let trxMax = this.state.trxMax;
      let hhldCntMin = this.state.hhldCntMin;
      let hhldCntMax = this.state.hhldCntMax;

      searchApply(keyword, status, locals, pyMin, pyMax, trxMin, trxMax, hhldCntMin, hhldCntMax, (data) => {
        let searchArr = [];
        this.setState({ searchResult: searchArr, recentResult: [], recentFocus: false, searchVisible: false });
        this.props.onRenderMarkerList({ data: (data.response.applyList.filter((v, i) => i < this.state.limitListSize)) });
      });
      uiCardMcsScroll.init();

    }, 600);


  }

  componentDidUpdate = () => {
    if (this.props.mapApplyDtl != null && this.props.mapApplyDtl.applyId != null && this.props.mapApplyDtl.applyId != this.state.applyDtl.applyId) {
      $('.btn_top').hide();
      this.setState({
        applyDtl: this.props.mapApplyDtl
      });
    }
  }

  menuClick = (url) => {

    //window.location.href = ('/' + url);
    if (url.search('applyPredict') > -1 || url.search('applyPay') > -1) {
      axios.get('apt/authorized/check',
        { headers: { 'Authorization': 'Bearer ' + localStorage.getItem('jwtToken') }, timeout: 1000 }).then(res => {
          if (res.data.response.isValid) {
            window.open(('/' + url), "_blank");
          }
          else {
            window.location.href = '/login';
          }
        }).catch(err => {
          if (localStorage.getItem('isAutoLogin') == 'true') {
            // 자동로그인 적용시 사용
            if (err.response.status === 401 || err.response.status === 403) {
              axios.post('apt/authorized/reIssue',
                { 'ref': localStorage.getItem('ref') },
                { headers: { 'Authorization': 'Bearer ' + localStorage.getItem('jwtToken') } }).then(res => {
                  if (res.status === 200) {
                    localStorage.setItem('jwtToken', res.headers.authorization);
                    window.open(('/' + url), "_blank");
                  }
                }).catch(err => {
                  window.location.href = '/login';
                });
            }
            else {
              window.location.href = '/login';
            }
          }
          else {
            window.location.href = '/login';
          }
        });
    }
    else {
      window.open(('/' + url), "_blank");
    }

  }

  refresh = () => {
    this.setState({ status: '1', isStatus: false, isLocal: false, isPy: false, isHhld: false, isTrx: false });
    this.handleSearch({ type: "ALL", min: -1, max: -1 });

    const keyword = '';
    const status = '1';
    const locals = [];
    searchApply(keyword, status, locals, -1, -1, -1, -1, -1, -1, (data) => {
      let searchArr = [];
      this.setState({ searchResult: searchArr, recentResult: [], recentFocus: false, searchVisible: false, applyList: data.response.applyList });
    });
    uiCardMcsScroll.init();

  }

  visibleCondition = (type) => {

    switch (type) {
      case "STATUS": this.setState({ isStatus: !this.state.isStatus }); break;
      case "LOCAL": this.setState({ isLocal: !this.state.isLocal }); break;
      case "PY": this.setState({ isPy: !this.state.isPy }); break;
      case "TRX": this.setState({ isTrx: !this.state.isTrx }); break;
      case "HHLD": this.setState({ isHhld: !this.state.isHhld }); break;
      default: break;
    }

    this.handleSearch({ type: type, min: -1, max: -1 });
  }

  handleSearch = ({ type, min, max }) => {
    setTimeout(() => {
      const keyword = this.state.keyword;
      const status = this.state.status;
      const locals = this.state.locals;
      let pyMin = this.state.pyMin;
      let pyMax = this.state.pyMax;
      let trxMin = this.state.trxMin;
      let trxMax = this.state.trxMax;
      let hhldCntMin = this.state.hhldCntMin;
      let hhldCntMax = this.state.hhldCntMax;

      if (type === 'PY') {
        this.setState({ pyMin: min, pyMax: max });
        pyMin = min;
        pyMax = max;
      }
      else if (type === 'TRX') {
        this.setState({ trxMin: min, trxMax: max });
        trxMin = min;
        trxMax = max;
      }
      else if (type === 'HHLD') {
        this.setState({ hhldCntMin: min, hhldCntMax: max });
        hhldCntMin = min;
        hhldCntMax = max;
      }


      searchApply(keyword, status, locals, pyMin, pyMax, trxMin, trxMax, hhldCntMin, hhldCntMax, (data) => {
        let searchArr = [];
        this.setState({ searchResult: searchArr, recentResult: [], recentFocus: false, searchVisible: false, applyList: data.response.applyList });
        this.props.onRenderMarkerList({ data: (data.response.applyList.filter((v, i) => i < this.state.limitListSize)) });
      });
      uiCardMcsScroll.init();

    }, 10);



    //this.props.onChange({type, min, max});
  }

  handleStatus = (value) => {


    this.setState({ status: value });
    const keyword = this.state.keyword;
    const locals = this.state.locals;
    const pyMin = this.state.pyMin;
    const pyMax = this.state.pyMax;
    const trxMin = this.state.trxMin;
    const trxMax = this.state.trxMax;
    const hhldCntMin = this.state.hhldCntMin;
    const hhldCntMax = this.state.hhldCntMax;
    if (value) {
      searchApply(keyword, value, locals, pyMin, pyMax, trxMin, trxMax, hhldCntMin, hhldCntMax, (data) => {
        let searchArr = [];
        value && this.setState({ searchResult: searchArr, recentResult: [], recentFocus: false, searchVisible: false, applyList: data.response.applyList });
        this.props.onRenderMarkerList({ data: (data.response.applyList.filter((v, i) => i < this.state.limitListSize)) });
      });
      uiCardMcsScroll.init();

    }
    this.props.onStatus(value);
  }

  handleLocals = (value) => {
    this.setState({ locals: value });
    const keyword = this.state.keyword;
    const status = this.state.status;
    const pyMin = this.state.pyMin;
    const pyMax = this.state.pyMax;
    const trxMin = this.state.trxMin;
    const trxMax = this.state.trxMax;
    const hhldCntMin = this.state.hhldCntMin;
    const hhldCntMax = this.state.hhldCntMax;
    if (value) {
      searchApply(keyword, status, value, pyMin, pyMax, trxMin, trxMax, hhldCntMin, hhldCntMax, (data) => {
        let searchArr = [];
        value && this.setState({ searchResult: searchArr, recentResult: [], recentFocus: false, searchVisible: false, applyList: data.response.applyList });
        this.props.onRenderMarkerList({ data: (data.response.applyList.filter((v, i) => i < this.state.limitListSize)) });
      });
      uiCardMcsScroll.init();

    }
    this.props.onLocals(value);
  }


  handleChangeKeyword = (e) => {

  }

  handleChangeKeyword = _.debounce((keyword) => {
    this.setState({ keyword: keyword });
    const status = this.state.status;
    const locals = this.state.locals;
    const pyMin = this.state.pyMin;
    const pyMax = this.state.pyMax;
    const trxMin = this.state.trxMin;
    const trxMax = this.state.trxMax;
    const hhldCntMin = this.state.hhldCntMin;
    const hhldCntMax = this.state.hhldCntMax;
    if (keyword) {
      searchApply(keyword, status, locals, pyMin, pyMax, trxMin, trxMax, hhldCntMin, hhldCntMax, (data) => {
        let searchArr = [];
        if ((data.response.applyList).length > 0) {
          (data.response.applyList).forEach(v => searchArr.push({ searchId: v.searchId, searchName: v.searchName }));
        }
        keyword && this.setState({ searchResult: searchArr, recentResult: [], searchIndex: -1, recentFocus: false, applyList: data.response.applyList });
        this.props.onRenderMarkerList({ data: (data.response.applyList.filter((v, i) => i < this.state.limitListSize)) });
      });
      uiCardMcsScroll.init();


    } else {
      let recentData = getRecentItem('applyMap');
      this.setState({ searchResult: [], recentResult: recentData, searchIndex: -1, recentFocus: true });
    }

  }, 300);

  debounceChangeKeyword = e => {

    const { target: { value } } = e;
    this.setState({ keyword: value, searchVisible: true, recentFocus: false })
    this.handleChangeKeyword(value);
  }

  handleBlurKeyword = (e) => {
    let recentData = getRecentItem('applyMap');
    this.setState({ searchResult: [], recentResult: recentData, searchIndex: -1, recentFocus: true });
  }

  handleClearRecent = () => {

    clearRecentItem('applyMap');
    this.setState({ recentResult: [] });
  }

  handleRemoveRecent = (searchId) => {
    let recentList = getRecentItem('applyMap');
    if (recentList.some(f => f.searchId === searchId)) {
      recentList.splice(findIndex(recentList, f => f.searchId === searchId), 1);
    }
    setRecentList('applyMap', recentList);
    this.setState({ recentResult: recentList });
  }

  handleSelectSearch = (search) => {
    addRecentItem('applyMap', search);
    this.setState({ searchInfo: search, keyword: search.searchName });

    const keyword = search.searchName;
    const status = this.state.status;
    const locals = this.state.locals;
    const pyMin = this.state.pyMin;
    const pyMax = this.state.pyMax;
    const trxMin = this.state.trxMin;
    const trxMax = this.state.trxMax;
    const hhldCntMin = this.state.hhldCntMin;
    const hhldCntMax = this.state.hhldCntMax;

    searchApply(keyword, status, locals, pyMin, pyMax, trxMin, trxMax, hhldCntMin, hhldCntMax, (data) => {
      this.setState({ applyList: data.response.applyList });
      this.props.onRenderMarkerList({ data: (data.response.applyList.filter((v, i) => i < this.state.limitListSize)) });
      this.props.onGotoFavApplyDtl(data.response.applyList[0].searchId);
    });
    uiCardMcsScroll.init();

  }

  handleKeydownKeyword = (e) => {
    let keyCode = e.keyCode;
    if (!this.state.searchResult.length > 0) {
      return;
    }
    if (keyCode === 38) {     // uparrowKey
      this.setState({ searchIndex: this.state.searchIndex <= 0 ? 0 : this.state.searchIndex - 1 });
    } else if (keyCode === 40) {     // downarrowKey
      let maxIndex = this.state.searchResult.length - 1;
      this.setState({ searchIndex: this.state.searchIndex >= maxIndex ? maxIndex : this.state.searchIndex + 1 });
    } else if (keyCode === 13) {      // enterKey
      if (this.state.searchIndex === -1) {
        this.setState({ searchResult: [], searchIndex: -1, recentFocus: false, searchVisible: false, keyword: this.state.searchResult[0].searchName });
        addRecentItem('applyMap', this.state.searchResult[0]);
        $('.apt_wrap').removeClass('dim');
        this.props.onGotoFavApplyDtl(this.state.searchResult[0].searchId);
      } else {
        let search = this.state.searchResult[this.state.searchIndex];
        this.setState({ searchResult: [], searchIndex: -1, recentFocus: false, searchVisible: false, keyword: search.searchName });
        addRecentItem('applyMap', search);
        $('.apt_wrap').removeClass('dim');
        this.props.onGotoFavApplyDtl(search.searchId);
      }
    }
  }

  fetchApplyDtl = (paramApplyId) => {
    $('.apt_wrap').removeClass('dim');
    $('.btn_top').hide();
    axios
      .get(`apt/apply/dtl?applyId=${paramApplyId}`)
      .then(res => {
        this.setState({
          applyDtl: res.data.response.applyDtl
        });
        this.props.onRenderMarker({ data: res.data.response.applyDtl });
        this.props.onPanTo({ lat: res.data.response.applyDtl.lat, lng: res.data.response.applyDtl.lng });

        setTimeout(() => {
          $('div.sub_header > h3').attr('data-tit', '최근 청약정보');
          $('div.sub_header > h3').text('최근 청약정보');
          uiAptHeaderCustom.init();
        }, 500);
      });

  }

  // 청약정보 > 아파트청약정보 > 최근 청약정보 청약클릭
  cardToggle = (e, applyId) => {
    var _cik = e.target,
      _move = $(_cik).parents('div[class^=panel_]'),
      _set = $(_cik).parents('div[class^=panel_]').width(),
      _panel = $('div[class^=panel_]').width(),
      _open = $('.btn_card'),
      _topBtn = $('.panel_card .btn_top');

    $(this).each(function (idx) {
      //_cik.on('click', function () {
      $('div[class^=panel_]').removeClass('on');
      _move.css('left', + -_set);
      _topBtn.hide();
      $('.apt_wrap').removeClass('dim');
      setTimeout(function () {
        _move.prev().css('left', - _panel + -1);
        _move.next().addClass('on');
      }, 400);
      //});

    });

    // GA Script 호출
    callGaTag('apply_' + applyId);
  }

  infoToggle = (e) => {
    e.stopPropagation();
    if ($($(e.target).parents(".toggle_tit")).hasClass('_on')) {
      $($(e.target).parents(".toggle_tit")).removeClass('_on').next().slideUp(200);
    } else {
      $($(e.target).parents(".toggle_tit")).addClass('_on').next().slideDown(200);
    }
  }

  moreView = function (e) {
    $(e.target).parent().toggleClass('show');
    $(e.target).parent().find('li').eq(2).nextAll().toggleClass('hide');
  }

  saveApplyFilter = () => {
    addLsItem('applyStatusFilter', { filter: this.state.status });

    addLsItem('applySrchFilter', {
      isStatus: this.state.isStatus,
      isLocal: this.state.isLocal,
      isTrx: this.state.isTrx,
      isPy: this.state.isPy,
      isHhld: this.state.isHhld
    });

    this.setState({ saveFilter: true }, () => {
      this.setState({ saveFilter: false });
    });
  }

  fetchApplyFavList = (favList) => {
    const param = favList.map(f => `applyId=${f.applyId}`).join('&');
    axios
      .get('/apt/map/getFavApplyList?' + param)
      .then(res => {
        let resData = res.data;
        resData.forEach(v => {
          if (favList.some(x => x.applyId === v.applyId)) {
            const itemIdx = findIndex(favList, x => x.applyId === v.applyId);
            v.favTime = favList[itemIdx].favTime;
          }
        });
        this.setState({ favResult: orderBy(resData, ["favTime"], ["desc"]) });
      });
  }

  handleFavResult = () => {
    const favList = getLcApplyFavList();
    if (!this.state.favDisplay) {
      this.fetchApplyFavList(favList);
    }
    this.setState({ favDisplay: !this.state.favDisplay });
  }

  handleAddFav = () => {
    const favList = getLcApplyFavList();
    this.fetchApplyFavList(favList);
  }

  handleClearFav = (fav) => {
    clearLcApplyFavItem(fav);
    const favList = getLcApplyFavList();
    this.fetchApplyFavList(favList);
  }

  handleFavCheck = (e) => {
    const { target: { checked } } = e;
    if (checked) {
      addLcApplyFavItem({ applyId: this.state.applyDtl.applyId, favTime: new Date() });
    } else {
      clearLcApplyFavItem({ applyId: this.state.applyDtl.applyId })
    }
    this.handleAddFav();
    //this.forceUpdate();
  }

  handleGotoFavApplyDtl = (e, applyId) => {
    e.preventDefault();
    this.props.onGotoFavApplyDtl(applyId);
  }

  btnCardNone = () => {
    $('.btn_card').addClass('none');
    setTimeout(() => {
      const keyword = this.state.keyword;
      const status = this.state.status;
      const locals = this.state.locals;
      let pyMin = this.state.pyMin;
      let pyMax = this.state.pyMax;
      let trxMin = this.state.trxMin;
      let trxMax = this.state.trxMax;
      let hhldCntMin = this.state.hhldCntMin;
      let hhldCntMax = this.state.hhldCntMax;

      searchApply(keyword, status, locals, pyMin, pyMax, trxMin, trxMax, hhldCntMin, hhldCntMax, (data) => {
        let searchArr = [];
        this.setState({ searchResult: searchArr, recentResult: [], recentFocus: false, searchVisible: false });
        this.props.onRenderMarkerList({ data: (data.response.applyList.filter((v, i) => i < this.state.limitListSize)) });
      });
      //uiCardMcsScroll.init();

    }, 300);
  }

  moreList = () => {
    let maxSize = (this.state.applyList.length >= this.state.limitListSize ? (this.state.limitListSize + 12) : this.state.applyList.length);
    this.props.onRenderMarkerList({ data: (this.state.applyList.filter((v, i) => i < maxSize)) });
    $('.card_area').removeClass('dim');
    this.setState({
      limitListSize: maxSize
    });
    //$('.card_area .scroll_area').animate({ scrollTop: $('.card_area .scroll_area ul').innerHeight() }, 200);
  }

  handleClickSearch = (e) => {
    e && e.preventDefault();
    if (this.state.searchResult.length > 0) {
      this.handleSelectSearch(this.state.searchResult[0]);
    }
  }

  scrollTop = () => {
    $('.card_area .scroll_area').mCustomScrollbar('scrollTo', 0, { scrollInertia: 10 });
  }

  render() {
    const { isStatus, isLocal, isPy, isHhld, isTrx, searchResult, recentResult, searchIndex, recentFocus, searchInfo, keyword, limitListSize, searchVisible } = this.state;
    const favorite = getLcApplyFavList().some(v => v.applyId === this.state.applyDtl.applyId);
    return (
      <>
        <div className="panel_fix">
          <div className="header_wrap">
            <h2>아파트 청약정보</h2>
            <button type="button" className="btn_fold on none"><i>닫기</i></button>
            <button type="button" className="btn_card none">청약리스트 다시보기</button>
          </div>
          <div className="content_fixed">
            <div className="ip_search">
              <div className="search_box focus">
                <input type="text" autocomplete="off" id="search_box01" required
                  onChange={this.debounceChangeKeyword}
                  onFocus={this.debounceChangeKeyword}
                  onKeyDown={this.handleKeydownKeyword}
                  value={keyword}
                />
                <label for="search_box01">청약정보를 검색해주세요.</label>
                {
                  recentFocus ?
                    <RecentResult
                      recentResult={recentResult}
                      onClearRecent={this.handleClearRecent}
                      onRemoveRecent={this.handleRemoveRecent}
                      onSelectRecent={(search) => this.handleSelectSearch(search)}
                    ></RecentResult>
                    : <SearchResult
                      searchResult={searchResult}
                      searchKeyword={keyword}
                      onKeyDown={this.handleKeydownKeyword}
                      searchIndex={searchIndex}
                      searchVisible={searchVisible}
                      onSelectSearch={(search) => this.handleSelectSearch(search)}></SearchResult>
                }
              </div>
              <button type="button" className="btn_search"
                onClick={this.handleClickSearch}><i>검색</i></button>
            </div>
            <div className="list_filter">
              <div className="filter_upper">
                <button type="button"
                  className={`${isStatus ? "btn_first _set" : "btn_first"}`}
                  onClick={() => this.visibleCondition("STATUS")}>진행상태
							</button>
                <button type="button"
                  className={`${isLocal ? "btn_first _set" : "btn_first"}`}
                  onClick={() => this.visibleCondition("LOCAL")}>전국
                            </button>
                <button type="button"
                  className={`${isTrx ? "btn_first _set" : "btn_first"}`}
                  onClick={() => this.visibleCondition("TRX")}>분양가
                            </button>
                <button type="button"
                  className={`${isPy ? "_set" : ""}`}
                  onClick={() => this.visibleCondition("PY")}>평형
                            </button>
                <button type="button"
                  className={`${isHhld ? "_set" : ""}`}
                  onClick={() => this.visibleCondition("HHLD")}>세대수
                            </button>
              </div>
              <div className="filter_control">
                <div className="item_star">
                  <div className="check_form">
                    <input type="checkbox" className="hide check-one" id="star"
                      defaultChecked={this.state.favDisplay}
                      onClick={this.handleFavResult} />
                    <label htmlFor="star">즐겨찾기</label>
                  </div>
                </div>
                <div className="item_save">
                  <div className="check_form">
                    <input type="checkbox" className="hide check-one" id="save" onClick={this.saveApplyFilter} />
                    <label htmlFor="save">필터저장</label>
                  </div>
                </div>
                <div className="item_reset">
                  <p onClick={this.refresh} ><i ></i>선택 초기화</p>
                </div>
              </div>
            </div>
          </div>
          <div className="content_list">
            {this.state.favDisplay ?
              <MapApplyFav
                favResult={this.state.favResult}
                onClearFav={this.handleClearFav}
                onMoveDtl={this.handleGotoFavApplyDtl}
              ></MapApplyFav>
              :
              <div className="filter_area">
                <div className="scroll_area mCustomScrollbar" data-mcs-theme="minimal">
                  <div class="scroll">
                    {
                      isStatus ?
                        <div className="filter_wrap">
                          <div className="tit_wrap">
                            <strong>진행상태</strong>
                            <span className="choice">{
                              this.state.status === '1' ? "전체" :
                                this.state.status === '2' ? "진행" :
                                  //this.state.status === '3' ? "예정" :
                                    this.state.status === '4' ? "완료" : ""
                            }</span>
                          </div>
                          <div className="filter_inner _line">
                            <button type="button" className={`${this.state.status === '1' ? "_set" : ""}`} onClick={() => this.handleStatus('1')}>전체</button>
                            <button type="button" className={`${this.state.status === '2' ? "_set" : ""}`} onClick={() => this.handleStatus('2')}>진행</button>
                            {/* <button type="button" className={`${this.state.status === '3' ? "_set" : ""}`} onClick={() => this.handleStatus('3')}>예정</button> */}
                            <button type="button" className={`${this.state.status === '4' ? "_set" : ""}`} onClick={() => this.handleStatus('4')}>완료</button>
                          </div>
                        </div>
                        :
                        null
                    }
                    {isLocal ?
                      <MapApplySrchLocal
                        onLocals={({ locals }) => this.handleLocals({ locals })}
                        saveFilter={this.state.saveFilter}
                        loadFilter={this.state.loadFilter}
                      ></MapApplySrchLocal> : null}
                    {isTrx ?
                      <MapAptSrchTrx
                        onSearch={(searchFilter) => this.handleSearch(searchFilter)}
                        saveFilter={this.state.saveFilter}
                        loadFilter={this.state.loadFilter}
                        isApply={this.state.isApply}
                      ></MapAptSrchTrx> : null}
                    {isPy ? <MapAptSrchPy
                      onSearch={(searchFilter) => this.handleSearch(searchFilter)}
                      saveFilter={this.state.saveFilter}
                      loadFilter={this.state.loadFilter}
                      isApply={this.state.isApply}
                    ></MapAptSrchPy> : null}
                    {isHhld ? <MapAptSrchHhld
                      onSearch={(searchFilter) => this.handleSearch(searchFilter)}
                      saveFilter={this.state.saveFilter}
                      loadFilter={this.state.loadFilter}
                      isApply={this.state.isApply}
                    ></MapAptSrchHhld> : null}
                  </div>
                </div>
              </div>
            }
          </div>
        </div>
        <div className="panel_card">
          <div className="header_wrap">
            <h3>최근 청약정보</h3>
            <p className="list_num">{this.state.applyList.length < limitListSize ? this.state.applyList.length : limitListSize}<em>건</em></p>
            <button type="button" className="btn_close"><i>돌아가기</i></button>
            <button type="button" className="btn_top" onClick={this.scrollTop}><i>위로</i></button>
          </div>
          <div className="content_list">
            <div className="card_area">
              <div className="scroll_area mCustomScrollbar" data-mcs-theme="minimal">
                <ul>
                  {
                    this.state.applyList.length <= 0 ?
                      <li>
                      </li>
                      :
                      this.state.applyList.map((result, idx) => {
                        return (
                          idx < limitListSize &&
                          <React.Fragment key={idx}>
                            <li className="card_item" onClick={() => this.fetchApplyDtl(result.applyId)}>
                              <span className={result.notiYn === 'N' ? 'date_soon' : (dateDiffMsg(result.pbshDate) == '완료' ? 'date_end' : 'date_ing')}>
                                {
                                  result.notiYn === 'N' ?
                                    '청약예정 '
                                    :
                                    (
                                      dateDiffMsg(result.notiDate) != '완료' ? <>모집공고 <em>{dateDiffMsg(result.notiDate)}</em></> :
                                        (
                                          result.spLocalDate != '' && dateDiffMsg(result.spLocalDate) != '완료' ? <>특별공급 <em>{dateDiffMsg(result.spLocalDate)}</em></> :
                                            (
                                              dateDiffMsg(result.fstLocalDate) != '완료' ? <>일반공급 <em>{dateDiffMsg(result.fstLocalDate)}</em></> :
                                                (
                                                  dateDiffMsg(result.pbshDate) != '완료' ? <>당첨자발표 <em>{dateDiffMsg(result.pbshDate)}</em></> :
                                                    '완료'
                                                )
                                            )
                                        )
                                    )
                                }
                              </span>
                              <div className="apt_card" id={"apt_card_" + result.applyId} onClick={(e) => this.cardToggle(e,result.applyId)}>
                                <div className="tit_wrap">
                                  <div className="tit_box">
                                    <h4>{result.aptName}</h4>
                                    {/* {(idx == 1 || idx == 7 || idx == 10 || idx == 11)
                                      && <span className="noti">인기</span>} */}
                                  </div>
                                  <p>{result.address}</p>
                                </div>
                                {
                                  result.notiYn === 'N' ?
                                    <div className="info_none">
                                      <div className="img_area">
                                        <figure>
                                          <img src={process.env.REACT_APP_ENV_URL + "resources/images/info_none_2.png"} alt="noti_img" />
                                        </figure>
                                        <p>아직 입주공고문이 올라오지 않았어요.</p>
                                      </div>
                                    </div>
                                    :
                                    <div className="info_wrap">
                                      <table className="tb_pri">
                                        <caption>
                                          <strong>청약 요약 정보</strong>
                                          <p>분양가, 세대, 면적 등 청약 핵심정보</p>
                                        </caption>
                                        <colgroup>
                                          <col style={{ "width": "30%" }} />
                                          <col style={{ "width": "70%" }} />
                                        </colgroup>
                                        <tbody>
                                          <tr>
                                            <th>분양가</th>
                                            <td className="_price">
                                              {numberReplaceWithStag(numberWithCounts(result.minApplyPrice * 10000))} ~ {numberReplaceWithStag(numberWithCounts(result.maxApplyPrice * 10000))}</td>
                                          </tr>
                                          <tr>
                                            <th>규제정보</th>
                                            <td>{
                                              (result.loanRateCase == 1 || result.loanRateCase == 2) ? '투기과열/투기지역' :
                                                (
                                                  (result.loanRateCase == 3) ? '조정대상지역' :
                                                    (
                                                      (result.loanRateCase == 4) ? '조정대상지역외수도권' :
                                                        (result.loanRateCase == 5) ? '일반지역' : ''
                                                    )
                                                )
                                            }</td>
                                          </tr>
                                          <tr>
                                            <th>총 세대 / 청약세대</th>
                                            <td>{textReplaceWithStag(numberWithCommas(result.applyCnt))}세대</td>
                                          </tr>
                                          <tr>
                                            <th>전용면적</th>
                                            <td>{textReplaceWithStag(result.minHsTypePy)} ~ {textReplaceWithStag(result.maxHsTypePy)}평 ({textReplaceWithStag(result.minHsType)} ~ {textReplaceWithStag(result.maxHsType)}㎡)</td>
                                          </tr>
                                        </tbody>
                                      </table>
                                      <table className="tb_sec">
                                        <caption>
                                          <strong>청약 요약 정보</strong>
                                          <p>공고일 / 발표일 리스트</p>
                                        </caption>
                                        <colgroup>
                                          <col style={{ "width": "40%" }} />
                                          <col style={{ "width": "60%" }} />
                                        </colgroup>
                                        <tbody>
                                          <tr>
                                            <th>입주자모집공고일</th>
                                            <td><s>{dateFormatMsg(result.notiDate)} ({dateDiffMsg(result.notiDate)})</s></td>
                                          </tr>
                                          <tr>
                                            <th>특별공급 청약일</th>
                                            <td><s>{result.spLocalDate == '' ? '사업주체문의' : dateFormatMsg(result.spLocalDate) + ' (' + dateDiffMsg(result.spLocalDate) + ')'}</s></td>
                                          </tr>
                                          <tr>
                                            <th>일반공급 청약일</th>
                                            <td><s>{dateFormatMsg(result.fstLocalDate)} ({dateDiffMsg(result.fstLocalDate)})</s></td>
                                          </tr>
                                          <tr>
                                            <th>당첨자 발표일</th>
                                            <td><s>{dateFormatMsg(result.pbshDate)} ({dateDiffMsg(result.pbshDate)})</s></td>
                                          </tr>
                                        </tbody>
                                      </table>
                                    </div>
                                }
                              </div>
                              {
                                result.notiYn === 'Y' &&
                                <div className="btn_wrap">
                                  <button type="button" className="btn_point" onClick={() => this.menuClick('applyCalc?applyId=' + result.applyId)}>가점계산</button>
                                  <button type="button" className="btn_per" onClick={() => this.menuClick('applyPredict?applyId=' + result.applyId)}>청약당첨계산</button>
                                </div>
                              }
                            </li>
                          </React.Fragment>
                        )
                      })
                  }
                </ul>
              </div>
              {
                this.state.applyList.length <= limitListSize ?
                  <button type="button" className="btn_more" onClick={this.scrollTop}>위로</button>
                  :
                  <button type="button" className="btn_more" onClick={this.moreList}>더보기</button>
              }
            </div>
          </div>
        </div >
        <div className="panel_fold">
          <div className={this.state.applyDtl.notiYn === 'Y' ? "apt_info_header" : "apt_info_header no_keyword"}>
            <div className="sub_header">
              <button type="button" className="btn_back_w apt_card_back" onClick={this.btnCardNone}><i>돌아가기</i></button>
              <h3 data-tit="최근 청약정보">최근 청약정보</h3>
              <button type="button" className="btn_fold on"><i>닫기</i></button>
            </div>
            <div className="info_area">
              <div className="apt_name">
                <div className="tit">
                  <div className="check_form ck_star_w">
                    <input type="checkbox" className="hide check-one" id="star02"
                      checked={favorite}
                      onClick={this.handleFavCheck} />
                    <label for="star02"></label>
                  </div>
                  <h3>{this.state.applyDtl.aptName}</h3>
                </div>
                <p className="txt">{this.state.applyDtl.address}</p>
              </div>
              {
                this.state.applyDtl.notiYn === 'Y' &&
                <div className="keyword">
                  <span>{dateFormatMsg(this.state.applyDtl.notiDate)}</span>
                  <span>{numberWithCounts(this.state.applyDtl.maxApplyPrice * 10000)}</span>
                  <span>{numberWithCommas(this.state.applyDtl.applyCnt)}세대</span>
                  {
                    (this.state.applyDtl) &&
                      (this.state.applyDtl.loanRateCase == 1 || this.state.applyDtl.loanRateCase == 2) ?
                      <span>투기 과열</span>
                      :
                      (this.state.applyDtl.loanRateCase == 3 || this.state.applyDtl.loanRateCase == 4) ?
                        <span>조정 지역</span>
                        :
                        null
                  }
                </div>
              }
            </div>
          </div>
          <div className="content_list">
            <div className="aptinfo_area">
              <div className="scroll_area mCustomScrollbar" data-mcs-theme="minimal">
                {
                  this.state.applyDtl.notiYn === 'N' ?
                    <div className="info_none">
                      <div className="img_area">
                        <figure>
                          <img src={process.env.REACT_APP_ENV_URL + "resources/images/info_none_3.png"} alt="noti_img" />
                        </figure>
                        <p>아직 입주공고문이 올라오지 않았어요.</p>
                      </div>
                      <div class="btn_inner col_half">
                        <button type="button" className="btn" onClick={() => this.menuClick('applyPay')}>단계별 필요 금액</button>
                        <button type="button" className="btn" onClick={() => this.menuClick('applyCalc')}>청약 가점 확인</button>
                      </div>
                    </div>
                    :
                    <div className="info_list">
                      <div className="info_tit _on">
                        <p>청약정보</p>
                        <i>화살표</i>
                      </div>
                      <div className="info_con" style={{ "display": "block" }}>
                        <table>
                          <caption>
                            <strong>청약정보</strong>
                            <p>공고일, 일정, 정보등 청약에 관련된 정보</p>
                          </caption>
                          <colgroup>
                            <col style={{ "width": "50px" }} />
                            <col style={{ "width": "100px" }} />
                          </colgroup>
                          <tbody>
                            <tr>
                              <th>주소</th>
                              <td>{this.state.applyDtl.address}</td>
                            </tr>
                            {
                              dateDiffMsg(this.state.applyDtl.pbshDate) === '완료' ?
                                <tr>
                                  <th>입주예정일</th>
                                  <td className="_day">{dateFormatMsg(this.state.applyDtl.expectDate)}<span>{dateDiffMsg(this.state.applyDtl.expectDate)}</span></td>
                                </tr>
                                :
                                <tr>
                                  <th>모집공고일</th>
                                  <td className="_day">{dateFormatMsg(this.state.applyDtl.notiDate)}<span>{dateDiffMsg(this.state.applyDtl.notiDate)}</span></td>
                                </tr>
                            }
                          </tbody>
                        </table>
                        <div className="info_list_sub col3">
                          <div className="toggle_tit _on" onClick={this.infoToggle}>
                            <p>청약일정</p>
                            <i>화살표</i>
                          </div>
                          <div className="toggle_con" style={{ "display": "block" }}>
                            <table>
                              <caption>
                                <strong>청약일정</strong>
                                <p>순위나 계약일 등 일정에 관련된 테이블</p>
                              </caption>
                              <colgroup>
                                <col style={{ "width": "33.3%" }} />
                                <col style={{ "width": "33.3%" }} />
                                <col style={{ "width": "33.3%" }} />
                              </colgroup>
                              <thead>
                                <tr>
                                  <th>분류</th>
                                  <th>해당지역</th>
                                  <th>기타지역</th>
                                </tr>
                              </thead>
                              <tbody>
                                <tr>
                                  <td>특별공급</td>
                                  <td>{this.state.applyDtl.spLocalDate == '' ? '사업주체문의' : dateFormatMsg2(this.state.applyDtl.spLocalDate)}</td>
                                  <td>{this.state.applyDtl.spEtcDate == '' ? '사업주체문의' : dateFormatMsg2(this.state.applyDtl.spEtcDate)}</td>
                                </tr>
                                <tr>
                                  <td>1순위</td>
                                  <td>{dateFormatMsg2(this.state.applyDtl.fstLocalDate)}</td>
                                  <td>{dateFormatMsg2(this.state.applyDtl.fstEtcDate)}</td>
                                </tr>
                                <tr>
                                  <td>2순위</td>
                                  <td>{dateFormatMsg2(this.state.applyDtl.sndLocalDate)}</td>
                                  <td>{dateFormatMsg2(this.state.applyDtl.sndEtcDate)}</td>
                                </tr>
                                <tr>
                                  <td>당첨자발표일</td>
                                  {this.state.applyDtl.pbshDate == null ? <td>사업주체문의</td> : <td>{dateFormatMsg2(this.state.applyDtl.pbshDate)}</td>}
                                  {this.state.applyDtl.pbshDate == null ? <td>사업주체문의</td> : <td>{dateFormatMsg2(this.state.applyDtl.pbshDate)}</td>}
                                </tr>
                                <tr>
                                  <td>계약일</td>
                                  {this.state.applyDtl.contBeginDate == null ? <td>사업주체문의</td> : <td>{dateFormatMsg2(this.state.applyDtl.contBeginDate)}</td>}
                                  {this.state.applyDtl.contBeginDate == null ? <td>사업주체문의</td> : <td>{dateFormatMsg2(this.state.applyDtl.contBeginDate)}</td>}
                                </tr>
                                <tr>
                                  <td>입주예정일</td>
                                  {this.state.applyDtl.expectDate == null ? <td>사업주체문의</td> : <td>{dateFormatMsg2(this.state.applyDtl.expectDate)}</td>}
                                  {this.state.applyDtl.expectDate == null ? <td>사업주체문의</td> : <td>{dateFormatMsg2(this.state.applyDtl.expectDate)}</td>}
                                </tr>
                              </tbody>
                            </table>
                          </div>
                        </div>
                        {
                          (dateDiffMsg(this.state.applyDtl.pbshDate) != '완료')
                          &&
                          <div className="info_list_sub col5">
                            <div className="toggle_tit _on" onClick={this.infoToggle}>
                              <p>공급정보</p>
                              <i>화살표</i>
                            </div>
                            <div className="toggle_con" style={{ "display": "block" }}>
                              <table>
                                <caption>
                                  <strong>공급정보</strong>
                                  <p>유형, 평수, 공급수, 가격 등 방에 관련 된 정보들</p>
                                </caption>
                                <colgroup>
                                  <col style={{ "width": "20%" }} />
                                  <col style={{ "width": "20%" }} />
                                  <col style={{ "width": "20%" }} />
                                  <col style={{ "width": "20%" }} />
                                  <col style={{ "width": "20%" }} />
                                </colgroup>
                                <thead>
                                  <tr>
                                    <th>주택유형</th>
                                    <th>공급면적</th>
                                    <th>분양가</th>
                                    <th>특별공급</th>
                                    <th>일반공급</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {
                                    this.state.applyDtl.areaList &&
                                    (this.state.applyDtl.areaList.length <= 0 ?
                                      <tr>
                                      </tr>
                                      :
                                      this.state.applyDtl.areaList.map((result, idx) => {
                                        return (
                                          <React.Fragment key={idx}>
                                            <tr>
                                              <td>{result.hsType}</td>
                                              <td>{result.hsTypePy}평</td>
                                              <td>{numberWithCounts(result.applyPrice * 10000)}</td>
                                              <td>{result.spCnt}</td>
                                              <td>{result.geCnt}</td>
                                            </tr>
                                          </React.Fragment>
                                        )
                                      })
                                    )
                                  }
                                </tbody>
                              </table>
                              <span className="table_tag">* 분양가 최고가 기준</span>
                            </div>
                          </div>
                        }

                        {
                          (dateDiffMsg(this.state.applyDtl.pbshDate) == '완료')
                          &&
                          <div className="info_list_sub col5">
                            <div className="toggle_tit _on" onClick={this.infoToggle}>
                              <p>청약경쟁률</p>
                              <i>화살표</i>
                            </div>
                            <div className="toggle_con" style={{ "display": "block" }}>
                              <table>
                                <caption>
                                  <strong>청약완료시 필요한 정보</strong>
                                  <p>주택유형, 공급면적, 분양가(최고가 기준), 1순위 경쟁률</p>
                                </caption>
                                <colgroup>
                                  <col style={{ "width": "25%" }} />
                                  <col style={{ "width": "25%" }} />
                                  <col style={{ "width": "25%" }} />
                                  <col style={{ "width": "25%" }} />
                                </colgroup>
                                <thead>
                                  <tr>
                                    <th>주택유형</th>
                                    <th>공급면적</th>
                                    <th>분양가</th>
                                    <th>1순위경쟁률</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {
                                    this.state.applyDtl.areaList &&
                                    (this.state.applyDtl.areaList.length <= 0 ?
                                      <tr>
                                      </tr>
                                      :
                                      this.state.applyDtl.areaList.map((result, idx) => {
                                        return (
                                          <React.Fragment key={idx}>
                                            <tr>
                                              <td>{result.hsType}</td>
                                              <td>{result.hsTypePy}평</td>
                                              <td>{numberWithCounts(result.applyPrice * 10000)}</td>
                                              <td>{result.firstRate}</td>
                                            </tr>
                                          </React.Fragment>
                                        )
                                      })
                                    )
                                  }
                                </tbody>
                              </table>
                              <span className="table_tag">* 분양가 최고가 기준</span>
                            </div>
                          </div>
                        }
                        <div className="btn_inner">
                          <button type="button" className="btn_basic" onClick={() => this.menuClick('applyPredict')}>청약 당첨 예상 계산</button>
                        </div>
                      </div>
                    </div>
                }
                <div className="info_list">
                  <div className="info_tit _on">
                    <p>단지정보</p><i></i>
                  </div>
                  <div className="info_con apt_info" style={{ "display": "block" }}>
                    <table>
                      <caption>
                        <strong>아파트단지 정보 리스트</strong>
                        <p>주소, 건축년도 및 아파트 정보 리스트</p>
                      </caption>
                      <colgroup>
                        <col style={{ "width": "50px" }} />
                        <col style={{ "width": "100px" }} />
                      </colgroup>
                      <tbody>
                        <tr>
                          <th>주소</th>
                          <td>{this.state.applyDtl.address}</td>
                        </tr>
                        <tr>
                          <th>세대수</th>
                          <td>{numberWithCommas(this.state.applyDtl.applyCnt)}세대</td>
                        </tr>
                      </tbody>
                    </table>
                    <div className="btn_inner col_half">
                      <button type="button" className="btn" onClick={() => this.menuClick('applyPay')}>단계별 필요 금액</button>
                      <button type="button" className="btn" onClick={() => this.menuClick('applyCalc')}>청약 가점 확인</button>
                    </div>
                  </div>
                </div>
                <div className="info_list">
                  <div className="info_tit _on">
                    <p>주변정보</p><i></i>
                  </div>
                  <div className="info_con around" style={{ "display": "block" }}>
                    <ul className="amenities">
                      {
                        (this.state.applyDtl.stationList != null && this.state.applyDtl.stationList.length > 0) ?
                          this.state.applyDtl.stationList &&
                          <li className={this.state.applyDtl.stationList.length > 3 ? "amenities_items more" : "amenities_items"}>
                            <strong className="ico_subway">지하철역</strong>
                            <ul className="info">
                              {
                                orderBy(this.state.applyDtl.stationList, ["distance"], ["asc"]).map((result, idx) => {
                                  return (
                                    //idx < 3 && (    
                                    <li className={idx > 2 ? "hide" : ""}>
                                      <div className="name" onClick={() => this.props.onPanTo({ lat: result.lat, lng: result.lng })}><span>{result.locName}</span><s>({numberWithCommas(result.distance)}m)</s></div>
                                      <b className="time">도보 {parseInt(result.distance / 66, 10)} 분</b>
                                    </li>
                                    //)
                                  )
                                })
                              }
                            </ul>
                            {this.state.applyDtl.stationList.length > 3 && <i className="ico_more" onClick={this.moreView}></i>}
                          </li>
                          :
                          null
                      }
                      {
                        (this.state.applyDtl.schoolList != null && this.state.applyDtl.schoolList.length > 0) ?
                          this.state.applyDtl.schoolList &&
                          <li className={this.state.applyDtl.schoolList.length > 3 ? "amenities_items more" : "amenities_items"}>
                            <strong className="ico_school">학교</strong>
                            <ul className="info">
                              {
                                orderBy(this.state.applyDtl.schoolList, ["distance"], ["asc"]).map((result, idx) => {
                                  return (
                                    //idx < 3 && (
                                    <li className={idx > 2 ? "hide" : ""}>
                                      <div className="name" onClick={() => this.props.onPanTo({ lat: result.lat, lng: result.lng })}><span>{result.locName}</span><s>({numberWithCommas(result.distance)}m)</s></div>
                                      <b className="time">도보 {parseInt(result.distance / 66, 10)} 분</b>
                                    </li>
                                    //)
                                  )
                                })
                              }
                            </ul>
                            {this.state.applyDtl.schoolList.length > 3 && <i className="ico_more" onClick={this.moreView}></i>}
                          </li>
                          :
                          null
                      }
                      {
                        (this.state.applyDtl.departList != null && this.state.applyDtl.departList.length > 0) ?
                          this.state.applyDtl.departList &&
                          <li className={this.state.applyDtl.departList.length > 3 ? "amenities_items more" : "amenities_items"}>
                            <strong className="ico_mart">마트 / 백화점</strong>
                            <ul className="info">
                              {
                                orderBy(this.state.applyDtl.departList, ["distance"], ["asc"]).map((result, idx) => {
                                  return (
                                    //idx < 3 && (
                                    <li className={idx > 2 ? "hide" : ""}>
                                      <div className="name" onClick={() => this.props.onPanTo({ lat: result.lat, lng: result.lng })}><span>{result.locName}</span><s>({numberWithCommas(result.distance)}m)</s></div>
                                      <b className="time">도보 {parseInt(result.distance / 66, 10)} 분</b>
                                    </li>
                                    //)
                                  )
                                })
                              }
                            </ul>
                            {this.state.applyDtl.departList.length > 3 && <i className="ico_more" onClick={this.moreView}></i>}
                          </li>
                          :
                          null
                      }
                      {
                        (this.state.applyDtl.hospitalList != null && this.state.applyDtl.hospitalList.length > 0) ?
                          this.state.applyDtl.hospitalList &&
                          <li className={this.state.applyDtl.hospitalList.length > 3 ? "amenities_items more" : "amenities_items"}>
                            <strong className="ico_hospital">병원</strong>
                            <ul className="info">
                              {
                                orderBy(this.state.applyDtl.hospitalList, ["distance"], ["asc"]).map((result, idx) => {
                                  return (
                                    //idx < 3 && (
                                    <li className={idx > 2 ? "hide" : ""}>
                                      <div className="name" onClick={() => this.props.onPanTo({ lat: result.lat, lng: result.lng })}><span>{result.locName}</span><s>({numberWithCommas(result.distance)}m)</s></div>
                                      <b className="time">도보 {parseInt(result.distance / 66, 10)} 분</b>
                                    </li>
                                    //)
                                  )
                                })
                              }
                            </ul>
                            {this.state.applyDtl.hospitalList.length > 3 && <i className="ico_more" onClick={this.moreView}></i>}
                          </li>
                          :
                          null
                      }
                      {
                        (this.state.applyDtl.parkList != null && this.state.applyDtl.parkList.length > 0) ?
                          this.state.applyDtl.parkList &&
                          <li className={this.state.applyDtl.parkList.length > 3 ? "amenities_items more" : "amenities_items"}>
                            <strong className="ico_park">공원</strong>
                            <ul className="info">
                              {
                                orderBy(this.state.applyDtl.parkList, ["distance"], ["asc"]).map((result, idx) => {
                                  return (
                                    //idx < 3 && (
                                    <li className={idx > 2 ? "hide" : ""}>
                                      <div className="name" onClick={() => this.props.onPanTo({ lat: result.lat, lng: result.lng })}><span>{result.locName}</span><s>({numberWithCommas(result.distance)}m)</s></div>
                                      <b className="time">도보 {parseInt(result.distance / 66, 10)} 분</b>
                                    </li>
                                    //)
                                  )
                                })
                              }
                            </ul>
                            {this.state.applyDtl.parkList.length > 3 && <i className="ico_more" onClick={this.moreView}></i>}
                          </li>
                          :
                          null
                      }
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}
export default MapApplySrch;