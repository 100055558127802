import React, { Component } from 'react';
import { addLsItem, getLsItem } from 'common/Search';
import { findIndex } from 'common/ArrayUtil';
import $ from 'jquery';
import 'jquery-ui-bundle'
import { filtersNumberReplaceWithStag } from 'common/StringUtil';

//// 컴포넌트 사용처 : 아파트 추천
class MapAptRcmdSrchApr extends Component {

  constructor(props) {
    super(props);
    this.state = {
      aprList: [
        { name: "전체", visible: true, min: -1, max: 9999, minTxt: "전체", maxTxt: "전체" },
        { name: "1년 미만", visible: false, min: 0, max: 1, minTxt: "1년 미만", maxTxt: "1년 미만" },
        { name: "1 ~ 3년", visible: false, min: 1, max: 3, minTxt: "1", maxTxt: "3년 미만" },
        { name: "3 ~ 5년", visible: false, min: 3, max: 6, minTxt: "3", maxTxt: "6년 미만" },
        { name: "5 ~ 10년", visible: false, min: 6, max: 10, minTxt: "6", maxTxt: "10년 미만" },
        { name: "10 ~ 15년", visible: false, min: 10, max: 15, minTxt: "10", maxTxt: "15년 미만" },
        { name: "15 ~ 20년", visible: false, min: 15, max: 20, minTxt: "15", maxTxt: "20년 미만" },
        { name: "20년 이상", visible: false, min: 20, max: 9999, minTxt: "20년 이상", maxTxt: "20년 이상" },
      ],

      selectedMinIdx: 0,
      selectedMaxIdx: 0,
      slideMinTxt: '',
      slideMaxTxt: '',
    };
  }

  componentDidMount() {
    let self = this;

    $(".sliderApr").slider({
      range: true,
      min: 0,
      max: 21,
      values: [0, 21],
      slide: function (event, ui) {
        self.setState({
          selectedMinIdx: -1, selectedMaxIdx: -1,
          slideMinTxt: ui.values[0],
          slideMaxTxt: ui.values[1] == 21 ? '20년 이상' : ui.values[1] + '년 미만'
        });
      },
      stop: function (event, ui) {

        const current = self.state.aprList.slice();
        current.forEach(v => v.visible = false)
        self.setState(({ aprList: current }));
        self.props.onSearch({ type: 'APR', min: ui.values[0], max: ui.values[1] > 20 ? 9999 : ui.values[1], load: true });
      }
    });
    if (this.props.loadFilter) {

      let aptAprFilter = getLsItem('aptAprFilter');
      if (aptAprFilter.filter && aptAprFilter.sliderValue) {
        $(".sliderApr").slider("values", aptAprFilter.sliderValue);
        this.setState({ aprList: aptAprFilter.filter });

        if (aptAprFilter.filter.some(f => f.visible)) {
          const minValue = aptAprFilter.filter.filter(f => f.visible).reduce((p, c) => p > c ? c : p).min;
          const maxValue = aptAprFilter.filter.filter(f => f.visible).reduce((p, c) => p > c ? p : c).max;
          this.props.onSearch({ type: 'APR', min: minValue, max: maxValue, load: false });
        } else {
          this.props.onSearch({ type: 'APR', min: aptAprFilter.sliderValue[0], max: aptAprFilter.sliderValue[1], load: false });
        }
      }
    }
    else {
      if (this.props.isRcmd) {
        $(".sliderApr").slider("values", [-1, -1]);
        const current = this.state.aprList.slice();
        current.forEach(v => v.visible = false);
        this.setState({
          selectedMinIdx: -1,
          selectedMaxIdx: -1,
        });
      }
    }
  }

  componentDidUpdate() {
    if (this.props.saveFilter) {
      addLsItem('aptAprFilter', { filter: this.state.aprList, sliderValue: $(".sliderApr").slider("option", "values") });
    }
  }

  selectApr(idx) {

    const current = this.state.aprList.slice();
    const checkedCnt = current.filter(e => e.visible).length;
    let visibleIndex = findIndex(this.state.aprList, i => i.visible);

    let minc = 0;
    let maxc = 0;

    let minIdx = 0;
    let maxIdx = 0;

    if (visibleIndex !== 0 && checkedCnt === 1 && idx > visibleIndex) {
      current.filter((v, i) => i >= visibleIndex && i <= idx).forEach(v => v.visible = true);
      minc = current[visibleIndex].min;
      maxc = current[idx].max;

      minIdx = visibleIndex;
      maxIdx = idx;
    } else {
      current.forEach(c => c.visible = false);
      current[idx].visible = true;
      minc = current[idx].min;
      maxc = current[idx].max;

      minIdx = idx;
      maxIdx = idx;
    }

    this.props.onSearch({ type: 'APR', min: minc, max: maxc, load: true });
    $(".sliderApr").slider("values", [minc, maxc]);
    this.setState({ aprList: current, selectedMinIdx: minIdx, selectedMaxIdx: maxIdx });
  }

  render() {
    const { aprList, selectedMinIdx, selectedMaxIdx, slideMinTxt, slideMaxTxt } = this.state;
    let minTxt = selectedMinIdx != -1 ? aprList[selectedMinIdx].minTxt : '';
    let maxTxt = selectedMaxIdx != -1 ? aprList[selectedMaxIdx].maxTxt : '';
    return (
      <li className="filter_wrap">
        <div className="tit_wrap">
          {
            this.props.isRcmd === true ?
              <>
                <strong>입주년차</strong>
                <span className="choice cursor">{
                  (minTxt == '' && maxTxt == '' && slideMinTxt == '' && slideMaxTxt == '') ?
                    <><s className="yet">0</s> 년</>
                    :
                    (selectedMinIdx !== -1 && selectedMaxIdx !== -1) ?
                      (
                        maxTxt == "전체" ? "전체" :
                          (minTxt == maxTxt || minTxt == "1년 미만") ? filtersNumberReplaceWithStag(maxTxt) :
                            <>{filtersNumberReplaceWithStag(minTxt)} ~ {filtersNumberReplaceWithStag(maxTxt)}</>
                      )
                      : (selectedMinIdx === -1 && selectedMaxIdx === -1) && (
                        (slideMinTxt === slideMaxTxt || slideMinTxt === 0 || slideMinTxt > 20) ? filtersNumberReplaceWithStag(slideMaxTxt) :
                          <>{filtersNumberReplaceWithStag(slideMinTxt)} ~ {filtersNumberReplaceWithStag(slideMaxTxt)}</>
                      )
                }<i className="ico_fold"></i></span>
              </>
              :
              <h4>입주년차</h4>
          }
        </div>
        <div className="filter_inner _line">
          <div className="slider sliderApr"></div>
          <ul className="slider_lbl">
            <li>0년</li>
            <li>5년</li>
            <li>10년</li>
            <li>15년</li>
            <li>20년</li>
          </ul>
          {
            this.props.isRcmd === true ?
              <div className="btn_list">
                {(this.state.aprList.map((v, idx) =>
                  <button type="button"
                    className={v.visible ? "_set" : ""}
                    onClick={() => this.selectApr(idx)}>{v.name}
                  </button>))}
              </div>
              :
              this.state.aprList.map((v, idx) =>
                <button type="button"
                  className={v.visible ? "_set" : ""}
                  onClick={() => this.selectApr(idx)}>{v.name}
                </button>
              )
          }
        </div>
      </li>
    );
  }
}
export default MapAptRcmdSrchApr;