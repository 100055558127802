import React, { Component, Fragment } from 'react';

//import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import { numberWithCounts } from 'common/StringUtil';

var Highcharts = require('highcharts');
require('highcharts/highcharts-more')(Highcharts);
class HalfDonut extends Component {

  constructor(props) {
    super(props);
    this.state = {

    };
  }

  componentDidMount = () => {

  }

  componentDidUpdate = () => {

  }

  percenTageFix = (value) => {
    if (value >= 100) {
      return 100;
    }
    else if (value <= 0) {
      return 0;
    }
    else {
      return value;
    }
  }

  render() {
    const yearPay = this.yearPay;
    const assets = this.props.assets;
    const totalAssets = this.props.totalAssets;
    const dpsAmt = this.props.dpsAmt;
    const totalLoanAmount = this.props.totalLoanAmount;
    const realLoanAmount =
      (dpsAmt <= (assets + totalLoanAmount) ? ((dpsAmt - assets) <= 0 ? 0 : (dpsAmt - assets)) : totalLoanAmount);

    return (
      <div className="chart_loan">
        <div className="label" style={{ "z-index": "100" }}>
          <img src={process.env.REACT_APP_ENV_URL + "resources/images/chart_loan_label.png"} alt="임시 이미지" />
        </div>
        <div className="chart_info">
          <div className="chart_area">
            <Fragment>
              <HighchartsReact highcharts={Highcharts}
                options={{
                  chart: {
                    type: 'pie',
                    height: 120,
                    //backgroundColor:'black',
                  },
                  // chart : {
                  //     type : 'gauge',
                  //     height : 120
                  // },
                  title: {
                    text: '',
                  },
                  tooltip: {
                    enabled: false,
                  },
                  pane: {
                    center: ['75%', '115%'],
                    size: 120,
                    startAngle: -90,
                    endAngle: 90,
                    background: {
                      borderWidth: 0,
                      backgroundColor: 'none',
                      innerRadius: '60%',
                      outerRadius: '100%',
                      shape: 'arc'
                    }
                  },
                  yAxis: [{
                    lineWidth: 0,
                    min: 0,
                    max: dpsAmt,
                    minorTickLength: 0,
                    tickLength: 0,
                    tickWidth: 0,
                    labels: {
                      enabled: false
                    },

                  }],
                  plotOptions: {
                    pie: {
                      slicedOffset: 2,
                      size: 175,
                      dataLabels: {
                        enabled: false,
                      },
                      startAngle: -90,
                      endAngle: 90,
                      //center: ['87%', '160%'],
                      center: ['50%', '120%'],
                      showInLegend: false,
                    },
                    // gauge: {
                    //     dataLabels: {
                    //         enabled: false
                    //     },
                    //     dial: {
                    //         baseLength: 200,
                    //         borderColor: 'black',
                    //         borderWidth: 1,
                    //         radius: '100%',
                    //         rearLength: 0
                    //     },
                    // }
                  },
                  // legend: {
                  // 	align: 'right',
                  // 	layout: 'vertical',
                  // 	verticalAlign: 'top',
                  // 	x: 0,
                  // 	y: 0,
                  // 	symbolWidth:6,
                  // 	symbolHeight:6,
                  // 	itemStyle : {
                  // 		fontSize:'12px',
                  // 		fontWeight:400,
                  // 		color:'#999999',
                  // 	},
                  // },
                  series: [{
                    type: 'pie',
                    innerSize: '90%',
                    name: [''],
                    colors: ["#15beb9", "#ff493b"],
                    data: [
                      //['보유자산금액', assets],
                      {
                        name: '보유자산금액',
                        y: assets,
                        selected: true,
                        sliced: true,
                      },
                      {
                        name: '대출필요금액',
                        y: realLoanAmount,
                        selected: true,
                        sliced: true,
                      }
                    ],
                    // },{
                    //     type: 'gauge',
                    //     name : [''],
                    //     data: [assets],

                    // }],
                  }],
                }} />
            </Fragment>
            <div class="total_price">
              {/* <span><s>3</s>억 <s>3,200</s></span> */}
              <span>{numberWithCounts(dpsAmt)}</span>
              <p>최근 거래 금액</p>
            </div>
          </div>
          <div className="chart_label">
            <div className="percent">
              <span>{<><s>{this.percenTageFix(parseInt(Math.round((assets / dpsAmt) * 100)))}%</s></>}</span>
              <span>{<><s>{this.percenTageFix(parseInt(Math.round((realLoanAmount / dpsAmt) * 100)))}%</s></>}</span>
            </div>
            <div className="price">
              <span>{<><s>{numberWithCounts(assets)}</s></>}</span>
              <span>{<><s>{numberWithCounts(realLoanAmount)}</s></>}</span>
            </div>
          </div>
        </div>
      </div>

    );
  }
}
export default HalfDonut;