import React, { Component, Fragment } from 'react';

import { dateDiffMsg, numberWithCounts, numberTextReplaceWithEmtag } from 'common/StringUtil';


class RMateColumnMSType extends Component {

  constructor(props) {
    super(props);
    this.state = {

    };
  }

  componentDidMount = () => {
    setTimeout(() => { this.renderCharts(this.props.graphList) }, 100)
  }


  componentDidUpdate = () => {

  }

  renderCharts = (graphList) => {
    const parentDivId = this.props.parentDivId;
    const rMateChartH5 = window.rMateChartH5;

    // -----------------------차트 설정 시작-----------------------

    // rMateChart 를 생성합니다.
    // 파라메터 (순서대로)
    //  1. 차트의 id ( 임의로 지정하십시오. )
    //  2. 차트가 위치할 div 의 id (즉, 차트의 부모 div 의 id 입니다.)
    //  3. 차트 생성 시 필요한 환경 변수들의 묶음인 chartVars
    //  4. 차트의 가로 사이즈 (생략 가능, 생략 시 100%)
    //  5. 차트의 세로 사이즈 (생략 가능, 생략 시 100%)
    rMateChartH5.create(("RMateColumnMSType" + parentDivId), parentDivId, "", "100%", "100%");





    // rMateChartH5.calls 함수를 이용하여 차트의 준비가 끝나면 실행할 함수를 등록합니다.
    //
    // argument 1 - rMateChartH5.create시 설정한 차트 객체 아이디 값
    // argument 2 - 차트준비가 완료되면 실행할 함수 명(key)과 설정될 전달인자 값(value)
    // 
    // 아래 내용은 
    // 1. 차트 준비가 완료되면 첫 전달인자 값을 가진 차트 객체에 접근하여
    // 2. 두 번째 전달인자 값의 key 명으로 정의된 함수에 value값을 전달인자로 설정하여 실행합니다.
    if (parentDivId === 'rMateDiv0') {
      rMateChartH5.calls(("RMateColumnMSType" + parentDivId), {
        "setLayout": this.getChartBody('xAxis', 'yAxis1', '가점 최고점', 'rgb(225, 225, 225)', 'yAxis2', '내 가점', 'rgb(255, 73, 59)'),
        "setData": this.getChartData(graphList)
      });
    }
    else if (parentDivId === 'rMateDiv2') {
      rMateChartH5.calls(("RMateColumnMSType" + parentDivId), {
        "setLayout": this.getChartBody('yearMonth', 'inCnt', '전입', 'rgb(94, 206, 197)', 'outCnt', '전출', 'rgb(255, 73, 59)'),
        "setData": this.getChartData(graphList)
      });
    }

    // -----------------------차트 설정 끝 -----------------------
  }

  getChartBody = (cateFieldStr, yFieldStr1, disFieldStr1, yFieldColor1, yFieldStr2, disFieldStr2, yFieldColor2) => {

    // 스트링 형식으로 레이아웃 정의.
    var layoutStr =
      `<rMateChart backgroundColor="#FFFFFF" borderStyle="none">
        <Options>
          <Caption text=" "/>
        </Options>
        <NumberFormatter id="nft" useThousandsSeparator="true"/>
        <CurrencyFormatter id="cft" alignSymbol="right" currencySymbol="억원" />
        <Column2DChart showDataTips="true" ${disFieldStr1 === '전입' ? `dataTipJsFunction = "commonChartJsDataTip"` : ``} columnWidthRatio="0.2">
          <horizontalAxis>
            <CategoryAxis id="hAxis" categoryField="${cateFieldStr}"/>
          </horizontalAxis>
          <verticalAxis>
          <LinearAxis id="vAxis" baseAtZero="true" formatter="{nft}" />
          </verticalAxis>
          <series>
            <Column2DSeries labelPosition="outside" yField="${yFieldStr1}" displayName="${disFieldStr1}" showValueLabels="[]"
              fill="${yFieldColor1}" 
              itemRollOverColor="${yFieldColor1}" 
              itemSelectionColor="${yFieldColor1}">
              <showDataEffect>
                <SeriesInterpolate/>
              </showDataEffect>
            </Column2DSeries>
            <Column2DSeries labelPosition="outside" yField="${yFieldStr2}" displayName="${disFieldStr2}" showValueLabels="[]"
              fill="${yFieldColor2}" 
              itemRollOverColor="${yFieldColor2}" 
              itemSelectionColor="${yFieldColor2}">
              <showDataEffect>
                <SeriesInterpolate/>
              </showDataEffect>
            </Column2DSeries>
          </series>
          <horizontalAxisRenderers>
                <Axis2DRenderer placement="bottom" axis="{hAxis}" showLine="true" tickLength="2" />
              </horizontalAxisRenderers>
        </Column2DChart>
        <Box horizontalAlign="center" width="100%">
          <SubLegend direction="horizontal" borderStyle="none">
            <LegendItem fill="${yFieldColor1}" label="${disFieldStr1}" itemRenderer="CircleItemRenderer"/>
            <LegendItem fill="${yFieldColor2}" label="${disFieldStr2}" itemRenderer="CircleItemRenderer"/>
          </SubLegend>
        </Box>
      </rMateChart>`;

    return layoutStr;
  }

  getChartData = (graphList) => {
    // 차트 데이터
    var chartData =
      graphList;
    return chartData;
  }


  dataTipJsFunc = (seriesId, seriesName, index, xName, yName, data, values) => {

    // if (seriesName == "ROI") {
    //   return data['Month'] + "ROI: " + " < b > " + data['roi'] + " %</b > ";
    // }
    // else {
    //   return data['Month'] + "Sales: " + " < b > $" + data['sales'] + "M</b > ";
    // }
  }

  render() {


    return (
      <>

      </>

    );
  }
}
export default RMateColumnMSType;