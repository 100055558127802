import React, { Component } from 'react';
import ExptScore from './calc/ExptScore';
import ExptRate from './calc/ExptRate';
import BuildRank from './calc/BuildRank';
import RecentResult from './calc/RecentResult';
import LocMap from './calc/LocMap';
import PrevTrx from './calc/PrevTrx';
import PopInOut from './calc/PopInOut';
import PrevPoint from './calc/PrevPoint';
import PrevGeCnt from './calc/PrevGeCnt';
import AnaylyzePoint from './calc/AnaylyzePoint';
import MarginPrice from "./calc/MarginPrice";
import AptTrxMonthly from "./calc/AptTrxMonthly";

//// 컴포넌트 사용처 : 청약 당첨예상 상세
class ApplyPredictDtl extends Component {

  componentDidUpdate = () => {
    if (!localStorage.getItem('jwtToken')) {
        window.location.href = '/login';
        window.close();
    }
  }

  render() {
    const calcResult = { ...this.props.calcResult, 'applyDtl': this.props.applyDtl, 'score': this.props.score, 'scoreCheckFlag': this.props.scoreCheckFlag };

    console.log(calcResult);

    return (
        <div class="analyze_wrap">
            <div class="tit_upper">
                <div class="my_info_wrap">
                    <button type="button" onClick={ this.props.closePredictDtl } class="btn_close dep_analyze"><i class="hide">그래프 닫기</i></button>
                    <ul class="info_inner dep_analyze" onClick={ this.props.closePredictDtl }>
                        <li>
                            <span class="sub">나의 청약 가점</span>
                            <span class="main"><em>{ this.props.score.total }</em>점</span>
                        </li>
                        <li>
                            <span class="sub">청약 희망 아파트</span>
                            <span class="main">{ this.props.calcResult.applyInfo.aptName }</span>
                        </li>
                        <li>
                            <span class="sub">희망 타입</span>
                            <span class="main"><em>{ this.props.applyDtl.hsType }</em> / <em>{ Math.round(this.props.applyDtl.hsArea * 0.3025) }</em>평형</span>
                        </li>
                    </ul>
                </div>
            </div>
            <section>
                <ExptScore calcResult={ calcResult } />
                <ExptRate calcResult={ calcResult } />
            </section>

            <section><MarginPrice calcResult={ calcResult } /></section>

            <section><AnaylyzePoint calcResult={ calcResult } onRenderGraph={ this.props.onRenderGraph } /></section>

            <section><BuildRank calcResult={ calcResult } /></section>

            <section class="acc_wrap on">
                <h3 class="tit on">최근 청약 결과<i class="btn on"></i></h3>
                <div class="cont"><RecentResult calcResult={ calcResult } /></div>
            </section>

            <section class="acc_wrap on">
                <h3 class="tit on">청약 아파트 입지 분석<i class="btn on"></i></h3>
                <div class="cont"><LocMap calcResult={ calcResult } /></div>
            </section>

            <section class="acc_wrap on">
                <h3 class="tit on">주변 시세 / 과거 청약결과 / 청약 지역 전입 · 전출인구<i class="btn on"></i></h3>
                <div class="cont chart">
                    <PrevTrx calcResult={ calcResult } onRenderGraph={ this.props.onRenderGraph } />
                    <AptTrxMonthly calcResult={ calcResult } onRenderGraph={ this.props.onRenderGraph } />
                    <PopInOut calcResult={ calcResult } onRenderGraph={ this.props.onRenderGraph } />
                    <PrevPoint calcResult={ calcResult } onRenderGraph={ this.props.onRenderGraph } />
                    <PrevGeCnt calcResult={ calcResult } onRenderGraph={ this.props.onRenderGraph } />
                </div>
            </section>
        </div >
    );
  }
}

export default ApplyPredictDtl;