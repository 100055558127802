import React, { Component } from 'react';
import { addLsItem, getLsItem } from 'common/Search';
import { findIndex } from 'common/ArrayUtil';
import $ from 'jquery';
import 'jquery-ui-bundle'
import { filtersNumberReplaceWithStag } from 'common/StringUtil';

//// 컴포넌트 사용처 : 아파트 정보
class MapAptSrchHhld extends Component {
  constructor(props) {
    super(props);
    this.state = {
      hhldList: [
        { name1: "전체", name2: null, visible: true, min: -1, max: 99999, minTxt: "전체", maxTxt: "전체" },
        { name1: "100 ~ ", name2: "300세대", visible: false, min: 100, max: 300, minTxt: "100", maxTxt: "300세대 미만" },
        { name1: "300 ~ ", name2: "500세대", visible: false, min: 300, max: 500, minTxt: "300", maxTxt: "500세대 미만" },
        { name1: "500 ~ ", name2: "700세대", visible: false, min: 500, max: 700, minTxt: "500", maxTxt: "700세대 미만" },
        { name1: "700 ~ ", name2: "1000세대", visible: false, min: 700, max: 1000, minTxt: "700", maxTxt: "1000세대 미만" },
        { name1: "1000 ~ ", name2: "2000세대", visible: false, min: 1000, max: 2000, minTxt: "1000", maxTxt: "2000세대 미만" },
        { name1: "2000 ~ ", name2: "3000세대", visible: false, min: 2000, max: 3000, minTxt: "2000", maxTxt: "3000세대 미만" },
        { name1: "3000세대 이상", name2: null, visible: false, min: 3000, max: 99999, minTxt: "3000세대 이상", maxTxt: "3000세대 이상" }
      ],

      selectedMinIdx: 0,
      selectedMaxIdx: 0,
      slideMinTxt: '',
      slideMaxTxt: '',
    };
  }

  componentDidMount() {

    let self = this;

    $(".sliderHhld").slider({
      range: true,
      min: 0,
      max: 3001,
      values: [0, 3001],
      slide: function (event, ui) {
        self.setState({
          selectedMinIdx: -1, selectedMaxIdx: -1,
          slideMinTxt: ui.values[0],
          slideMaxTxt: ui.values[1] === 3001 ? '3000세대 이상' : ui.values[1] + '세대 미만'
        });
      },
      stop: function (event, ui) {

        const current = self.state.hhldList.slice();
        current.forEach(v => v.visible = false)
        self.setState({ hhldList: current });
        self.props.onSearch({ type: 'HHLD', min: ui.values[0], max: ui.values[1] > 3000 ? 9999 : ui.values[1], load: true });
      }
    });

    if (this.props.loadFilter) {
      let aptHhldFilter = null;
      if (this.props.isApply) {
        aptHhldFilter = getLsItem('applyHhldFilter');
      }
      else {
        aptHhldFilter = getLsItem('aptHhldFilter');
      }

      if (aptHhldFilter && aptHhldFilter.filter && aptHhldFilter.sliderValue) {
        $(".sliderHhld").slider("values", aptHhldFilter.sliderValue);
        this.setState({ hhldList: aptHhldFilter.filter });

        if (aptHhldFilter.filter.some(f => f.visible)) {
          const minValue = aptHhldFilter.filter.filter(f => f.visible).reduce((p, c) => p > c ? c : p).min;
          const maxValue = aptHhldFilter.filter.filter(f => f.visible).reduce((p, c) => p > c ? p : c).max;
          this.props.onSearch({ type: 'HHLD', min: minValue, max: maxValue, load: false });
        } else {
          this.props.onSearch({ type: 'HHLD', min: aptHhldFilter.sliderValue[0], max: aptHhldFilter.sliderValue[1], load: false });
        }
      }
    }
  }

  componentDidUpdate() {
    if (this.props.saveFilter) {
      if (this.props.isApply) {
        addLsItem('applyHhldFilter', { filter: this.state.hhldList, sliderValue: $(".sliderHhld").slider("option", "values") });
      }
      else {
        addLsItem('aptHhldFilter', { filter: this.state.hhldList, sliderValue: $(".sliderHhld").slider("option", "values") });
      }
    }
  }

  selectHhld(idx) {

    const current = this.state.hhldList.slice();
    const checkedCnt = current.filter(e => e.visible).length;
    let visibleIndex = findIndex(this.state.hhldList, i => i.visible);

    let minc = 0;
    let maxc = 0;

    let minIdx = 0;
    let maxIdx = 0;

    if (visibleIndex !== 0 && checkedCnt === 1 && idx > visibleIndex) {
      current.filter((v, i) => i >= visibleIndex && i <= idx).forEach(v => v.visible = true);
      minc = current[visibleIndex].min;
      maxc = current[idx].max;

      minIdx = visibleIndex;
      maxIdx = idx;
    } else {
      current.forEach(c => c.visible = false);
      current[idx].visible = true;
      minc = current[idx].min;
      maxc = current[idx].max;

      minIdx = idx;
      maxIdx = idx;
    }

    this.props.onSearch({ type: 'HHLD', min: minc, max: maxc, load: true });
    $(".sliderHhld").slider("values", [minc, maxc]);
    this.setState({ hhldList: current, selectedMinIdx: minIdx, selectedMaxIdx: maxIdx });
  }

  render() {
    const { hhldList, selectedMinIdx, selectedMaxIdx, slideMinTxt, slideMaxTxt } = this.state;
    let minTxt = selectedMinIdx != -1 ? hhldList[selectedMinIdx].minTxt : '';
    let maxTxt = selectedMaxIdx != -1 ? hhldList[selectedMaxIdx].maxTxt : '';
    return (
      <div className="filter_wrap">
        <div className="tit_wrap">
          <strong>세대수</strong>
          <span className="choice">{
            (selectedMinIdx !== -1 && selectedMaxIdx !== -1) ? (
              maxTxt == "전체" ? "전체" :
                minTxt == maxTxt ? filtersNumberReplaceWithStag(maxTxt) :
                  <>{filtersNumberReplaceWithStag(minTxt)} ~ {filtersNumberReplaceWithStag(maxTxt)}</>
            )
              : (selectedMinIdx === -1 && selectedMaxIdx === -1) && (
                (slideMinTxt === slideMaxTxt || slideMinTxt === 0 || slideMinTxt > 3000) ? filtersNumberReplaceWithStag(slideMaxTxt) :
                  <>{filtersNumberReplaceWithStag(slideMinTxt)} ~ {filtersNumberReplaceWithStag(slideMaxTxt)}</>
              )
          }</span>
        </div>
        <div className="filter_inner _line">
          <div className="slider sliderHhld"></div>
          <ul className="slider_lbl">
            <li>0</li>
            <li>750</li>
            <li>1500</li>
            <li>2250</li>
            <li>3000</li>
          </ul>
          {
            this.state.hhldList.map((v, idx) => {
              const vName = v.name2 == null ? <>{v.name1}</> : <>{v.name1} <br /> {v.name2}</>;
              return <button type="button" className={v.visible ? "_set" : ""} onClick={() => this.selectHhld(idx)}>
                {vName}
              </button>
            }
            )
          }
        </div>
      </div>
    );
  }
}
export default MapAptSrchHhld;