import React, { Component } from 'react';
import MapAptSrch from './MapAptSrch';
import MapAptDtl from './MapAptDtl';
import PopDiv from '../PopDiv';
import { addLsItem, getLsItem } from 'common/Search';
import { AptOverlay } from 'routes/common/overLay/AptOverlay.js';
import { BjdOverlay } from 'routes/common/overLay/BjdOverlay.js';
import { AroundOverlay } from 'routes/common/overLay/AroundOverlay.js';
import { getFavList, clearFavItem } from 'routes/map/MapAptFavUtil';
import axios from 'axios';
import queryString from 'query-string';
import $ from 'jquery';
import { findIndex } from 'common/ArrayUtil';
import { orderBy } from 'lodash';
import { uiAptHeaderCustom, callGaTag, popupClose } from 'common/Design';
import { Link } from 'react-router-dom';

//// 컴포넌트 사용처 : 아파트 정보
class MapApt extends Component {

  constructor(props) {
    super(props);
    this.map = {};
    this.state = {
      aptDtl: [],
      aptArea: {},
      trxDr: 'D',
      graphList: [],
      trxList: [],
      trxLimitSize: 10,
      favResult: [],
      favDisplay: false,
      onRenderGraph: false,
    };
    this.overlayList = [];
    this.aroundOverlayList = [];
    this.filter = {
      dr: 'D',
      trx: { trxMin: -1, trxMax: -1 },
      py: { pyMin: -1, pyMax: -1 },
      hhld: { hhldMin: -1, hhldMax: -1 },
      apr: { aprMin: -1, aprMax: -1 },
      pk: { pkMin: -1, pkMax: -1 }
    };
  }
  componentDidMount() {

    $('html').addClass('_full');

    // marker back
    $('.panel_marker .btn_back_w').click(() => {

      $('.panel_fold').addClass('on');
      $('.panel_marker').removeClass('on');
      $('.map_marker').removeClass('_active');
      $('.panel_marker').removeClass('_first');
    });

    // marker fold
    $('.panel_fold .btn_fold').click(() => {
      if ($('.panel_marker').hasClass('_first')) {
        $('.panel_fold').removeClass('on');
        $('.panel_marker').addClass('on');
      }
    });

    let aptLoc = getLsItem('aptLoc');
    let lat = aptLoc && aptLoc.lat ? aptLoc.lat : 37.57100;
    let lng = aptLoc && aptLoc.lng ? aptLoc.lng : 126.97696;

    let mapContainer = document.getElementById('mapview'); // 지도를 표시할 div 
    let mapOption = {
      center: new kakao.maps.LatLng(lat, lng), // eslint-disable-line
      level: 3
    };

    let map = new kakao.maps.Map(mapContainer, mapOption); // eslint-disable-line
    this.map = map;
    map.setMinLevel(2);
    map.setMaxLevel(11);

    kakao.maps.event.addListener(map, 'dragend', () => this.overlayMap(map)); // eslint-disable-line
    kakao.maps.event.addListener(map, 'zoom_changed', () => {  // eslint-disable-line

      this.clearOverlay();
      this.overlayMap(this.map);
    });

    const { search } = this.props.location;	// 문자열 형식으로 결과값이 반환된다.
    const queryObj = queryString.parse(search);	// 문자열의 쿼리스트링을 Object로 변환

    if (queryObj.aptId) {
      this.clickMarker(queryObj.aptId);
      $('.btn_back_w').off('click');

    } else {
      this.overlayMap(map);
    }
  }

  overlayMap = (map, callback) => {

    let param = `&trxMin=${this.filter.trx.trxMin}&trxMax=${this.filter.trx.trxMax}` +
      `&pyMin=${this.filter.py.pyMin}&pyMax=${this.filter.py.pyMax}` +
      `&hhldMin=${this.filter.hhld.hhldMin}&hhldMax=${this.filter.hhld.hhldMax}` +
      `&aprMin=${this.filter.apr.aprMin}&aprMax=${this.filter.apr.aprMax}` +
      `&pkMin=${this.filter.pk.pkMin}&pkMax=${this.filter.pk.pkMax}`;

    const latlng = map.getCenter();
    const bounds = map.getBounds();
    const sw = bounds.getSouthWest();   // 영역의 남서 좌표
    const ne = bounds.getNorthEast();   // 영역의 북동 좌표

    const getMapURL = `/apt/map/getMap?lat=${latlng.getLat()}&lng=${latlng.getLng()}&level=${map.getLevel()}` +
      `&swLat=${sw.getLat()}&swLng=${sw.getLng()}&neLat=${ne.getLat()}&neLng=${ne.getLng()}` +
      `${param}&trxType=${this.filter.dr ? this.filter.dr : ''}`;

    axios
      .get(getMapURL)
      .then(res => {
        if (res.data.code !== '0000') {
          alert('좌표 값이 정확하지 않습니다.\n재시도 부탁 드립니다.');

          return false;
        }

        addLsItem('aptLoc', { lat: res.data.lat, lng: res.data.lng });

        const positionList = [];

        res.data.list.forEach(v => {

          positionList.push(new kakao.maps.LatLng(v.lat, v.lng)); // eslint-disable-line
          if (this.overlayList.find(ov => ov.getPosition() && (ov.getPosition().getLat() === v.lat && ov.getPosition().getLng() === v.lng))) { // dragend 이벤트는 신규 마커만 가져온다.
            return;
          }

          let overlay = map.getLevel() < 5 ? new AptOverlay(v, map.getLevel()) : new BjdOverlay(v, map.getLevel());
          overlay.setMap(map);
          this.overlayList.push(overlay);

          map.getLevel() < 5 && $('#marker_' + v.markerId).click((e) => {
            e.preventDefault();
            this.clickMarker(v.markerId, e.currentTarget.dataset.areaId, this.filter.dr);
          });

          if (map.getLevel() >= 5) {
            this.aroundOverlayList.forEach(ov => ov.setMap(null));
            this.aroundOverlayList = [];
          }

          map.getLevel() >= 5 && map.getLevel() < 7 && $('#markerBjd_' + v.markerId).click(() => {
            let moveLatLng = new kakao.maps.LatLng($('#latBjd_' + v.markerId).val(), $('#lngBjd_' + v.markerId).val()); // eslint-disable-line
            map.setCenter(moveLatLng);
            map.setLevel(4);
          });

          map.getLevel() >= 7 && $('#markerBjd_' + v.markerId).click(() => {
            let moveLatLng = new kakao.maps.LatLng($('#latBjd_' + v.markerId).val(), $('#lngBjd_' + v.markerId).val()); // eslint-disable-line
            map.panTo(moveLatLng);
          });
        });

        this.overlayList.forEach((ov, idx) => {

          if (!positionList.find(p => ov.getPosition() && (p.getLat() === ov.getPosition().getLat() && p.getLng() === ov.getPosition().getLng()))) {
            this.overlayList.splice(idx, 1);
            ov && ov.getPosition() && ov.setMap(null);
          }
        });

        if (typeof callback === 'function') {
          callback();
        }

      })
  }

  clearOverlay = () => {
    this.overlayList.forEach(ov => ov.setMap(null));
    this.overlayList = [];
  }

  selectApt = (aptId) => {

    $('.map_marker').removeClass('_active');

    if ($('#marker_' + aptId).children().hasClass('type_apt')) {

      if ($('#marker_' + aptId).hasClass('_active')) {
        $('#marker_' + aptId).removeClass('_active');
        $('.panel_marker').removeClass('on');
      } else {
        $('#marker_' + aptId).addClass('_active');
        $('.panel_fold.on').removeClass('on');
        $('.panel_fold').find('.btn_fold').addClass('on');
        $('.panel_fold').removeClass('fold');
        $('.panel_fold').removeClass('now');
        $('.panel_marker').removeClass('fold');
        $('.panel_marker').addClass('_first');
        $('.panel_marker').addClass('on');
        $('.panel_marker .btn_fold').addClass('on');
      }
    }

  }

  // 아파트정보 > 마커 클릭
  clickMarker = (markerId, aptAreaId, trxType) => {

    //console.log(`clickMarker : ${markerId} ${aptAreaId} ${trxType}`);
    if (trxType == undefined) {
      trxType = 'D';
    }


    this.fetchAptDtl(markerId, trxType, (res) => {
      const aptDtl = res.data.response;
      let moveLatLng = new kakao.maps.LatLng(aptDtl.lat, aptDtl.lng); // eslint-disable-line
      this.map.setCenter(moveLatLng);
      this.map && this.overlayMap(this.map);
      this.renderingAroundDataIcons(aptDtl);

      this.fetchAptAreaDtl(markerId, aptAreaId, trxType, (res) => {
        this.setState({
          onRenderGraph: false,
        })
        const aptArea = res.data.response;
        //recentPy
        $(".select_apt .btn_select").html(`<s>${aptArea.pubAreaPy}</s>평 (<s>${aptArea.pubArea}</s>㎡)`);

        this.selectApt(markerId);
        $('ul.chart_tit').find('li').removeClass('current');
        $('ul.chart_tit').find('li').eq(0).addClass('current');
        $('.tab_cont').addClass('current');
        this.setState({
          aptDtl: aptDtl, aptArea: aptArea, trxDr: trxType, graphList: aptArea.graphDataList,
          trxList: aptArea.trxList, trxLimitSize: 10, onRenderGraph: true,
        });
      });

      // GA Script 호출
      callGaTag('apt_' + markerId);

      setTimeout(() => {
        $('div.sub_header > h3').attr('data-tit', '');
        $('div.sub_header > h3').text('');
        uiAptHeaderCustom.init();
      }, 500);
    })
  }

  fetchAptDtl = (aptId, trxType, callback) => {
    if (this.state.aptDtl && this.state.aptDtl.length > 0) return;
    else {
      axios
        .get(`apt/map/aptDtl?aptId=${aptId}&trxType=${trxType}`)
        .then(callback);
    }
  }

  fetchAptAreaDtl = (aptId, aptAreaId, trxType, callback) => {
    axios
      .get(`/apt/map/aptAreaDtl?aptId=${aptId}&aptAreaId=${aptAreaId}&trxType=${trxType}`)
      .then(callback);

  }

  numberWithCommas = (x) => {
    if (x === null || x === undefined || x === NaN) return;
    else {
      return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    }
  }

  handleChange = ({ type, min, max }) => {

    switch (type) {
      case "TRX": this.filter.trx = { trxMin: min, trxMax: max }; break;
      case "PY": this.filter.py = { pyMin: min, pyMax: max }; break;
      case "HHLD": this.filter.hhld = { hhldMin: min, hhldMax: max }; break;
      case "APR": this.filter.apr = { aprMin: min, aprMax: max }; break;
      case "PK": this.filter.pk = { pkMin: min, pkMax: max }; break;
      case "ALL": {
        this.filter = {
          dr: 'D',
          trx: { trxMin: -1, trxMax: -1 },
          py: { pyMin: -1, pyMax: -1 },
          hhld: { hhldMin: -1, hhldMax: -1 },
          apr: { aprMin: -1, aprMax: -1 },
          pk: { pkMin: -1, pkMax: -1 }
        }
        break;
      }
      default: break;
    }
  }

  handleDR = (value) => {
    this.filter.dr = value ? value : 'D';
    this.setState({ trxDr: this.filter.dr });
  }

  handleSrchLoad = () => {
    this.clearOverlay();
    this.map && this.overlayMap(this.map);
  }

  handleSearch = ({ searchId, searchType, lat, lng, recentD, recentR }) => {
    this.clearOverlay();
    let moveLatLng = new kakao.maps.LatLng(lat, lng); // eslint-disable-line
    this.map.setCenter(moveLatLng);

    this.map && this.overlayMap(this.map, () => {
      if (searchId != null && searchType === 'A') {
        let recentArr = null;
        if (this.state.trxDr == 'D') {
          recentArr = recentD.split('_');
        }
        else if (this.state.trxDr == 'R') {
          recentArr = recentR.split('_');
        }
        this.clickMarker(searchId, recentArr[1], recentArr[0]);
      }
    });
  }

  handleChangeArea = (aptId, aptAreaId, trxType) => {

    ////console.log(`${aptId}, ${aptAreaId}, ${trxType}`);

    this.fetchAptAreaDtl(aptId, aptAreaId, trxType, (res) => {
      this.setState({
        onRenderGraph: false,
      })
      const aptArea = res.data.response;
      $('ul.chart_tit').find('li').removeClass('current');
      $('ul.chart_tit').find('li').eq(0).addClass('current');
      $('.tab_cont').addClass('current');
      this.setState({
        aptArea: aptArea, trxDr: trxType, graphList: aptArea.graphDataList,
        trxList: aptArea.trxList, trxLimitSize: 10, onRenderGraph: true,
      });

    });
  }

  handlePanTo = ({ lat, lng }) => {
    let moveLatLng = new kakao.maps.LatLng(lat, lng); // eslint-disable-line
    this.map.panTo(moveLatLng);
  }

  comonentWillUnmount() {
    ////console.log('MAP_comonentWillUnmount');
    $('html').removeClass('_full');
  }

  renderingAroundDataIcons(aptDtl) {

    this.aroundOverlayList.forEach(ov => ov.setMap(null));
    this.aroundOverlayList = [];

    aptDtl.schoolList.forEach(v => {
      v.type = 'school';
      let overlay = new AroundOverlay(v);
      overlay.setMap(this.map);
      this.aroundOverlayList.push(overlay);
    });

    aptDtl.departList.forEach(v => {
      v.type = 'ico_mart';
      let overlay = new AroundOverlay(v);
      overlay.setMap(this.map);
      this.aroundOverlayList.push(overlay);
    });

    aptDtl.stationList.forEach(v => {
      v.type = 'ico_subway';
      let overlay = new AroundOverlay(v);
      overlay.setMap(this.map);
      this.aroundOverlayList.push(overlay);
    });

    aptDtl.hospitalList.forEach(v => {
      v.type = 'ico_hospital';
      let overlay = new AroundOverlay(v);
      overlay.setMap(this.map);
      this.aroundOverlayList.push(overlay);
    });

    aptDtl.parkList.forEach(v => {
      v.type = 'ico_park';
      let overlay = new AroundOverlay(v);
      overlay.setMap(this.map);
      this.aroundOverlayList.push(overlay);
    });

    $('.map_marker_icon').click((e) => this.handlePanTo(e.currentTarget.dataset));

  }

  getFavAptList = (favList) => {

    const param = favList.map(f => `aptId=${f.aptId}&aptAreaId=${f.aptAreaId}`).join('&');
    axios
      .get('/apt/map/getFavAptList?' + param)
      .then(res => {
        let resData = res.data;
        resData.forEach(v => {
          if (favList.some(x => x.aptId === v.aptId)) {
            const itemIdx = findIndex(favList, x => x.aptId === v.aptId);
            v.favTime = favList[itemIdx].favTime;
          }
        });
        this.setState({ favResult: orderBy(resData, ["favTime"], ["desc"]) });
      })
  }

  handleFavResult = () => {

    const favList = getFavList();
    ////console.log(favList);
    if (!this.state.favDisplay) {
      this.getFavAptList(favList);
    }
    this.setState({ favDisplay: !this.state.favDisplay });
  }

  handleAddFav = () => {
    const favList = getFavList();
    this.getFavAptList(favList);
  }

  handleClearFav = (fav) => {
    clearFavItem(fav);
    const favList = getFavList();
    this.getFavAptList(favList);
  }

  handleGraph = (aptAreaId, dataTab) => {
    this.setState({
      onRenderGraph: false,
    })
    axios
      .get(`apt/map/aptDtlGraphList?aptAreaId=${aptAreaId}&trxType=${this.state.trxDr}&graphTab=${dataTab}`)
      .then(
        (res) => {
          this.setState({ graphList: res.data.response.graphDataList, onRenderGraph: true, });
        }
      );
    $('.tab_cont').addClass('current');
  }

  setTrxLimitSize = (listSize) => {
    this.setState({
      trxLimitSize: listSize,
    })
  }

  render() {

    const { search } = this.props.location;	// 문자열 형식으로 결과값이 반환된다.
    const queryObj = queryString.parse(search);	// 문자열의 쿼리스트링을 Object로 변환

    return (<>
      <div id="content">
        <section class="apt_wrap">
          {

            (!queryObj.aptId) &&
            <MapAptSrch
              pathname={this.props.location.pathname}
              onChange={this.handleChange}
              onSrchLoad={this.handleSrchLoad}
              onDR={this.handleDR}
              onSearch={this.handleSearch}
              onGotoDtl={this.clickMarker}
              favDisplay={this.state.favDisplay}
              favResult={this.state.favResult}
              onFavResult={this.handleFavResult}
              onFavClear={this.handleClearFav}
            ></MapAptSrch>
          }
          <MapAptDtl
            pathname={this.props.location.pathname}
            queryAptId={queryObj.aptId}
            aptDtl={this.state.aptDtl}
            aptArea={this.state.aptArea}
            trxDr={this.state.trxDr}
            graphList={this.state.graphList}
            onAddFav={this.handleAddFav}
            onChangeArea={this.handleChangeArea}
            onPanTo={this.handlePanTo}
            onGraph={this.handleGraph}
            onRenderGraph={this.state.onRenderGraph}
            trxList={this.state.trxList}
            trxLimitSize={this.state.trxLimitSize}
            onTrxLimitSize={(limitSize) => this.setTrxLimitSize(limitSize)}
          />
        </section>
        <div className="map_img" id="mapview" ></div>
        {
          // [SOM.220530] 스타벅스 관심사 프로모션 '다시보지않기' 버튼 체크 조건 추가
          // localStorage.getItem('interestEventPop01Open') != 'false' &&
          // this.props.location.pathname === "/" ? <PopDiv /> : null
          
          // [SOM.220809] 메인접속시 팝업 띄우기 
          //this.props.location.pathname === "/" ? <PopDiv /> : null
        }

        {/* [SOM.230922] LGU+ 가입/해지 작업 공지 팝업 */}
        {/* <PopDiv /> */}

      </div>
      {/* 챗봇 - 2022.08.23 추가 */}
      <div id="mw-chat" className="ly_chat">
        <div className="cont">
          <button type="button" onClick={() => popupClose('chat', 'mwf')} className="popclose"></button>
          <a href="#" onClick={() => {window.Twc.Chat.open()}}><img src={process.env.REACT_APP_ENV_URL + "resources/images/ico_chat.png"} alt="챗봇" /></a>
        </div>
      </div>
      {/* //  챗봇 - 2022.08.23 추가 */}
    </>);
  }
}
export default MapApt;