import React, { Component, Fragment } from 'react';

//import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import { numberWithCounts } from 'common/StringUtil';
import $ from 'jquery';

var Highcharts = require('highcharts');
require('highcharts/highcharts-more')(Highcharts);
class Donut extends Component {

  constructor(props) {
    super(props);
    this.state = {

    };
  }

  componentDidMount = () => {
    $('.highcharts-container').css('width', '194');
    $('.highcharts-container').css('height', '133');
    Highcharts.charts.forEach(v => v != undefined && v.reflow());

    // $('.pct_wrap').eq(0).css('z-index', 1);
    // $('.pct_wrap').eq(0).css('left', '145px');
    // $('.pct_wrap').eq(0).css('top', '-15px');

    // $('.pct_wrap').eq(1).css('z-index', 1);
    // $('.pct_wrap').eq(1).css('left', '165px');

    // $('.pct_wrap').eq(2).css('z-index', 1);
    // $('.pct_wrap').eq(2).css('left', '-10px');
  }

  componentDidUpdate = () => {
    $('.highcharts-container').css('width', '194');
    $('.highcharts-container').css('height', '133');
    Highcharts.charts.forEach(v => v != undefined && v.reflow());
    //console.log(Highcharts.charts.length);
    //Highcharts.charts[0].reflow();

    // $('.pct_wrap').eq(0).css('z-index', 1);
    // $('.pct_wrap').eq(0).css('left', '145px');
    // $('.pct_wrap').eq(0).css('top', '-15px');

    // $('.pct_wrap').eq(1).css('z-index', 1);
    // $('.pct_wrap').eq(1).css('left', '165px');

    // $('.pct_wrap').eq(2).css('z-index', 1);
    // $('.pct_wrap').eq(2).css('left', '-10px');
  }

  percenTageFix = (value) => {
    if (value >= 100) {
      return 100;
    }
    else if (value <= 0) {
      return 0;
    }
    else {
      return value;
    }
  }

  render() {
    const fstAmt = parseInt(this.props.fstAmt);
    const sndAmt = parseInt(this.props.sndAmt);
    const trdAmt = parseInt(this.props.trdAmt);
    const totAmt = fstAmt + sndAmt + trdAmt;

    return (
      <>
        {
          (fstAmt > 0 && trdAmt > 0) &&
          <>
            <div class="pct_wrap" style={{ "z-index": "1", "left": "131px", "top": "-15px" }}>
              <strong class="tit">계약금</strong>
              <p>{this.percenTageFix(fstAmt / totAmt * 100)}%</p>
            </div>
            <div class="pct_wrap" style={{ "z-index": "1", "left": "163px" }}>
              <strong class="tit">중도금</strong>
              <p>{this.percenTageFix(sndAmt / totAmt * 100)}%</p>
            </div>
            <div class="pct_wrap" style={{ "z-index": "1", "left": "-5px" }}>
              <strong class="tit">잔금</strong>
              <p>{this.percenTageFix(trdAmt / totAmt * 100)}%</p>
            </div>
          </>
        }
        <figure>
          {
            (fstAmt > 0 && trdAmt > 0) ?
              <Fragment>
                <HighchartsReact highcharts={Highcharts}
                  options={{
                    chart: {
                      plotBackgroundColor: null,
                      plotBorderWidth: null,
                      plotShadow: false,
                      type: 'pie'
                    },
                    title: {
                      text: '',
                    },
                    tooltip: {
                      enabled: false,
                    },
                    plotOptions: {
                      pie: {
                        dataLabels: {
                          enabled: false,
                        },
                        showInLegend: false,
                      },
                    },
                    series: [{
                      size: '125%',
                      innerSize: '60%',
                      colors: ["#888888", "#15beb9", "#f16d63"],
                      data: [
                        {
                          name: '계약금',
                          y: fstAmt,
                          selected: true,
                        },
                        {
                          name: '중도금',
                          y: sndAmt,
                          selected: true,
                        },
                        {
                          name: '잔금',
                          y: trdAmt,
                          selected: true,
                        }
                      ],
                    }],
                  }} />
              </Fragment>
              :
              <img src={process.env.REACT_APP_ENV_URL + "resources/images/chart_donut.png"} alt="임시 이미지" />
          }
        </figure>
      </>
    );
  }
}

export default Donut;