import React, { Component } from 'react';
import MapApplySrch from './MapApplySrch';
import axios from 'axios';
import $ from 'jquery'
import Moment from 'moment';
import queryString from 'query-string';
import { AroundOverlay } from 'routes/common/overLay/AroundOverlay.js';
import { ApplyOverlay } from 'routes/common/overLay/ApplyOverlay';
import { uiAptHeaderCustom } from 'common/Design';

//// 컴포넌트 사용처 : 청약 정보
class MapApply extends Component {

  constructor(props) {
    super(props);
    this.map = {};
    this.state = {
      applyId: '',
      mapApplyDtl: [],
      favResult: [],
    };
    this.overLayList = [];
    this.aroundOverLayList = [];
    this.filter = {
      dr: true,
      trx: { trxMin: -1, trxMax: -1 },
      py: { pyMin: -1, pyMax: -1 },
      hhld: { hhldMin: -1, hhldMax: -1 },
      apr: { aprMin: -1, aprMax: -1 },
      pk: { pkMin: -1, pkMax: -1 }
    };
    this.search = {
      type: "",
      id: ""
    };

  }

  componentDidMount() {

    $('html').addClass('_full');
    $('.panel_marker .btn_fold').hide();

    // marker back
    $('.panel_marker .btn_back_w').click(() => {
      $('.panel_fold').addClass('on');
      $('.panel_marker').removeClass('on');
      $('.panel_fold .btn_fold').removeClass('on');
      $('.map_marker').removeClass('_active');
      $('.panel_marker').removeClass('_first');
    });

    // marker fold
    $('.panel_fold .btn_fold').click(() => {
      if ($('.panel_marker').hasClass('_first')) {
        $('.panel_fold').removeClass('on');
        $('.panel_marker').addClass('on');
      }
    });


    if (window.kakao) {
      let mapContainer = document.getElementById('mapview'); // 지도를 표시할 div
      let mapOption = {
        center: new kakao.maps.LatLng(37.57100, 126.97696), // eslint-disable-line
        level: 3
      };

      let map = new kakao.maps.Map(mapContainer, mapOption); // eslint-disable-line
      this.map = map;

      kakao.maps.event.addListener(map, 'dragend', () => { }); // eslint-disable-line
      kakao.maps.event.addListener(map, 'zoom_changed', () => {  // eslint-disable-line

      });

      const { search } = this.props.location;	// 문자열 형식으로 결과값이 반환된다.
      const queryObj = queryString.parse(search);	// 문자열의 쿼리스트링을 Object로 변환

      if (queryObj.applyId !== undefined && queryObj.applyId !== '') {
        this.clearOverlay();
        this.clearAroundOverlay();
        $('.apt_wrap').removeClass('dim');
        map.setLevel(3);
        if ($('.panel_fold').attr('class').search('on') <= -1) {
          this.cardToggle();
        }
        setTimeout(() => {
          this.fetchMapApplyDtl(queryObj.applyId, queryObj.lat, queryObj.lng);
        }, 300);
        //$('#apt_card_' + queryObj.applyId).click();

      }
    } else {
      $('#mapview').removeClass('map_img');
    }

  }

  overlayMap = (map) => {

    let param = `&trxMin=${this.filter.trx.trxMin}&trxMax=${this.filter.trx.trxMax}` +
      `&pyMin=${this.filter.py.pyMin}&pyMax=${this.filter.py.pyMax}` +
      `&hhldMin=${this.filter.hhld.hhldMin}&hhldMax=${this.filter.hhld.hhldMax}` +
      `&aprMin=${this.filter.apr.aprMin}&aprMax=${this.filter.apr.aprMax}` +
      `&pkMin=${this.filter.pk.pkMin}&pkMax=${this.filter.pk.pkMax}`;

    let isZoomEvent = this.overLayList.length === 0 ? true : false;
    let latlng = map.getCenter();

  }

  dateFormatMsg = (x) => {
    if (x === null || x === undefined || x === NaN) return '';
    else {
      return Moment(x).format('YY. MM. DD');
    }
  }

  dateDiffMsg = (x) => {
    if (x === null || x === undefined || x === NaN) return;
    else {
      const currDate = Moment().tz("Asia/Seoul");
      currDate.set({ hour: 0, minute: 0, second: 0, millisecond: 0 });

      const targetDateStr = `${x.substr(0, 4)}-${x.substr(4, 2)}-${x.substr(6, 2)}`;
      const targetDate = Moment(new Date(targetDateStr)).tz("Asia/Seoul");
      targetDate.set({ hour: 0, minute: 0, second: 0, millisecond: 0 });

      if (currDate.diff(targetDate, 'days') > 0) {
        return '완료';
      }
      else if (currDate.diff(targetDate, 'days') === 0) {
        return 'D - DAY';
      }
      else {
        return 'D ' + (currDate.diff(targetDate, 'days')).toString().replace('-', '- ');
      }
    }
  }

  handleChange = ({ type, min, max }) => {

    switch (type) {
      case "TRX": this.filter.trx = { trxMin: min, trxMax: max }; break;
      case "PY": this.filter.py = { pyMin: min, pyMax: max }; break;
      case "HHLD": this.filter.hhld = { hhldMin: min, hhldMax: max }; break;
      case "APR": this.filter.apr = { aprMin: min, aprMax: max }; break;
      case "PK": this.filter.pk = { pkMin: min, pkMax: max }; break;
      case "ALL": {
        this.filter = {
          dr: true,
          trx: { trxMin: -1, trxMax: -1 },
          py: { pyMin: -1, pyMax: -1 },
          hhld: { hhldMin: -1, hhldMax: -1 },
          apr: { aprMin: -1, aprMax: -1 },
          pk: { pkMin: -1, pkMax: -1 }
        }
        break;
      }
      default: break;
    }
    this.clearOverlay();
    this.clearAroundOverlay();
  }

  handleStatus = ({ value }) => {
    this.clearOverlay();
    this.clearAroundOverlay();
  }

  handleLocal = ({ value }) => {
    this.clearOverlay();
    this.clearAroundOverlay();
  }

  handleSearch = ({ searchType, searchId }) => {
    this.search.type = searchType;
    this.search.id = searchId;
    this.clearOverlay();
    this.clearAroundOverlay();
  }

  handleRenderMarkerList = ({ data }) => {
    this.clearOverlay();
    this.clearAroundOverlay();
    data.forEach(v => {
      let overlay = new ApplyOverlay(v);
      overlay.setMap(this.map);
      this.overLayList.push(overlay);

      $('#markerApply_' + v.applyId).click((e) => {
        e.preventDefault();
        $('.apt_wrap').removeClass('dim');
        this.map.setLevel(3);
        if ($('.panel_fold').attr('class').search('on') <= -1) {
          this.cardToggle();
        }
        this.fetchMapApplyDtl(v.applyId, null, null);
      });
    });
  }

  clearOverlay = () => {
    this.overLayList.forEach(ov => ov.setMap(null));
    this.overLayList = [];
  }

  clearAroundOverlay = () => {
    this.aroundOverLayList.forEach(ov => ov.setMap(null));
    this.aroundOverLayList = [];
  }

  handleRenderMarker = ({ data }) => {
    // this.clearOverlay();
    // this.clearAroundOverlay();
    // let overlay = new ApplyOverlay(data);
    // overlay.setMap(this.map);
    // this.overLayList.push(overlay);

    // $('#markerApply_' + data.applyId).click((e) => {
    //   e.preventDefault();
    //   $('.apt_wrap').removeClass('dim');
    //   this.map.setLevel(3);
    //   this.cardToggle();
    //   this.fetchMapApplyDtl(data.applyId, null, null);
    // });

    this.renderingAroundDataIcons(data);
  }

  handlePanTo = ({ lat, lng }) => {
    this.map.setLevel(3);
    let moveLatLng = new kakao.maps.LatLng(lat, lng); // eslint-disable-line
    this.map.panTo(moveLatLng);
  }

  comonentWillUnmount() {
    ////console.log('MAP_comonentWillUnmount');
    $('html').removeClass('_full');
  }

  renderingAroundDataIcons(data) {
    this.aroundOverLayList.forEach(ov => ov.setMap(null));
    this.aroundOverLayList = [];

    const dtl = data;

    dtl.schoolList.forEach(v => {
      v.type = 'school';
      let overlay = new AroundOverlay(v);
      overlay.setMap(this.map);
      this.aroundOverLayList.push(overlay);
    });

    dtl.departList.forEach(v => {
      v.type = 'ico_mart';
      let overlay = new AroundOverlay(v);
      overlay.setMap(this.map);
      this.aroundOverLayList.push(overlay);
    });

    dtl.stationList.forEach(v => {
      v.type = 'ico_subway';
      let overlay = new AroundOverlay(v);
      overlay.setMap(this.map);
      this.aroundOverLayList.push(overlay);
    });

    dtl.hospitalList.forEach(v => {
      v.type = 'ico_hospital';
      let overlay = new AroundOverlay(v);
      overlay.setMap(this.map);
      this.aroundOverLayList.push(overlay);
    });

    dtl.parkList.forEach(v => {
      v.type = 'ico_park';
      let overlay = new AroundOverlay(v);
      overlay.setMap(this.map);
      this.aroundOverLayList.push(overlay);
    });
  }

  fetchMapApplyDtl = (paramApplyId, paramLat, paramLng) => {
    $('.apt_wrap').removeClass('dim');
    axios
      .get(`apt/apply/dtl?applyId=${paramApplyId}`)
      .then(res => {
        this.setState({
          mapApplyDtl: res.data.response.applyDtl
        });
        this.handleRenderMarker({ data: res.data.response.applyDtl });
        if (paramLat != null && paramLng != null) {
          this.handlePanTo({ lat: paramLat, lng: paramLng });
        }
        else {
          this.handlePanTo({ lat: res.data.response.applyDtl.lat, lng: res.data.response.applyDtl.lng });
        }
      })
      .then(() => {
        setTimeout(() => {
          $('div.sub_header > h3').attr('data-tit', '최근 청약정보');
          $('div.sub_header > h3').text('최근 청약정보');
          uiAptHeaderCustom.init();
        }, 50);

        this.setState({
          mapApplyDtl: []
        });
      });
  }

  cardToggle = () => {
    // var _cik = $('.apt_card'),
    //   _move = _cik.parents('div[class^=panel_]'),
    //   _set = _cik.parents('div[class^=panel_]').width(),
    //   _panel = $('div[class^=panel_]').width();

    var _cik = $('.apt_card'),
      _move = $('.panel_card'),
      _set = $('.panel_card').width(),
      _panel = $('.panel_card').width();
    if (!_move.hasClass('on')) {
      $('div[class^=panel_]').removeClass('on');
      _move.css('left', + -_set);
      setTimeout(function () {
        _move.prev().css('left', - _panel);
        _move.next().addClass('on');
      }, 400);
    } else if (_move.hasClass('on') && _cik.hasClass('btn_back_w')) {
      _move.removeClass('on');
      _move.prev().prev().css('left', + 0);
      if (_move.offset().left >= -360) {
        setTimeout(function () {
          _move.prev().css('left', + _panel);
        }, 100);
      }
    }
  }

  handleGoToFavApplyDtl = (favApplyId) => {
    this.clearOverlay();
    this.clearAroundOverlay();
    $('.apt_wrap').removeClass('dim');
    this.map.setLevel(3);
    if ($('.panel_fold').attr('class').search('on') <= -1) {
      this.cardToggle();
    }
    this.fetchMapApplyDtl(favApplyId, null, null);
    // setTimeout(() => {
    //   $('div.sub_header > h3').attr('data-tit', '최근 분양 정보');
    //   $('div.sub_header > h3').text('최근 분양 정보');
    //   uiAptHeaderCustom.init();
    // }, 100);
  }

  render() {
    return (<>
      <div id="content">
        <section className="apt_wrap dim">
          <MapApplySrch
            location={this.props.location}
            mapApplyDtl={this.state.mapApplyDtl}
            onChange={({ type, min, max }) => this.handleChange({ type, min, max })}
            onStatus={(status) => this.handleStatus(status)}
            onLocals={(local) => this.handleLocal(local)}
            onSearch={(search) => this.handleSearch(search)}
            onRenderMarkerList={({ data }) => (window.kakao) ? this.handleRenderMarkerList({ data }) : null}
            onRenderMarker={({ data }) => this.handleRenderMarker({ data })}
            onPanTo={({ lat, lng }) => this.handlePanTo({ lat, lng })}
            onGotoFavApplyDtl={(applyId) => this.handleGoToFavApplyDtl(applyId)}
          ></MapApplySrch>
        </section>
        <div className="map_img" id="mapview" ></div>
      </div>
    </>);
  }
}
export default MapApply;