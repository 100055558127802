import React from 'react';
import axios from 'axios';
import CommonComponent from './Common'

//// 컴포넌트 사용처 : 가입
class Join extends CommonComponent {
  componentDidMount() {
    this.addImpression();
    this.telcoClick(1);
  }

  addImpression = () => {
    let paramForm = new FormData();
    paramForm.append('siteCode', 'AA'); //아파트청약케어 PC홈페이지가입창 가입코드 : AA
    paramForm.append('ctgCode', '1');

    axios
      .post('apt/regist/addImpression', paramForm)
      .then(res => {
      });
  }

  render() {
    const { telcoCode, phoneNo2, captchaAnswer, authCode } = this.state;
    return (
      <div id="content">
        <div className="inner_wrap">
          <div className="con_tit_wrap">
            <h2 className="tit">회원가입</h2>
            <ul className="sub_txt">
              <li>- 아파트청약케어 가입을 위하여, 휴대폰 인증이 필요해요.</li>
              <li>- 본인명의 휴대폰번호로만 가입이 가능해요.</li>
              <li>- 휴대폰 정보 입력 후, 수신 된 인증번호를 입력해주세요.</li>
            </ul>
          </div>
          <div className="form_wrap join_wrap">
            <section className="form_inner">
              <ul className="form">
                <li className="item">
                  <strong className="tit">통신사</strong>
                  <div className="ip_wrap rdo_wrap">
                    <div className="rdo_item">
                      <input type="radio" id="telcoCode1" name="telcoCode" onClick={() => this.telcoClick(1)} />
                      <label htmlFor="telcoCode1">SKT</label>
                    </div>
                    <div className="rdo_item">
                      <input type="radio" id="telcoCode2" name="telcoCode" onClick={() => this.telcoClick(2)} />
                      <label htmlFor="telcoCode2">KT</label>
                    </div>
                    <div className="rdo_item">
                      <input type="radio" id="telcoCode3" name="telcoCode" onClick={() => this.telcoClick(3)} />
                      <label htmlFor="telcoCode3">LGU+</label>
                    </div>
                  </div>
                </li>
                <li className="item">
                  <strong className="tit">휴대폰번호</strong>
                  <div className="ip_wrap">
                    <div className="select_box">
                      <select name="phoneNo1" id="phoneNo1" title="휴대폰 번호 앞자리 선택" className="code" onChange={this.onChangePhoneNo1}>
                        <option value="010">010</option>
                        <option value="011">011</option>
                        <option value="016">016</option>
                        <option value="017">017</option>
                        <option value="018">018</option>
                        <option value="019">019</option>
                      </select>
                    </div>
                    <div className="ip_box">
                      <label htmlFor="phoneNo2" className="lbl" >휴대폰번호를 입력해주세요.</label>
                      <input type="number" pattern="[0-9]+" id="phoneNo2" className="ip ip_phone code" name="phoneNo2" maxLength="8" onChange={this.onChangePhoneNo2} />
                    </div>
                  </div>
                  <button id="btn_setend" type="button" className="btn btn01" disabled={!phoneNo2} onClick={this.checkPhone}>인증요청</button>
                </li>
                <li id="section_auth" className="item" style={{ "display": "none" }}>
                  <strong className="tit">인증번호</strong>
                  <div className="ip_wrap">
                    <div className="ip_box">
                      <label htmlFor="authCode" className="lbl">인증번호를 입력해주세요.</label>
                      <input type="number" id="authCode" className="ip code" name="authCode" maxLength="6" onChange={this.onChangeAuthCode} disabled />
                      <span id="auth_timer" className="time" style={{ display: 'none' }}>03:00</span>
                    </div>
                  </div>
                  <button id="btn_reque" type="button" className="btn btn02" disabled={!phoneNo2} onClick={this.checkPhone} style={{ display: 'none' }}>재발송</button>
                </li>
                <li id="section_stepB" className="item" style={{ display: 'none' }}>
                  <strong className="tit">보안문자</strong>
                  <div className="security_wrap">
                    <div className="img" ><img id="img_captcha" src={process.env.REACT_APP_ENV_URL + "resources/images/temp01.png"} alt="" /></div>
                    <button id="newSafeNum" type="button" className="btn_reset">새로고침</button>
                  </div>
                  <div className="ip_wrap">
                    <div className="ip_box">
                      <label htmlFor="answer_captcha" className="lbl">보안문자(숫자)입력</label>
                      <input type="number" id="answer_captcha" className="ip code" name="security" maxLength="6" onChange={this.onChangeCaptchaAnswer} />
                    </div>
                  </div>
                  <button id="submit_captcha" type="button" className="btn btn03">다음</button>
                </li>
              </ul>
              <div id="termsCheck" className="terms_list">
                <ul className="all_list">
                  <li className="all">
                    <span className="checkbox i_all">
                      <input type="checkbox" name="allCheck" id="allCheck" value="" onClick={() => this.allAgreeClick()} />
                      <label htmlFor="allCheck"><i></i>모두동의</label>
                    </span>
                  </li>
                </ul>
                <ul className="item_list">
                  <li>
                    <span className="checkbox i_sub">
                      <input type="checkbox" name="terms" id="terms01" value="" onClick={() => this.agreeClick(1)} />
                      <label htmlFor="terms01"><i></i></label>
                      <button type="button" className="btn" onClick={() => this.agreeClickPop(1)}>서비스약관</button>
                    </span>
                  </li>
                  <li>
                    <span className="checkbox i_sub">
                      <input type="checkbox" name="terms" id="terms02" value="" onClick={() => this.agreeClick(2)} />
                      <label htmlFor="terms02"><i></i></label>
                      <button type="button" className="btn" onClick={() => this.agreeClickPop(2)}>개인정보수집 및 이용</button>
                    </span>
                  </li>
                  <li>
                    <span className="checkbox i_sub">
                      <input type="checkbox" name="terms" id="terms03" value="" onClick={() => this.agreeClick(3)} />
                      <label htmlFor="terms03"><i></i></label>
                      <button type="button" className="btn" onClick={() => this.agreeClickPop(3)}>개인정보 제3자 제공</button>
                    </span>
                  </li>
                  <li>
                    <span className="checkbox i_sub">
                      <input type="checkbox" name="terms" id="terms04" value="" onClick={() => this.agreeClick(4)} />
                      <label htmlFor="terms04"><i></i></label>
                      <button type="button" className="btn" id="btnTerms04" onClick={() => this.agreeClickPop(4)}>SKT개인정보 제3자 제공</button>
                    </span>
                  </li>
                  <li>
                    <span className="checkbox i_sub">
                      <input type="checkbox" name="terms" id="termsPos" value="" onClick={() => this.agreeClick('pos')} />
                      <label htmlFor="termsPos"><i></i></label>
                      <button type="button" className="btn" id="btnTermsPos" onClick={() => this.agreeClickPop('pos')}>위치기반서비스 이용약관</button>
                    </span>
                  </li>
                  {/* <li>
                    <span className="checkbox i_sub">
                      <input type="checkbox" name="terms" id="terms05" value="" onClick={() => this.agreeClick(5)} />
                      <label htmlFor="terms05"><i></i></label>
                      <button type="button" className="btn">마케팅 활용 동의<em>(선택)</em></button>
                    </span>
                  </li>
                  <li>
                    <span className="checkbox i_sub">
                      <input type="checkbox" name="terms" id="terms05_01" value="" onClick={() => this.agreeClick('5_1')} />
                      <label htmlFor="terms05_01"><i></i></label>
                      <button type="button" className="btn">마케팅 수신 동의<em>(선택)</em></button>
                    </span>
                  </li> */}
                </ul>
              </div>
            </section>
          </div>
          <div className="btn_wrap">
            <button type="button" className="btn" disabled={!authCode} onClick={this.submitRegist}>아파트청약케어 유료가입</button>
          </div>
          <div className="info_txt_wrap">
            {
              telcoCode === 1 ?
                <>
                  <p className="txt fc">매월 3,300원(부가세포함)의 유료 서비스로, 사용 일 수 만큼 일할 계산되어 과금됩니다. </p>
                  <p className="txt">본 서비스는 가입 즉시 서비스가 개시되는 상품으로 청약철회가 불가능 합니다.<br />(전자상거래 등에서의 소비자 보호에 관한 법률 제17조 2항 5호에 따라 디지털 콘텐츠의 제공이 개시된 경우 청약철회를 제공하지 않습니다.)</p>

                  <p className="txt">본 서비스는 (주)헥토이노베이션과 SKT가 제휴하여 공동으로 제공하는 서비스입니다.</p>
                  <p className="txt">서비스 관련 문의사항은 아래 고객센터로 문의바랍니다.</p>
                  <p className="txt">(주)헥토이노베이션 고객센터 : 1599-2985 (가입/이용/해지/환불 등 서비스 상세 문의)</p>
                  <p className="txt">SKT 고객센터 : 114 및 Tworld (해지 및 일반 문의)</p>
                </>
                :
                telcoCode === 2 ?
                  <>
                    <p className="txt fc">매월 3,300원(부가세포함)의 유료 서비스로, 사용 일 수 만큼 일할 계산되어 과금됩니다. </p>
                    <p className="txt">본 서비스는 가입 즉시 서비스가 개시되는 상품으로 청약철회가 불가능 합니다.<br />(전자상거래 등에서의 소비자 보호에 관한 법률 제17조 2항 5호에 따라 디지털 콘텐츠의 제공이 개시된 경우 청약철회를 제공하지 않습니다.)</p>

                    <p className="txt">본 서비스는 (주)헥토이노베이션과 KT가 제휴하여 공동으로 제공하는 서비스입니다.</p>
                    <p className="txt">서비스 관련 문의사항은 아래 고객센터로 문의바랍니다.</p>
                    <p className="txt">(주)헥토이노베이션 고객센터 : 1599-2985 (가입/이용/해지/환불 등 서비스 상세 문의)</p>
                    <p className="txt">KT 고객센터 : 114 및 kt.com (해지 및 일반 문의)</p>
                  </>
                :
                telcoCode === 3 ?
                  <>
                    <p className="txt fc">매월 3,300원(부가세포함)의 유료 서비스로, 사용 일 수 만큼 일할 계산되어 과금됩니다. </p>
                    <p className="txt">본 서비스는 가입 즉시 서비스가 개시되는 상품으로 청약철회가 불가능 합니다.<br />(전자상거래 등에서의 소비자 보호에 관한 법률 제17조 2항 5호에 따라 디지털 콘텐츠의 제공이 개시된 경우 청약철회를 제공하지 않습니다.)</p>

                    <p className="txt">본 서비스는 (주)헥토이노베이션과 LGU+가 제휴하여 공동으로 제공하는 서비스입니다.</p>
                    <p className="txt">서비스 관련 문의사항은 아래 고객센터로 문의바랍니다.</p>
                    <p className="txt">(주)헥토이노베이션 고객센터 : 1599-2985 (가입/이용/해지/환불 등 서비스 상세 문의)</p>
                    <p className="txt">LGU+ 고객센터 : 114 및 www.lguplus.com (해지 및 일반 문의)</p>
                  </>
                  :
                  null
            }
          </div>
        </div>
      </div>
    );
  }
}
export default Join;