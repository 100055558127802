import React, { Component } from 'react';
import { addLsItem, getLsItem } from 'common/Search';
import { findIndex } from 'common/ArrayUtil';
import $ from 'jquery';
import 'jquery-ui-bundle'
import { filtersNumberReplaceWithStag } from 'common/StringUtil';

//// 컴포넌트 사용처 : 아파트 정보
class MapAptSrchPy extends Component {

  constructor(props) {
    super(props);
    this.state = {
      type: 1,
      pyList: [
        { name1: "전체", name2: "전체", visible: true, min: -1, max: 9999, minTxt1: "전체", maxTxt1: "전체", minTxt2: "전체", maxTxt2: "전체" },
        { name1: "10평 미만", name2: "33.06㎡ 미만", visible: false, min: 0, max: 10, minTxt1: "10평 미만", maxTxt1: "10평 미만", minTxt2: "33.06㎡ 미만", maxTxt2: "33.06㎡ 미만" },
        { name1: "10평 대", name2: "33.06㎡ 대", visible: false, min: 10, max: 20, minTxt1: "10", maxTxt1: "20평 미만", minTxt2: "33.06㎡", maxTxt2: "66.12㎡ 미만" },
        { name1: "20평 대", name2: "66.12㎡ 대", visible: false, min: 20, max: 30, minTxt1: "20", maxTxt1: "30평 미만", minTxt2: "66.12㎡", maxTxt2: "99.18㎡ 미만" },
        { name1: "30평 대", name2: "99.18㎡ 대", visible: false, min: 30, max: 40, minTxt1: "30", maxTxt1: "40평 미만", minTxt2: "99.18㎡", maxTxt2: "132.24㎡ 미만" },
        { name1: "40평 대", name2: "132.24㎡ 대", visible: false, min: 40, max: 50, minTxt1: "40", maxTxt1: "50평 미만", minTxt2: "132.24㎡", maxTxt2: "165.30㎡ 미만" },
        { name1: "50평 대", name2: "165.30㎡ 대", visible: false, min: 50, max: 60, minTxt1: "50", maxTxt1: "60평 미만", minTxt2: "165.30㎡", maxTxt2: "198.00㎡ 미만" },
        { name1: "60평 대 이상", name2: "198.00㎡ 대", visible: false, min: 60, max: 9999, minTxt1: "60평 이상", maxTxt1: "60평 이상", minTxt2: "198.00㎡ 이상", maxTxt2: "198.00㎡ 이상" }
      ],

      selectedMinIdx: 0,
      selectedMaxIdx: 0,
      slideMinTxt: '',
      slideMaxTxt: '',
    };
  }

  componentDidMount() {

    let self = this;

    $(".sliderPy").slider({
      range: true,
      min: 0,
      max: 61,
      values: [0, 61],
      slide: function (event, ui) {
        self.setState({
          selectedMinIdx: -1, selectedMaxIdx: -1,
          slideMinTxt: (self.state.type === 1 ? ui.values[0] : (ui.values[0] > 0 ? parseFloat(ui.values[0] * 3.3).toFixed(2) + '㎡' : 0)),
          slideMaxTxt: (self.state.type === 1 ?
            (ui.values[1] === 61 ? '60평 이상' : ui.values[1] + '평 미만') :
            (ui.values[1] === 61 ? '198.00㎡ 이상' : parseFloat(ui.values[1] * 3.3).toFixed(2) + '㎡ 미만'))
        });
      },
      stop: function (event, ui) {
        const current = self.state.pyList.slice();
        current.forEach(v => v.visible = false)
        self.setState(({ pyList: current }));
        self.props.onSearch({ type: 'PY', min: ui.values[0], max: ui.values[1] > 60 ? 9999 : ui.values[1], load: true });
      }
    });

    if (this.props.loadFilter) {
      let aptPyFilter = null;
      if (this.props.isApply) {
        aptPyFilter = getLsItem('applyPyFilter');
      }
      else {
        aptPyFilter = getLsItem('aptPyFilter');
      }

      if (aptPyFilter && aptPyFilter.filter && aptPyFilter.sliderValue) {
        $(".sliderPy").slider("values", aptPyFilter.sliderValue);
        this.setState({ pyList: aptPyFilter.filter });

        if (aptPyFilter.filter.some(f => f.visible)) {
          const minValue = aptPyFilter.filter.filter(f => f.visible).reduce((p, c) => p > c ? c : p).min;
          const maxValue = aptPyFilter.filter.filter(f => f.visible).reduce((p, c) => p > c ? p : c).max;
          this.props.onSearch({ type: 'PY', min: minValue, max: maxValue, load: false });
        } else {
          this.props.onSearch({ type: 'PY', min: aptPyFilter.sliderValue[0], max: aptPyFilter.sliderValue[1], load: false });
        }
      }
    }
  }

  componentDidUpdate() {
    if (this.props.saveFilter) {
      if (this.props.isApply) {
        addLsItem('applyPyFilter', { filter: this.state.pyList, sliderValue: $(".sliderPy").slider("option", "values") });
      }
      else {
        addLsItem('aptPyFilter', { filter: this.state.pyList, sliderValue: $(".sliderPy").slider("option", "values") });
      }
    }
  }

  selectPy(idx) {

    const current = this.state.pyList.slice();
    const checkedCnt = current.filter(e => e.visible).length;
    let visibleIndex = findIndex(this.state.pyList, i => i.visible);

    let minc = 0;
    let maxc = 0;

    let minIdx = 0;
    let maxIdx = 0;

    if (visibleIndex !== 0 && checkedCnt === 1 && idx > visibleIndex) {
      current.filter((v, i) => i >= visibleIndex && i <= idx).forEach(v => v.visible = true);
      minc = current[visibleIndex].min;
      maxc = current[idx].max;

      minIdx = visibleIndex;
      maxIdx = idx;

    } else {
      current.forEach(c => c.visible = false);
      current[idx].visible = true;
      minc = current[idx].min;
      maxc = current[idx].max;

      minIdx = idx;
      maxIdx = idx;
    }

    this.props.onSearch({ type: 'PY', min: minc, max: maxc, load: true });
    $(".sliderPy").slider("values", [minc, maxc]);
    this.setState({ pyList: current, selectedMinIdx: minIdx, selectedMaxIdx: maxIdx });
  }

  changeType = () => {
    const valArr = $(".sliderPy").slider("option", "values");
    if (this.state.selectedMinIdx === -1 && this.state.selectedMaxIdx === -1) {
      if (this.state.type === 1) {
        this.setState({
          type: 2,
          slideMinTxt: (valArr[0] > 0 && valArr[0] < 61) ? parseFloat(valArr[0] * 3.3).toFixed(2) + '㎡' : 0,
          slideMaxTxt: valArr[1] === 61 ? '198.00㎡ 이상' : parseFloat(valArr[1] * 3.3).toFixed(2) + '㎡ 미만',
        });
      }
      else if (this.state.type === 2) {
        this.setState({
          type: 1,
          slideMinTxt: (valArr[0] > 0 && valArr[0] < 61) ? valArr[0] + '평' : 0,
          slideMaxTxt: valArr[1] === 61 ? '60평 이상' : valArr[1] + '평 미만',
        });
      }
    }
    else {
      if (this.state.type === 1) {
        this.setState({
          type: 2,
          minTxt: (valArr[0] > 0 && valArr[0] < 61) ? parseFloat(valArr[0] * 3.3).toFixed(2) + '㎡' : 0,
          maxTxt: valArr[1] === 61 ? '198.00㎡ 이상' : parseFloat(valArr[1] * 3.3).toFixed(2) + '㎡ 미만',
        });
      }
      else if (this.state.type === 2) {
        this.setState({
          type: 1,
          minTxt: (valArr[0] > 0 && valArr[0] < 61) ? valArr[0] + '평' : 0,
          maxTxt: valArr[1] === 61 ? '60평 이상' : valArr[1] + '평 미만',
        });
      }
    }
  }

  render() {
    const { pyList, type, selectedMinIdx, selectedMaxIdx, slideMinTxt, slideMaxTxt } = this.state;
    let minTxt = selectedMinIdx != -1 ? (type === 1 ? pyList[selectedMinIdx].minTxt1 : pyList[selectedMinIdx].minTxt2) : '';
    let maxTxt = selectedMaxIdx != -1 ? (type === 1 ? pyList[selectedMaxIdx].maxTxt1 : pyList[selectedMaxIdx].maxTxt2) : '';

    return (
      <div className="filter_wrap">
        <div className="tit_wrap">
          <strong>평형</strong>
          <button type="button" className="btn_unit2" onClick={this.changeType}>
            <i>단위변경
                        {
                this.state.type === 1 ?
                  '(㎡)'
                  :
                  '(평)'
              }</i>
          </button>
          <span className="choice">
            {(selectedMinIdx !== -1 && selectedMaxIdx !== -1) ? (
              maxTxt == "전체" ? "전체" :
                (minTxt == maxTxt
                  || (type === 1 && minTxt == "10평 미만")
                  || (type === 2 && minTxt == "33.06㎡ 미만")
                ) ? filtersNumberReplaceWithStag(maxTxt) :
                  <>{filtersNumberReplaceWithStag(minTxt)} ~ {filtersNumberReplaceWithStag(maxTxt)}</>
            )
              : (selectedMinIdx === -1 && selectedMaxIdx === -1) && (
                (slideMinTxt === slideMaxTxt || slideMinTxt === 0 ||
                  (type === 1 && slideMinTxt > 60) ||
                  (type === 2 && Math.round(slideMinTxt) > Math.round('198.00'))) ? filtersNumberReplaceWithStag(slideMaxTxt) :
                  <>{filtersNumberReplaceWithStag(slideMinTxt)} ~ {filtersNumberReplaceWithStag(slideMaxTxt)}</>
              )
            }</span>
        </div>
        <div className="filter_inner _line">
          <div className="slider sliderPy"></div>
          <ul className="slider_lbl">
            {
              this.state.type === 1 ?
                <>
                  <li>0</li>
                  <li>15평</li>
                  <li>30평</li>
                  <li>45평</li>
                  <li>60평</li>
                </>
                :
                <>
                  <li>0</li>
                  <li>49.5㎡</li>
                  <li>99.0㎡</li>
                  <li>148.5㎡</li>
                  <li>198.0㎡</li>
                </>
            }

          </ul>
          {
            this.state.pyList.map((v, idx) =>
              <button type="button"
                className={v.visible ? "_set" : ""}
                onClick={() => this.selectPy(idx)}>
                {this.state.type === 1 ? v.name1 : v.name2}

              </button>
            )
          }
        </div>
      </div>
    );
  }
}
export default MapAptSrchPy;