import React, { Component } from 'react';

//// 컴포넌트 사용처 : 아파트 정보
class MapAptSrchDR extends Component {

  render() {
    return (
      <div className="filter_wrap">
        <div className="tit_wrap">
          <strong>거래유형</strong>
          <span className="choice">{this.props.isDeal ? "매매" : "전·월세"}</span>
        </div>
        <div className="filter_inner _line">
          <button type="button"
            className={`${this.props.isDeal ? "_set" : ""}`}
            onClick={() => this.props.onDR(true)}>매매
                    </button>
          <button type="button"
            className={`${this.props.isDeal ? "" : "_set"}`}
            onClick={() => this.props.onDR(false)}>전·월세
                    </button>
        </div>
      </div>
    );
  }
}
export default MapAptSrchDR;