import React, { Component } from 'react';
import axios from 'axios';
import { Link } from 'react-router-dom';
import $ from 'jquery';

class NotFoundPage extends Component {

  constructor(props) {
    super(props);

  }


  render() {
    return (
      <p>ERROR</p>
    );
  }
}

export default NotFoundPage;
