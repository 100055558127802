import React from 'react';
import CommonComponent from './Common';
import { Link } from 'react-router-dom';

//// 컴포넌트 사용처 : 부동산정책
class RealtyPolicy extends CommonComponent {
  componentDidMount() {
    this.fetchMolitLandNewsList();
  }

  renderPaging() {
    const beginPage = this.state.paginationObj.beginPage;
    const endPage = this.state.paginationObj.endPage;
    const totalPage = this.state.paginationObj.totalPage;

    let pageArr = [];

    for (let i = beginPage; i <= endPage; i++) {
      if (i === 1) {
        pageArr.push(1);
      }
      else {
        pageArr.push(i);
      }
    }
    return (
      pageArr.map((page, idx) => {
        if (page === this.state.currentPage) {
          return (
            <li key={idx}>
              <a href="#" className="on" onClick={() => this.moveMolitLandNewsPage(page)} title="{page}번 페이지로 이동">{page}</a>
            </li>
          )
        }
        else {
          return (
            <li key={idx}>
              <a href="#" onClick={() => this.moveMolitLandNewsPage(page)} title="{page}번 페이지로 이동">{page}</a>
            </li>
          )
        }
      })
    )
  }

  renderFrontArrow() {
    if (this.state.currentPage !== 1) {
      return (
        <span>
          <b title="앞으로1페이지"><Link to="#" onClick={() => this.moveMolitLandNewsPage((this.state.currentPage - 1))}>◁</Link></b>
        </span>
      )
    }
  }

  renderAfterArrow() {
    console.log(this.state);
    const totalPage = this.state.paginationObj.totalPage;
    if (this.state.currentPage !== totalPage) {
      return (
        <span>
          <b title="뒤로1페이지"><Link to="#" onClick={() => this.moveMolitLandNewsPage((this.state.currentPage + 1))}>▷</Link></b>
        </span>
      )
    }
  }

  render() {
    const totalPage = this.state.paginationObj.totalPage;
    return (
      <div id="content">
        <div className="inner_wrap">
          <h2 className="hide">부동산정보</h2>
          <div className="con_tit_wrap">
            <h3 className="tit">부동산정책</h3>
            <p className="sub_txt">국토교통부 보도자료(주택토지)의 정책 정보입니다.</p>
          </div>
          <div className="bbs_wrap">
            <table className="bbs_list toggle_list">
              <caption>
                <strong>국토교통부 보도자료(주택토지)의 정책정보</strong>
                <p>국토교통부 주택토지 정책정보 목록으로 번호,제목,등록일 로 구성된 표</p>
              </caption>
              <colgroup>
                <col style={{ "width": "95px" }} />
                <col />
                <col style={{ "width": "120px" }} />
              </colgroup>
              <thead>
                <tr>
                  <th><code>NO</code></th>
                  <th>제목</th>
                  <th>등록일</th>
                </tr>
              </thead>
              <tbody>
                {
                  this.state.policyList.length <= 0 ?
                    <tr className="nodata">
                      <td colSpan="3">게시글이 없습니다.</td>
                    </tr>
                    :
                    this.state.policyList.map((notice, idx) => {
                      return (
                        <React.Fragment key={idx}>
                          <tr>
                            <td className="num"><code>{notice.num}</code></td>
                            <td className="tit">
                              <div>
                                <a href={notice.url} target="_blank">{notice.title}</a>
                              </div>
                            </td>
                            <td className="date"><code>{notice.bdTime}</code></td>
                          </tr>
                        </React.Fragment>
                      )
                    })
                }
              </tbody>
            </table>
            {
              this.state.policyList.length > 0 ?
                <div className="pagination">
                  <a href="#" className="btn_prve" title="이전 5개 목록으로 이동" onClick={() => this.moveMolitLandNewsPrevPages()}></a>
                  <ul>
                    {this.renderPaging()}
                  </ul>
                  <a href="#" className="btn_next" title="다음 5개 목록으로 이동" onClick={() => this.moveMolitLandNewsNextPages()}></a>
                </div>
                : ''
            }
          </div>
        </div>
      </div>
    );
  }
}
export default RealtyPolicy;