import React, { Component } from 'react';
import Moment from 'moment';
import 'moment/locale/ko';

//// 컴포넌트 사용처 : 청약 당첨예상 상세
class ExptScore extends Component {

  gotoApply = (e) => {
    e.preventDefault();
    window.open(`/apply?applyId=${this.props.calcResult.applyInfo.applyId}`, '_blank');
  }

  render() {

    const { applyInfo, applyDtl, applyCalendar, exptScore, score } = this.props.calcResult;
    const min10Percent = (exptScore != null ?
      Math.round(exptScore.exptMinScore / 10) :
      0);

    const { scoreType, percent } = (() => {
      let obj = {};

      if (exptScore) {
        if (exptScore.exptMinScore) {
          if (score.total <= (exptScore.exptMinScore - min10Percent)) {
            obj = { scoreType: 'bad', percent: Math.round((score.total * 25) / (exptScore.exptMinScore - 0)) };
          } else {
            if (score.total <= exptScore.exptMinScore) {
              obj = { scoreType: 'normal', percent: 25 + Math.round(((score.total - (exptScore.exptMinScore - min10Percent)) * 25) / min10Percent) };
            } else {
              if (score.total < exptScore.exptMinScore + min10Percent) {
                obj = { scoreType: 'good', percent: 50 + Math.round(((score.total - exptScore.exptMinScore) * 25) / min10Percent) };
              } else {
                obj = { scoreType: 'best', percent: 75 + Math.round(((score.total - (exptScore.exptMinScore + min10Percent)) * 25) / (84 - exptScore.exptMinScore + min10Percent)) };
              }
            }
          }
        } else {
          obj = { scoreType: 'shortfall', percent: 0 };
        }
      } else {
        obj = { scoreType: 'shortfall', percent: 0 };
      }

      return obj;
    })();

    // const { scoreType, percent } =
    //   (exptScore != null ? (
    //     score.total <= exptScore.exptMinScore - min10Percent ?
    //       { scoreType: 'bad', percent: Math.round((score.total * 25) / (exptScore.exptMinScore - 0)) }
    //       : score.total <= exptScore.exptMinScore ? { scoreType: 'normal', percent: 25 + Math.round(((score.total - (exptScore.exptMinScore - min10Percent)) * 25) / min10Percent) }
    //         : score.total < exptScore.exptMinScore + min10Percent ? { scoreType: 'good', percent: 50 + Math.round(((score.total - exptScore.exptMinScore) * 25) / min10Percent) }
    //           : { scoreType: 'best', percent: 75 + Math.round(((score.total - (exptScore.exptMinScore + min10Percent)) * 25) / (84 - exptScore.exptMinScore + min10Percent)) }
    //   ) : { scoreType: 'bad', percent: 0 });

    //console.log({ scoreType, percent });

    const firstDate = Math.floor(Moment.duration(Moment().diff(Moment(applyCalendar.fstLocalDate, 'YYYYMMDD'))).asDays());

    const getCatption = {
      'bad': <p>고객님은 해당 아파트 청약 신청 시 <em>당첨 될 가능성이 희박해요.</em><br /></p>
      , 'normal': <p>고객님은 해당 아파트 청약 신청 시 <em>당첨 될 가능성이 낮아요.</em><br />추첨을 노려보시는건 어떨까요?</p>
      , 'good': <p>고객님은 해당 아파트 청약 신청 시 <em>당첨 안정권으로</em><br />청약 신청해보시는 것을 조심스레 추천드려요.</p>
      , 'best': <p>고객님은 해당 아파트 청약 신청 시 <em>당첨 될 가능성이 아주 높아요.</em><br />무조건 신청하시는 것을 추천드려요.</p>
      , 'shortfall': <p>고객님은 해당 아파트 청약 신청 시 <em>당첨 안정권이에요.</em><br />단, 해당 지역 내 미달 공고가 발생하고 있으니 신중히 고민하시고 신청해주세요!</p>
    };

    return (
      <>
        <h3>
          <s>1.</s>당첨 가능성 및 예상 경쟁률
        <div className="q_mark bt">
            <div className="q_mark_inner">
              <p className="tit">당첨가능성 및 예상경쟁률이란?</p>
              <p className="txt">
                고객님의 입력하신 청약가점으로, 청약을 희망하는 아파트에 당첨 될 가능성이 있는지를 안내해드려요.<br />
                과거 청약결과와 주변 정보들을 기반으로 청약을 희망하는 아파트의 당첨 예상 최저 가점과 최고가점을 계산해요.<br />
                또한, 해당 아파트에 접수 될 청약 신청 건수를 예측하여 예상경쟁률을 산출하여 안내해드려요.
            </p>
            </div>
          </div>
        </h3>
        <div className="sec" id="sec1">
          <h4><strong>{applyInfo.aptName}</strong>에 당첨 될 가능성은?</h4>
          {/* <!-- best/good/normal/bad로 색상,이모지 변경 --> */}
          <div className={`ty_graph ${scoreType === 'shortfall' ? 'best' : scoreType}`}>
            <div>
              <div className="graph">
                <div className="dount_area">
                  <div className="circle_chart" data-percent={percent} data-permax="100">
                    <div className="chart-box">
                      <svg>
                        <defs>
                          <linearGradient id={`dount_${scoreType}`} x1="0" y1="0" x2="1" y2="1">
                            <stop className="color_1" offset="0%" />
                            <stop className="color_2" offset="100%" />
                          </linearGradient>
                        </defs>
                        <circle stroke={`url(#dount_${scoreType})`} r="49%" cx="50%" cy="50%" className="circle_progress" stroke-width="15" stroke-linecap="round"></circle>
                        <circle className="marker" r="8" cx="50%" cy="125" style={{ "filter": "url(#shadow)" }}></circle>
                        <filter id="shadow">
                          <feDropShadow dx="0" dy="0" stdDeviation="2"
                            flood-color="#CDCDCD" />
                        </filter>
                      </svg>
                      <div className="per-txt"><span>
                        {
                          scoreType === 'bad' ? '당첨희박' :
                            scoreType === 'normal' ? '당첨저조' :
                              scoreType === 'good' ? '안정권' :
                                scoreType === 'best' ? '당첨권' :
                                    scoreType === 'shortfall' ? '미달예상' : ''
                        }
                      </span></div>
                    </div>
                  </div>
                </div>
                <div className="btn_inner">
                  <button type="button" className="btn_line" onClick={this.gotoApply}>청약정보 상세확인</button>
                </div>
              </div>
            </div>
            <div>
              <div className="caption emoji">
                {
                  getCatption[scoreType]
                }
                <strong>1순위 접수일 : <s>{Moment(applyCalendar.fstLocalDate).format('YYYY. MM. DD')}</s> <span>{firstDate <= 0 ? 'D -' + Math.abs(firstDate) : '완료'}</span></strong>
              </div>
              <div className="progress multi">
                <div className="bg">
                  <div className="point low" data-point={exptScore != null ? exptScore.exptMinScore : 0}>
                    {/*<span><s>{exptScore != null ? exptScore.exptMinScore : 0}</s>점<small>최저</small></span>*/}
                    <span>
                      {(exptScore) ? ((exptScore.exptMinScore) ? <><s>{exptScore.exptMinScore}</s>점<small>최저</small></> : <><div>미달</div><div>예상</div></>) : <><div>미달</div><div>예상</div></>}
                    </span>
                  </div>
                  <div className="point my" data-point={score.total}>
                    <span><small>내 가점</small><s>{score.total}</s>점</span>
                  </div>
                  <div className="point max" data-point={exptScore != null ? exptScore.exptMaxScore : 0}>
                    {/*<span><s>{exptScore != null ? exptScore.exptMaxScore : 0}</s>점<small>최고</small></span>*/}
                    <span>
                      {(exptScore) ? ((exptScore.exptMaxScore) ? <><s>{exptScore.exptMaxScore}</s>점<small>최고</small></> : <><div>미달</div><div>예상</div></>) : <><div>미달</div><div>예상</div></>}
                    </span>
                  </div>
                </div>
                <div className="progress_lbl">
                  <span>0</span>
                  <span>84</span>
                </div>
                <ul className="chart_lbl">
                  <li>예상 최저 가점</li>
                  <li>내 가점 점수</li>
                  <li>예상 최고 가점</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default ExptScore;
