
export const findIndex = (arr, fnCheck) => {

    let result = -1;

    for(let i = 0 ; i < arr.length ; i++){
        if(fnCheck(arr[i])){
            result = i;
            break;
        }
    }
    return result;
}
