import axios from 'axios';

export const searchAptBjd = (keyword, callback) => {
  axios
    .get(`/apt/map/search?searchType[]=B&searchType[]=A&keyword=` + encodeURI(keyword))
    .then(res => {
      callback.call(null, res.data);
    })
    .catch(error => console.error(error))
}

export const searchBjd = (keyword, callback) => {
  axios
    .get(`/apt/map/search?searchType[]=B&keyword=` + encodeURI(keyword))
    .then(res => {
      callback.call(null, res.data);
    })
    .catch(error => console.error(error))
}

export const searchApply = (keyword, status, localsArr, pyMin, pyMax, trxMin, trxMax, hhldCntMin, hhldCntMax, callback) => {
  let paramLocals = '';
  if (localsArr.locals !== undefined) {
    if (localsArr.locals.length > 0) {
      localsArr.locals.forEach(element => paramLocals += element + ',');
    }
  }

  axios
    .get(`apt/apply/list?aptName=` + encodeURI(keyword) + `&status=${status}&locals=${paramLocals}&pyMin=${pyMin}&pyMax=${pyMax}&trxMin=${trxMin}&trxMax=${trxMax}&hhldCntMin=${hhldCntMin}&hhldCntMax=${hhldCntMax}`)
    .then(res => {

      callback.call(null, res.data);
    })
    .catch(error => console.error(error))
}

export const addRecentItem = (type, addItem) => {

  if (!addItem) return;

  let lc = window.localStorage;
  let std = lc.getItem(type) === null ? new Array() : JSON.parse(lc.getItem(type));

  if (std.length === 0 || !std.some(v => v.searchId === addItem.searchId)) {
    std.push(addItem);
    if (std.length > 10) {
      std.shift();
    }
    lc.setItem(type, JSON.stringify(std));
  }
}

export const getRecentItem = (type) => {

  let lc = window.localStorage;
  let std = lc.getItem(type) === null ? new Array() : JSON.parse(lc.getItem(type));
  return std;
}

export const clearRecentItem = (type) => {

  let lc = window.localStorage;
  lc.setItem(type, '[]');
}

export const setRecentList = (type, recentList) => {

  let lc = window.localStorage;
  lc.setItem(type, JSON.stringify(recentList));
}

export const addLsItem = (type, addItem) => {

  if (!addItem) return;
  let lc = window.localStorage;
  lc.setItem(type, JSON.stringify(addItem));
}

export const getLsItem = (type) => {

  let lc = window.localStorage;

  let std = lc.getItem(type) === 0 ? null : JSON.parse(lc.getItem(type));
  return std;
}

export const clearLsItem = (type) => {

  let lc = window.localStorage;
  lc.setItem(type, '{}');
}
