import { Component } from 'react';
import axios from 'axios';
import $ from 'jquery';
import Moment from 'moment';
import { popupOpen } from 'common/Design';

class CommonComponent extends Component {

  constructor(props) {
    super(props);
    this.state = {
      telcoCode: '',
      mdn: '010',
      phoneNo1: '010',
      phoneNo2: '',
      allAgree: false,
      agree1: false,
      agree2: false,
      agree3: false,
      agree4: false,
      agree5: false,
      agree5_1: false,
      agreePos: false,
      authCode: '',
      captchaAnswer: '',
      savedPhoneNo: '',
      savedUser: '',
      token: '',

      currentPage: 1,
      noticeList: [],
      paginationObj: {},
      //noticeInfo : [],

      faqList: [],
      faqType: '',

      policyList: [],

      aptRecommParam1: 'N', //주택소유여부
      aptRecommParam2: '', //지역
      aptRecommParam3: 0, //평수
      aptRecommParam4: 0, //아파트연식

      aptRecommParam5: 0, //연소득액
      aptRecommParam6: 0, //현재보유자산
      aptRecommParam7: 0, //기존대출연상환액

      aptRecommLoanRateMap: {}, //대출지표맵(LTV등)

      aptRecommSearchList: [], // 주소검색결과리스트
      aptRecommResultList: [], // 추천결과리스트

      isKtCsPhone: false,
      progress: false,

      // [SOM.230208] 민원대응 로그 저장 관련
      accessJoin: Moment().format('YYYYMMDDHHmmss'),
      accessJoinUri: (window.location.pathname + window.location.search),
      accessCheck: '',
      accessCheckUri: '',
      referrer: document.referrer,
    }
  }

  telcoClick = (code, e) => {
    if (code == 1 || code == 2 || code == 3) {
      this.setState({
        telcoCode: code,
      });

      this.setState((state) => {
        state.allAgree = false;
        state.agree1 = false;
        state.agree2 = false;
        state.agree3 = false;
        state.agree4 = false;
        state.agreePos = false;
        $('#allCheck').prop('checked', false);
        $('input[id^=terms]').prop('checked', false);
      });

      $('#telcoCode' + code).prop('checked', true);
      $('#btnTerms04').text(
        (code === 1 ? 'SKT 개인정보 제3자 제공' : (code === 2 ? 'KT 개인정보 제3자 제공' : 'LGU+ 및 MVNO 제3자제공')));

      // [SOM.230922] LGU+ 가입/해지 작업 공지 팝업 (해지탭 LGU+ 선택시)
      // if (code === 3) {
      //   popupOpen('notice3','pc');
      // } 
    }
    else {
      alert('서비스 준비중입니다.');
      $('#telcoCode' + code).prop('checked', false);
      this.telcoClick(1);
    }

  }
  allAgreeCheckConfirm = () => {
    if (this.state.allAgree === false) {
      if (!window.confirm('약관에 모두 동의 하시겠습니까?')) return false;
      else {
        this.setState((state) => {
          state.allAgree = true;
          state.agree1 = true;
          state.agree2 = true;
          state.agree3 = true;
          state.agree4 = true;
          state.agreePos = true;
          //state.agree5 = true;
          //state.agree5_1 = true;
          $('#allCheck').prop('checked', true);
          $('input[id^=terms]').prop('checked', true);
        });
        return true;
      }
    }
  }
  allAgreeCheck = () => {
    if (this.state.agree1 === true && this.state.agree2 === true && this.state.agree3 === true && this.state.agree4 === true && this.state.agreePos === true) {
      //this.state.agree5 === true && this.state.agree5_1 === true

      this.setState((state) => {
        state.allAgree = true;
        $('#allCheck').prop('checked', true);
      })
    }
    else {
      this.setState((state) => {
        state.allAgree = false;
        $('#allCheck').prop('checked', false);
      })
    }
  }
  allAgreeClick = () => {
    this.setState((state) => {
      state.allAgree = $('#allCheck').prop('checked');
      state.agree1 = $('#allCheck').prop('checked');
      state.agree2 = $('#allCheck').prop('checked');
      state.agree3 = $('#allCheck').prop('checked');
      state.agree4 = $('#allCheck').prop('checked');
      state.agreePos = $('#allCheck').prop('checked');
      //state.agree5 = $('#allCheck').prop('checked');
      //state.agree5_1 = $('#allCheck').prop('checked');
      $('input[id^=terms]').prop('checked', $('#allCheck').prop('checked'));
    });
  }
  agreeClick = (agreeCode) => {
    if (agreeCode === 1) {
      this.setState((state) => {
        state.agree1 = $('#terms01').prop('checked');
        this.allAgreeCheck();
      });
    }
    else if (agreeCode === 2) {
      this.setState((state) => {
        state.agree2 = $('#terms02').prop('checked');
        this.allAgreeCheck();
      });
    }
    else if (agreeCode === 3) {
      this.setState((state) => {
        state.agree3 = $('#terms03').prop('checked');
        this.allAgreeCheck();
      });
    }
    else if (agreeCode === 4) {
      this.setState((state) => {
        state.agree4 = $('#terms04').prop('checked');
        this.allAgreeCheck();
      });
    }
    else if (agreeCode === 'pos') {
      this.setState((state) => {
        state.agreePos = $('#termsPos').prop('checked');
        this.allAgreeCheck();
      });
    }
    // else if (agreeCode === 5) {
    //   this.setState((state) => {
    //     state.agree5 = $('#terms05').prop('checked');
    //     $('#terms05_01').prop('checked', $('#terms05').prop('checked'));
    //     state.agree5_1 = $('#terms05').prop('checked');
    //     this.allAgreeCheck();
    //   });
    // }
    // else if (agreeCode === '5_1') {
    //   this.setState((state) => {
    //     state.agree5 = $('#terms05_01').prop('checked');
    //     $('#terms05').prop('checked', $('#terms05_01').prop('checked'));
    //     state.agree5_1 = $('#terms05_01').prop('checked');
    //     this.allAgreeCheck();
    //   });
    // }

  }
  agreeClickPop = (agreeCode) => {
    if (agreeCode === 1) {
      $('#terms01').prop('checked', true);
      this.setState((state) => {
        state.agree1 = true;
        this.allAgreeCheck();
      });
      window.open('/apt/terms?type=1&loc=N&telcoCode=' + this.state.telcoCode, 'apc', 'toolbar=no,scrollbars=yes,resizable=no,top=0,left=0,width=600,height=600');
    }
    else if (agreeCode === 2) {
      $('#terms02').prop('checked', true);
      this.setState((state) => {
        state.agree2 = true;
        this.allAgreeCheck();
      });
      window.open('/apt/terms?type=2&telcoCode=' + this.state.telcoCode, 'apc', 'toolbar=no,scrollbars=yes,resizable=no,top=0,left=0,width=600,height=600');
    }
    else if (agreeCode === 3) {
      $('#terms03').prop('checked', true);
      this.setState((state) => {
        state.agree3 = true;
        this.allAgreeCheck();
      });
      window.open('/apt/terms?type=3&telcoCode=' + this.state.telcoCode, 'apc', 'toolbar=no,scrollbars=yes,resizable=no,top=0,left=0,width=600,height=600');
    }
    else if (agreeCode === 4) {
      //if (this.state.telcoCode === 1 || this.state.telcoCode === 2) {
        $('#terms04').prop('checked', true);
        this.setState((state) => {
          state.agree4 = true;
          this.allAgreeCheck();
        });
        window.open('/apt/terms?type=4&telcoCode=' + this.state.telcoCode, 'apc', 'toolbar=no,scrollbars=yes,resizable=no,top=0,left=0,width=600,height=600');
      //}
    }
    else if (agreeCode === 'pos') {
      //if (this.state.telcoCode === 1 || this.state.telcoCode === 2) {
        $('#termsPos').prop('checked', true);
        this.setState((state) => {
          state.agreePos = true;
          this.allAgreeCheck();
        });
        window.open('/apt/terms?type=6&loc=N', 'apc', 'toolbar=no,scrollbars=yes,resizable=no,top=0,left=0,width=600,height=600');
      //}
    }
    else {
      alert('서비스 준비중입니다.');
    }
  }
  onChangePhoneNo1 = (e) => {
    if (typeof e.target.value !== Number) {
      return false;
    }
    this.setState({
      phoneNo1: e.target.value,
      mdn: (e.target.value + this.state.phoneNo2)
    });
  }
  onChangePhoneNo2 = (e) => {
    let value = e.target.value;
    let currentLength = e.target.value.length;
    let maxLength = e.target.getAttribute('maxLength');

    if (maxLength < currentLength) {
      value = e.target.value.slice(0, maxLength);
      $('#phoneNo2').val(value);
    }

    this.setState({
      phoneNo2: value,
      mdn: (this.state.phoneNo1 + value)
    });
  }
  onChangeAuthCode = (e) => {
    let value = e.target.value;
    let currentLength = e.target.value.length;
    let maxLength = e.target.getAttribute('maxLength');

    if (maxLength < currentLength) {
      value = e.target.value.slice(0, maxLength);
      $('#authCode').val(value);
    }

    this.setState({
      authCode: e.target.value,
    });
  }
  onChangeCaptchaAnswer = (e) => {
    let value = e.target.value;
    let currentLength = e.target.value.length;
    let maxLength = e.target.getAttribute('maxLength');

    if (maxLength < currentLength) {
      value = e.target.value.slice(0, maxLength);
      $('#answer_captcha').val(value);
    }

    this.setState({
      captchaAnswer: value,
    });
  }
  commonUtil = {
    authTimer: null,
    displayTimer(str) {
      var html = $("#" + str).html();
      var min = html.substr(6, 2);
      var sec = html.substr(9, 2);

      if (sec === "00") {
        min = "0" + (parseInt(min) - 1);
        sec = "59";

      } else {
        if (html.substr(9, 1) === "0") {
          sec = html.substr(10, 1);
        }
        var tmp = parseInt(sec) - 1;
        if (tmp < 10) {
          sec = "0" + tmp;
        } else {
          sec = tmp;
        }
      }
      if (min === '00' && sec === '00') {
        $("#" + str).html("유효시간 만료");

      } else {
        $("#" + str).html("(" + "유효시간" + " " + min + ":" + sec + ")");
        this.authTimer = setTimeout(() => {
          this.displayTimer(str);
        }, 1000);
      }
    }
  }
  //regist
  checkPhone = () => {
    ////console.log(this.state.allAgree);
    if (this.state.telcoCode === '') {
      alert('통신사를 선택해주세요.');
      return false;
    }
    else if (this.state.mdn === '') {
      alert('휴대폰번호를 입력해주세요.');
      return false;
    }
    //// [SOM.221223] LGU+ CS폰 예외처리 (QA용)
    // else if ((this.state.telcoCode === 3) && !(this.state.mdn === '01022530158' 
    //                                           || this.state.mdn === '01039154463' 
    //                                           || this.state.mdn === '01076225149' 
    //                                           || this.state.mdn === '01023899310' 
    //                                           || this.state.mdn === '01056104463')) {
    //   alert('서비스 준비중입니다.');
    //   $('#telcoCode3').prop('checked', false);
    //   this.telcoClick(1);
    //   return false;
    // }
    else if (this.state.allAgree === false) {
      //alert('약관에 모두 동의해주세요.');
      if (this.allAgreeCheckConfirm() === false) {
        return false;
      }
    }

    if (this.commonUtil.authTimer != null) {
      window.clearTimeout(this.commonUtil.authTimer);
      $("#auth_timer").html("");
    }

    let paramForm = new FormData();
    paramForm.append('phoneNo', this.state.mdn);
    paramForm.append('telcoCode', this.state.telcoCode);
    paramForm.append('siteCode', 'AA'); //아파트청약케어 PC홈페이지가입창 가입코드 : AA
    paramForm.append('ctgCode', '1');
    paramForm.append('captchaAnswer', this.state.captchaAnswer);

    axios
      .post('apt/regist/checkPhone', paramForm)
      .then(res => {
        if (res.data.code === "E0000") {
          alert("휴대폰으로 인증번호가 발송되었습니다.");

          window.captcha.close();

          $('input[name=telcoCode]').attr("disabled", "disabled");
          $('#allCheck').attr("disabled", "disabled");
          $('input[name=terms]').attr("disabled", "disabled");
          $('#btn_setend').attr("disabled", "disabled");
          $('#section_stepB').hide();
          $("#section_auth").show();
          $("#phoneNo1").attr("disabled", "disabled");
          $("#phoneNo2").attr("disabled", "disabled");
          $("#btn_reque").show();
          $("#authCode").removeAttr("disabled").focus();
          $('#authCode').val('');

          this.setState({
            captchaAnswer: '',  // [SOM.230113] 재전송시에도 캡챠 뜨도록 보안문자값 초기화
            authCode: '',
          });

          // [SOM.230208] 민원대응 로그 저장 관련
          this.setState({
            accessCheck: Moment().format('YYYYMMDDHHmmss'),
            accessCheckUri: '/apt/regist/checkPhone',
          });

          $("#auth_timer").show().html("(유효시간 03:00)");
          this.commonUtil.authTimer = setTimeout(() => {
            this.commonUtil.displayTimer('auth_timer');
          });
        }
        else {
          if (res.data.code === "EW101" || res.data.code === "EW102") {
            if (res.data.code === "EW102") alert(res.data.message);
            
            window.captcha.phoneNo = this.state.mdn;

            $("#newSafeNum").click();
            $('#section_auth').hide();
            $("#section_stepB").show();
            $("#answer_captcha").val('');
            $('#authCode').val('');

            this.setState({
              captchaAnswer: '',
              authCode: '',
            });

          } else {
            alert(res.data.message);
            window.location.reload();
          }
        }
      });
  }
  submitRegist = (e) => {
    if (this.state.authCode === '') {
      alert('인증번호를 입력해주세요.');
      return false;
    }

    if (!window.confirm('가입을 진행하시겠습니까?')) return false;

    let paramForm = new FormData();
    paramForm.append('phoneNo', this.state.mdn);
    paramForm.append('telcoCode', this.state.telcoCode);
    paramForm.append('authCode', this.state.authCode);
    paramForm.append('siteCode', 'AA'); //아파트청약케어 PC홈페이지가입창 가입코드 : AA
    paramForm.append('ctgCode', '1');
    paramForm.append('marketingYn', 'N');
    // [SOM.230208] 민원대응 로그 저장 관련
    paramForm.append('accessJoin', this.state.accessJoin);
    paramForm.append('accessJoinUri', this.state.accessJoinUri);
    paramForm.append('accessCheck', this.state.accessCheck);
    paramForm.append('accessCheckUri', this.state.accessCheckUri);
    paramForm.append('referrer', this.state.referrer);

    axios
      .post('apt/regist/submitRegist', paramForm)
      .then(res => {
        if (res.data.code === "E0000") {
          if(res.data.response.svcCheckYn === "Y"){
            alert("LGU+ 시스템 점검으로 인하여 가입이 지연되고 있습니다.\n시스템 점검 후 가입이 완료 될 예정입니다.\n불편을 드려 죄송합니다");
          } else {
            alert("가입이 완료되었습니다.");
          }
          //window.location.reload();
          window.location.href = '/';
        }
        else {
          alert(res.data.message);
        }
      });
  }
  //expire
  expireCheckPhone = (e) => {
    if (this.state.telcoCode === '') {
      alert('통신사를 선택해주세요.');
      return false;
    }
    else if (this.state.mdn === '') {
      alert('휴대폰번호를 입력해주세요.');
      return false;
    }
    //// [SOM.221226] LGU+ CS폰 예외처리 (QA용)
    // else if ((this.state.telcoCode === 3) && !(this.state.mdn === '01022530158' 
    //                                           || this.state.mdn === '01039154463' 
    //                                           || this.state.mdn === '01076225149' 
    //                                           || this.state.mdn === '01023899310' 
    //                                           || this.state.mdn === '01056104463')) {
    //   alert('서비스 준비중입니다.');
    //   $('#telcoCode3').prop('checked', false);
    //   this.telcoClick(1);
    //   return false;
    // }

    if (this.commonUtil.authTimer != null) {
      window.clearTimeout(this.commonUtil.authTimer);
      $("#auth_timer").html("");
    }

    let paramForm = new FormData();
    paramForm.append('phoneNo', this.state.mdn);
    paramForm.append('telcoCode', this.state.telcoCode);
    paramForm.append('siteCode', 'AA'); //아파트청약케어 PC홈페이지가입창 가입코드 : AA
    paramForm.append('ctgCode', '1');
    paramForm.append('captchaAnswer', this.state.captchaAnswer);

    axios
      .post('apt/expire/checkPhone', paramForm)
      .then(res => {
        if (res.data.code === "E0000") {
          alert("휴대폰으로 인증번호가 발송되었습니다.");
          window.captcha.close();
          $('input[name=telcoCode]').attr("disabled", "disabled");
          $('#btn_setend').attr("disabled", "disabled");
          $('#section_stepB').hide();
          $("#section_auth").show();
          $("#phoneNo1").attr("disabled", "disabled");
          $("#phoneNo2").attr("disabled", "disabled");
          $("#btn_reque").show();
          $("#authCode").removeAttr("disabled").focus();
          $('#authCode').val('');
          this.setState({
            captchaAnswer: '',  // [SOM.230113] 재전송시에도 캡챠 뜨도록 보안문자값 초기화
            authCode: '',
          });

          $("#auth_timer").show().html("(유효시간 03:00)");
          this.commonUtil.authTimer = setTimeout(() => {
            this.commonUtil.displayTimer('auth_timer');
          });
        }
        else {
          if (res.data.code === "EW101" || res.data.code === "EW102") {
            if (res.data.code === "EW102") alert(res.data.message);

            // [SOM.231221] 해지 시 보안문자 노출 + SMS 전송 횟수 제한 정책 삭제 (기존 캡챠 주석) - (S)
            /*
            window.captcha.phoneNo = this.state.mdn;
            $("#newSafeNum").click();
            $('#section_auth').hide();
            $("#section_stepB").show();
            $("#answer_captcha").val('');
            $('#authCode').val('');
            this.setState({
              captchaAnswer: '',
              authCode: '',
            });
            */
           // [SOM.231221] 해지 시 보안문자 노출 + SMS 전송 횟수 제한 정책 삭제 (기존 캡챠 주석) - (E)

            // [SOM.231221] 해지 시 보안문자 노출 + SMS 전송 횟수 제한 정책 삭제 (수정) - (S)
            window.captcha.close();
            $('input[name=telcoCode]').attr("disabled", "disabled");
            $('#btn_setend').attr("disabled", "disabled");
            $('#section_stepB').hide();
            $("#section_auth").show();
            $("#phoneNo1").attr("disabled", "disabled");
            $("#phoneNo2").attr("disabled", "disabled");
            $("#btn_reque").show();
            $("#authCode").removeAttr("disabled").focus();
            $('#authCode').val('');
            this.setState({
              captchaAnswer: '',  // [SOM.230113] 재전송시에도 캡챠 뜨도록 보안문자값 초기화
              authCode: '',
            });

            $("#auth_timer").show().html("(유효시간 03:00)");
            this.commonUtil.authTimer = setTimeout(() => {
              this.commonUtil.displayTimer('auth_timer');
            });
            // [SOM.231221] 해지 시 보안문자 노출 + SMS 전송 횟수 제한 정책 삭제 (수정) - (E)

          } else {
            alert(res.data.message);
            window.location.reload();
          }
        }
      });
  }
  expireSubmit = async (e) => {
    // const axios = require('axios');
    if (this.state.authCode === '') {
      alert('인증번호를 입력해주세요.');
      return false;
    }

    let paramForm = new FormData();
    paramForm.append('phoneNo', this.state.mdn);
    paramForm.append('telcoCode', this.state.telcoCode);
    paramForm.append('siteCode', 'AA'); //아파트청약케어 PC홈페이지가입창 가입코드 : AA
    paramForm.append('authCode', this.state.authCode);
    paramForm.append('ctgCode', '1');
    paramForm.append('marketingYn', '');

    if (this.state.progress) return;

    this.setState({ progress: true });

    await axios
      .post('apt/expire/submit', paramForm)
      .then(res => {
        if (res.data.code === "E0000") {
          if(res.data.response.svcCheckYn === "Y"){ // 통신사 점검기간중 기가입자 지연해지 처리
            alert("현재 LGU+ 시스템 점검으로 서비스를 해지하실 수 없습니다.\n점검 후 입력하신 휴대폰 번호 기준으로 서비스 해지가 진행됩니다.\n불편을 드려 죄송합니다.");
          } else {  // 점검 아닐 경우 or 점검기간중 임시 가입한 유저 해지 
            alert("해지가 완료되었습니다.");
          }
          
          //window.location.reload();
          localStorage.removeItem('jwtToken');
          localStorage.removeItem('ref');
          localStorage.removeItem('isAutoLogin');

          window.location.href = '/';
          
        }
        else {
          alert(res.data.message);
        }
      });

    this.setState({ progress: false });
  }
  //login
  authCheckPhone = (e) => {
    if (this.state.telcoCode === '') {
      alert('통신사를 선택해주세요.');
      return false;
    }
    else if (this.state.mdn === '') {
      alert('휴대폰번호를 입력해주세요.');
      return false;
    }

    if (this.commonUtil.authTimer != null) {
      window.clearTimeout(this.commonUtil.authTimer);
      $("#auth_timer").html("");
    }

    let paramForm = new FormData();
    paramForm.append('phoneNo', this.state.mdn);
    paramForm.append('telcoCode', this.state.telcoCode);
    paramForm.append('siteCode', 'AA');//아파트청약케어 PC홈페이지가입창 가입코드 : AA
    paramForm.append('ctgCode', '1');
    paramForm.append('captchaAnswer', this.state.captchaAnswer);

    axios
      .post('apt/user/checkPhone', paramForm)
      .then(res => {
        if (res.data.code === "E0000") {
          alert("휴대폰으로 인증번호가 발송되었습니다.");
          window.captcha.close();
          $('input[name=telcoCode]').attr("disabled", "disabled");
          $('#btn_setend').attr("disabled", "disabled");
          $('#section_stepB').hide();
          $("#section_auth").show();
          $("#phoneNo1").attr("disabled", "disabled");
          $("#phoneNo2").attr("disabled", "disabled");
          $("#btn_reque").show();
          $("#authCode").removeAttr("disabled").focus();
          $('#authCode').val('');
          this.setState({
            captchaAnswer: '',  // [SOM.230113] 재전송시에도 캡챠 뜨도록 보안문자값 초기화
            authCode: '',
          });

          $("#auth_timer").show().html("(유효시간 03:00)");


          this.commonUtil.authTimer = setTimeout(() => {
            this.commonUtil.displayTimer('auth_timer');
          });
          // this.setState({
          //   stateAuthTimer: this.commonUtil.authTimer,
          // });
        }
        else {
          if (res.data.code === "EW101" || res.data.code === "EW102") {
            if (res.data.code === "EW102") alert(res.data.message);
            window.captcha.phoneNo = this.state.mdn;
            $("#newSafeNum").click();
            $('#section_auth').hide();
            $("#section_stepB").show();
            $("#answer_captcha").val('');
            $('#authCode').val('');
            this.setState({
              captchaAnswer: '',
              authCode: '',
            });

          } else {
            alert(res.data.message);
            window.location.reload();
          }
        }
      });
  }
  authSubmit = (e) => {
    if (this.state.authCode === '') {
      alert('인증번호를 입력해주세요.');
      return false;
    }

    let paramForm = new FormData();
    paramForm.append('phoneNo', this.state.mdn);
    paramForm.append('telcoCode', this.state.telcoCode);
    paramForm.append('authCode', this.state.authCode);
    paramForm.append('siteCode', 'AA');//아파트청약케어 PC홈페이지가입창 가입코드 : AA
    paramForm.append('ctgCode', '1');
    paramForm.append('marketingYn', 'N');
    paramForm.append('isAutoLogin', $('#savedUser').prop("checked"));

    axios
      .post('apt/user/submitAuth', paramForm)
      .then(res => {
        if (res.data.code === "E0000") {
          localStorage.setItem('jwtToken', res.headers.authorization);
          if ($('#savedUser').prop("checked") === true) {
            localStorage.setItem('ref', res.data.response.ref);
          }

          alert("로그인 완료되었습니다.");

          if ($('#savedPhoneNo').prop("checked") === true) { localStorage.setItem('savedPhoneNo', this.state.phoneNo2); }
          else { localStorage.removeItem('savedPhoneNo'); }

          if ($('#savedUser').prop("checked") === true) { localStorage.setItem('isAutoLogin', true); }
          else { localStorage.removeItem('isAutoLogin'); }

          window.location.href = '/';
        }
        else {
          alert(res.data.message);
        }
      });
  }
  //notice
  fetchNoticeList = (e) => {
    axios
      .get('apt/notice/list?currentPage=' + this.state.currentPage)
      .then(res => {
        this.setState({
          noticeList: res.data.response.noticeList,
          paginationObj: res.data.response.pagination
        });
      });

    $('tr.q').removeClass('on');
    $('div.con').hide();
  }
  moveNoticePage = (pageNum) => {
    this.setState((state) => {
      state.currentPage = pageNum;
      this.fetchNoticeList();
    });
  }
  moveNoticePrevPages = () => {
    this.setState((state) => {
      let moveGroupBeginPage = (this.state.currentPage - 5);
      if (moveGroupBeginPage <= 0) {
        moveGroupBeginPage = 1;
      }
      state.currentPage = moveGroupBeginPage;
      this.fetchNoticeList();
    });
  }
  moveNoticeNextPages = () => {
    this.setState((state) => {
      let moveGroupEndPage = (this.state.currentPage + 5);
      if (moveGroupEndPage > this.state.paginationObj.totalPage) {
        moveGroupEndPage = this.state.paginationObj.totalPage;
      }
      state.currentPage = moveGroupEndPage;
      this.fetchNoticeList();
    });
  }
  //faq
  fetchFaqList = (e) => {
    axios
      .get('apt/faq/list?currentPage=' + this.state.currentPage + '&type=' + this.state.faqType)
      .then(res => {
        this.setState({
          faqList: res.data.response.faqList,
          paginationObj: res.data.response.pagination,
          faqType: res.data.response.type
        });
      });
  }
  moveFaqPage = (pageNum) => {
    this.setState((state) => {
      state.currentPage = pageNum;
      this.fetchFaqList();
      $('tr.q').removeClass('on');
      $('div.con').hide();
    });
  }
  moveFaqPrevPages = () => {
    this.setState((state) => {
      let moveGroupBeginPage = (this.state.currentPage - 5);
      if (moveGroupBeginPage <= 0) {
        moveGroupBeginPage = 1;
      }
      state.currentPage = moveGroupBeginPage;
      this.fetchFaqList();
      $('tr.q').removeClass('on');
      $('div.con').hide();
    });
  }
  moveFaqNextPages = () => {
    this.setState((state) => {
      let moveGroupEndPage = (this.state.currentPage + 5);
      if (moveGroupEndPage > this.state.paginationObj.totalPage) {
        moveGroupEndPage = this.state.paginationObj.totalPage;
      }
      state.currentPage = moveGroupEndPage;
      this.fetchFaqList();
      $('tr.q').removeClass('on');
      $('div.con').hide();
    });
  }
  changeFaqType = (paramFaqType) => {
    this.setState((state) => {
      state.currentPage = 1;
      state.faqType = paramFaqType;
      this.fetchFaqList();
    });
    $('tr.q').removeClass('on');
    $('div.con').hide();
  }
  // molitLandNews 
  fetchMolitLandNewsList = (e) => {
    axios
      .get('apt/realtyPolicy/list?currentPage=' + this.state.currentPage)
      .then(res => {
        this.setState({
          policyList: res.data.response.policyList,
          paginationObj: res.data.response.pagination
        });
      })
      .catch(error => {
        if (typeof error.response.data !== 'undefined') {
          alert(error.response.data.message);
          return;
        }
      });
  }
  moveMolitLandNewsPage = (pageNum) => {
    this.setState((state) => {
      state.currentPage = pageNum;
      this.fetchMolitLandNewsList();
    });
  }
  moveMolitLandNewsPrevPages = () => {
    this.setState((state) => {
      let moveGroupBeginPage = (this.state.currentPage - 5);
      if (moveGroupBeginPage <= 0) {
        moveGroupBeginPage = 1;
      }
      state.currentPage = moveGroupBeginPage;
      this.fetchMolitLandNewsList();
    });
  }
  moveMolitLandNewsNextPages = () => {
    this.setState((state) => {
      let moveGroupEndPage = (this.state.currentPage + 5);
      if (moveGroupEndPage > this.state.paginationObj.totalPage) {
        moveGroupEndPage = this.state.paginationObj.totalPage;
      }
      state.currentPage = moveGroupEndPage;
      this.fetchMolitLandNewsList();
    });
  }
  //aptRecommend
  onChangeAptRecommendParam1 = (e) => {
    this.setState({
      aptRecommParam1: e.target.value,
    });
  }
  onChangeAptRecommendParam2 = (e) => {
    this.setState({
      aptRecommParam2: e.target.value,
    });
  }
  onChangeAptRecommendParam3 = (e) => {
    const max = e.target.getAttribute('maxLength');
    let value = e.target.value;

    if (value.length > max) value = value.slice(0, max);
    this.setState({
      aptRecommParam3: value,
    });
  }
  onChangeAptRecommendParam4 = (e) => {
    const max = e.target.getAttribute('maxLength');
    let value = e.target.value;

    if (value.length > max) value = value.slice(0, max);
    this.setState({
      aptRecommParam4: value,
    });
  }
  onChangeAptRecommendParam5 = (e) => {
    const max = e.target.getAttribute('maxLength');
    let value = e.target.value;

    if (value.length > max) value = value.slice(0, max);
    this.setState({
      aptRecommParam5: value,
    });
  }
  onChangeAptRecommendParam6 = (e) => {
    const max = e.target.getAttribute('maxLength');
    let value = e.target.value;

    if (value.length > max) value = value.slice(0, max);
    this.setState({
      aptRecommParam6: value,
    });
  }
  onChangeAptRecommendParam7 = (e) => {
    const max = e.target.getAttribute('maxLength');
    let value = e.target.value;

    if (value.length > max) value = value.slice(0, max);
    this.setState({
      aptRecommParam7: value,
    });
  }
  fetchSearchList = (e) => {
    if (this.state.aptRecommParam2 === '') {
      alert("지역을 확인하여 주세요.");
      return;
    }
    axios
      .get(`apt/recommend/addressList?param2=${this.state.aptRecommParam2}`)
      .then(res => {
        if (res.data.response != null && res.data.response.searchList.length > 0) {
          this.setState({
            aptRecommSearchList: res.data.response.searchList
          });
        }
        else {
          alert('검색결과가 없습니다. 다른 주소로 검색하여 주세요.');
          return;
        }
      });
  }
  selectAddr = (e, rateMap) => {
    e.preventDefault();
    this.setState({
      aptRecommParam2: e.target.innerText,
      aptRecommSearchList: [],
      aptRecommLoanRateMap: rateMap
    });
  }
  calcLoanAmonut = (e) => {
    this.goResult();

  }
  goResult = (e) => {
    if (this.state.aptRecommParam2 === '' || this.state.aptRecommParam3 === 0) {
      alert("지역 과 평수를 확인하여 주세요.");
      return;
    }

    document.location.href = `/aptRecommendResult?param1=${this.state.aptRecommParam1}&param2=${this.state.aptRecommParam2}&param3=${this.state.aptRecommParam3}&param4=${this.state.aptRecommParam4}&param5=${this.state.aptRecommParam5}&param6=${this.state.aptRecommParam6}&param7=${this.state.aptRecommParam7}`;
  }
  fetchResultList = (search) => {
    const paramArr = search.split('?')[1].split('&');
    const param1 = paramArr[0].split('param1=')[1];
    const param2 = paramArr[1].split('param2=')[1];
    const param3 = paramArr[2].split('param3=')[1];
    const param4 = paramArr[3].split('param4=')[1];
    const param5 = paramArr[4].split('param5=')[1];
    const param6 = paramArr[5].split('param6=')[1];
    const param7 = paramArr[6].split('param7=')[1];
    axios
      //.get(`apt/recommend/resultList?param1=${param1}&param2=${param2}&param3=${param3}&param4=${param4}&param5=${param5}&param6=${param6}&param7=${param7}`)
      .get(`apt/recommend/aptList?param1=${param1}&param2=${param2}&param3=${param3}&param4=${param4}&param5=${param5}&param6=${param6}&param7=${param7}`)
      .then(res => {
        if (res.data.response != null && res.data.response.aptList.length > 0) {
          this.setState({
            //aptRecommResultList : res.data.response.resultList
            aptRecommResultList: res.data.response.aptList
          });
        }
      });
  }

  // 민원대응로그용 시간 구하기
  getCurrentDate = () => {
    const date = new Date();
    const year = date.getFullYear().toString();

    let month   = date.getMonth() + 1;
    let day     = date.getDate();
    let hour    = date.getHours();
    let minutes = date.getMinutes();
    let seconds = date.getSeconds();

    month   = ((month < 10) ? '0' : '') + month.toString();
    day     = ((day < 10) ? '0' : '') + day.toString();
    hour    = ((hour < 10) ? '0' : '') + hour.toString();
    minutes = ((minutes < 10) ? '0' : '') + minutes.toString();
    seconds = ((seconds < 10) ? '0' : '') + seconds.toString();

    return year + month + day + hour + minutes + seconds;
  }
}
export default CommonComponent