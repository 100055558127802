import React, { Component } from 'react';
import { addLsItem, getLsItem } from 'common/Search';
import { findIndex } from 'common/ArrayUtil';
import $ from 'jquery';
import 'jquery-ui-bundle'
import { filtersNumberReplaceWithStag } from 'common/StringUtil';

//// 컴포넌트 사용처 : 아파트 정보
class MapAptSrchPk extends Component {

  constructor(props) {
    super(props);
    this.state = {
      pkList: [
        { name: "전체", visible: true, min: -1, max: 9999, minTxt: "전체", maxTxt: "전체" },
        { name: "1.0미만", visible: false, min: 0, max: 10, minTxt: "1.0대 미만", maxTxt: "1.0대 미만" },
        { name: "1.0 ~ 1.1", visible: false, min: 10, max: 11, minTxt: "1.0", maxTxt: "1.1대 미만" },
        { name: "1.1 ~ 1.2", visible: false, min: 11, max: 12, minTxt: "1.1", maxTxt: "1.2대 미만" },
        { name: "1.2 ~ 1.3", visible: false, min: 12, max: 13, minTxt: "1.2", maxTxt: "1.3대 미만" },
        { name: "1.3 ~ 1.4", visible: false, min: 13, max: 14, minTxt: "1.3", maxTxt: "1.4대 미만" },
        { name: "1.4 ~ 1.5", visible: false, min: 14, max: 15, minTxt: "1.4", maxTxt: "1.5대 미만" },
        { name: "1.5 ~ 2.0", visible: false, min: 15, max: 20, minTxt: "1.5", maxTxt: "2.0대 미만" },
        { name: "2.0이상", visible: false, min: 20, max: 9999, minTxt: "2.0대 이상", maxTxt: "2.0대 이상" },
      ],

      selectedMinIdx: 0,
      selectedMaxIdx: 0,
      slideMinTxt: '',
      slideMaxTxt: '',
    };
  }

  componentDidMount() {

    let self = this;

    $(".sliderPk").slider({
      range: true,
      min: 0,
      max: 21,
      values: [0, 21],
      slide: function (event, ui) {
        self.setState({
          selectedMinIdx: -1, selectedMaxIdx: -1,
          slideMinTxt: ui.values[0] > 0 ? parseFloat(ui.values[0] / 10).toFixed(1) : 0,
          slideMaxTxt: ui.values[1] === 21 ? '2.0대 이상' : parseFloat(ui.values[1] / 10).toFixed(1) + '대 미만'
        });
      },
      stop: function (event, ui) {

        const current = self.state.pkList.slice();
        current.forEach(v => v.visible = false)
        self.setState(({ pkList: current }));
        self.props.onSearch({ type: 'PK', min: ui.values[0], max: ui.values[1] > 20 ? 9999 : ui.values[1], load: true });
      }
    });

    if (this.props.loadFilter) {

      let aptPkFilter = getLsItem('aptPkFilter');
      if (aptPkFilter && aptPkFilter.filter && aptPkFilter.sliderValue) {
        $(".sliderPk").slider("values", aptPkFilter.sliderValue);
        this.setState({ pkList: aptPkFilter.filter });

        if (aptPkFilter.filter.some(f => f.visible)) {
          const minValue = aptPkFilter.filter.filter(f => f.visible).reduce((p, c) => p > c ? c : p).min;
          const maxValue = aptPkFilter.filter.filter(f => f.visible).reduce((p, c) => p > c ? p : c).max;
          this.props.onSearch({ type: 'PK', min: minValue, max: maxValue, load: false });
        } else {
          this.props.onSearch({ type: 'PK', min: aptPkFilter.sliderValue[0], max: aptPkFilter.sliderValue[1], load: false });
        }
      }
    }
  }

  componentDidUpdate() {
    if (this.props.saveFilter) {
      addLsItem('aptPkFilter', { filter: this.state.pkList, sliderValue: $(".sliderPk").slider("option", "values") });
    }
  }

  selectPk(idx) {

    const current = this.state.pkList.slice();
    const checkedCnt = current.filter(e => e.visible).length;
    let visibleIndex = findIndex(this.state.pkList, i => i.visible);

    let minc = 0;
    let maxc = 0;

    let minIdx = 0;
    let maxIdx = 0;

    if (visibleIndex !== 0 && checkedCnt === 1 && idx > visibleIndex) {
      current.filter((v, i) => i >= visibleIndex && i <= idx).forEach(v => v.visible = true);
      minc = current[visibleIndex].min;
      maxc = current[idx].max;

      minIdx = visibleIndex;
      maxIdx = idx;
    } else {
      current.forEach(c => c.visible = false);
      current[idx].visible = true;
      minc = current[idx].min;
      maxc = current[idx].max;

      minIdx = idx;
      maxIdx = idx;
    }

    this.props.onSearch({ type: 'PK', min: minc, max: maxc, load: true });
    $(".sliderPk").slider("values", [minc, maxc]);
    this.setState({ pkList: current, selectedMinIdx: minIdx, selectedMaxIdx: maxIdx });
  }

  render() {
    const { pkList, selectedMinIdx, selectedMaxIdx, slideMinTxt, slideMaxTxt } = this.state;
    let minTxt = selectedMinIdx != -1 ? pkList[selectedMinIdx].minTxt : '';
    let maxTxt = selectedMaxIdx != -1 ? pkList[selectedMaxIdx].maxTxt : '';
    return (
      <div className="filter_wrap">
        <div className="tit_wrap">
          <strong>주차대수</strong>
          <span className="choice">{
            (selectedMinIdx !== -1 && selectedMaxIdx !== -1) ? (
              maxTxt == "전체" ? "전체" :
                (minTxt == maxTxt || minTxt == "1.0대 미만") ? filtersNumberReplaceWithStag(maxTxt) :
                  <>{filtersNumberReplaceWithStag(minTxt)} ~ {filtersNumberReplaceWithStag(maxTxt)}</>
            )
              : (selectedMinIdx === -1 && selectedMaxIdx === -1) && (
                (slideMinTxt === slideMaxTxt || slideMinTxt === 0 || Math.round(slideMinTxt) > Math.round('2.0')) ? filtersNumberReplaceWithStag(slideMaxTxt) :
                  <>{filtersNumberReplaceWithStag(slideMinTxt)} ~ {filtersNumberReplaceWithStag(slideMaxTxt)}</>
              )
          }</span>
        </div>
        <div className="filter_inner _line">
          <div className="slider sliderPk"></div>
          <ul className="slider_lbl">
            <li>0.0</li>
            <li>0.5</li>
            <li>1.0</li>
            <li>1.5</li>
            <li>2.0</li>
          </ul>
          {
            this.state.pkList.map((v, idx) =>
              <button type="button"
                className={v.visible ? "_set" : ""}
                onClick={() => this.selectPk(idx)}>{v.name}
              </button>
            )
          }
        </div>
      </div>
    );
  }
}
export default MapAptSrchPk;