import React from 'react';
import CommonComponent from './Common'
import $ from 'jquery'

//// 컴포넌트 사용처 : 로그인
class Login extends CommonComponent {
  componentDidMount() {
    localStorage.removeItem('jwtToken');
    localStorage.removeItem('ref');
    localStorage.removeItem('isAutoLogin');

    this.telcoClick(1);

    if (localStorage.getItem('savedPhoneNo') !== '') {
      // $('#phoneNo2').val(localStorage.getItem('savedPhoneNo'));
      this.setState({
        phoneNo2: localStorage.getItem('savedPhoneNo'),
        mdn: this.state.phoneNo1 + localStorage.getItem('savedPhoneNo')
      });
    }
  }

  render() {
    const { phoneNo2, captchaAnswer, authCode } = this.state;
    return (
      <div id="content" className="login">
        <div className="inner_wrap">
          <div className="con_tit_wrap">
            <h2 className="tit">로그인</h2>
            <ul className="sub_txt">
              <li>- 아파트청약케어 이용을 위해 로그인해주세요.</li>
              <li>- 가입하셨던 휴대폰번호로도 로그인이 가능해요!</li>
            </ul>
          </div>
          <div className="form_wrap join_wrap">
            <section className="form_inner">
              <ul className="form">
                <li className="item">
                  <strong className="tit">통신사</strong>
                  <div className="ip_wrap rdo_wrap">
                    <div className="rdo_item">
                      <input type="radio" id="telcoCode1" name="telcoCode" onClick={() => this.telcoClick(1)} />
                      <label htmlFor="telcoCode1">SKT</label>
                    </div>
                    <div className="rdo_item">
                      <input type="radio" id="telcoCode2" name="telcoCode" onClick={() => this.telcoClick(2)} />
                      <label htmlFor="telcoCode2">KT</label>
                    </div>
                    <div className="rdo_item">
                      <input type="radio" id="telcoCode3" name="telcoCode" onClick={() => this.telcoClick(3)} />
                      <label htmlFor="telcoCode3">LGU+</label>
                    </div>
                  </div>
                </li>
                <li className="item">
                  <strong className="tit">휴대폰번호</strong>
                  <div className="ip_wrap">
                    <div className="select_box">
                      <select name="phoneNo1" id="phoneNo1" title="휴대폰 번호 앞자리 선택" className="code" onChange={this.onChangePhoneNo1}>
                        <option value="010">010</option>
                        <option value="011">011</option>
                        <option value="016">016</option>
                        <option value="017">017</option>
                        <option value="018">018</option>
                        <option value="019">019</option>
                      </select>
                    </div>
                    <div className="ip_box">
                      <label htmlFor="phoneNo2" className="lbl" >휴대폰번호를 입력해주세요.</label>
                      <input type="number" pattern="[0-9]+" id="phoneNo2" className="ip ip_phone code" name="phoneNo2" maxLength="8" value={phoneNo2} onChange={this.onChangePhoneNo2} />
                    </div>
                  </div>
                  <button id="btn_setend" type="button" className="btn btn01" disabled={!phoneNo2} onClick={this.authCheckPhone}>인증요청</button>
                </li>
                <li id="section_auth" className="item" style={{ "display": "none" }}>
                  <strong className="tit">인증번호</strong>
                  <div className="ip_wrap">
                    <div className="ip_box">
                      <label htmlFor="authCode" className="lbl">인증번호를 입력해주세요.</label>
                      <input type="number" id="authCode" className="ip code" name="authCode" maxLength="6" onChange={this.onChangeAuthCode} disabled />
                      <span id="auth_timer" className="time" style={{ display: 'none' }}>03:00</span>
                    </div>
                  </div>
                  <button id="btn_reque" type="button" className="btn btn02" disabled={!phoneNo2} onClick={this.authCheckPhone} style={{ display: 'none' }}>재발송</button>
                </li>
                <li id="section_stepB" className="item" style={{ display: 'none' }}>
                  <strong className="tit">보안문자</strong>
                  <div className="security_wrap">
                    <div className="img" ><img id="img_captcha" src={process.env.REACT_APP_ENV_URL + "resources/images/temp01.png"} alt="" /></div>
                    <button id="newSafeNum" type="button" className="btn_reset">새로고침</button>
                  </div>
                  <div className="ip_wrap">
                    <div className="ip_box">
                      <label htmlFor="answer_captcha" className="lbl">보안문자(숫자)입력</label>
                      <input type="number" id="answer_captcha" className="ip code" name="security" maxLength="6" onChange={this.onChangeCaptchaAnswer} />
                    </div>
                  </div>
                  <button id="submit_captcha" type="button" className="btn btn03">다음</button>
                </li>
              </ul>
              <div id="termsCheck" className="terms_list">
                <ul className="all_list">
                  <li className="all">
                    <span className="checkbox i_all">
                      <input type="checkbox" name="savedPhoneNo" id="savedPhoneNo" value="" />
                      <label htmlFor="savedPhoneNo"><i></i>휴대폰번호 저장</label>
                    </span>
                  </li>
                  <li className="all">
                    <span className="checkbox i_all">
                      <input type="checkbox" name="savedUser" id="savedUser" value="" />
                      <label htmlFor="savedUser"><i></i>로그인 상태 유지</label>
                    </span>
                  </li>
                </ul>
              </div>
            </section>
          </div>
          <div className="btn_wrap">
            <button type="button" className="btn" disabled={!authCode} onClick={this.authSubmit}>로그인</button>
          </div>
        </div>
      </div>
    );
  }
}
export default Login;