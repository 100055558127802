import React, { Component } from 'react';
import Moment from 'moment';
import { numberWithCounts } from 'common/StringUtil';

//// 컴포넌트 사용처 : 청약 당첨예상 상세
class RecentResult extends Component {

  render() {

    const { maxScoreList, minScoreList, recentList } = this.props.calcResult;

    return (
      <>
        <div className="sec" id="sec5">
          <div className="bubble_tit">
            <strong className="high">당첨 최고 가점<s>TOP 3</s></strong>
            <strong className="low">당첨 최저 가점<s>TOP 3</s></strong>
          </div>
          <div className="bubble">
            <ul className="high">
              {
                maxScoreList.map((v, idx) => {

                  return <li key={idx}>
                    <strong className="rank">TOP 0{idx + 1}</strong>
                    <p>{v.aptName}</p>
                    <span>최고 <s>{v.maxScore}</s></span>
                  </li>;
                })
              }
            </ul>
            <ul className="low">
              {
                minScoreList.map((v, idx) => {
                  return <li key={idx}>
                    <strong className="rank">TOP 0{idx + 1}</strong>
                    <p>{v.aptName}</p>
                    <span>최고 <s>{v.minScore}</s></span>
                  </li>
                })
              }
            </ul>
          </div>
          <table className="tb_apt ty01">
            <caption>
              <strong>청약 정보 리스트</strong>
              <p>이름, 주소, 실거래가 등 청약정보 테이블</p>
            </caption>
            <colgroup>
              <col style={{ 'width': '30%' }} />
              <col style={{ 'width': '15%' }} />
              <col style={{ 'width': '30%' }} />
              <col style={{ 'width': '15%' }} />
              <col style={{ 'width': '10%' }} />
            </colgroup>
            <thead>
              <tr>
                <th>아파트명</th>
                <th>지역</th>
                <th>실거래가 / 준공년도 / 세대수 / 지역안내</th>
                <th>최고 · 최저 가점</th>
                <th>경쟁률</th>
              </tr>
            </thead>
            <tbody>
              {
                recentList.map((v, idx) => {
                  return <tr key={idx}>
                    <td>{v.aptName}</td>
                    <td>{v.juso}</td>
                    <td>{numberWithCounts(v.applyPrice * 10000)} / {Moment(v.notiDate).format('YYYY. MM')} /  {v.applyCnt}세대 / {v.loanRate === '1' ? '청약과열' : v.loanRate === '2' ? '조정지역' : '일반지역'}</td>
                    <td className="high_low"><span>{v.maxScore} · </span><span>{v.minScore}</span></td>
                    <td>{v.rate}:1</td>
                  </tr>
                })
              }
            </tbody>
          </table>
        </div>
      </>
    );
  }
}

export default RecentResult;
