import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { popupOpen, popupClose } from 'common/Design';
import $ from 'jquery'
import axios from 'axios';

// import { auth } from 'common/Authorization';

class InterestEvent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isPopup: false,
      isCheckPhone: false,
    };
    this.etcInput = React.createRef();
    this.loginJoin = props.location.state.login;
  }

  componentDidMount() {
    document.querySelector('#header').style.display = 'none';
    document.querySelector('#content').style.marginTop = '0';
    document.querySelector('#wrap').style.minWidth = 'auto';
  }

  componentWillUnmount() {
    document.querySelector('#header').removeAttribute('style');
    document.querySelector('#content').removeAttribute('style');
    document.querySelector('#wrap').removeAttribute('style');
  }

  // [SOM.220603] 닫기 버튼 클릭 시
  closeHandler = () => {
    if (window.confirm('현재 이벤트 진행중입니다. 정말 중단하시겠습니까?')) {
      window.location.href = '/apartMap';
    }
  };

  // 기타 클릭 시
  etcHander = (e) => {
    if (e.target.checked) {
      this.etcInput.current.removeAttribute('disabled');
      this.etcInput.current.focus();
    } else {
      this.etcInput.current.setAttribute('disabled', 'disabled');
      this.etcInput.current.value = '';
    }
  };

  // 제출 버튼 클릭 시
  submitHandler = () => {
    this.setState({
      isPopup: true,
    });

    // 유효성 체크
    var checkPhoneRslt = this.checkPhone(this.loginJoin);
    
    // axios 응답시간 지연으로 타이머 추가
    setTimeout(() => {

      if (this.loginJoin) {   // 로그인한 회원일때
        if(this.state.isCheckPhone) {   // 결과값 체크
          // 완료팝업 띄우기
          popupOpen('complete', 'mw');
        } 

      } else {  // 비회원일때
        
        if ( checkPhoneRslt ) {
          // 전화번호 입력 팝업 띄우기            
          popupOpen('form', 'mw');
        }
      }

    }, 300);

  };

  // [SOM.220602] 비회원 > 전화번호 입력 alert창에서 확인 버튼 클릭시
  submitHandler2 = () => {
    
    if ($('#phoneNo2').val() == '') {
      alert('휴대폰번호를 입력해주세요.');
      return false;
    } else if ($('#termsCreditAgree2').prop('checked') === false) {
      alert('개인정보수집 및 이용동의를 확인해주세요.');
      return false;
    }

    var phoneNo = $('#phoneNo1').val() + $('#phoneNo2').val();

    // 서버로 관심사 data 전송
    this.interestSave(phoneNo);

    // axios 응답시간 지연으로 타이머 추가
    setTimeout(() => {

      if(this.state.isCheckPhone) {   // 결과값 체크
        popupOpen('complete', 'mw');  // 완료팝업 띄우기
        popupClose('form', 'mw');     // 전화번호 입력 팝업 종료
      }

    }, 300);
  };

  // [SOM.220602] 전화번호 자리수 체크
  onChangePhoneNo2 = (e) => {
    let value = e.target.value;
    let currentLength = e.target.value.length;
    let maxLength = e.target.getAttribute('maxLength');

    if (maxLength < currentLength) {
      value = e.target.value.slice(0, maxLength);
      $('#phoneNo2').val(value);
    }
  }

  // 제출 완료 시
  // Link가 아니라 button으로 하는게 나을지도...?
  completeHandler = () => {
    popupClose('complete', 'mw');
    this.setState({
      isPopup: false,
    });

    // [SOM.220530] 제출 완료시 팝업 미노출
    localStorage.setItem('interestEventPop01Open', false);
  };

  // [SOM.220530] 다시보지않기 버튼 클릭시 호출 
  interestEventPopCloseNeverOpen = () => {
    if (window.confirm('현재 이벤트 진행중입니다. 정말 중단하시겠습니까?')) {
      localStorage.setItem('interestEventPop01Open', false);
      window.location.href = '/'; 
    }
  }

  // [SOM.220531] 개인정보수집 및 이용동의 약관 띄우기
  agreeClickPop = () => {
    window.open('/apt/terms?type=2', 'apc', 'toolbar=no,scrollbars=yes,resizable=no,top=0,left=0,width=600,height=600');
  }

  // [SOM.220531] 유효성 체크 & 관심사 data 저장
  checkPhone = (chkLoginJoin) => {
    if ($("input:checkbox[name='item']").is(":checked") === false) {
      alert("관심사 정보를 체크해주세요.");
      return false;
    } else if($('#item_etc').prop('checked') === true && $('#item_etc_input').val() == '') {
      alert("상세내용을 작성해주세요.");
      return false;
    } else if (chkLoginJoin && $('#termsCreditAgree').prop('checked') === false) {
      alert('개인정보수집 및 이용동의를 확인해주세요.');
      return false;
    }

    if(chkLoginJoin) {
      this.interestSave('');  // 회원 로그인일때 관심사 data 저장
    } else {
      return true;
    }
  }

  // [SOM.220602] 서버로 관심사 data 전송
  interestSave = async (phoneNo) => {
    let loginExpireCheck = false;

    var item_arr = [];
    if($("input[name=item]:checked").each(function () {
      item_arr.push( $(this).val() );
     }))
    
    var item_str = item_arr.join('|');          // 관심사(선택값) 구분자로 연결
    var item_etc = $('#item_etc_input').val();  // 관심사(입력값)

    let paramForm = new FormData();
    paramForm.append('interestItem', item_str);       // 관심사 (선택값_공통) - 구분자포함
    paramForm.append('interestItemEtc', item_etc);    // 관심사 (입력값_공통)
    paramForm.append('phoneNo', phoneNo);             // 전화번호(비회원일때만)
    paramForm.append('path', '1');                    // 유입경로 (1:PC, 2:mobile)

    if (this.loginJoin) {   // 로그인한 회원일때
      // jwt 유효성 체크 및 토큰 재발급
      // const verify = await auth.verify();
      //
      // switch (verify.resVerify.type) {
      //   case 'checkSuccess': {
      //     break;
      //   }
      //   case 'checkFail': {
      //     loginExpireCheck = true;
      //     alert("로그인 시간이 만료되었습니다. 다시 로그인해주세요.");
      //     window.location.href = '/login';
      //     break;
      //   }
      //   case 'reIssueSuccess': {
      //     localStorage.setItem('jwtToken', verify.resVerify.authorization);
      //     break;
      //   }
      //   case 'reIssueFail': {
      //     loginExpireCheck = true;
      //     alert("로그인 시간이 만료되었습니다. 다시 로그인해주세요.");
      //     window.location.href = '/login';
      //     break;
      //   }
      //   default : {}
      // }
    }

    if(!loginExpireCheck) {
      axios
        .post('apt/promotion/userInterestSave', paramForm, { headers: { 'Authorization': 'Bearer ' + localStorage.getItem('jwtToken') } })
        .then(res => {
          if (res.data.code === "E0000") {
            this.setState({
              isCheckPhone: true,
            });
          }
          else {
            alert(res.data.message);
            return false;
          }
        });
    }
  }

  render() {
    return (
      <>
        <div id="content" className="promotion_wrap interest">
          <div className="interest_wrap type1">
            <div className="img_top_wrap">
              <img
                src={
                  process.env.REACT_APP_ENV_URL +
                  'resources/images/promotion/interest/img_top2.png'
                }
                alt="부동산 관심사 체크하고 스벅 아메리카노 받자"
              />
              <img
                src={
                  process.env.REACT_APP_ENV_URL +
                  'resources/images/promotion/interest/img_bean.png'
                }
                alt="커피콩"
                className="img_bean"
                aria-hidden="true"
              />
              <img
                src={
                  process.env.REACT_APP_ENV_URL +
                  'resources/images/promotion/interest/img_bean_1.png'
                }
                alt="커피콩"
                className="img_bean1"
                aria-hidden="true"
              />
              <img
                src={
                  process.env.REACT_APP_ENV_URL +
                  'resources/images/promotion/interest/img_bean_2.png'
                }
                alt="커피콩"
                className="img_bean2"
                aria-hidden="true"
              />
            </div>
            <div className="noti_box">
              <div className="noti_box_header">
                <strong className="noti_box_title">
                  부동산 서비스 이용시
                  <br />
                  주로 확인하는 정보를 선택해주세요.
                </strong>
                <p>* 중복 선택가능</p>
              </div>
              <div className="label_check_wrap">
                <label className="label_check">
                  <input type="checkbox" name="item" id="item1" value="1" />
                  <span>아파트 실거래가</span>
                </label>
                <label className="label_check">
                  <input type="checkbox" name="item" id="item2" value="2" />
                  <span>민간분양</span>
                </label>
                <label className="label_check">
                  <input type="checkbox" name="item" id="item3" value="3" />
                  <span>공공분양/임대</span>
                </label>
                <label className="label_check">
                  <input type="checkbox" name="item" id="item4" value="4" />
                  <span>무순위 청약</span>
                </label>
                <label className="label_check">
                  <input type="checkbox" name="item" id="item5" value="5" />
                  <span>재개발/재건축 정보</span>
                </label>
                <label className="label_check">
                  <input type="checkbox" name="item" id="item6" value="6" />
                  <span>학군 정보</span>
                </label>
                <label className="label_check">
                  <input type="checkbox" name="item" id="item7" value="7" />
                  <span>주택담보대출</span>
                </label>
                <label className="label_check">
                  <input type="checkbox" name="item" id="item8" value="8" />
                  <span>청약 당첨 시 시세차익</span>
                </label>
              </div>
              <div className="label_check_wrap">
                <label className="label_check">
                  <input type="checkbox" name="item" id="item_etc" value="0" onChange={this.etcHander} />
                  <span>기타</span>
                </label>
              </div>
              <input
                type="text"
                className="interest_input"
                id="item_etc_input"
                maxLength="30"
                placeholder="주로 확인하는 정보를 직접 입력해주세요."
                disabled
                ref={this.etcInput}
              />
              {this.loginJoin && (
                <div className="noti_box_bottom">
                  <label className="label_check type1">
                    <input type="checkbox" name="termsCreditAgree" id="termsCreditAgree" value="" />
                    <span>
                      <span className="blind">개인정보수집 및 이용동의</span>
                    </span>
                  </label>
                  <button type="button" onClick={() => this.agreeClickPop()}>개인정보수집 및 이용동의</button>
                </div>
              )}
            </div>
            <img
              src={
                process.env.REACT_APP_ENV_URL +
                'resources/images/promotion/interest/img_notice2.png'
              }
              alt="유의사항"
            />
            {!this.state.isPopup && (
              <button type="button" className="btn_close" onClick={()=>this.closeHandler()}>
                <span className="blind">닫기</span>
              </button>
            )}
          </div>
          <div className="btn_bottom_fixed">
            <button type="button" className="btn_secondary" onClick={this.interestEventPopCloseNeverOpen}>
              다시보지않기
            </button>
            <button
              type="button"
              className="btn_primary"
              onClick={this.submitHandler}
            >
              제출
            </button>
          </div>
        </div>

        {/* 정보 입력 팝업 */}
        {!this.loginJoin && (
          <div id="mw-form" className="ly_cpinfo_pc ly_interest_type1 ndim">
            <div className="wrap">
              <div className="content">
                <strong className="ly_interest_title">
                  교환권을 받으실 정보를
                  <br />
                  입력해주세요.
                </strong>
                <div className="ly_interest_form">
                  <select name="phoneNo1" id="phoneNo1" defaultValue="010">
                    <option value="010">010</option>
                    <option value="011">011</option>
                    <option value="016">016</option>
                    <option value="017">017</option>
                    <option value="018">018</option>
                    <option value="019">019</option>
                  </select>
                  <span className="unit"></span>
                  <input type="number" pattern="[0-9]+" id="phoneNo2" name="phoneNo2" maxLength="8" onChange={this.onChangePhoneNo2}/>
                </div>
                <div className="content_bottom">
                  <label className="label_check type1">
                    <input type="checkbox" name="termsCreditAgree2" id="termsCreditAgree2" value=""/>
                    <span>
                      <span className="blind">개인정보수집 및 이용동의</span>
                    </span>
                  </label>
                  <button type="button" onClick={() => this.agreeClickPop()}>개인정보수집 및 이용동의</button>
                </div>
              </div>
              <div className="btn_area_wide">
                <button
                  type="button"
                  className="skip"
                  onClick={this.submitHandler2}
                >
                  확인
                </button>
              </div>
              <button
                type="button"
                className="btn_close"
                onClick={() => popupClose('form', 'mw')}
              >
                <span className="blind">닫기</span>
              </button>
            </div>
          </div>
        )}

        {/* 완료 팝업 */}
        <div id="mw-complete" className="ly_cpinfo_pc ly_interest_type1 ndim">
          <div className="wrap">
            <div className="content">
              <strong className="ly_interest_title type1">
                부동산 관심사 응답이
                <br />
                완료되었습니다.
              </strong>
              <p className="p_strong">참여해주셔서 감사합니다!</p>
            </div>
            <div className="btn_area_wide">
              <Link to="/" className="skip" onClick={this.completeHandler}>
                확인
              </Link>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default InterestEvent;
