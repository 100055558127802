import React, { Component } from 'react';
import $ from 'jquery'
import { orderBy } from 'lodash';
import { addFavItem, getFavList, clearFavItem } from 'routes/map/MapAptFavUtil';
import { numberWithCommas, numberWithCounts, dateReplaceWithStag, numberReplaceWithStag, dateFormatMsg } from 'common/StringUtil';
import { uiCalcSelect } from 'common/Design';

import MultiType from 'routes/chart/MultiType';
import RMateCandleStickType from 'routes/rMateChart/RMateCandleStickType';
import RMateChartH5 from "../rMateChart/RMateChartH5";

//// 컴포넌트 사용처 : 아파트 정보 상세
class MapAptDtl extends Component {

  constructor(props) {
    super(props);
  }

  componentDidMount = () => {
    uiCalcSelect.init();
    $('html').addClass('_full');
  }

  mappingChartValue = (mapArr, objName) => mapArr.map(item => item[objName])

  fetchGraph = (dataTab) => {
    this.props.onGraph(this.props.aptArea.aptAreaId, dataTab);
  }

  handleFavCheck = (e) => {

    const { target: { checked } } = e;
    if (checked) {
      addFavItem({ aptId: this.props.aptDtl.aptId, aptAreaId: this.props.aptArea.aptAreaId, favTime: new Date() });
    } else {
      clearFavItem({ aptId: this.props.aptDtl.aptId })
    }
    this.props.onAddFav();
    this.forceUpdate();
  }

  moreView = function (e) {
    $(e.target).parent().toggleClass('show');
    $(e.target).parent().find('li').eq(2).nextAll().toggleClass('hide');
  }

  // recentPy = () => {
  //   if (!this.props.aptDtl.aptAreaList || !this.props.aptArea) {
  //     return <></>;
  //   }
  //   const aptArea = this.props.aptDtl.aptAreaList.find(v => Number(v.aptAreaId) === this.props.aptArea.aptAreaId);
  //   return aptArea ? <><s>{aptArea.pubAreaPy}</s>평 (<s>{aptArea.pubArea}</s>㎡)</> : <></>;

  // }

  render() {

    const { queryAptId, pathname, aptDtl, aptArea, trxDr, graphList, onRenderGraph, trxList, trxLimitSize, onTrxLimitSize } = this.props;
    const favorite = getFavList().some(v => v.aptId === aptDtl.aptId);

    return (
      <div className={queryAptId && pathname !== "/" ? "panel_marker _first on" : "panel_marker"} >
        <div className="apt_info_header">
          <div className="sub_header">
            <button type="button" className="btn_back_w"><i>돌아가기</i></button>
            <h3 data-tit={aptDtl.aptName}></h3>
            <button type="button" className="btn_fold on"><i>닫기</i></button>
          </div>
          <div className="info_area">
            <div className="apt_name">
              <div className="tit">
                <div className="check_form ck_star_w">
                  <input type="checkbox" className="hide check-one" id="star02"
                    checked={favorite}
                    onClick={this.handleFavCheck} />
                  <label htmlFor="star02"></label>
                </div>
                <h3>{aptDtl.aptName}</h3>
              </div>
              <p className="txt">{aptDtl.jibunAddr}</p>
            </div>
            <div className="keyword">
              <span><s>{aptDtl.regYear}</s>년</span>
              {aptDtl.dpsAmt && aptDtl.dpsAmt != '-' && <span>{numberReplaceWithStag(numberWithCounts(aptDtl.dpsAmt * 10000))}</span>}
              <span><s>{numberWithCommas(aptDtl.hhldCnt)}</s>세대</span>
              {
                (aptDtl) &&
                  (aptDtl.loanRateCase == 1 || aptDtl.loanRateCase == 2) ?
                  <span>투기 과열</span>
                  :
                  (aptDtl.loanRateCase == 3 || aptDtl.loanRateCase == 4) ?
                    <span>조정 지역</span>
                    :
                    null
              }
            </div>
          </div>
        </div>
        <div className="content_list">
          <div className="aptinfo_area">
            <div className="scroll_area mCustomScrollbar" data-mcs-theme="minimal">
              <div className="scroll">
                <div className="info_list">
                  <div className="info_tit _on">
                    <p>최근 실거래가 정보</p><i></i>
                  </div>
                  <div className="info_con" style={{ "display": "block" }}>
                    <table>
                      <caption>
                        <strong>아파트 거래가 목록</strong>

                        <p>최근 거래 금액부터 일자 등 거래 관련 목록</p>
                      </caption>
                      <colgroup>
                        <col style={{ "width": "50px" }} />
                        <col style={{ "width": "100px" }} />
                      </colgroup>
                      <tbody>
                        <tr>
                          <th className="_selcet_top">최근 거래 평형</th>
                          <td className="calc_form">
                            <div className="ip_wrap select_apt in">
                              <button className="btn_select"></button>
                              <div className="select_wrap mCustomScrollbar" data-mcs-theme="minimal">
                                <div className="con">
                                  <ul>
                                    {
                                      aptDtl.aptAreaList &&
                                      aptDtl.aptAreaList.map((result, idx) => {
                                        return (
                                          <React.Fragment key={idx}>
                                            <li onClick={() => this.props.onChangeArea(aptDtl.aptId, result.aptAreaId, trxDr)}>
                                              <span><s>{result.pubAreaPy}</s>평 (<s>{result.pubArea}</s>㎡)</span>
                                            </li>
                                          </React.Fragment>
                                        )
                                      })
                                    }
                                  </ul>
                                </div>
                              </div>
                            </div>
                          </td>
                        </tr>
                        {
                          aptArea &&
                          <>
                            {
                              aptArea.trxDate && aptArea.trxDate != '-' &&
                              <tr>
                                <th>최근 거래일</th>
                                <td>{dateReplaceWithStag(aptArea.trxDate)}</td>
                              </tr>
                            }
                            {
                              aptArea.dpsAmt && aptArea.dpsAmt != '-' &&
                              <tr className="_ty1">
                                <th>최근 거래 금액</th>
                                <td className={aptArea.prevDpsAmt === null ? null : (aptArea.dpsAmt * 10000) > (aptArea.prevDpsAmt * 10000) ? '_price_up' : '_price_down'}>{numberReplaceWithStag(numberWithCounts(aptArea.dpsAmt * 10000))}
                                  <span>{
                                    aptArea.prevDpsAmt === null ? null :
                                      (aptArea.dpsAmt * 10000) > (aptArea.prevDpsAmt * 10000) ?
                                        <>( {numberReplaceWithStag(numberWithCounts((aptArea.dpsAmt * 10000) - (aptArea.prevDpsAmt * 10000)))}<em> ▲ </em>)</> :
                                        (aptArea.dpsAmt * 10000) < (aptArea.prevDpsAmt * 10000) ?
                                          <>( {numberReplaceWithStag(numberWithCounts((aptArea.prevDpsAmt * 10000) - (aptArea.dpsAmt * 10000)))}<em> ▼ </em>)</> :
                                          null
                                  }</span></td>
                              </tr>
                            }
                          </>
                        }
                        <tr>
                          <th className="_top">최근 시세</th>
                          <td className="calc_form">
                            <div className="ip_wrap rdo_wrap">
                              <div className="rdo_item">
                                <input className="ip_sale" type="radio" id="rdo05-01" name="rdo05"
                                  checked={trxDr === 'D' ? true : false}
                                  onClick={(e) => { e.preventDefault(); this.props.onChangeArea(aptDtl.aptId, aptArea.aptAreaId, 'D'); }} />
                                <label for="rdo05-01"><i>매매</i></label>
                              </div>
                              <div className="rdo_item">
                                <input className="ip_rent" type="radio" id="rdo05-02" name="rdo05"
                                  checked={trxDr === 'R' ? true : false}
                                  onClick={(e) => { e.preventDefault(); this.props.onChangeArea(aptDtl.aptId, aptArea.aptAreaId, 'R'); }} />
                                <label for="rdo05-02"><i>전·월세</i></label>
                              </div>
                            </div>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                    <div className="chart_list">
                      <ul className="chart_tit">
                        <li data-tab="tab1" className="current"><a href="#" onClick={() => this.fetchGraph('tab1')}>전체 기간</a></li>
                        <li data-tab="tab2"><a href="#" onClick={() => this.fetchGraph('tab2')}>최근 1년</a></li>
                        <li data-tab="tab3"><a href="#" onClick={() => this.fetchGraph('tab3')}>최근 3년</a></li>
                      </ul>
                      {/* <div className="tab_cont current">
                        <div className="tab_inner">
                          <MultiType
                            graphList={graphList}
                          ></MultiType>
                        </div>
                      </div> */}
                      <div className="tab_cont current">
                        <div className="tab_inner" >
                          <div id="rMateDivAptDtl" style={{ "width": "310px", "height": "260px" }} className={!graphList || graphList.length <= 0 ? 'tab_inner dim' : ''}>
                            {
                              // onRenderGraph && <RMateCandleStickType parentDivId={'rMateDivAptDtl'} graphList={graphList} onRenderGraph={onRenderGraph} />
                              onRenderGraph && <RMateChartH5 chartId={'rMateDivAptDtl'} layout={'COMBINATION_2D_CHART_1'} data={graphList} chartVars={''} width={'100%'} height={'100%'} />
                            }
                          </div>
                        </div>
                      </div>
                    </div>
                    {
                      onRenderGraph && graphList && graphList.length > 0 &&
                      <>
                        <div className="table_trade">
                          <table className="tb">
                            <caption>
                              <strong>거래정보</strong>
                              <p>해당 아파트 거래에 관련된 정보 테이블</p>
                            </caption>
                            <colgroup>
                              <col style={{ "width": "26%;" }} />
                              <col style={{ "width": "auto;" }} />
                              <col style={{ "width": "auto;" }} />
                              <col style={{ "width": "18%;" }} />
                            </colgroup>
                            <thead>
                              <tr>
                                <th>거래일</th>
                                <th>실거래가</th>
                                <th>타입</th>
                                <th>층</th>
                              </tr>
                            </thead>
                            <tbody>
                              {
                                orderBy(trxList, ["trxDate"], ["desc"]).map((v, index) => {
                                  return (
                                    index < trxLimitSize &&
                                    <tr>
                                      <td>{dateFormatMsg(v.trxDate)}</td>
                                      <td>{numberWithCounts(v.dpsAmt * 10000)}</td>
                                      <td>{(aptDtl.aptAreaList.find(x => x.aptAreaId == v.aptAreaId)).pubAreaPy}평({(aptDtl.aptAreaList.find(x => x.aptAreaId == v.aptAreaId)).pubArea}㎡)</td>
                                      <td>{v.flrNo}층</td>
                                    </tr>
                                  )
                                })
                              }
                            </tbody>
                          </table>
                          {graphList && trxList.length > 10 && trxLimitSize != trxList.length &&
                            <div className="btn_area">
                              <button type="button" className="btn" onClick={() => onTrxLimitSize(trxList.length)}>더보기</button>
                            </div>
                          }
                        </div>
                      </>
                    }
                  </div>
                </div>
              </div>
              <div className="info_list">
                <div className="info_tit _on">
                  <p>단지정보</p><i></i>
                </div>
                <div className="info_con apt_info" style={{ "display": "block" }}>
                  <table>
                    <caption>
                      <strong>아파트단지 정보 리스트</strong>
                      <p>주소, 건축년도 및 아파트 정보 리스트</p>
                    </caption>
                    <colgroup>
                      <col style={{ "width": "50px" }} />
                      <col style={{ "width": "100px" }} />
                    </colgroup>
                    <tbody>
                      <tr>
                        <th>아파트명</th>
                        <td>{aptDtl.aptName}</td>
                      </tr>
                      <tr>
                        <th>주소</th>
                        <td>{aptDtl.jibunAddr}</td>
                      </tr>
                      <tr>
                        <th>건축년도</th>
                        <td>{aptDtl.regYear}년</td>
                      </tr>
                      <tr>
                        <th>세대수</th>
                        <td>{numberWithCommas(aptDtl.hhldCnt)}세대</td>
                      </tr>
                      <tr>
                        <th>층수</th>
                        <td>{aptDtl.minFlrCnt === aptDtl.maxFlrCnt ? <>{aptDtl.minFlrCnt}</> : <>{aptDtl.minFlrCnt} ~ {aptDtl.maxFlrCnt}</>}층</td>
                      </tr>
                      <tr>
                        <th>주차대수</th>
                        <td>총 {numberWithCommas(aptDtl.pkngCnt)}대</td>
                      </tr>
                      <tr>
                        <th>용적률</th>
                        <td>{aptDtl.vlRate}%</td>
                      </tr>
                      <tr>
                        <th>건폐율</th>
                        <td>{aptDtl.bcRate}%</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
              <div className="info_list">
                <div className="info_tit _on">
                  <p>주변정보</p><i></i>
                </div>
                <div className="info_con apt_around" style={{ "display": "block" }}>
                  <ul className="amenities">
                    {
                      (aptDtl.stationList != null && aptDtl.stationList.length > 0) ?
                        aptDtl.stationList &&
                        <li className={aptDtl.stationList.length > 3 ? "amenities_items more" : "amenities_items"}>
                          <strong className="ico_subway">지하철역</strong>
                          <ul className="info">
                            {
                              orderBy(aptDtl.stationList, ["distance"], ["asc"]).map((result, idx) => {
                                return (
                                  //idx < 3 && (    
                                  <li className={idx > 2 ? "hide" : ""}>
                                    <div className="name" onClick={() => this.props.onPanTo({ lat: result.lat, lng: result.lng })}><span>{result.locName}</span><s>({numberWithCommas(result.distance)}m)</s></div>
                                    <b className="time">도보 {parseInt(result.distance / 66, 10)} 분</b>
                                  </li>
                                  //)
                                )
                              })
                            }
                          </ul>
                          {aptDtl.stationList.length > 3 && <i className="ico_more" onClick={this.moreView}></i>}
                        </li>
                        :
                        null
                    }
                    {
                      (aptDtl.schoolList != null && aptDtl.schoolList.length > 0) ?
                        aptDtl.schoolList &&
                        <li className={aptDtl.schoolList.length > 3 ? "amenities_items more" : "amenities_items"}>
                          <strong className="ico_school">학교</strong>
                          <ul className="info">
                            {
                              orderBy(aptDtl.schoolList, ["distance"], ["asc"]).map((result, idx) => {
                                return (
                                  //idx < 3 && (
                                  <li className={idx > 2 ? "hide" : ""}>
                                    <div className="name" onClick={() => this.props.onPanTo({ lat: result.lat, lng: result.lng })}><span>{result.locName}</span><s>({numberWithCommas(result.distance)}m)</s></div>
                                    <b className="time">도보 {parseInt(result.distance / 66, 10)} 분</b>
                                  </li>
                                  //)
                                )
                              })
                            }
                          </ul>
                          {aptDtl.schoolList.length > 3 && <i className="ico_more" onClick={this.moreView}></i>}
                        </li>
                        :
                        null
                    }
                    {
                      (aptDtl.departList != null && aptDtl.departList.length > 0) ?
                        aptDtl.departList &&
                        <li className={aptDtl.departList.length > 3 ? "amenities_items more" : "amenities_items"}>
                          <strong className="ico_mart">마트 / 백화점</strong>
                          <ul className="info">
                            {
                              orderBy(aptDtl.departList, ["distance"], ["asc"]).map((result, idx) => {
                                return (
                                  //idx < 3 && (
                                  <li className={idx > 2 ? "hide" : ""}>
                                    <div className="name" onClick={() => this.props.onPanTo({ lat: result.lat, lng: result.lng })}><span>{result.locName}</span><s>({numberWithCommas(result.distance)}m)</s></div>
                                    <b className="time">도보 {parseInt(result.distance / 66, 10)} 분</b>
                                  </li>
                                  //)
                                )
                              })
                            }
                          </ul>
                          {aptDtl.departList.length > 3 && <i className="ico_more" onClick={this.moreView}></i>}
                        </li>
                        :
                        null
                    }
                    {
                      (aptDtl.hospitalList != null && aptDtl.hospitalList.length > 0) ?
                        aptDtl.hospitalList &&
                        <li className={aptDtl.hospitalList.length > 3 ? "amenities_items more" : "amenities_items"}>
                          <strong className="ico_hospital">병원</strong>
                          <ul className="info">
                            {
                              orderBy(aptDtl.hospitalList, ["distance"], ["asc"]).map((result, idx) => {
                                return (
                                  //idx < 3 && (
                                  <li className={idx > 2 ? "hide" : ""}>
                                    <div className="name" onClick={() => this.props.onPanTo({ lat: result.lat, lng: result.lng })}><span>{result.locName}</span><s>({numberWithCommas(result.distance)}m)</s></div>
                                    <b className="time">도보 {parseInt(result.distance / 66, 10)} 분</b>
                                  </li>
                                  //)
                                )
                              })
                            }
                          </ul>
                          {aptDtl.hospitalList.length > 3 && <i className="ico_more" onClick={this.moreView}></i>}
                        </li>
                        :
                        null
                    }
                    {
                      (aptDtl.parkList != null && aptDtl.parkList.length > 0) ?
                        aptDtl.parkList &&
                        <li className={aptDtl.parkList.length > 3 ? "amenities_items more" : "amenities_items"}>
                          <strong className="ico_park">공원</strong>
                          <ul className="info">
                            {
                              orderBy(aptDtl.parkList, ["distance"], ["asc"]).map((result, idx) => {
                                return (
                                  //idx < 3 && (
                                  <li className={idx > 2 ? "hide" : ""}>
                                    <div className="name" onClick={() => this.props.onPanTo({ lat: result.lat, lng: result.lng })}><span>{result.locName}</span><s>({numberWithCommas(result.distance)}m)</s></div>
                                    <b className="time">도보 {parseInt(result.distance / 66, 10)} 분</b>
                                  </li>
                                  //)
                                )
                              })
                            }
                          </ul>
                          {aptDtl.parkList.length > 3 && <i className="ico_more" onClick={this.moreView}></i>}
                        </li>
                        :
                        null
                    }
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>



    );
  }
}
export default MapAptDtl;