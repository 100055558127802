export const R_MATE_CHART_H5_LAYOUT = Object.freeze({
  COMBINATION_2D_CHART_1: `<rMateChart backgroundColor="#FFFFFF" borderStyle="none">
                      <Options>
                          <Legend color="#999" borderStyle="none" right="0" useVisibleCheck="true"/>
                      </Options>
                      <CurrencyFormatter id="currencyFmt" currencySymbol="억" alignSymbol="right"/>
                      <Combination2DChart dataTipBorderColorOnSeries="false" dataTipStyleName="dataTipStyle" showDataTips="true" dataTipMode="single" dataTipJsFunction="tipInfo">
                          <horizontalAxis><Category$^{linear}Axis id="actYearMonth" categoryField="actYearMonth" padding="0.5"/></horizontalAxis>
                          <verticalAxis><LinearAxis id="vAxisActCnt" minimum="$^{minimumactCnt}" maximum="$^{maximumactCnt}" formatter="{currencyFmt}"/></verticalAxis>
                          <series>
                              <Column2DSet columnWidthRatio="0.3" dataTipBackgroundColorOnSeries="false" color="#222" fontWeight="bold" type="stacked" showTotalLabel="true" totalLabelJsFunction="totalLabelFunc" >
                                  <series>
                                      <Column2DSeries itemRollOverColor="#d8d8d8" yField="actCnt" displayName="거래량">
                                          <fill><SolidColor color="#d8d8d8"/></fill>
                                      </Column2DSeries>
                                  </series>
                              </Column2DSet>
                              <Line2DSeries dataTipBorderColorOnSeries="false" dataTipBorderColor="#222" itemRollOverColor="#fff" radius="5" yField="maxDpsAmt" displayName="매매최대가" itemRenderer="CircleItemRenderer">
                                  <verticalAxis><LinearAxis id="vAxisMaxDpsAmt" minimum="$^{minimummaxDpsAmt}" maximum="$^{maximummaxDpsAmt}" formatter="{currencyFmt}"/></verticalAxis>
                                  <lineStroke><Stroke color="#24c3b6" weight="2"/></lineStroke>
                                  <fills>
                                      <SolidColor color="#fff"/>
                                  </fills>
                                  <stroke><Stroke color="#24c3b6" weight="2"/></stroke>
                              </Line2DSeries>
                          </series>
                          <verticalAxisRenderers><Axis2DRenderer axis="{vAxisMaxDpsAmt}" showLine="false"/></verticalAxisRenderers>
                          <horizontalAxisRenderers><$^{renderer}Renderer axis="{actYearMonth}" visibleItemSize="$^{visibleItemSize}"/></horizontalAxisRenderers>
                      </Combination2DChart>
                  </rMateChart>`,
  COMBINATION_2D_CHART_2: `<rMateChart backgroundColor="#FFFFFF" borderStyle="none">
                      <Options>
                          <Legend color="#999" borderStyle="none" right="0" useVisibleCheck="true"/>
                      </Options>
                      <NumberFormatter id="numberFmt" useThousandsSeparator="true"/>
                      <Combination2DChart dataTipBorderColorOnSeries="false" dataTipStyleName="dataTipStyle" showDataTips="true" dataTipMode="single" dataTipJsFunction="tipPredict">
                          <horizontalAxis><Category$^{linear}Axis id="notiDate" categoryField="notiDate" padding="0.5"/></horizontalAxis>
                          <verticalAxis><LinearAxis id="vAxisTotalReqCnt" minimum="$^{minimumtotalReqCnt}" maximum="$^{maximumtotalReqCnt}" formatter="{numberFmt}"/></verticalAxis>
                          <series>
                              <Column2DSeries columnWidthRatio="0.3" dataTipBackgroundColorOnSeries="false" color="#222" fontWeight="bold" type="stacked" itemRollOverColor="#d8d8d8" yField="totalReqCnt" displayName="신청건수">
                                  <fill><SolidColor color="#d8d8d8"/></fill>
                              </Column2DSeries>
                              <Line2DSeries dataTipBorderColorOnSeries="false" dataTipBorderColor="#222" itemRollOverColor="#fff" radius="5" yField="totalGeCnt" displayName="당첨건수" itemRenderer="CircleItemRenderer">
                                  <verticalAxis><LinearAxis id="vAxisTotalGeCnt" minimum="$^{minimumtotalReqCnt}" maximum="$^{maximumtotalReqCnt}" formatter="{numberFmt}"/></verticalAxis>
                                  <lineStroke><Stroke color="#24c3b6" weight="2"/></lineStroke>
                                  <fills>
                                      <SolidColor color="#fff"/>
                                  </fills>
                                  <stroke><Stroke color="#24c3b6" weight="2"/></stroke>
                              </Line2DSeries>
                          </series>
                          <verticalAxisRenderers><Axis2DRenderer axis="{vAxisTotalReqCnt}" showLine="false"/></verticalAxisRenderers>
                          <horizontalAxisRenderers><$^{renderer}Renderer axis="{notiDate}" visibleItemSize="$^{visibleItemSize}"/></horizontalAxisRenderers>
                      </Combination2DChart>
                  </rMateChart>`,
  LINE_2D_CHART_1: `<rMateChart backgroundColor="#FFFFFF" borderStyle="none">
                     <Options>
                        <Legend color="#999" borderStyle="none" right="0" useVisibleCheck="true"/>
                     </Options>
                     <NumberFormatter id="numberFmt" useThousandsSeparator="true"/>
                     <Line2DChart dataTipBorderColorOnSeries="false" dataTipStyleName="dataTipStyle" showDataTips="true" dataTipMode="single" dataTipJsFunction="tipTrxDate">
                        <horizontalAxis><Category$^{linear}Axis id="trxDateMonthly" categoryField="trxDateMonthly" padding="0.5"/></horizontalAxis>
                        <verticalAxis><LinearAxis id="vAxisTrxDateMonthlyCnt" minimum="$^{minimumtrxDateMonthlyCnt}" maximum="$^{maximumtrxDateMonthlyCnt}" formatter="{numberFmt}"/></verticalAxis>
                        <series>
                            <Line2DSeries form="curve" dataTipBorderColorOnSeries="false" dataTipBorderColor="#222" itemRollOverColor="#fff" radius="5" yField="trxDateMonthlyCnt" displayName="거래량" itemRenderer="CircleItemRenderer">                          
                                <lineStroke><Stroke color="rgb(94, 206, 197)" weight="2"/></lineStroke>
                                <fills><SolidColor color="#fff"/></fills>
                                <stroke><Stroke color="rgb(94, 206, 197)" weight="2"/></stroke>
                            </Line2DSeries>
                        </series>
                         <verticalAxisRenderers><Axis2DRenderer axis="{vAxisTrxDateMonthlyCnt}" showLine="false"/></verticalAxisRenderers>
                        <horizontalAxisRenderers><$^{renderer}Renderer axis="{trxDateMonthly}" visibleItemSize="$^{visibleItemSize}"/></horizontalAxisRenderers>
                     </Line2DChart>
                  </rMateChart>`
});