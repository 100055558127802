import React, { Component } from 'react';
import Moment from 'moment';
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import RMateAreaLineCurveType from 'routes/rMateChart/RMateAreaLineCurveType';

//// 컴포넌트 사용처 : 청약 당첨예상 상세
class PrevPoint extends Component {


  render() {

    const { applyInfo, prevPoint } = this.props.calcResult;

    const prevPointResult = prevPoint.length > 0 ? prevPoint : [{ notiDate: applyInfo.notiDate, minScore: 0, maxScore: 0 }];

    const maxOptions = {
      chart: {
        type: 'areaspline'
      },
      title: {
        text: ''
      },
      xAxis: {
        categories: prevPointResult.map(v => Moment(v.notiDate).format('YY. MM'))
      },
      yAxis: {
        title: {
          text: ''
        },
      },
      plotOptions: {

        areaspline: {
          fillColor: {
            linearGradient: [0, 0, 0, 300],
            stops: [
              [0, 'rgb(94,206,197)'],
              [1, Highcharts.color('rgb(94,206,197)').setOpacity(0).get('rgba')]
            ]
          },
          marker: {
            enabled: false
          },
          lineWidth: 4

        },
      },
      series: [{
        data: prevPointResult.map(v => v.maxScore),
        color: 'rgb(94, 206, 197)'
      }],
      legend: {
        enabled: false
      }
    }

    const minOptions = {
      chart: {
        type: 'spline'
      },
      title: {
        text: ''
      },
      xAxis: {
        categories: prevPointResult.map(v => Moment(v.notiDate).format('YY. MM'))
      },
      yAxis: {
        title: {
          text: ''
        },
      },
      plotOptions: {
        spline: {
          marker: {
            enabled: false
          },
          lineWidth: 4
        }
      },
      series: [{
        data: prevPointResult.map(v => v.minScore),
        color: 'rgb(252, 77, 64)'
      }],
      legend: {
        enabled: false
      }
    }

    const minData = prevPointResult.filter((v, idx) => idx === 0)[0];
    const maxData = prevPointResult.filter((v, idx) => idx === prevPointResult.length - 1)[0];

    let minGraphList = new Array();
    let maxGraphList = new Array();

    let best = Math.max.apply(null, prevPointResult.map(v => v["maxScore"]));
    let lowest = Math.min.apply(null, prevPointResult.map(v => v["minScore"]));

    prevPointResult.map(v => {
      const yyyy = v.notiDate.substr(2, 2).replace('-', '');
      const mm = v.notiDate.substr(4, 2).replace('-', '');

      const targetDateStr = `${yyyy}. ${mm}`;
      minGraphList.push({ 'notiDate': targetDateStr, 'minScore': v.minScore, 'counts': '점', 'lowest': lowest });
    });

    prevPointResult.map(v => {
      const yyyy = v.notiDate.substr(2, 2).replace('-', '');
      const mm = v.notiDate.substr(4, 2).replace('-', '');

      const targetDateStr = `${yyyy}. ${mm}`;
      maxGraphList.push({ 'notiDate': targetDateStr, 'maxScore': v.maxScore, 'counts': '점', 'best': best });
    });

    return (
      <div className="sec" id="sec10" >
        <strong>
          과거 청약 당첨가점
        <div className="q_mark bt">
            <div className="q_mark_inner">
              <p className="tit">과거 청약 당첨가점이란?</p>
              <p className="txt">
                청약아파트가 위치하는 지역에서 진행되었었던, 과거 청약 아파트들의 당첨가점을 안내해드려요.<br />
                해당 지역의 청약 당첨가점이 상승중인지, 하락중인지 확인하여 청약 신청에 도움을 드려요.
            </p>
            </div>
          </div>
        </strong>
        <div className="ty_graph best">
          <div>
            <div className="graph">
              <div className="dount_area">
                <div className="circle_chart full" data-percent="100" data-permax="100">
                  <div className="chart-box">
                    <svg>
                      <defs>
                        <linearGradient id="dount_best" x1="0" y1="0" x2="1" y2="1">
                          <stop className="color_1" offset="0%" />
                          <stop className="color_2" offset="100%" />
                        </linearGradient>
                      </defs>
                      <circle stroke="url(#dount_best)" r="50%" cx="50%" cy="50%" className="circle_progress" stroke-width="15" stroke-linecap="round"></circle>
                    </svg>
                    <div className="per-point">
                      <span><s>{Math.abs(maxData.maxScore - minData.maxScore)}</s>점{maxData.maxScore > minData.maxScore ? '상승' : '하락'}</span><p>최고가점</p>
                    </div>
                  </div>
                </div>
              </div>
              <p className="noti">* {Moment(minData.notiDate).format('YY년 MM월')} 대비</p>
            </div>
          </div>
          <div>
            <div id="rMateDiv3_1" className="chart" style={{ "width": "760px", "height": "400px" }}>
              {
                this.props.onRenderGraph &&
                <RMateAreaLineCurveType
                  parentDivId={'rMateDiv3_1'}
                  graphList={maxGraphList}
                ></RMateAreaLineCurveType>
              }
            </div>
          </div>
        </div>
        <div className="ty_graph bad">
          <div>
            <div className="graph">
              <div className="dount_area">
                <div className="circle_chart full" data-percent="100" data-permax="100">
                  <div className="chart-box">
                    <svg>
                      <defs>
                        <linearGradient id="dount_bad" x1="0" y1="0" x2="1" y2="1">
                          <stop className="color_1" offset="0%" />
                          <stop className="color_2" offset="100%" />
                        </linearGradient>
                      </defs>
                      <circle stroke="url(#dount_bad)" r="50%" cx="50%" cy="50%" className="circle_progress" stroke-width="15" stroke-linecap="round"></circle>
                    </svg>
                    <div className="per-point"><span><s>{Math.abs(maxData.minScore - minData.minScore)}</s>점{maxData.minScore > minData.minScore ? '상승' : '하락'}</span><p>최저가점</p></div>
                  </div>
                </div>
              </div>
              <p className="noti">* {Moment(minData.notiDate).format('YY년 MM월')} 대비</p>
            </div>
          </div>
          <div>
            <div id="rMateDiv3_2" className="chart" style={{ "width": "760px", "height": "400px" }}>
              {
                this.props.onRenderGraph &&
                < RMateAreaLineCurveType
                  parentDivId={'rMateDiv3_2'}
                  graphList={minGraphList}
                ></RMateAreaLineCurveType>
              }
            </div>
          </div>
        </div>
      </div >
    );
  }
}

export default PrevPoint;
