import React, { Component } from 'react';
import { orderBy } from 'lodash';
import { numberWithCounts, numberWithCommas, numberWithCommas2, dateFormatMsg } from 'common/StringUtil';

//// 컴포넌트 사용처 : 청약 정보 즐겨찾기
class MapApplyFav extends Component {
  render() {
    ////console.log(this.props.favResult);

    return (
      <div className="list_area bookmark">
        <div className="scroll_area mCustomScrollbar" data-mcs-theme="minimal">
          <ul>
            <li className="bookmark_tit">
              <strong>분양정보 즐겨찾기</strong>
            </li>
            {
              this.props.favResult.length > 0 ?
                this.props.favResult.map(v => {

                  const numbers = (v.maxApplyPrice * 10000).toString().replace(/\B(?=(\d{4})+(?!\d))/g, ",").split(',');
                  let number1 = '';
                  let number2 = '';

                  number1 = numbers[0].toString().replace(/(^0+)/, "");
                  if (numbers.length > 1) {
                    number2 = numbers[1].toString().replace(/(^0+)/, "");
                  }

                  const amtStr = (v.maxApplyPrice) > 10000 ? <><s>{number1}</s> 억 <s>{number2}</s></> : <><s>{number1}</s></>;

                  return <li className="apt_item">
                    <button type="button" className="btn_apt" onClick={(e) => this.props.onMoveDtl(e, v.applyId)}><i>청약정보 리스트</i></button>
                    <div className="item_info">
                      <i className="ico_close" onClick={() => this.props.onClearFav(v)}></i>
                      <h4>{v.aptName}</h4>
                      <p class="address">{v.address}</p>
                      <p class="price">{amtStr}</p>
                      <p>
                        {dateFormatMsg(v.notiDate)} / {numberWithCommas(v.applyCnt)}세대
                        {
                          (v) &&
                            (v.loanRateCase == 1 || v.loanRateCase == 2) ?
                            ' / 투기 과열'
                            :
                            (v.loanRateCase == 3 || v.loanRateCase == 4) ?
                              ' / 조정 지역'
                              :
                              null
                        }
                      </p>
                    </div>
                  </li>
                }
                )
                :
                <li className="info_none">
                  <div className="img_area">
                    <figure>
                      <img src={process.env.REACT_APP_ENV_URL + "resources/images/info_none_2.png"} alt="noti_img" />
                    </figure>
                    <p>아직 즐겨찾기된 아파트가 없어요</p>
                  </div>
                </li>
            }
          </ul>
        </div>
      </div>
    );
  }
}
export default MapApplyFav;