const USER_TOKEN = "user/USER_TOKEN"

export const setToken = (token) => {
    return {
        type: USER_TOKEN,
        token
    }
}

const initialState = {
    token : '',
}

function reducer(state = initialState, action) {
    switch(action.type) {
        case USER_TOKEN:
            return {
                ...state,
                token: action.token
            }
        default:
            return state
    }
}

export default reducer;